<template>
  <div>
    <ul
      style="
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: flex-end;
      "
      v-if="!props.search"
    >
      <el-button
        type="primary"
        icon="Plus"
        link
        @click="
          delete state.selectId;
          state.showCompile = true;
        "
      >
        添加
      </el-button>
    </ul>
    <el-table :data="state.tabData">
      <el-table-column type="index" label="#"> </el-table-column>
      <el-table-column
        prop="Bh"
        label="合同编号"
        :formatter="$tab_TextFormat"
      />
      <el-table-column prop="Zt" label="状态" :formatter="$tab_StatusFormat" />
      <el-table-column
        prop="CjrXm"
        label="操作员"
        :formatter="$tab_TextFormat"
      />
      <el-table-column
        prop="Cjsj"
        label="操作时间"
        :formatter="$tab_TextFormat"
      />
      <el-table-column label="操作" width="700" fixed="right">
        <template #default="{ row }">
          <el-button
            type="primary"
            :icon="Download"
            link
            v-if="row.Bh"
            @click="onDownLoad(row.Wjdz)"
            >下载</el-button
          >
          <template v-if="!props.search">
            <template v-if="row.Zt.Key != 4 && row.Zt.Key != 5">
              <el-button
                type="primary"
                :icon="Upload"
                link
                @click="onShenBao(row.Id)"
                v-if="row.Zt.Key == 0"
              >
                申报
              </el-button>
              <el-button
                type="warning"
                :icon="Edit"
                link
                @click="
                  state.selectId = row.Id;
                  state.showCompile = true;
                "
                v-if="row.Zt.Key == 0"
              >
                变更
              </el-button>
              <el-button
                type="danger"
                :icon="Delete"
                link
                @click="onDelete(row.Id)"
              >
                作废
              </el-button>
            </template>
            <el-button
              type="warning"
              :icon="Promotion"
              link
              @click="onResend(row.Id)"
              v-if="row.Zt.Key == 1"
            >
              重发短信
            </el-button>
            <template v-if="row.Zt.Key == 1 || row.Zt.Key == 2">
              <el-button
                type="primary"
                :icon="CopyDocument"
                link
                @click="onCopy(row.Qslj)"
              >
                复制链接
              </el-button>
              <el-button
                type="primary"
                :icon="Grid"
                link
                @click="onBuildQrCode(row)"
              >
                二维码
              </el-button>
            </template>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="(state.selectId ? '变更' : '添加') + '合同'"
        @close="state.showCompile = false"
      >
        <c_LvYouDingDanHeTongCompile
          @close="
            state.showCompile = false;
            onLoad();
          "
          :ddId="props.id"
          :id="state.selectId"
          :model="props.model"
        ></c_LvYouDingDanHeTongCompile>
      </c_popup>
    </transition>
    <div
      v-show="state.showQrCode"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 9999;
      "
      @click="state.showQrCode = false"
    >
      <div
        ref="qrcodeBox"
        style="background-color: #fff; padding: 20px"
        @click.stop=""
      >
        <div ref="qrcodeDom"></div>
        <ul
          style="
            text-align: center;
            font-weight: 600;
            margin-top: 10px;
            font-size: 19px;
            color: rgba(0, 0, 0, 0.8);
          "
        >
          {{
            props.model.Mc
          }}
        </ul>
        <ul
          style="
            text-align: center;
            color: #a2a2a2;
            margin-top: 5px;
            font-size: 15px;
          "
        >
          {{
            state.bh
          }}
        </ul>
        <ul style="text-align: center; color: #a2a2a2; margin-top: 5px">
          (请使用手机件扫一扫签署电子合同)
        </ul>
      </div>
      <ul
        style="
          width: 340px;
          text-decoration: solid;
          text-align: center;
          background-color: #fff;
          padding-bottom: 10px;
        "
      >
        <a href="#" @click="onDownLoadQrCode">下载</a>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_ht_getlist,
  lvyoudingdan_ht_shenbao,
  lvyoudingdan_ht_delete,
  lvyoudingdan_ht_resend,
} from "@/http/api";
import { confirm_func } from "@/util/tFormat";
import { ElMessage } from "element-plus";
import QRCode from "qrcodejs2-fix";
import html2canvas from "html2canvas";

export default {
  props: {
    id: Number,
    model: Object,
    search: Boolean,
  },
  setup(props) {
    const qrcodeBox = ref(null);
    const qrcodeDom = ref(null);
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_ht_getlist(props.id).then((res) => {
        if (res.status == 200) state.tabData = res.data;
      });
    };

    const onShenBao = (id) => {
      confirm_func(
        lvyoudingdan_ht_shenbao,
        id,
        onLoad,
        "您确定提交申报申请吗？"
      );
    };

    const onDelete = (id) => {
      confirm_func(lvyoudingdan_ht_delete, id, onLoad, "您确认作废该合同吗？");
    };

    const onResend = (id) => {
      confirm_func(
        lvyoudingdan_ht_resend,
        id,
        onLoad,
        "您确认要重发签署短信吗？"
      );
    };

    const onDownLoad = (url) => {
      window.open(url);
    };

    const onBuildQrCode = (row) => {
      state.bh = row.Bh;

      qrcodeDom.value.innerHTML = "";
      state.showQrCode = true;
      new QRCode(qrcodeDom.value, {
        text: row.Qslj,
        width: 300, // 二维码图片宽度
        height: 300, // 二维码图片高度
      });
    };

    const onCopy = (value) => {
      try {
        if (navigator.clipboard && window.isSecureContext) {
          // navigator clipboard 向剪贴板写文本
          navigator.clipboard.writeText(value);
        } else {
          // 创建text area
          let textArea = document.createElement("textarea");
          textArea.value = value;
          // 使text area不在viewport，同时设置不可见
          textArea.style.position = "absolute";
          textArea.style.opacity = 0;
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          // 执行复制命令并移除文本框
          document.execCommand("copy");
          textArea.remove();
        }
        ElMessage({
          type: "success",
          message: "已复制",
          grouping: true,
        });
      } catch (err) {
        ElMessage({
          type: "error",
          message: "复制失败",
          grouping: true,
        });
      }
    };

    const onDownLoadQrCode = () => {
      const element = qrcodeBox.value;

      html2canvas(element).then((canvas) => {
        // 创建一个图像元素
        let img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.style.width = "500px"; // 可以设置图片的宽度
        img.style.height = "auto"; // 图片高度将按比例调整

        // 将图片添加到页面上显示
        // 例如，可以将img元素插入到DOM中的某个位置
        document.body.appendChild(img);

        // 如果需要下载图片
        let a = document.createElement("a");
        a.href = img.src;
        a.download = props.model.Mc + "_电子合同二维码.png";
        a.click();
      });
    };

    return {
      state,
      props,
      onShenBao,
      onLoad,
      onDelete,
      onDownLoad,
      onCopy,
      onBuildQrCode,
      onResend,
      qrcodeBox,
      qrcodeDom,
      onDownLoadQrCode,
    };
  },
};
</script>
<style scoped></style>
