<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1; display: flex; align-items: center">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%; margin-right: 10px"
          placeholder="退款单号、订单编号、订单名称"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-radio-group
          v-model="state.params.Params.IsCheck"
          style="margin-right: 10px"
          @change="onLoad()"
        >
          <el-radio-button>全部</el-radio-button>
          <el-radio-button :label="true">已审核</el-radio-button>
          <el-radio-button :label="false">待审核</el-radio-button>
        </el-radio-group>
        <el-date-picker
          v-model="state.params.Params.Ftsjs"
          type="daterange"
          start-placeholder="--发团开始时间--"
          end-placeholder="--发团截止时间--"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD 00:00:00"
          style="flex-grow: unset"
        />
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin: 0 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          sortable="Bh"
          label="状态/退款单号"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />
        <el-table-column
          prop="DdBh"
          sortable="DdBh"
          label="订单编号"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          prop="DdMc"
          sortable="DdMc"
          label="订单名称"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          prop="Je"
          sortable="Je"
          label="退款金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Bz"
          label="退款备注"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          label="操作"
          width="100"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="warning"
              :icon="Operation"
              link
              v-if="row.ShowCheck"
              @click="
                state.selectId = row.Id;
                state.showCheck = true;
              "
              >审核
            </el-button>
            <el-button
              type="primary"
              :icon="Search"
              link
              v-else
              @click="
                state.selectId = row.Id;
                state.showDetail = true;
              "
              >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCheck"
        title="退款审核"
        @close="state.showCheck = false"
      >
        <c_LvYouDingDanTuiKuanCheck
          @close="
            state.showCheck = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_LvYouDingDanTuiKuanCheck>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showDetail"
        title="退款单详情"
        @close="state.showDetail = false"
      >
        <c_LvYouDingDanTuiKuanDetail
          :id="state.selectId"
        ></c_LvYouDingDanTuiKuanDetail>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  lvyoudingdan_getskpage,
  lvyoudingdan_getztselect,
  lvyoudingdan_export,
} from "@/http/api";
import { useRouter } from "vue-router";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: {
      Lx: 2,
      IsCheck: false,
    },
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive(new InitFormData());

    onMounted(() => {
      lvyoudingdan_getztselect().then((res) => {
        if (res.status == 200) state.ztData = res.data;
      });

      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      lvyoudingdan_getskpage(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = ({ column }) => {
      if (column.order) {
        state.params.Order = column.sortable;
        state.params.Asc = column.order === "ascending";
      } else {
        const { params } = new InitFormData();
        state.params.Order = params.Order;
        state.params.Asc = params.Asc;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    const onExport = () => {
      lvyoudingdan_export(state.params).then((res) => {
        if (res.status == 200) {
          console.log(res);
          //通过后台返回 的word文件流设置文件名并下载
          var blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = "旅游订单表.xlsx"; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
      onExport,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
