<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
    "
    ref="boxDom"
  >
    <div class="kehu_gr_box">
      <el-button
        type="primary"
        :icon="Edit"
        link
        @click="state.showCompile = true"
        style="position: absolute; top: 10px; right: 10px"
      >
        编辑
      </el-button>
      <div class="info">
        <div style="display: flex; align-items: flex-start">
          <div style="flex: 2; font-size: 18px; font-weight: 600">
            {{ state.model.Mc
            }}<text v-if="state.model.Jc">{{
              "【" + state.model.Jc + "】"
            }}</text>
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <text class="title">联系人：</text>
            {{ state.model.Lxr || "-" }}
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <text class="title">联系电话：</text>
            {{ state.model.Sjhm || "-" }}
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <div
            style="flex: 2; display: flex; align-items: center; color: #a2a2a2"
          >
            <text style="color: #1779de" v-if="state.model.IsQy">[启用]</text>
            <text style="color: #f00" v-else>[停用]</text>
            <text style="margin-left: 10px">{{ state.model.Bh }}</text>
            <text style="margin-left: 10px">个人客户</text>
            <li style="margin-left: 10px">
              <el-icon v-if="state.model.Xb == 1" color="#1E90FF"
                ><Male
              /></el-icon>
              <el-icon v-else-if="state.model.Xb == 2" color="#f00"
                ><Female
              /></el-icon>
            </li>
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <text class="title">客户经理：</text>
            <text v-if="state.model.JlBmMc">
              {{ "【" + state.model.JlBmMc + "】" }}
            </text>
            {{ state.model.JlXm || "-" }}
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <text class="title">生日：</text>
            {{ state.model.Sr || "-" }}
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <div style="flex: 2; display: flex; align-items: center">
            <text class="title">QQ号：</text>
            {{ state.model.Qq || "-" }}
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <text class="title">微信：</text>
            {{ state.model.Wxh || "-" }}
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <text class="title">微博：</text>
            {{ state.model.Wb || "-" }}
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <div style="flex: 2; display: flex; align-items: center">
            <text class="title">现住地址：</text>
            {{ state.model.Dz || "-" }}
          </div>
          <div style="flex: 2; display: flex; align-items: center">
            <text class="title">备注：</text>
            {{ state.model.Bz || "-" }}
          </div>
        </div>
      </div>
      <div style="flex: 1; display: flex; align-items: center">
        <li
          style="
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 600;
          "
        >
          <div style="font-size: 20px">{{ state.model.Jycs }}</div>
          <text style="color: #a2a2a2; margin-top: 10px">交易次数</text>
        </li>
        <li
          style="
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 600;
          "
        >
          <c_money :value="state.model.Xfje" :size="20"></c_money>
          <text style="color: #a2a2a2; margin-top: 10px">消费金额</text>
        </li>
        <li
          style="
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 600;
          "
        >
          <c_money :value="state.model.Ye" :size="20"></c_money>
          <text style="color: #a2a2a2; margin-top: 10px">账户余额</text>
        </li>
      </div>
    </div>
    <el-tabs
      v-model="state.tabName"
      :before-leave="onTabsChange"
      ref="tabsDom"
      type="card"
      style="margin-top: 10px"
    >
      <el-tab-pane label="商品订单" name="spdd">
        <c_KeHuSpddList
          :khId="props.data?.id"
          v-if="state.tabName == 'spdd'"
        ></c_KeHuSpddList>
      </el-tab-pane>
      <el-tab-pane label="旅游订单" name="lydd">
        <c_KeHuLyddList
          :khId="props.data?.id"
          v-if="state.tabName == 'lydd'"
        ></c_KeHuLyddList>
      </el-tab-pane>
      <el-tab-pane label="资金流水" name="zjls">
        <c_KeHuJyjlList
          :khId="props.data?.id"
          v-if="state.tabName == 'zjls'"
        ></c_KeHuJyjlList>
      </el-tab-pane>
    </el-tabs>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        title="修改个人客户"
        @close="state.showCompile = false"
      >
        <c_KeHuGeRenCompile
          @close="
            state.showCompile = false;
            onLoadDetail();
          "
          :id="props.data?.id"
        ></c_KeHuGeRenCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { kehu_detail } from "@/http/api";
import http from "@/http";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const tabsDom = ref(null);
    const boxDom = ref(null);
    const state = reactive({
      tabName: "spdd",
      model: {},
    });
    onMounted(() => {
      if (props.data?.id) {
        onLoadDetail();
      }
    });

    const onLoadDetail = () => {
      kehu_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          const { model, item } = res.data;
          state.model = model;
          state.item = item;
        }
      });
    };

    return {
      state,
      props,
      onLoadDetail,
      boxDom,
      tabsDom,
      http,
    };
  },
};
</script>
<style scoped lang="less">
.lyddTab {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  margin-top: 10px;
}

.kehu_gr_box {
  box-shadow: 0 0 10px 0 #e2e2e2;
  padding: 10px 20px;
  border-radius: 7px;
  display: flex;
  position: relative;

  .info {
    flex: 3;
    box-shadow: 0 0 0 0;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: rgba(50, 50, 50, 0.9);

    & > div:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .title {
    color: #a2a2a2;
  }
}
</style>
