<template>
  <div style="height: 100%; overflow-y: auto; padding: 10px 20px; z-index: 2">
    <ul style="position: relative; text-align: center">
      <el-image
        :src="require('@/assets/images/logo.png')"
        style="position: absolute; left: 20px; height: 30px; top: 10px"
      />
      <li style="font-size: 24px; font-weight: 600">肥西文旅集团团队行程单</li>
      <li style="font-size: 18px">NO:{{ state.model.Bh }}</li>
      <el-button
        icon="printer"
        style="position: absolute; right: 20px; top: 20px"
        ref="printDom"
        @click="onPrint"
        >打印</el-button
      >
    </ul>
    <div
      style="
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 20px;
      "
    >
      <ul style="border-bottom: 1px solid #e2e2e2; padding: 10px">
        <text style="font-weight: 600">{{ state.model.Mc }}</text>
        <text style="color: #a2a2a2">{{ state.item.Lx?.Text }}</text>
      </ul>
      <ul style="padding: 10px">
        <text style="color: #a2a2a2">出发日期：</text>
        <text>{{ state.item.Ftsj }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">行程天数：</text>
        <text>{{ state.model.Ts + "天" + state.model.Ys + "晚" }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">回团日期：</text>
        <text>{{ state.item.Htsj }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">带团导游：</text>
        <text>{{
          state.dys
            .map((m) => {
              return m.Xm;
            })
            .join("、")
        }}</text>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      行程信息
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      {{ state.model.Xcxx }}
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      费用包含
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      {{ state.model.Fybk }}
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      费用不含
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      {{ state.model.Fybh }}
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      注意事项
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      {{ state.model.Zysx }}
    </div>
  </div>
  <div id="divDom">
    <div
      style="
        height: 100%;
        overflow-y: auto;
        padding: 10px 20px;
        font-size: 30px;
      "
    >
      <ul style="position: relative; text-align: center">
        <el-image
          :src="require('@/assets/images/logo.png')"
          style="position: absolute; left: 20px; height: 50px; top: 20px"
        />
        <li style="font-size: 50px; font-weight: 600">
          肥西文旅集团团队行程单
        </li>
        <li style="font-size: 30px; margin-top: 10px">
          NO:{{ state.model.Bh }}
        </li>
      </ul>
      <div
        style="
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          overflow: hidden;
          margin-top: 20px;
        "
      >
        <ul style="border-bottom: 1px solid #e2e2e2; padding: 10px">
          <text style="font-weight: 600">{{ state.model.Mc }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">{{
            state.item.Lx?.Text
          }}</text>
        </ul>
        <ul style="padding: 10px">
          <text style="color: #a2a2a2">出发日期：</text>
          <text>{{ state.item.Ftsj }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">行程天数：</text>
          <text>{{ state.model.Ts + "天" + state.model.Ys + "晚" }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">回团日期：</text>
          <text>{{ state.item.Htsj }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">带团导游：</text>
          <text>{{
            state.dys
              .map((m) => {
                return m.Xm;
              })
              .join("、")
          }}</text>
        </ul>
      </div>
      <ul style="font-weight: 600; margin: 10px 0">
        行程信息
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 10px;
          min-height: 200px;
        "
      >
        {{ state.model.Xcxx }}
      </div>
      <ul style="font-weight: 600; margin: 10px 0">
        费用包含
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 10px;
          min-height: 200px;
        "
      >
        {{ state.model.Fybk }}
      </div>
      <ul style="font-weight: 600; margin: 10px 0">
        费用不含
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 10px;
          min-height: 200px;
        "
      >
        {{ state.model.Fybh }}
      </div>
      <ul style="font-weight: 600; margin: 10px 0">
        注意事项
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 10px;
          min-height: 200px;
        "
      >
        {{ state.model.Zysx }}
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_detail } from "@/http/api";
import html2canvas from "html2canvas";
import printJS from "print-js";
import { ElLoading } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const state = reactive({
      model: {},
      item: {},
      mxs: [],
      dys: [],
      cgs: [],
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_detail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs, dys, cgs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;
          state.dys = dys;
          state.cgs = cgs;
        }
      });
    };

    //打印
    const onPrint = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("divDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      printJS({
        printable: imgData,
        type: "image",
        style: `@media print { @page {size: auto; margin: 0; } body{margin:0;padding:0}}`,
        imageStyle: "width: 100%; margin:0; padding: 0;",
        documentTitle: "",
      });

      loading.close();
    };

    return { state, onPrint };
  },
};
</script>
<style scoped></style>
