<template>
  <div class="listBox" style="overflow-y: auto">
    <ul v-if="state.model?.Zt?.Key == 0" style="margin-bottom: 10px">
      <el-button type="primary" @click="onConfirm()">确认订单</el-button>
      <el-button type="info" @click="state.showCancelDialog = true">
        取消订单
      </el-button>
    </ul>
    <div class="infoBox">
      <ul>
        <li>
          <div class="title">
            <li>状态</li>
            ：
          </div>
          <div class="content">
            <c_status :model="state.model.Zt"> </c_status>
          </div>
        </li>
        <li>
          <div class="title">
            <li>订单编号</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Bh }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>订单来源</li>
            ：
          </div>
          <div class="content">
            <c_status :model="state.model.Ly"> </c_status>
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>客户信息</li>
            ：
          </div>
          <div class="content">
            {{ state.model.KhMc }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>客户联系人</li>
            ：
          </div>
          <div class="content">
            {{ state.model.KhLxr }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>联系电话</li>
            ：
          </div>
          <div class="content">
            {{ state.model.KhSjhm }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>销售价</li>
            ：
          </div>
          <div class="content">
            <c_money :value="state.model.Xsj" style="color: #f59c23"></c_money>
          </div>
        </li>
        <li>
          <div class="title">
            <li>已收</li>
            ：
          </div>
          <div class="content">
            <c_money :value="state.model.Ysje" style="color: #f59c23"></c_money>
          </div>
        </li>
        <li>
          <div class="title">
            <li>未收</li>
            ：
          </div>
          <div class="content">
            <c_money
              :value="state.model.Xsj - state.model.Ysje"
              style="color: #f59c23"
            ></c_money>
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>结算价</li>
            ：
          </div>
          <div class="content">
            <c_money :value="state.model.Jsj" style="color: #f59c23"></c_money>
          </div>
        </li>
        <li>
          <div class="title">
            <li>已付</li>
            ：
          </div>
          <div class="content">
            <c_money :value="state.model.Yfje" style="color: #f59c23"></c_money>
          </div>
        </li>
        <li>
          <div class="title">
            <li>未付</li>
            ：
          </div>
          <div class="content">
            <c_money
              :value="state.model.Jsj - state.model.Yfje"
              style="color: #f59c23"
            ></c_money>
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>返利</li>
            ：
          </div>
          <div class="content">
            <c_money :value="state.model.Fl" style="color: #f59c23"></c_money>
          </div>
        </li>
        <li style="flex: 2">
          <div class="title">
            <li>下单时间</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Cjsj }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>订单备注</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Bz }}
          </div>
        </li>
      </ul>
    </div>
    <el-tabs
      v-model="state.tabsName"
      style="margin-top: 10px"
      @tab-change="onTabsChange"
    >
      <el-tab-pane label="商品信息" name="mx">
        <c_ShangPinDingDanMxList
          :ddId="props.data?.id"
          :key="state.mxKey"
        ></c_ShangPinDingDanMxList>
      </el-tab-pane>
      <el-tab-pane label="游客名单表" name="yk">
        <c_ShangPinDingDanYkList
          :ddId="props.data?.id"
          :key="state.ykKey"
        ></c_ShangPinDingDanYkList>
      </el-tab-pane>
      <el-tab-pane label="变更申请" name="bgjl">
        <c_ShangPinDingDanBgjlList
          :ddId="props.data?.id"
          :key="state.bgjlKey"
        ></c_ShangPinDingDanBgjlList>
      </el-tab-pane>
      <el-tab-pane label="操作日志" name="rz">
        <c_ShangPinDingDanRzList
          :ddId="props.data?.id"
          :key="state.rzKey"
        ></c_ShangPinDingDanRzList>
      </el-tab-pane>
    </el-tabs>
  </div>
  <el-dialog v-model="state.showCancelDialog" title="取消订单">
    <el-input
      type="textarea"
      v-model="state.qxBz"
      :autosize="{ minRows: 5 }"
      placeholder="请输入取消原因"
    ></el-input>
    <template #footer>
      <el-button type="primary" @click="onCancel()">确认取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  shangpindingdan_detail,
  shangpindingdan_confirm,
  shangpindingdan_cancel,
  shangpindingdanbiangengjilu_listbyddid,
  shangpindingdanbiangengjilu_check,
} from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      model: {},
      tabsName: "mx",
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      shangpindingdan_detail(props.data?.id).then((res) => {
        if (res.status == 200) state.model = res.data;
      });
    };

    const onConfirm = () => {
      shangpindingdan_confirm(props.data?.id).then((res) => {
        ElMessage({
          message: res.data,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: res.status ? "success" : "error",
        });

        if (res.status == 200) {
          onLoad();
        }
      });
    };

    const onCancel = () => {
      if (!state.qxBz) {
        ElMessage({
          message: "请输入取消原因",
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: "error",
        });
        return false;
      }
      shangpindingdan_cancel(props.data?.id, state.qxBz).then((res) => {
        ElMessage({
          message: res.data,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: res.status ? "success" : "error",
        });

        if (res.status == 200) {
          onLoad();
          state.showCancelDialog = false;
        }
      });
    };

    //选项卡切换事件
    const onTabsChange = (value) => {
      if (value == "mx") {
        state.mxKey = Math.random();
      } else if (value == "yk") {
        state.ykKey = Math.random();
      } else if (value == "bgjl") {
        state.bgjlKey = Math.random();
      } else if (value == "rz") {
        state.rzKey = Math.random();
      }
    };

    const onLoadBgjl = () => {
      shangpindingdanbiangengjilu_listbyddid(props.data?.id).then((res) => {
        console.log(res);
        if (res.status == 200) {
          state.bgjlData = res.data;
        }
      });
    };

    //变更记录审核
    const onBgjlCheck = (id, result) => {
      shangpindingdanbiangengjilu_check(id, result).then((res) => {
        ElMessage({
          message: res.data,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: res.status ? "success" : "error",
        });
        if (res.status == 200) {
          onLoad();
          onLoadBgjl();
        }
      });
    };

    return { state, onConfirm, onCancel, onTabsChange, onBgjlCheck, props };
  },
};
</script>
<style lang="less" scoped>
.infoBox {
  .title {
    width: 80px;
  }
}
</style>
