<template>
  <div style="background-color: #fff">
    <div style="position: relative; background-color: #fff; z-index: 99">
      <div style="position: relative; background-color: #fff; z-index: 99">
        <ul
          style="
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: flex-end;
          "
          v-if="!props.search"
        >
          <el-button
            type="primary"
            icon="Download"
            link
            @click="onExportFttzsPdf"
          >
            导出PDF
          </el-button>
          <el-button type="primary" icon="Printer" link @click="onPrintFttzs">
            打印
          </el-button>
          <el-button type="primary" size="small" icon="Plus" @click="onAdd">
            订单增人
          </el-button>
          <el-button type="danger" size="small" icon="Delete" @click="onDelete">
            订单减人
          </el-button>
          <el-button
            type="primary"
            icon="Edit"
            @click="
              delete state.selectId;
              state.showCompile = true;
            "
            size="small"
          >
            编辑名单
          </el-button>
        </ul>
        <el-table :data="state.tabData" style="z-index: 99999" ref="tabDom">
          <el-table-column type="selection" width="55" />
          <el-table-column type="index" label="#" width="60"> </el-table-column>
          <el-table-column prop="Xm" label="游客姓名"> </el-table-column>
          <el-table-column
            prop="Sjhm"
            label="手机号码"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Zjlx"
            label="证件类型"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Zjh"
            label="证件号"
            :formatter="$tab_TextFormat"
          />
          <el-table-column label="性别">
            <template #default="{ row }">
              <text v-if="row.Xb == 1" style="color: #008">男</text>
              <text v-else-if="row.Xb == 2" style="color: #f00">女</text>
              <text v-else>-</text>
            </template>
          </el-table-column>
          <el-table-column
            prop="Csrq"
            label="出生日期"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Bz"
            label="备注"
            :formatter="$tab_TextFormat"
          />
        </el-table>
      </div>
      <c_LvYouDingDanYouKePdf
        :id="props.id"
        :mc="props.mc"
      ></c_LvYouDingDanYouKePdf>
    </div>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        title="编辑游客"
        @close="state.showCompile = false"
        style="position: absolute; top: 0"
      >
        <c_LvYouDingDanYouKeCompile
          @close="
            state.showCompile = false;
            onLoad();
          "
          :ddId="props.id"
        ></c_LvYouDingDanYouKeCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_getyk,
  lvyoudingdan_delyk,
  lvyoudingdan_addyk,
} from "@/http/api";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import printJS from "print-js";

export default {
  props: {
    id: Number,
    search: Boolean,
    mc: String,
  },
  setup(props, { emit }) {
    const tabDom = ref(null);
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_getyk(props.id).then((res) => {
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    };

    const onDelete = () => {
      const ids = tabDom.value.getSelectionRows().map((m) => {
        return m.Id;
      });

      if (ids.length <= 0) {
        ElMessage({
          type: "warning",
          message: "请选择游客",
          grouping: true,
        });
        return false;
      }

      ElMessageBox.confirm(`您确定删除${ids.length}位游客？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        lvyoudingdan_delyk(ids).then((res) => {
          if (res.status == 200) {
            onLoad();
            emit("onLoad");
            ElMessageBox.confirm(
              "当前订单人数发生变动，请确认是否需要修正订单金额？",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                dangerouslyUseHTMLString: true,
                type: "success",
              }
            ).then(() => {
              emit("onShowEdit");
            });
          }
        });
      });
    };

    //导出发团通知书
    const onExportFttzsPdf = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("ykPdfDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(props.mc + "-游客名单.pdf");
      loading.close();
    };

    //打印发团通知书
    const onPrintFttzs = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("ykPdfDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      printJS({
        printable: imgData,
        type: "image",
        style: `@media print { @page {size: auto; margin: 0; } body{margin:10px}}`,
        documentTitle: "",
      });
      loading.close();
    };

    const onAdd = () => {
      ElMessageBox.prompt("请填写要增加的人数", "提示", {
        confirmButtonText: "确认增加",
        cancelButtonText: "取消",
        inputType: "number",
      }).then(({ value }) => {
        lvyoudingdan_addyk(props.id, value).then((res) => {
          if (res.status == 200) {
            onLoad();
            emit("onLoad");
            ElMessageBox.confirm(
              "当前订单人数发生变动，请确认是否需要修正订单金额？",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                dangerouslyUseHTMLString: true,
                type: "success",
              }
            ).then(() => {
              emit("onShowEdit");
            });
          }
        });
      });
    };

    return {
      state,
      onDelete,
      props,
      onLoad,
      tabDom,
      onExportFttzsPdf,
      onPrintFttzs,
      onAdd,
    };
  },
};
</script>
<style scoped></style>
