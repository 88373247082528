<template>
  <c_huifu_lydd
    :id="state.id"
    type="T_JSAPI"
    :key="state.id"
    @onSubmit="onSubmit"
  ></c_huifu_lydd>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { huifu_getopenid, huifu_lydd_create } from "@/http/api";
import { ElMessage } from "element-plus";

import c_huifu_lydd from "./Main.vue";

export default {
  components: {
    c_huifu_lydd,
  },
  setup() {
    const route = useRoute();
    const state = reactive({});
    onMounted(() => {
      document.title = "灵西文化科技有限公司";
      state.id = route.query.id;

      const url = window.location.href;
      const beginIndex = url.indexOf("code");
      const endIndex = url.indexOf("&");
      if (beginIndex < 0 || endIndex < 0) {
        //如果url中不包含code参数，由于微信的回调地址不支持hash模式的路由，所以地址中不能带有#号，所以整体地址为：http://ra.gujinghotels.com:82/?code=8Maea6TwUpVhQ1qqE893kQzxyJD9qVwPVPFi560VXsc&state=STATE#/?storeid=1
        state.statusCode = 400;
        return false;
      }

      state.weChatCode = window.location.href.slice(beginIndex + 5, endIndex);

      huifu_getopenid(state.weChatCode).then((res) => {
        if (res.status == 200) {
          state.openid = res.data;
        }
      });
    });

    const onSubmit = (item) => {
      item.OpenId = state.openid;
      huifu_lydd_create("T_JSAPI", item).then((res) => {
        if (res.status == 200) {
          if (typeof res.data === "object") wechatPay(res.data);
          else ElMessage.warning("请使用支付宝扫码支付！");
        }
      });
    };

    const wechatPay = (result) => {
      //封装请求微信支付接口数据
      var request_data = {
        appId: result.appId,
        timeStamp: result.timeStamp,
        nonceStr: result.nonceStr,
        package: result.package,
        signType: "RSA",
        paySign: result.paySign,
      };

      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        request_data,
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            ElMessage({ type: "success", message: "支付成功" });
          }
        }
      );

      // if (window.WeixinJSBridge == "undefined") {
      //   if (document.addEventListener) {
      //     document.addEventListener(
      //       "WeixinJSBridgeReady",
      //       onBridgeReady(request_data),
      //       false
      //     );
      //   } else if (document.attachEvent) {
      //     document.attachEvent(
      //       "WeixinJSBridgeReady",
      //       onBridgeReady(request_data)
      //     );
      //     document.attachEvent(
      //       "OnWeixinJSBridgeReady",
      //       onBridgeReady(request_data)
      //     );
      //   }
      // } else {
      //   onBridgeReady(request_data);
      // }
    };
    //微信方法
    const onBridgeReady = (request_data) => {
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        request_data,
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            ElMessage({ type: "success", message: "支付成功" });
          }
        }
      );
    };
    return { state, onSubmit, onBridgeReady };
  },
};
</script>
<style scoped></style>
