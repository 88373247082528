<template>
  <div class="listBox">
    <ul
      style="
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        display: flex;
        width: 50%;
        margin: 0 auto;
        line-height: 40px;
        cursor: pointer;
      "
    >
      <li
        style="
          flex: 1;
          text-align: center;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        "
        :style="{
          background: state.lx == 1 ? '#1779DE' : '',
          color: state.lx == 1 ? '#fff' : '',
        }"
        @click="state.lx = 1"
      >
        未对账
      </li>
      <li
        style="
          flex: 1;
          text-align: center;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        "
        :style="{
          background: state.lx == 2 ? '#1779DE' : '',
          color: state.lx == 2 ? '#fff' : '',
        }"
        @click="state.lx = 2"
      >
        已对账
      </li>
    </ul>
    <c_DuiZhangDanLycgWdzList v-show="state.lx == 1">
    </c_DuiZhangDanLycgWdzList>
    <c_DuiZhangDanLycgYdzList v-show="state.lx == 2">
    </c_DuiZhangDanLycgYdzList>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  setup() {
    const state = reactive({
      lx: 1,
    });
    onMounted(() => {});
    return { state };
  },
};
</script>
<style scoped></style>
