<template>
  <el-button
    type="primary"
    icon="Plus"
    link
    style="margin-bottom: 10px"
    @click="
      delete state.selectId;
      state.showGz = true;
    "
  >
    添加规则
  </el-button>
  <div v-for="item in state.gzData" :key="item" class="gzBox">
    <ul style="display: flex; align-items: center">
      <li style="flex: 2">
        <text style="font: 600 13px/13px ''">
          {{ item.YxKssj + " ~ " + item.YxJzsj }}
        </text>
        <text
          style="margin-left: 10px"
          :style="{ color: item.IsQy ? '#080' : '#f00' }"
        >
          {{ item.IsQy ? "启用" : "停用" }}
        </text>
      </li>
      <li style="flex: 2">
        <text class="colorA2">规则名称：</text>{{ item.Mc }}
      </li>
      <li style="flex: 1">
        <text class="colorA2">优先级：</text>{{ item.Xh }}
      </li>
      <li>
        <el-switch
          v-model="item.IsQy"
          style="
            --el-switch-on-color: #13ce66;
            --el-switch-off-color: #ff4949;
            margin-right: 10px;
          "
          inline-prompt
          active-text="启用"
          inactive-text="停用"
          size="small"
          @change="onChange($event, item.Id)"
        />
        <el-button
          type="primary"
          :icon="Edit"
          link
          @click="onOpenCompile(item.Id)"
          >编辑
        </el-button>
        <el-button type="danger" :icon="Delete" link @click="onDelete(item.Id)"
          >删除
        </el-button>
      </li>
    </ul>
    <ul style="display: flex; align-items: center">
      <li style="flex: 1" v-if="item.IsTqKs">
        开售时间：<span v-if="item.TqKsts <= 0"
          >当天<text
            style="color: #1779de; font-weight: 600; font-size: 16px"
            >{{ item.TqKssj }}</text
          ></span
        >
        <span v-else
          >提前<text style="color: #f00; font-weight: 600; font-size: 16px">{{
            item.TqKsts
          }}</text
          >天的<text
            style="color: #1779de; font-weight: 600; font-size: 16px"
            >{{ item.TqKssj }}</text
          ></span
        >开始销售
      </li>
      <li style="flex: 1" v-if="item.IsTqTs">
        停售时间：<span v-if="item.TqTsts <= 0"
          >当天<text
            style="color: #1779de; font-weight: 600; font-size: 16px"
            >{{ item.TqTssj }}</text
          ></span
        ><span v-else
          >提前<text style="color: #f00; font-weight: 600; font-size: 16px">{{
            item.TqTsts
          }}</text
          >天的<text
            style="color: #1779de; font-weight: 600; font-size: 16px"
            >{{ item.TqTssj }}</text
          ></span
        >停止销售
      </li>
    </ul>
    <ul style="display: flex; align-items: center">
      <li style="flex: 1">
        <text class="colorA2">使用规则：</text>
        <text v-if="item.Sygz == 1">有效期内每天都可以使用</text>
        <text v-else-if="item.Sygz == 2">
          有效期内每月
          <el-tag v-for="rq in item.Zdsyrs" :key="rq" style="margin-right: 5px">
            {{ rq + "号" }}
          </el-tag>
        </text>
        <text v-else-if="item.Sygz == 3">
          有效期内每周
          <el-tag v-for="rq in item.Zdsyrs" :key="rq" style="margin-right: 5px">
            {{ weekArr[rq] }}
          </el-tag>
        </text>
        <text v-else-if="item.Sygz == 4">
          <el-tag v-for="rq in item.Zdsyrs" :key="rq" style="margin-right: 5px">
            {{ rq }}
          </el-tag>
        </text>
      </li>
    </ul>
    <ul>
      <text class="colorA2">关联套餐：</text>
    </ul>
    <ul style="margin: 0 10px">
      <ul
        v-for="tc in item.Tcs"
        :key="tc"
        style="
          border: 1px solid #e2e2e2;
          border-radius: 5px;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
        "
      >
        <li style="flex: 1">
          {{ tc.TcMc }}
        </li>
        <li style="flex: 1">
          <text class="colorA2">市场价：</text>{{ tc.Scj }}
        </li>
        <li style="flex: 1">
          <text class="colorA2">非协议同行价：</text>{{ tc.Fxythj }}
        </li>
        <li style="flex: 1">
          <text class="colorA2">协议同行价：</text>{{ tc.Xythj }}
        </li>
        <li style="flex: 1">
          <text class="colorA2">结算价：</text>{{ tc.Jsj }}
        </li>
        <li style="flex: 1">
          <text class="colorA2">单日可售数：</text>{{ tc.Kc }}
        </li>
      </ul>
    </ul>
  </div>
  <transition name="el-zoom-in-center">
    <c_popup
      v-if="state.showGz"
      :title="state.tcId ? '编辑规则' : '添加规则'"
      @close="state.showGz = false"
    >
      <c_ShangPinGzCompile
        @close="
          onLoad();
          state.showGz = false;
        "
        :id="state.selectId"
        :spId="props.spId"
      ></c_ShangPinGzCompile>
    </c_popup>
  </transition>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  xiaoshouguize_getbyspid,
  xiaoshouguize_delete,
  xiaoshouguize_status,
} from "@/http/api";
import { confirm_func } from "@/util/tFormat";
import { ElMessage } from "element-plus";
import { weekArr } from "@/util/enum.js";

export default {
  props: {
    spId: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    //加载数据
    const onLoad = () => {
      xiaoshouguize_getbyspid(props.spId).then((res) => {
        if (res.status == 200) {
          state.gzData = res.data;
        }
      });
    };

    //删除套餐
    const onDelete = (id) => {
      confirm_func(xiaoshouguize_delete, id, onLoad);
    };

    //打开编辑框
    const onOpenCompile = (id) => {
      state.selectId = id;
      state.showGz = true;
    };

    //规则启用/停用
    const onChange = (value, id) => {
      xiaoshouguize_status(id, value).then((res) => {
        if (res.status == 200) ElMessage.success(res.data);
      });
    };

    return { state, onDelete, props, onLoad, onOpenCompile, onChange, weekArr };
  },
};
</script>
<style scoped lang="less">
.gzBox {
  margin: 0 5px 10px 5px;
  min-width: 50px;
  box-shadow: 0 0 5px 1px #e2e2e2;
  padding: 10px;
  color: #000;
  font-size: 12px;
  line-height: 30px;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 5px 1px rgb(200, 200, 200);
  }
}
</style>
