<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
    "
    ref="boxDom"
  >
    <div class="infoBox">
      <ul style="display: flex; align-items: flex-start">
        <li style="font-weight: 600; font-size: 16px">
          {{ state.model.Mc }}
        </li>
        <li>
          <div class="title">
            <li>法人</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Fr || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>法人证件号</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Frzjh || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <text v-if="state.model.IsQy" style="color: #1779de">启用</text>
          <text v-else style="color: #f00">停用</text>
          <text style="color: #a2a2a2; font-size: 12px">{{
            state.model.Fl?.Mc || ""
          }}</text>
        </li>
        <li>
          <div class="title">
            <li>法人手机号</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Frsjh || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>签约类目</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Qylm || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>佣金</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Yj + "%" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>开户时间</li>
            ：
          </div>
          <div class="content">
            {{
              state.model.Khsj
                ? $dayjs(state.model.Khsj).format("YYYY-MM-DD")
                : "-"
            }}
          </div>
        </li>
        <li>
          <div class="title" style="width: fit-content">
            <li>统一社会信用代码</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Tyshxydm || "-" }}
          </div>
        </li>
      </ul>
      <ul style="display: flex; align-items: center; margin-top: 5px">
        <li>
          <div class="title">
            <li>联系人</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Lxr || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>手机号</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Lxdh || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>QQ账号</li>
            ：
          </div>
          <div class="content">
            {{ state.model.QQ || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>微信账号</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Wx || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>邮编</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Yb || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>邮箱</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Yx || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>协议期</li>
            ：
          </div>
          <div class="content">
            {{
              state.model.Xykssj
                ? $dayjs(state.model.Khsj).format("YYYY-MM-DD") +
                  " ~ " +
                  $dayjs(state.model.Xyjzsj).format("YYYY-MM-DD")
                : "-"
            }}
          </div>
        </li>
        <li style="flex: 2">
          <div class="title">
            <li>公司网站</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Wz || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>地址</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Dz || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>返利说明</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Bz || "-" }}
          </div>
        </li>
      </ul>
    </div>
    <el-tabs
      v-model="state.tabName"
      ref="tabsDom"
      class="comTab detailTab"
      style="position: inherit"
      @tab-change="onTabsChange"
    >
      <el-tab-pane label="商品订单" name="dd">
        <c_GongYingShangDdList
          :gysId="props.data?.id"
          :key="state.ddKey"
        ></c_GongYingShangDdList>
      </el-tab-pane>
      <el-tab-pane label="采购订单" name="cg">
        <c_GongYingShangLycgList
          :gysId="props.data?.id"
          :key="state.cgKey"
        ></c_GongYingShangLycgList>
      </el-tab-pane>
      <el-tab-pane label="用户" name="yh">
        <c_GongYingShangYhList
          :gysId="props.data?.id"
          :key="state.yhKey"
        ></c_GongYingShangYhList>
      </el-tab-pane>
      <el-tab-pane label="账户" name="zh">
        <c_GongYingShangZhList
          :gysId="props.data?.id"
          :key="state.zhKey"
        ></c_GongYingShangZhList>
      </el-tab-pane>
    </el-tabs>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        title="修改供应商"
        @close="state.showCompile = false"
      >
        <c_Compile
          @close="
            state.showCompile = false;
            onLoadDetail();
          "
          :id="props.data?.id"
        ></c_Compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { gongyingshang_detail } from "@/http/api";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const tabsDom = ref(null);
    const boxDom = ref(null);
    const state = reactive({
      model: {},
      tabName: "dd",
    });
    onMounted(() => {
      console.log(props);

      if (props.data?.id) {
        onLoadDetail();
      }
    });

    const onLoadDetail = () => {
      gongyingshang_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          state.model = res.data;
        }
      });
    };

    //选项卡切换事件
    const onTabsChange = (value) => {
      if (value == "dd") {
        state.ddKey = Math.random();
      } else if (value == "cg") {
        state.cgKey = Math.random();
      } else if (value == "yh") {
        state.yhKey = Math.random();
      } else if (value == "zh") {
        state.zhKey = Math.random();
      }
    };

    return {
      state,
      props,
      onLoadDetail,
      boxDom,
      tabsDom,
      onTabsChange,
    };
  },
};
</script>
<style lang="less" scoped>
.title {
  width: 80px;
}
</style>
