<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      display: flex;
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
    "
  >
    <c_HomeMenu></c_HomeMenu>
    <div
      style="flex: 1; overflow: hidden; display: flex; flex-direction: column"
    >
      <c_HomeHeader
        style="height: 70px; border-bottom: 1px solid #e2e2e2"
      ></c_HomeHeader>
      <div style="flex: 1; overflow: hidden">
        <el-tabs
          v-model="$store.state.navIndex"
          type="card"
          class="demo-tabs"
          :before-leave="tabBeforeHanlde"
          @tab-remove="onCloseMy"
        >
          <template v-for="(item, index) in $store.state.navData" :key="item">
            <el-tab-pane
              :name="index"
              :label="item.title"
              style="height: calc(100vh - 112px)"
              :closable="index != 0"
            >
              <template #label>
                <el-dropdown trigger="hover" ref="dropdownRef">
                  <text style="font-size: 12px">{{ item.title }}</text>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="onReload(index)">
                        <el-icon> <Refresh /> </el-icon>刷新
                      </el-dropdown-item>
                      <el-dropdown-item
                        @click="onCloseMy(index)"
                        v-if="item.path != 'HomeMain'"
                      >
                        <el-icon> <Close /> </el-icon>关闭
                      </el-dropdown-item>
                      <el-dropdown-item @click="onCloseOther(index)">
                        <el-icon> <Remove /> </el-icon>关闭其他
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
              <ul
                style="
                  font-weight: 600;
                  font-size: 14px;
                  padding-left: 10px;
                  height: 30px;
                  display: flex;
                  align-items: flex-end;
                  color: rgb(60, 60, 60);
                "
                v-if="
                  ![
                    'HomeMain',
                    'LvYouDingDanDetail',
                    'LvYouDingDanSearch',
                    'LvYouDingDanJieSuanDetail',
                  ].includes(item.path)
                "
              >
                {{
                  item.title
                }}
              </ul>
              <component
                :is="'c_' + item.path"
                :class="index >= state.oldIndex ? 'anRight' : 'anLeft'"
                style="height: calc(100% - 30px)"
                :ref="(el) => setItemRef(el, 'comDom' + index)"
                :key="item.key"
                :data="item.data"
              ></component>
            </el-tab-pane>
          </template>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { home_getuser } from "@/http/api";
import store from "@/store";

export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      activeIndex: 0,
      oldIndex: 0,
      tabsData: [{ path: "main", title: "首页" }],
      currentComponent: "c_js",
    });

    const comDoms = [];
    const setItemRef = (el, key) => {
      if (el) {
        comDoms[key] = el;
      }
    };

    const stopBack = () => {
      history.pushState(null, document.title, location.href);
    };

    onUnmounted(() => {
      window.removeEventListener("popstate", stopBack);
    });

    onMounted(() => {
      history.pushState(null, document.title, location.href);
      window.addEventListener("popstate", stopBack);

      store.commit("onNavInit");

      state.url = window.location.origin + "/#/";
      home_getuser().then((res) => {
        if (res.status == 200) store.commit("setLoginUser", res.data);
      });
    });

    const tabBeforeHanlde = (value, oldIndex) => {
      state.oldIndex = oldIndex;
      localStorage.setItem("navIndex", value);
    };

    //刷新当前
    const onReload = (index) => {
      // comDoms["comDom" + store.state.navIndex].onReset();
      store.commit("onRefresh", index);
    };

    //关闭当前
    const onCloseMy = (index) => {
      store.commit("onCloseMy", index);
    };

    //关闭其他
    const onCloseOther = (index) => {
      store.commit("onCloseOther", index);
    };

    return {
      state,
      router,
      tabBeforeHanlde,
      setItemRef,
      onReload,
      onCloseMy,
      onCloseOther,
    };
  },
};
</script>
<style lang="less">
.demo-tabs {
  & > .el-tabs__header {
    margin-bottom: 0;
  }

  & > .el-tabs__header .el-tabs__item.is-active {
    background-color: #f5f5f5 !important;
    border-bottom: 1px solid #f5f5f5 !important;
  }

  :focus-visible {
    outline: none !important;
  }

  .demo-tabs .el-tabs__new-tab {
    margin-right: 10px;
  }

  .el-tabs__nav {
    // border-top: 0 !important;
    font-size: 12px;
  }
}

.anLeft {
  animation: fadeIn 0.8s 0.02s ease backwards;
}

.anRight {
  animation: fadeIn 0.8s 0.02s ease backwards;
}
</style>
