<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="domForm"
        :rules="rules"
      >
        <el-form-item
          label="角色名称"
          prop="Mc"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Mc"></el-input>
        </el-form-item>
        <el-form-item label="角色说明" prop="Remark">
          <el-input
            type="textarea"
            v-model="state.model.Sm"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { juese_add, juese_detail, juese_edit } from "@/http/api";
import { ElMessage } from "element-plus";
import store from "@/store";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      model: {
        IsQy: true,
      },
    });
    onMounted(() => {
      if (props.id) {
        store.commit("openLoading");
        juese_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          } else ElMessage.error(res.data);
          store.commit("closeLoading");
        });
      }
    });

    const onSubmit = () => {
      if (props.id) {
        juese_edit(props.id, state.model).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });
          if (res.status == 200) {
            setTimeout(() => {
              emit("close", true);
            }, 500);
          }
        });
      } else {
        juese_add(state.model).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });
          if (res.status == 200) {
            setTimeout(() => {
              emit("close", true);
            }, 500);
          }
        });
      }
    };

    return { state, onSubmit, props };
  },
};
</script>
<style scoped></style>
