<template>
  <div
    style="
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 24px;
      padding: 10px;
    "
    id="ykPdfDom"
  >
    <ul style="text-align: center; font-weight: 600; margin-bottom: 10px">
      {{
        props.mc + "-名单表"
      }}
    </ul>
    <table
      style="
        text-align: center;
        border-collapse: collapse;
        width: 100%;
        border-radius: 10px;
        border-style: hidden;
        border-color: #000;
      "
      border="1"
      cellpadding="20"
    >
      <tr>
        <th>序号</th>
        <th>游客姓名</th>
        <th>手机号码</th>
        <th>证件类型</th>
        <th>证件号</th>
        <th>性别</th>
        <th>出生日期</th>
        <th>备注</th>
      </tr>
      <tr v-for="(item, index) in state.tabData" :key="item">
        <td>{{ index + 1 }}</td>
        <td>{{ item.Xm }}</td>
        <td>{{ item.Sjhm }}</td>
        <td>{{ item.Zjlx }}</td>
        <td>{{ item.Zjh }}</td>
        <td>{{ item.Xb == 1 ? "男" : "女" }}</td>
        <td>{{ item.Csrq }}</td>
        <td>{{ item.Bz }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_getyk } from "@/http/api";
export default {
  props: {
    mc: String,
    id: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      console.log(props);
      lvyoudingdan_getyk(props.id).then((res) => {
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    });
    return { state, props };
  },
};
</script>
<style scoped></style>
