<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li
        style="display: flex; align-items: center"
        v-if="
          $store.state.loginUser?.IsAdmin ||
          $store.state.loginUser?.Qxs?.includes('B_ZhangHu_List_添加')
        "
      >
        <el-button
          type="primary"
          icon="Plus"
          link
          @click="
            delete state.selectId;
            state.showCompile = true;
          "
        >
          添加
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="Mc"
          label="账户名称"
          :formatter="
            (row, column, cellValue) =>
              $tab_Highlinght(cellValue, state.params.Params.Key)
          "
        >
        </el-table-column>
        <el-table-column
          prop="Hh"
          label="户号"
          :formatter="
            (row, column, cellValue) =>
              $tab_Highlinght(cellValue || '-', state.params.Params.Key)
          "
        />
        <el-table-column
          prop="Zcje"
          label="支出金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Srje"
          label="收入金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column prop="Sm" label="说明" :formatter="$tab_TextFormat" />
        <el-table-column prop="CjrXm" label="创建人" />
        <el-table-column prop="Cjsj" sortable="Cjsj" label="创建时间" />
        <el-table-column
          label="操作"
          width="280"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Edit"
              link
              @click="
                state.selectId = row.Id;
                state.showCompile = true;
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_ZhangHu_List_编辑')
              "
              >编辑
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(row.Id)"
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_ZhangHu_List_删除')
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="(state.selectId ? '修改' : '添加') + '对公账户'"
        @close="state.showCompile = false"
      >
        <c_ZhangHuCompile
          @close="
            state.showCompile = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_ZhangHuCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { zhanghu_list, zhanghu_delete } from "@/http/api";
import { confirm_func } from "@/util/tFormat";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: {
      Lx: 2,
    },
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  setup() {
    const state = reactive(new InitFormData());
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      zhanghu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      if (column.order) {
        state.params.Order = column.prop;
        state.params.Asc = column.order === "ascending";
      } else {
        state.params.Order = "Cjsj";
        state.params.Asc = false;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      confirm_func(zhanghu_delete, id, onLoad);
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onDelete,
    };
  },
};
</script>
<style lang="less" scoped></style>
