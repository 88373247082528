<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <div style="flex: 1; overflow-y: auto; padding: 10px">
      <ul
        style="
          box-shadow: 0 0 10px 1px #e2e2e2;
          border-radius: 7px;
          padding: 10px;
          display: flex;
          height: 120px;
        "
        :style="{ marginTop: index != 0 ? '15px' : '' }"
        v-for="(item, index) in state.listData"
        :key="item"
      >
        <li style="width: 20vw; height: 100%">
          <el-image
            :src="require('@/assets/images/nodata.png')"
            fit="contain"
            style="height: 100%"
          ></el-image>
        </li>
        <div
          style="
            margin: 0 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
          "
        >
          <ul style="margin-left: -5px; font-weight: 600; font-size: 14px">
            {{
              "【" + item.SpMc + "】" + item.SpTcMc
            }}
          </ul>
          <ul>
            <li
              style="
                background-color: #1779de;
                display: flex;
                width: fit-content;
                color: #fff;
                padding: 2px 5px;
                border-radius: 2px;
                font-size: 12px;
              "
            >
              {{ item.SpPlMc }}
            </li>
          </ul>
          <ul style="display: flex; align-items: center">
            <li style="flex: 1">
              <c_money :value="item.Xsdj" size="20"></c_money>
            </li>
            x1
          </ul>
          <ul>
            {{
              item.Syrq
            }}使用
          </ul>
        </div>
      </ul>
    </div>
    <div style="margin: 20px">
      <el-button
        type="success"
        v-if="state.type == 'T_JSAPI'"
        style="width: 100%"
        size="large"
        @click="onSubmit"
      >
        支付￥{{ state.totalPrice }}
      </el-button>
      <el-button
        type="primary"
        v-else-if="state.type == 'A_JSAPI'"
        style="width: 100%"
        size="large"
        @click="onSubmit"
      >
        支付宝￥{{ state.totalPrice }}
      </el-button>
      <el-button
        type="danger"
        v-else
        style="width: 100%"
        size="large"
        @click="onSubmit"
      >
        支付￥{{ state.totalPrice }}
      </el-button>
      <!-- <iframe :src="state.payUrl" style="width: 50vw; height: 50vh"></iframe> -->
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { huifu_getdetail, huifu_create } from "@/http/api";

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      totalPrice: 0.01,
      listData: [
        {
          SpMc: "三河景区门票",
          SpTcMc: "三河景区三联票",
          SpFlMc: "特产",
          Xsdj: 100,
          Sl: 10,
          Xszj: 1000,
          SpTcDw: "张",
          SpFm: require("@/assets/images/nodata.png"),
          Syrq: "2024年2月1日",
        },
        {
          SpMc: "三河景区门票",
          SpTcMc: "三河景区三联票",
          SpFlMc: "特产",
          Xsdj: 100,
          Sl: 10,
          Xszj: 1000,
          SpTcDw: "张",
          SpFm: require("@/assets/images/nodata.png"),
          Syrq: "2024年2月1日",
        },
      ],
    });
    onMounted(() => {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/Alipay/i) == "alipay") {
        //alert("支付宝浏览器");
        state.type = "A_JSAPI";
      } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        // alert("微信浏览器");
        state.type = "T_JSAPI";
      } else {
        state.laiyuan = "U_JSAPI";
      }
      state.dh = route.params.dh;
      huifu_getdetail(state.dh).then((res) => {
        if (res.status == 200) state.listData = res.data;
      });
    });

    const onSubmit = () => {
      huifu_create("A_JSAPI", { type: state.type }).then((res) => {
        // state.payUrl = res.data;
        window.location.href = res.data;
        // const iframe = document.createElement("iframe");
        // iframe.style.display = "none";
        // iframe.setAttribute("src", res.data);
        // iframe.setAttribute(
        //   "sandbox",
        //   "allow-top-navigation-by-user-activation allow-scripts"
        // );
        // iframe.onload = function () {
        //   // 4秒后删除 iframe，否则会跳回上一个页面
        //   setTimeout(() => {
        //     document.body.removeChild(iframe);
        //   }, 4000);
        // };

        // document.body.appendChild(iframe);

        // var iframe = document.createElement("iframe");
        // iframe.src = res.data;
        // iframe.sandbox = "allow-scripts allow-same-origin";
        // document.body.appendChild(iframe);
        // console.log(123);
      });
    };
    return { state, onSubmit };
  },
};
</script>
<style scoped></style>
