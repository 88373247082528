<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
        inline
      >
        <ul style="display: flex">
          <el-form-item
            label="状态"
            prop="IsQy"
            :rules="[
              { required: true, message: '请选择供应商状态', trigger: 'blur' },
            ]"
            style="flex: 1; margin-right: 0"
          >
            <el-switch v-model="state.model.IsQy" />
          </el-form-item>
          <el-form-item label="分类" style="flex: 1">
            <el-select v-model="state.model.FlId" placeholder="--请选择类型--">
              <el-option
                v-for="item in state.flData || []"
                :key="item.Id"
                :label="item.Mc"
                :value="item.Id"
              />
            </el-select>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="供应商名称"
            prop="Mc"
            :rules="[
              { required: true, message: '请输入供应商名称', trigger: 'blur' },
            ]"
            style="flex: 1; margin-right: 0"
          >
            <el-input v-model="state.model.Mc"></el-input>
          </el-form-item>
          <el-form-item label="供应商简称" style="flex: 1">
            <el-input v-model="state.model.Jc"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="法人" style="flex: 1; margin-right: 0">
            <el-input v-model="state.model.Fr"></el-input>
          </el-form-item>
          <el-form-item label="法人证件号" style="flex: 1">
            <el-input v-model="state.model.Frzjh"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="法人手机号" style="flex: 1; margin-right: 0">
            <el-input v-model="state.model.Frsjh"></el-input>
          </el-form-item>
          <el-form-item label="签约类目" style="flex: 1">
            <el-input v-model="state.model.Qylm"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="佣金" style="flex: 1; margin-right: 0">
            <el-input v-model="state.model.Yj"></el-input>
          </el-form-item>
          <el-form-item label="开户时间" style="flex: 1">
            <el-date-picker
              v-model="state.model.Khsj"
              type="day"
              placeholder="日期"
              style="flex: 1"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :clearable="false"
              @change="onLoad()"
            />
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="统一社会信用代码"
            style="flex: 1; margin-right: 0"
            label-width="140"
          >
            <el-input v-model="state.model.Tyshxydm"></el-input>
          </el-form-item>
          <el-form-item label="" style="flex: 1"> </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="联系人" style="flex: 1; margin-right: 0">
            <el-input v-model="state.model.Lxr"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" style="flex: 1">
            <el-input v-model="state.model.Lxdh"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="QQ账号" style="flex: 1; margin-right: 0">
            <el-input v-model="state.model.QQ"></el-input>
          </el-form-item>
          <el-form-item label="微信账号" style="flex: 1">
            <el-input v-model="state.model.Wx"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="邮编" style="flex: 1; margin-right: 0">
            <el-input v-model="state.model.Yb"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" style="flex: 1">
            <el-input v-model="state.model.Yx"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="协议期" style="flex: 1; margin-right: 0">
            <el-date-picker
              v-model="state.model.Xysj"
              type="daterange"
              range-separator="至"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item label="公司网站" style="flex: 1">
            <el-input v-model="state.model.Wz"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="公司地址" style="flex: 1">
            <el-input v-model="state.model.Dz"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="返利说明" style="flex: 1; margin-right: 0">
            <el-input
              type="textarea"
              v-model="state.model.Bz"
              :autosize="{ minRows: 4 }"
            />
          </el-form-item>
        </ul>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  gongyingshang_add,
  gongyingshang_detail,
  gongyingshang_edit,
  zidian_select,
} from "@/http/api";
import { post_response } from "@/util/tFormat";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {
        IsQy: true,
      },
    });
    onMounted(() => {
      loadFl();
      if (props.id) {
        gongyingshang_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            state.model.Xysj = [state.model.Xykssj, state.model.Xyjzsj];
          }
        });
      }
    });

    //加载品类下拉数据
    const loadFl = () => {
      zidian_select("供应商分类").then((res) => {
        if (res.status == 200) {
          state.flData = res.data;
        }
      });
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (state.model.Xysj) {
            state.model.Xykssj = state.model.Xysj[0];
            state.model.Xyjzsj = state.model.Xysj[1];
          }
          if (props.id) {
            gongyingshang_edit(props.id, state.model).then((res) => {
              post_response(res, emit);
            });
          } else {
            gongyingshang_add(state.model).then((res) => {
              post_response(res, emit);
            });
          }
        }
      });
    };

    return { state, onSubmit, props, loadFl, formDom };
  },
};
</script>
<style scoped></style>
