<template>
  <el-table
    :data="state.tableData"
    v-loading="!Array.isArray(state.tableData)"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <el-table-column label="#" type="index"></el-table-column>
    <el-table-column
      prop="Zt"
      label="状态"
      :formatter="$tab_StatusFormat"
      width="80"
    ></el-table-column>
    <el-table-column
      prop="Syrq"
      label="使用日期"
      :formatter="$tab_TextFormat"
      width="110"
    ></el-table-column>
    <el-table-column
      prop="SpMc"
      label="商品名称"
      :formatter="$tab_TextFormat"
    ></el-table-column>
    <el-table-column
      prop="SpPlMc"
      label="商品品类"
      :formatter="$tab_TextFormat"
    ></el-table-column>
    <el-table-column
      prop="SpTcMc"
      label="套餐名称"
      :formatter="$tab_TextFormat"
    ></el-table-column>
    <el-table-column prop="Sl" label="数量"></el-table-column>
    <el-table-column label="销售价">
      <template #default="{ row }">
        <text>{{
          $priceFormat(row.Xsdj) +
          " * " +
          row.Sl +
          " = " +
          $priceFormat(row.Xszj)
        }}</text>
      </template>
    </el-table-column>
    <el-table-column
      label="已收/未收"
      :formatter="
        (row) =>
          $tab_GroupFormat($priceFormat(row.Ysje), $priceFormat(row.Khqk))
      "
    ></el-table-column>
    <el-table-column label="结算价">
      <template #default="{ row }">
        <text>{{
          $priceFormat(row.Jsdj) +
          " * " +
          row.Sl +
          " = " +
          $priceFormat(row.Jszj)
        }}</text>
      </template>
    </el-table-column>
    <el-table-column
      label="已付/未付"
      :formatter="
        (row) =>
          $tab_GroupFormat($priceFormat(row.Yfje), $priceFormat(row.Jsqk))
      "
    ></el-table-column>
    <el-table-column
      prop="Fl"
      label="返利"
      :formatter="$tab_MoneyFormat"
    ></el-table-column>
  </el-table>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpindingdan_getmx } from "@/http/api";
export default {
  props: {
    ddId: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      shangpindingdan_getmx(props.ddId).then((res) => {
        if (res.status == 200) state.tableData = res.data;
      });
    });
    return { state };
  },
};
</script>
<style scoped></style>
