<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="导游"
          prop="DyId"
          :rules="[{ required: true, message: '请选择导游', trigger: 'blur' }]"
        >
          <el-select
            v-model="state.model.DyId"
            style="width: 100%"
            :filterable="false"
          >
            <el-option
              v-for="item in state.dyData"
              :key="item"
              :label="item.Xm + '[' + item.Sjhm + ']'"
              :value="item.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="带团日期"
          prop="Dtrq"
          :rules="[
            { required: true, message: '请选择带团日期', trigger: 'blur' },
          ]"
        >
          <el-date-picker
            v-model="state.model.Dtrq"
            type="daterange"
            range-separator="至"
            value-format="YYYY-MM-DD 00:00:00"
          />
        </el-form-item>
        <el-form-item
          label="补助金额"
          prop="Bzje"
          :rules="[
            { required: true, message: '请输入补助金额', trigger: 'blur' },
          ]"
        >
          <div style="display: flex; align-items: center">
            <el-input
              type="number"
              v-model.number="state.model.Bzje"
              placeholder="--补助金额--"
            >
              <template #append>元</template></el-input
            >
            <el-checkbox
              v-model="state.model.IsXf"
              label="是否现发"
              size="large"
              style="margin-left: 20px"
            />
          </div>
        </el-form-item>
        <el-form-item label="派遣说明">
          <el-input
            v-model="state.model.Bz"
            type="textarea"
            :autosize="{ minRows: 5 }"
          />
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import {
  daoyou_select,
  lvyoudingdan_adddypq,
  lvyoudingdan_editdypq,
  lvyoudingdan_dypqdetail,
} from "@/http/api";
import { ElMessage, dayjs } from "element-plus";
import { cardTypeArr } from "@/util/enum";

export default {
  props: {
    id: Number,
    ddId: Number,
    dates: Array,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {
        Xb: 1,
        Zjlx: "身份证",
      },
      jsData: [],
    });
    onMounted(() => {
      console.log(props.dates);
      state.model.Dtrq = props.dates;
      daoyou_select().then((res) => {
        if (res.status == 200) state.dyData = res.data;
      });

      if (props.id) {
        lvyoudingdan_dypqdetail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            state.model.Dtrq = [state.model.Dtksrq, state.model.Dtjsrq];
          }
        });
      } else {
        state.defaultCsrq = dayjs().subtract(18, "year").format("YYYY-MM-DD");
      }
    });

    //出生日期有效性
    const onJudgeCsrq = (value) => {
      if (dayjs(value) < dayjs()) {
        return false;
      } else return true;
    };

    const onSubmit = () => {
      console.log(state.model);
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            lvyoudingdan_editdypq(props.id, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          } else {
            lvyoudingdan_adddypq(props.ddId, state.model).then((res) => {
              ElMessage({
                message: res.data,
                showClose: true,
                dangerouslyUseHTMLString: true,
                type: res.status ? "success" : "error",
              });
              if (res.status == 200) {
                setTimeout(() => {
                  emit("close", true);
                }, 500);
              }
            });
          }
        }
      });
    };

    return { state, formDom, onSubmit, props, cardTypeArr, onJudgeCsrq };
  },
};
</script>
<style scoped></style>
