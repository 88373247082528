import axios from "axios";
import router from "@/router";
import { ElMessage, ElLoading } from "element-plus";

const $http = axios.create({
  // baseURL: "https://shwl.cs.ebsys.cn:475",
  baseURL: window.apiUrl,
  // baseURL: "http://localhost:5164",
  // baseURL: "http://192.168.43.44:5164",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

let loading;

// 添加请求拦截器
$http.interceptors.request.use(
  function (config) {
    if (config.headers.LoadMsg) {
      loading = ElLoading.service({
        lock: true,
        text: decodeURIComponent(config.headers.LoadMsg),
        background: "rgba(0, 0, 0, 0.7)",
      });
    }

    const tokenModel = localStorage.getItem("token");
    if (tokenModel) {
      config.headers.Authorization = "Bearer " + JSON.parse(tokenModel)?.token;
    }

    if (config.params?.Params) {
      config.params.Params = JSON.stringify(config.params.Params);
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
$http.interceptors.response.use(
  function (response) {
    if (loading != null) {
      loading.close();
    }
    return response;
  },
  function (error) {
    if (loading != null) {
      loading.close();
    }

    //如果response为空的，接口服务未启动
    if (error.response === undefined) {
      ElMessage({
        message: "服务器异常，请求失败！",
        grouping: true,
        type: "error",
      });
      return Promise.reject(-10);
    } else {
      //400一般错误，直接弹出提示框
      if (error.response.status === 400) {
        if (error.response.config.headers.ShowError) {
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            message: error.response.data,
          });
        }
        return Promise.reject(error.response);
      }
      //401未授权，直接弹出提示框并跳转到登录页
      if (error.response.status == 401) {
        //返回值==1表示token过期，重新获取token
        router.push("/login");
        if (error.response.config.headers.ShowError) {
          // ElMessage.error("登录过期，请重新登录");
          return Promise.reject(401);
        } else {
          return Promise.reject(error.response);
        }
      }
      //404表示访问的接口路径不正确，直接提示
      // if (error.response.status == 404) {
      //   router.push("/404");
      // }
      //500表示接口代码出现异常，代码中并未使用try来捕获
      if (error.response.status == 500) {
        if (error.response.config.headers.ShowError) {
          ElMessage({
            message: "系统异常，请求失败！",
            grouping: true,
            type: "error",
          });
          return Promise.reject(500);
        } else {
          return Promise.reject(error.response);
        }
      }

      return Promise.reject(error.response);
    }
  }
);

export default $http;
