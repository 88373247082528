<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <el-image
      v-if="!state.type || state.type == 'T_JSAPI'"
      :src="require('@/assets/images/loading.gif')"
      style="margin: auto; margin-top: 15vh"
    ></el-image>
    <template v-else>
      <div v-if="state.type == 'A_JSAPI' || state.type == 'T_JSAPI'"></div>
      <c_huifu_lydd
        :id="state.id"
        :type="state.type"
        :key="state.id"
        v-if="state.type == 'A_JSAPI' || state.type == 'T_JSAPI'"
        @onSubmit="onSubmit"
      ></c_huifu_lydd>
      <div v-else style="text-align: center; padding-top: 20px">
        请时候微信或支付宝扫码~
      </div>
    </template>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { huifu_lydd_create } from "@/http/api";
import { ElMessage } from "element-plus";

import c_huifu_lydd from "./Main.vue";

export default {
  components: {
    c_huifu_lydd,
  },
  setup() {
    const route = useRoute();
    const state = reactive({});
    onBeforeMount(() => {});
    onMounted(() => {
      document.title = "灵西文化科技有限公司";
      // state.dh = route.params.dh;
      state.id = route.params.id;
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/Alipay/i) == "alipay") {
        // alert("支付宝浏览器");
        state.type = "A_JSAPI";
      } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        state.type = "T_JSAPI";
        const redirect_uri = encodeURIComponent(
          "http://shwl.cs.ebsys.cn/#/lydd/wx?id=" + state.id
        );
        // const redirect_uri = encodeURIComponent(
        //   "http://192.168.3.46/#/hf/wx?dh=" + state.dh
        // );
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx00280cfc822fb27a&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_base#wechat_redirect";
      } else {
        // state.laiyuan = "U_JSAPI";
        state.type = "U_JSAPI";
      }

      // const url = window.location.href;
      // const beginIndex = url.indexOf("hf");
      // const endIndex = url.indexOf("&");
      // if (beginIndex < 0 || endIndex < 0) {
      //   //如果url中不包含code参数，由于微信的回调地址不支持hash模式的路由，所以地址中不能带有#号，所以整体地址为：http://ra.gujinghotels.com:82/?code=8Maea6TwUpVhQ1qqE893kQzxyJD9qVwPVPFi560VXsc&state=STATE#/?storeid=1
      //   return false;
      // }
      // state.dh = window.location.href.slice(beginIndex + 1, endIndex);
      // localStorage.clear();
    });

    const onSubmit = (item) => {
      huifu_lydd_create(state.type, item).then((res) => {
        if (res.status == 200 || res.status == 204) {
          if (typeof res.data === "string") window.location.href = res.data;
          else ElMessage.warning("请使用微信扫码支付！");
        }
      });

      //   huifu_create(state.type, item).then((res) => {
      //     // state.payUrl = res.data;
      //     window.location.href = res.data;
      //   });
      //   const redirect_uri = encodeURIComponent(
      //     "http://shwl.cs.ebsys.cn/#/hf/wx/240112153657973"
      //   );
      //   window.location.href =
      //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3eb4edb2772202c7&redirect_uri=" +
      //     redirect_uri +
      //     "&response_type=code&scope=snsapi_base#wechat_redirect";
    };
    return { state, onSubmit };
  },
};
</script>
<style scoped></style>
