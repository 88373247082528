<template>
  <div class="listBox">
    <el-table
      :data="state.tableData"
      v-loading="!Array.isArray(state.tableData)"
      element-loading-background="rgba(0, 0, 0, 0.2)"
      :default-sort="{
        prop: state.params.Order,
        order: state.params.Asc == false ? 'descending' : 'ascending',
      }"
      @sort-change="onSortChange"
    >
      <el-table-column
        fixed
        label="#"
        width="50"
        type="index"
        :index="
          (index) =>
            (state.params.PageIndex - 1) * state.params.PageSize + index + 1
        "
        align="center"
      />
      <el-table-column
        prop="IsQy"
        sortable="IsQy"
        label="状态/编号"
        width="160"
        :formatter="
          (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
        "
      />
      <el-table-column
        prop="Ly"
        sortable="Ly"
        label="来源"
        width="100"
        :formatter="$tab_StatusFormat"
      />
      <el-table-column
        prop="Mc"
        sortable="Mc"
        label="客户"
        :formatter="
          (row) => $tab_GroupFormat(row.KhMc, row.KhLxr + ' ' + row.KhSjhm)
        "
        min-width="140"
      />
      <el-table-column
        prop="Bz"
        label="订单备注"
        min-width="140"
        :formatter="$tab_TextFormat"
      />
      <el-table-column
        prop="Cjsj"
        label="下单时间"
        align="center"
        width="170"
      />
      <el-table-column prop="Sl" label="商品数" align="center" />
      <el-table-column prop="Ycps" label="已出票" align="center" />
      <el-table-column label="未出票" align="center">
        <template #default="{ row }">
          <text>{{ row.Sl - row.Ycps }}</text>
        </template>
      </el-table-column>
      <el-table-column
        sortable="Xsj"
        label="结算价/销售价(元)"
        width="160"
        :formatter="
          (row) => $tab_GroupFormat(row.Jsj.toFixed(2), row.Xsj.toFixed(2))
        "
        align="center"
      />
      <el-table-column
        sortable="Ysje"
        label="已收/未收(元)"
        width="140"
        :formatter="
          (row) =>
            $tab_GroupFormat(
              row.Ysje.toFixed(2),
              (row.Jsj - row.Ysje).toFixed(2)
            )
        "
        align="center"
      />
      <el-table-column prop="Fl" sortable="Fl" width="110" label="返利(元)" />
      <el-table-column
        sortable="Yfje"
        label="已付/未付(元)"
        width="140"
        :formatter="
          (row) =>
            $tab_GroupFormat(
              row.Ysje.toFixed(2),
              (row.Jsj - row.Yfje).toFixed(2)
            )
        "
        align="center"
      />
      <el-table-column
        label="操作"
        width="100"
        align="center"
        fixed="right"
        className="tabBtn"
      >
        <template #default="{ row }">
          <el-button
            type="primary"
            :icon="Edit"
            link
            @click="
              $store.commit('openMenu', {
                path: 'ShangPinDingDanDetail',
                title: '商品订单详情',
                data: { id: row.Id },
              })
            "
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes(
                'B_ShangPinDingDan_List_管理'
              )
            "
            >管理
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { gongyingshang_getspdd } from "@/http/api";
import { useRouter } from "vue-router";

export default {
  props: {
    gysId: Number,
  },
  components: {},
  setup(props) {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      gongyingshang_getspdd(props.gysId, state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      state.params.PageIndex = 1;
      state.params.PageSize = 20;
      state.params.Params = {};
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
    };
  },
};
</script>
<style></style>
