<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="类型"
          prop="Lx"
          :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]"
        >
          <el-radio-group v-model="state.model.Lx" class="ml-4">
            <el-radio :label="1">收款</el-radio>
            <el-radio :label="2">退款</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="支付方式"
          prop="Zffs"
          v-if="state.model.Lx == 1"
          :rules="[
            { required: true, message: '请选择支付方式', trigger: 'blur' },
          ]"
        >
          <el-select
            v-model="state.model.Zffs"
            filterable
            allow-create
            default-first-option
            :reserve-keyword="false"
          >
            <el-option label="汇付支付" :value="1" />
            <el-option label="线上转账" :value="2" />
            <el-option label="现金支付" :value="3" />
            <el-option label="客户余额支付" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="Je">
          <el-input-number
            v-model="state.model.Je"
            :min="0.01"
            :precision="2"
            :step="0.01"
            :controls="false"
            style="width: 100%"
            class="numberInput"
          />
        </el-form-item>
        <el-form-item label="备注" prop="Bz">
          <el-input
            v-model="state.model.Bz"
            type="textarea"
            :autosize="{ minRows: 3 }"
          />
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
    <div
      v-show="state.showQrCode"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 9999;
      "
      @click="onClose()"
    >
      <div
        style="height: 80%; overflow: hidden; position: relative"
        @click.stop=""
      >
        <el-image
          :src="require('@/assets/images/code.png')"
          fit=""
          style="height: 100%"
        />
        <div
          ref="qrcodeDom"
          style="
            position: absolute;
            top: 16%;
            left: 23%;
            z-index: 99;
            width: 170px;
            height: 170px;
          "
          id="code"
        ></div>
        <div
          style="
            position: absolute;
            top: 63%;
            left: 10%;
            z-index: 99;
            width: 250px;
            height: 160px;
            padding: 0 10px;
            font-size: 11px;
            color: rgba(0, 0, 0, 0.8);
          "
        >
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款单位：肥西县灵西文化科技有限公司
          </ul>
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款金额：{{
              $priceFormat(state.item.Je)
            }}元
          </ul>
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款业务订单号：{{
              state.item?.Bh
            }}
          </ul>
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款人：{{
              state.item?.CjrXm
            }}
          </ul>
          <ul style="padding: 8px 0; white-space: nowrap; overflow: hidden">
            收款摘要：{{
              props.ddModel?.Mc
            }}
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { lvyoudingdan_addsk } from "@/http/api";
import { ElMessage } from "element-plus";
import QRCode from "qrcodejs2-fix";

export default {
  props: {
    id: Number,
    ddId: Number,
    ddModel: Object,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const qrcodeDom = ref(null);
    const state = reactive({
      model: {
        Lx: 1,
      },
      item: {},
    });
    onMounted(() => {});

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (state.model.Lx == 2) state.model.Zffs = 0;
          lvyoudingdan_addsk(props.ddId, state.model).then((res) => {
            ElMessage({
              message: "添加成功。",
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: res.status ? "success" : "error",
            });
            if (res.status == 200) {
              if (state.model.Lx == 1 && state.model.Zffs == 1) {
                state.item = res.data;
                onShowPayCode(res.data.Id);
              } else {
                setTimeout(() => {
                  emit("close", true);
                }, 500);
              }
            }
          });
        }
      });
    };

    const onShowPayCode = (id) => {
      qrcodeDom.value.innerHTML = "";
      const text = "http://shwl.cs.ebsys.cn/#/Lydd/Pay/" + id;

      state.showQrCode = true;
      new QRCode(qrcodeDom.value, {
        text,
        width: 170, // 二维码图片宽度
        height: 170, // 二维码图片高度
      });
    };

    const onClose = () => {
      state.showQrCode = false;
      emit("close", true);
    };

    return {
      state,
      formDom,
      props,
      onSubmit,
      onClose,
      qrcodeDom,
    };
  },
};
</script>
<style lang="less">
.numberInput {
  & .el-input-number,
  & .el-input__inner {
    text-align: left !important;
  }

  & .el-input__wrapper {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
</style>
