import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/Index.vue"),
  },
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Home/Index.vue"),
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("../views/Home/Main.vue"),
  },
  {
    path: "/yh",
    name: "YongHu",
    component: () => import("../views/YongHu/List.vue"),
  },
  {
    path: "/js",
    name: "JueSe",
    component: () => import("../views/JueSe/List.vue"),
  },
  {
    path: "/zd",
    name: "ZiDian",
    component: () => import("../views/ZiDian/List.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("../views/Home/404.vue"),
  },
  {
    path: "/hf/:dh",
    name: "HuiFu",
    component: () => import("../views/HuiFu/Index.vue"),
  },
  {
    path: "/hf/wx",
    name: "WeChat",
    component: () => import("../views/HuiFu/WeChat.vue"),
  },
  {
    path: "/lydd/pay/:id",
    name: "HuiFuLyddPay",
    component: () => import("../views/HuiFu/Lydd/Index.vue"),
  },
  {
    path: "/lydd/wx",
    name: "HuiFuLyddPayWx",
    component: () => import("../views/HuiFu/Lydd/WeChat.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
