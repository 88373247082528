<template>
  <div class="listBox">
    <ul class="sBox">
      <el-input
        v-model="state.params.Params.Key"
        style="width: 15%"
        placeholder="订单号"
        clearable
        @clear="
          delete state.params.Params.Key;
          onLoad();
        "
      ></el-input>
      <el-dropdown
        split-button
        @click="onLoad"
        type="primary"
        style="margin-left: 10px"
      >
        查询
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
              重置
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column type="expand" fixed="left">
          <template #default="{ row }">
            <div style="padding: 0 10px">
              <el-table :data="row.SpMxs" size="small">
                <el-table-column prop="Syrq" label="使用日期"></el-table-column>
                <el-table-column prop="SpMc" label="商品名称"></el-table-column>
                <el-table-column prop="SpPlMc" label="商品品类名称">
                </el-table-column>
                <el-table-column prop="SpTcMc" label="商品套餐名称">
                </el-table-column>
                <el-table-column prop="Xsdj" label="单价"></el-table-column>
                <el-table-column prop="Ysl" label="原数量"></el-table-column>
                <el-table-column prop="Yj" label="变更前总额"></el-table-column>
                <el-table-column prop="Bgsl" label="变更数量">
                  <template #default="{ row }">
                    <text
                      v-if="row.Bgsl < 0"
                      style="
                        color: #f00;
                        font: 600 16px/16px '';
                        margin-left: 4px;
                      "
                    >
                      {{ row.Bgsl }}</text
                    >
                    <text
                      v-else-if="row.Bgsl > 0"
                      style="color: #080; font: 600 16px/16px ''"
                      >+{{ row.Bgsl }}</text
                    >
                    <text v-else style="font: 600 16px/16px ''">{{
                      row.Bgsl
                    }}</text>
                  </template>
                </el-table-column>
                <el-table-column prop="Bgj" label="变更金额">
                  <template #default="{ row }">
                    <text
                      v-if="row.Bgj < 0"
                      style="
                        color: #f00;
                        font: 600 16px/16px '';
                        margin-left: 4px;
                      "
                    >
                      {{ row.Bgj }}</text
                    >
                    <text
                      v-else-if="row.Bgj > 0"
                      style="color: #080; font: 600 16px/16px ''"
                      >+{{ row.Bgj }}</text
                    >
                    <text v-else style="font: 600 16px/16px ''">{{
                      row.Bgj
                    }}</text>
                  </template>
                </el-table-column>
                <el-table-column prop="Xj" label="变更后总额">
                  <template #default="{ row }">
                    <text style="color: #1779de; font: 600 16px/16px ''">
                      {{ row.Xj }}
                    </text>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          fixed
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          width="100"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        >
          <template #default="{ row }">
            <text v-if="row.Shzt == null">待审核</text>
            <text v-else-if="row.Shzt == true" style="color: #080">已通过</text>
            <text v-else-if="row.Shzt == false" style="color: #f00">
              未通过
            </text>
          </template>
        </el-table-column>
        <el-table-column
          prop="KhMc"
          sortable="KhMc"
          label="客户名称"
          min-width="160"
        />
        <el-table-column prop="Ddh" sortable="Ddh" label="订单号" width="160">
          <template #default="{ row }">
            <text
              class="dhCss"
              @click="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes(
                  'B_ShangPinDingDan_List_管理'
                )
                  ? $store.commit('openMenu', {
                      path: 'ShangPinDingDanDetail',
                      title: '商品订单详情',
                      data: { id: row.DdId },
                      refresh: true,
                    })
                  : ''
              "
            >
              {{ row.Ddh }}
            </text>
          </template>
        </el-table-column>
        <el-table-column
          sortable="Yj"
          label="原金额(元)"
          width="140"
          :formatter="$tab_MoneyFormat"
        >
          <template #default="{ row }">
            <text
              style="color: #ffa500; font: 600 16px/16px ''; margin-left: 4px"
            >
              {{ row.Yj?.toFixed(2) }}
            </text>
          </template>
        </el-table-column>
        <el-table-column
          sortable="Bgj"
          label="变动金额(元)"
          width="140"
          :formatter="$tab_MoneyFormat"
        >
          <template #default="{ row }">
            <text
              v-if="row.Bgj < 0"
              style="color: #f00; font: 600 16px/16px ''; margin-left: 4px"
            >
              {{ row.Bgj?.toFixed(2) }}
            </text>
            <text
              v-else-if="row.Bgj > 0"
              style="color: #080; font: 600 16px/16px ''"
            >
              +{{ row.Bgj?.toFixed(2) }}
            </text>
            <text v-else style="font: 600 16px/16px ''">{{
              row.Bgj?.toFixed(2)
            }}</text>
          </template>
        </el-table-column>
        <el-table-column
          sortable="Xj"
          label="变更后金额(元)"
          width="140"
          :formatter="$tab_MoneyFormat"
        >
          <template #default="{ row }">
            <text style="color: #f00; font: 600 16px/16px ''; margin-left: 4px">
              {{ row.Xj?.toFixed(2) }}
            </text>
          </template>
        </el-table-column>
        <el-table-column
          prop="Bgsj"
          sortable="Bgsj"
          label="提交时间"
          align="center"
          width="170"
        />
        <el-table-column
          prop="Bgsj"
          sortable="Bgsj"
          label="审核信息"
          width="160"
          :formatter="
            (row) => $tab_GroupFormat(row.ShyhXm || '-', row.Shsj || '')
          "
        />
        <el-table-column
          prop="Shbz"
          label="审核意见"
          width="200"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          label="操作"
          width="170"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <template v-if="row.Shzt == null">
              <el-button
                type="success"
                :icon="Select"
                link
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes(
                    'B_ShangPinDingDanBianGengJiLu_List_审核'
                  )
                "
                :disabled="row.Shzt != null"
                @click="onCheck(row.Id, true)"
              >
                通过
              </el-button>
              <el-button
                type="danger"
                :icon="CloseBold"
                link
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes(
                    'B_ShangPinDingDanBianGengJiLu_List_审核'
                  )
                "
                :disabled="row.Shzt != null"
                @click="onCheck(row.Id, false)"
              >
                不通过
              </el-button>
            </template>
            <div v-else>
              <text v-if="row.Shzt == null">待审核</text>
              <text v-else-if="row.Shzt == true" style="color: #080"
                >已通过</text
              >
              <text v-else-if="row.Shzt == false" style="color: #f00">
                未通过
              </text>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  shangpindingdanbiangengjilu_list,
  shangpindingdanbiangengjilu_check,
} from "@/http/api";
import { ElMessageBox, ElMessage } from "element-plus";

export default {
  components: {},
  setup() {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Bgsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      shangpindingdanbiangengjilu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Bgsj",
          Asc: false,
        },
      });
      onLoad();
    };

    //变更记录审核
    const onCheck = (id, result) => {
      ElMessageBox.confirm(
        `您确定${
          result
            ? '<strong style="color:#080">同意</strong>'
            : '<strong style="color:#f00">拒绝</strong>'
        }此变更申请吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning",
        }
      ).then(() => {
        if (result == false) {
          ElMessageBox.prompt("请输入审核备注", "审核不通过", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            inputType: "textarea",
          }).then(({ value }) => {
            shangpindingdanbiangengjilu_check(id, result, value).then((res) => {
              if (res.status == 200) {
                ElMessage.success(res.data);
                onLoad();
              }
            });
          });
        } else {
          shangpindingdanbiangengjilu_check(id, result, "").then((res) => {
            ElMessage({
              message: res.data,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: res.status ? "success" : "error",
            });
            if (res.status == 200) {
              onLoad();
            }
          });
        }
      });
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onCheck,
    };
  },
};
</script>
<style lang="less" scoped>
.dhCss {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
