<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      padding: 10px;
    "
  >
    <div
      style="flex: 3; overflow: hidden; display: flex; flex-direction: column"
    >
      <ul
        style="
          width: 100%;
          border: 1px solid #e2e2e2;
          border-bottom: 0;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
        "
      >
        <li style="flex: 1">
          <el-select
            v-model="state.params.Params.GysId"
            placeholder="--请选择供应商--"
            value-key="Mc"
            :disabled="!Array.isArray(state.gysData)"
            style="width: 200px; margin-right: 10px"
            @change="onLoad()"
          >
            <el-option
              v-for="item in state.gysData"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
          </el-select>
          <el-input
            v-model="state.params.Params.Key"
            style="width: 200px"
            placeholder="关键字"
            clearable
            @clear="
              delete state.params.Params.Key;
              onLoad();
            "
          ></el-input>
          <el-dropdown
            split-button
            @click="onLoad"
            type="primary"
            style="margin-left: 10px"
          >
            查询
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                  重置
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
      </ul>
      <ul style="overflow: hidden; display: flex; flex-direction: column">
        <el-table
          :data="state.tableData"
          v-loading="!Array.isArray(state.tableData)"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          :default-sort="{
            prop: state.params.Order,
            order: state.params.Asc == false ? 'descending' : 'ascending',
          }"
          @sort-change="onSortChange"
        >
          <el-table-column width="60" align="center" fixed="left">
            <template #header>选择</template>
            <template #default="{ row }">
              <el-checkbox
                :checked="row.IsXz"
                @change="
                  ($event) => {
                    row.IsXz = $event;
                  }
                "
                :key="state.keys"
              />
            </template>
          </el-table-column>
          <el-table-column
            fixed
            label="#"
            width="50"
            type="index"
            :index="
              (index) =>
                (state.params.PageIndex - 1) * state.params.PageSize + index + 1
            "
            align="center"
          />
          <el-table-column label="商品信息" min-width="200">
            <template #default="{ row }">
              <div>
                <ul>
                  <text style="color: #000">{{ row.SpMc }}</text>
                  <text style="color: #a2a2a2">[{{ row.GysMc }}]</text>
                </ul>
                <ul style="font-size: 12px; line-height: 12px; color: #a2a2a2">
                  {{
                    row.SpPlMc +
                    "  " +
                    row.Syrq +
                    "  " +
                    row.SpTcMc +
                    "  " +
                    $priceFormat(row.Jsdj) +
                    "/" +
                    row.SpTcDw +
                    " x " +
                    row.Sl
                  }}
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单信息" min-width="200">
            <template #default="{ row }">
              <div>
                <ul>
                  <text style="color: #000">{{ row.Dh }}</text>
                  <text style="color: #a2a2a2">[{{ row.KhMc }}]</text>
                </ul>
                <ul style="font-size: 12px; line-height: 12px; color: #a2a2a2">
                  {{
                    row.KhLxr + "  " + row.KhSjhm + "  " + row.Xdsj
                  }}
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Jszj" label="结算金额(元)" width="120">
            <template #default="{ row }">
              <c_money :value="row.Jszj"></c_money>
            </template>
          </el-table-column>
          <el-table-column prop="Yfje" label="已付金额(元)" width="120">
            <template #default="{ row }">
              <c_money :value="row.Yfje"></c_money>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="Fl"
            label="返利"
            width="100"
            :formatter="$tab_MoneyFormat"
          >
            <template #default="{ row }">
              <el-input
                v-if="row.IsXz"
                v-model="row.Fl"
                type="number"
              ></el-input>
              <c_money v-else :value="row.Fl"></c_money>
            </template>
          </el-table-column> -->
        </el-table>
      </ul>
      <c_page
        :total="state.totalCount"
        :pageIndex="state.params.PageIndex"
        @onLoad="
          (pageIndex) => {
            state.params.PageIndex = pageIndex;
            onLoad();
          }
        "
      >
      </c_page>
    </div>
    <div
      style="
        flex: 1;
        margin-left: 10px;
        box-shadow: 0 0 10px 1px #e2e2e2;
        border-radius: 7px;
      "
    >
      <div style="padding: 30px 20px">
        <ul style="display: flex; border-bottom: 1px solid #e2e2e2">
          <li
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <text style="color: #080">{{ state.selectArr.length }}笔</text>
            <text style="color: #a2a2a2; font-size: 12px">已勾选</text>
          </li>
          <li
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-left: 1px solid #e2e2e2;
            "
          >
            <c_money :value="state.model.Zje"></c_money>
            <text style="color: #a2a2a2; font-size: 12px">合计结算金额</text>
          </li>
        </ul>
        <ul style="display: flex">
          <li
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <c_money :value="state.model.Fl"></c_money>
            <text style="color: #a2a2a2; font-size: 12px">合计返利金额</text>
          </li>
          <li
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-left: 1px solid #e2e2e2;
            "
          >
            <c_money :value="state.model.Yfje"></c_money>
            <text style="color: #a2a2a2; font-size: 12px">合计应付金额</text>
          </li>
        </ul>
        <ul>
          <li>付款方式：</li>
          <el-radio-group v-model="state.model.Fkfs">
            <el-radio :label="1">转账</el-radio>
            <el-radio :label="2">现金</el-radio>
          </el-radio-group>
          <li style="margin-top: 10px">选择到账银行：</li>
          <el-select
            v-model="state.model.GysZh"
            placeholder="--请选择到账银行：--"
            value-key="Id"
            style="margin-top: 10px"
          >
            <el-option
              v-for="item in state.zhData || []"
              :key="item"
              :label="
                '卡号：' +
                item.Kh +
                '，收款人：' +
                item.Skr +
                '，开户行：' +
                item.Khyh
              "
              :value="item"
            />
            <template #footer>
              <el-button size="small" @click="onLoadGysZh()"> 刷新 </el-button>
            </template>
          </el-select>
          <el-input
            type="textarea"
            v-model="state.model.Bz"
            :autosize="{ minRows: 4 }"
            style="margin: 10px 0"
            placeholder="备注"
          />
          <el-button type="primary" @click="onSubmit()">提交对账</el-button>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  shangpindingdanmingxi_dz_list,
  gongyingshang_select,
  gongyingshangzhanghu_selectbygysid,
  duizhangdan_add,
} from "@/http/api";
import { ElMessage } from "element-plus";
import store from "@/store";

export default {
  components: {},
  setup() {
    const state = reactive({
      tableData: [],
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: { TableName: "DuiZhangDan_List" },
      },
      model: { Fkfs: 1 },
    });
    onMounted(() => {
      loadGys();
    });

    const onLoad = () => {
      delete state.tableData;
      shangpindingdanmingxi_dz_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });

      onLoadGysZh();
    };

    //加载供应商下拉数据
    const loadGys = () => {
      gongyingshang_select().then((res) => {
        if (res.status == 200) state.gysData = res.data;
      });
    };

    const onLoadGysZh = () => {
      if (!state.params.Params.GysId) {
        ElMessage({
          type: "warning",
          message: "请选择供应商",
          grouping: true,
        });
        return false;
      }
      gongyingshangzhanghu_selectbygysid(state.params.Params.GysId).then(
        (res) => {
          if (res.status == 200) state.zhData = res.data;
        }
      );
    };

    const onSubmit = () => {
      if (!state.params.Params.GysId) {
        ElMessage.warning("请选择供应商!");
        return false;
      }
      const mxs = state.tableData
        .filter((w) => w.IsXz)
        .map((m) => {
          return m.Id;
        });
      if (mxs.length <= 0) {
        ElMessage.warning("请选择订单!");
        return false;
      }

      if (!state.model.Fkfs) {
        ElMessage.warning("请选择付款方式!");
        return false;
      }

      if (!state.model.GysZh) {
        ElMessage.warning("请选择到账银行!");
        return false;
      }

      const data = {
        GysId: state.params.Params.GysId,
        Fkfs: state.model.Fkfs,
        Dzyh: state.model.GysZh.Khyh,
        Dzkh: state.model.GysZh.Kh,
        Skr: state.model.GysZh.Skr,
        Yfje: onBuildTotal().totalJszj,
        Bz: state.model.Bz,
        Mxs: mxs,
      };
      duizhangdan_add(data).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          store.commit("onCloseCurrent");
          store.commit("openMenu", {
            path: "DuiZhangDanList",
            title: "对账付款",
            refresh: true,
          });
        }
      });
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    const onBuildTotal = () => {
      let totalCount = 0,
        totalJszj = 0,
        totalFlje = 0,
        totalYfje = 0;
      state.tableData?.forEach((e, index) => {
        if (e.IsXz) {
          totalCount += index;
          totalJszj += e.Jszj;
          totalFlje += e.Fl;
          totalYfje += e.Yfje;
        }
      });

      return {
        totalCount,
        totalJszj,
        totalFlje,
        totalYfje,
      };
    };

    return {
      state,
      onLoad,
      onReset,
      onBuildTotal,
      onSubmit,
      onLoadGysZh,
    };
  },
};
</script>
<style>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
