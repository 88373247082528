<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <el-image
      v-if="!state.model"
      :src="require('@/assets/images/loading.gif')"
      style="margin: auto; margin-top: 15vh"
    ></el-image>
    <template v-else>
      <div v-if="state.model.Mxs?.length <= 0" style="text-align: center">
        <el-image :src="require('@/assets/images/nodata.png')"></el-image>
      </div>
      <template v-else>
        <div style="display: flex; align-items: center; padding: 10px">
          <li style="flex: 1">{{ state.model?.Bh }}</li>
          <li style="color: #a2a2a2">{{ state.model?.Cjsj }}</li>
        </div>
        <div style="flex: 1; overflow-y: auto; padding: 10px">
          <ul
            style="
              box-shadow: 0 0 10px 1px #e2e2e2;
              border-radius: 7px;
              padding: 10px;
              display: flex;
              height: 120px;
            "
            :style="{ marginTop: index != 0 ? '15px' : '' }"
            v-for="(item, index) in state.model?.Mxs"
            :key="item"
          >
            <li style="width: 20vw; height: 100%">
              <el-image
                :src="$http.defaults.baseURL + item.SpFm"
                fit="contain"
                style="height: 100%"
              ></el-image>
            </li>
            <div
              style="
                margin: 0 10px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1;
              "
            >
              <ul style="margin-left: -5px; font-weight: 600; font-size: 14px">
                {{
                  "【" + item.SpMc + "】" + item.SpTcMc
                }}
              </ul>
              <ul>
                <li
                  style="
                    background-color: #1779de;
                    display: flex;
                    width: fit-content;
                    color: #fff;
                    padding: 2px 5px;
                    border-radius: 2px;
                    font-size: 12px;
                  "
                >
                  {{ item.SpPlMc }}
                </li>
              </ul>
              <ul style="display: flex; align-items: center">
                <li style="flex: 1">
                  <c_money :value="item.Khqk" size="20"></c_money>
                </li>
                x{{
                  item.Sl
                }}
              </ul>
              <ul style="display: flex; align-items: center">
                <text style="color: #a2a2a2">使用日期：</text>
                {{
                  item.Syrq
                }}
              </ul>
            </div>
          </ul>
        </div>
        <div style="margin: 20px">
          <el-button
            type="success"
            v-if="props.type == 'T_JSAPI'"
            style="width: 100%"
            size="large"
            @click="onSubmit"
          >
            立即支付￥{{ (state.model?.Khqk || 0).toFixed(2) }}
          </el-button>
          <el-button
            type="primary"
            v-else-if="props.type == 'A_JSAPI'"
            style="width: 100%"
            size="large"
            @click="onSubmit"
          >
            立即支付￥{{ (state.model?.Khqk || 0).toFixed(2) }}
          </el-button>
          <!-- <el-button
          type="danger"
          v-else
          style="width: 100%"
          size="large"
          @click="onSubmit"
        >
          支付￥{{ (state.model?.Xsj || 0).toFixed(2) }}
        </el-button> -->
          <!-- <iframe :src="state.payUrl" style="width: 50vw; height: 50vh"></iframe> -->
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { huifu_getdetail } from "@/http/api";

export default {
  props: {
    dh: String,
    type: String,
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {
      if (props.dh) {
        huifu_getdetail(props.dh).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          }
        });
      }
    });

    const onSubmit = () => {
      //   huifu_create(props.type, { ddId: state.model.Id }).then((res) => {
      //       if (res.status == 200)
      //       {
      //           if (props.type == "A_JSAPI")
      //           {
      //             window.location.href = res.data;
      //           } else {

      //         }
      //     }
      //   });

      emit("onSubmit", { ddId: state.model.Id });

      //   huifu_create("123", { type: state.type }).then((res) => {
      //     // state.payUrl = res.data;
      //     window.location.href = res.data;
      //     // const iframe = document.createElement("iframe");
      //     // iframe.style.display = "none";
      //     // iframe.setAttribute("src", res.data);
      //     // iframe.setAttribute(
      //     //   "sandbox",
      //     //   "allow-top-navigation-by-user-activation allow-scripts"
      //     // );
      //     // iframe.onload = function () {
      //     //   // 4秒后删除 iframe，否则会跳回上一个页面
      //     //   setTimeout(() => {
      //     //     document.body.removeChild(iframe);
      //     //   }, 4000);
      //     // };

      //     // document.body.appendChild(iframe);

      //     // var iframe = document.createElement("iframe");
      //     // iframe.src = res.data;
      //     // iframe.sandbox = "allow-scripts allow-same-origin";
      //     // document.body.appendChild(iframe);
      //     // console.log(123);
      //   });
    };
    return { state, props, onSubmit, emit };
  },
};
</script>
<style scoped></style>
