<template>
  <el-table
    :data="state.tableData"
    v-loading="!Array.isArray(state.tableData)"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <el-table-column fixed label="#" width="50" type="index" align="center" />
    <el-table-column prop="IsQy" sortable="IsQy" label="状态/编号" width="130">
      <template #default="{ row }">
        <div style="display: flex; flex-direction: column">
          <ul :style="{ color: row.Zt.Color }">
            {{
              row.Zt.Text
            }}
          </ul>
          <ul>
            {{
              row.Bh
            }}
          </ul>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="Ly"
      sortable="Ly"
      label="来源"
      :formatter="$tab_StatusFormat"
    />
    <el-table-column
      prop="Mc"
      sortable="Mc"
      label="客户"
      :formatter="
        (row) => $tab_GroupFormat(row.KhMc, row.KhLxr + ' ' + row.KhSjhm)
      "
      min-width="140"
    />
    <el-table-column prop="Bz" label="订单备注" :formatter="$tab_TextFormat" />
    <el-table-column prop="Cjsj" label="下单时间" align="center" width="140" />
    <el-table-column prop="Sl" label="商品数" align="center" />
    <el-table-column prop="Ycps" label="已出票" align="center" />
    <el-table-column label="未出票" align="center">
      <template #default="{ row }">
        <text>{{ row.Sl - row.Ycps }}</text>
      </template>
    </el-table-column>
    <el-table-column
      sortable="Xsj"
      label="结算价/销售价(元)"
      width="160"
      :formatter="
        (row) => $tab_GroupFormat(row.Jsj.toFixed(2), row.Xsj.toFixed(2))
      "
      align="center"
    />
    <el-table-column
      sortable="Ysje"
      label="已收/未收(元)"
      width="140"
      :formatter="
        (row) =>
          $tab_GroupFormat(row.Ysje.toFixed(2), (row.Jsj - row.Ysje).toFixed(2))
      "
      align="center"
    />
    <el-table-column prop="Fl" sortable="Fl" label="返利(元)" width="110" />
    <el-table-column
      sortable="Yfje"
      label="已付/未付"
      width="110"
      :formatter="
        (row) =>
          $tab_GroupFormat(row.Ysje.toFixed(2), (row.Jsj - row.Yfje).toFixed(2))
      "
      align="center"
    />
    <el-table-column
      label="操作"
      width="100"
      align="center"
      fixed="right"
      className="tabBtn"
    >
      <template #default="{ row }">
        <el-button
          type="primary"
          :icon="Edit"
          link
          @click="
            $store.commit('openMenu', {
              path: 'TongHangKeHuDingDanDetail',
              title: '商品订单详情',
              data: { id: row.Id },
            })
          "
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('B_TongHangKeHuDingDan_编辑')
          "
        >
          管理
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpin_getddbyid } from "@/http/api";
export default {
  props: {
    spId: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      shangpin_getddbyid(props.spId).then((res) => {
        if (res.status == 200) {
          state.tableData = res.data;
        }
      });
    });
    return { state };
  },
};
</script>
<style scoped></style>
