<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          align="center"
          width="140"
        >
          <template #default="{ row }">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                line-height: 18px;
              "
            >
              <c_status :model="row.Zt"></c_status>
              <ul class="secondUl">
                {{
                  row.Bh
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          sortable="Zzfs"
          label="到账方式/到账银行"
          min-width="120"
        >
          <template #default="{ row }">
            <div style="line-height: 18px">
              <c_status :model="row.Zzfs"></c_status>
              <ul class="secondUl">
                {{
                  row.Dzyh
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable="Fkr" label="付款人/付款账号" min-width="120">
          <template #default="{ row }">
            <div style="line-height: 18px">
              <ul>
                {{
                  row.Fkr || "-"
                }}
              </ul>
              <ul class="secondUl">
                {{
                  row.Fkzh
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Fklsh"
          label="付款流水号"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          sortable="Dzje"
          label="到账金额/手续费(元)"
          min-width="120"
        >
          <template #default="{ row }">
            <div style="line-height: 18px">
              <ul>
                {{
                  row.Dzje
                }}
              </ul>
              <ul class="secondUl">
                {{
                  row.Sxf
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Cjsj"
          sortable="Cjsj"
          label="创建信息"
          width="160"
          :formatter="(row) => $tab_GroupFormat(row.CjrXm, row.Cjsj)"
        />
        <el-table-column
          label="操作"
          width="160"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Select"
              link
              @click="
                state.selectId = row.Id;
                state.showRl = true;
              "
              v-if="
                row.Zt?.Key == 0 &&
                ($store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes(
                    'B_DaoZhangGongShi_Rl_认领'
                  ))
              "
            >
              认领
            </el-button>
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                state.selectId = row.Id;
                state.showDetail = true;
              "
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showRl"
        title="到账认领"
        @close="state.showRl = false"
      >
        <c_rl
          @close="
            state.showRl = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_rl>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showDetail"
        title="到账认领详情"
        @close="state.showDetail = false"
      >
        <c_detail
          @close="
            state.showDetail = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_detail>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { daozhanggongshi_list } from "@/http/api";

import c_rl from "./RlDetail.vue";
import c_detail from "./Detail.vue";
export default {
  components: {
    c_rl,
    c_detail,
  },
  setup() {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: { TableName: "DaoZhangGongShi_Rl" },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      daozhanggongshi_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          console.log(list);
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
    };
  },
};
</script>
<style>
.secondUl {
  font-size: 12px !important;
  color: #a2a2a2;
}
</style>
