<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="domForm"
        :rules="rules"
      >
        <el-form-item
          label="上级组织"
          prop="SjId"
          :rules="[
            { required: true, message: '请选择上级组织', trigger: 'blur' },
          ]"
        >
          <el-tree-select
            v-model="state.model.SjId"
            :data="state.treeData"
            node-key="Id"
            clearable
            accordion
            :props="{ label: 'Mc', children: 'Xjs' }"
            :render-after-expand="false"
            :highlight-current="true"
            :default-expand-all="true"
            :check-strictly="true"
            placeholder="--上级组织--"
            :disabled="props.id"
            @clear="
              delete state.model.SjId;
              onLoadXh();
            "
          >
            <template #default="{ data }">
              <div
                style="display: flex; align-items: center"
                @click="onSjSelect(data)"
              >
                <el-icon>
                  <Guide />
                </el-icon>
                {{ data.Mc }}
              </div>
            </template>
          </el-tree-select>
        </el-form-item>
        <el-form-item
          label="编号"
          prop="Bm"
          :rules="[{ required: true, message: '请输入编号', trigger: 'blur' }]"
          v-if="props.id"
        >
          <el-input v-model="state.model.Bm" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="Mc"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Mc"></el-input>
        </el-form-item>
        <el-form-item label="序号" prop="Xh">
          <el-input type="number" v-model="state.model.Xh"></el-input>
        </el-form-item>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, getCurrentInstance } from "vue";
import {
  bumen_tree,
  bumen_getxh,
  bumen_add,
  bumen_edit,
  bumen_detail,
} from "@/http/api";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {
        Type: 1,
      },
    });
    onMounted(() => {
      bumen_tree({}).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });

      if (props.id) {
        bumen_detail(props.id).then((res) => {
          state.model = res.data;
        });
      } else {
        onLoadXh();
      }
    });

    //加载序号
    const onLoadXh = (id) => {
      bumen_getxh(id).then((res) => {
        if (res.status == 200) state.model.Xh = res.data;
      });
    };

    //选择上级部门，赋值上级部门的编码
    const onSjSelect = (data) => {
      state.model.SjBm = data.Bm;
      onLoadXh(data.Id);
    };

    const onSubmit = () => {
      if (props.id) {
        bumen_edit(props.id, state.model).then((res) => {
          proxy.$post_response(res, emit);
        });
      } else {
        bumen_add(state.model).then((res) => {
          proxy.$post_response(res, emit);
        });
      }
    };

    return { state, onSubmit, props, onLoadXh, onSjSelect };
  },
};
</script>
<style scoped></style>
