<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="状态"
          prop="IsQy"
          :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        >
          <el-switch v-model="state.model.IsQy" />
        </el-form-item>
        <el-form-item
          label="开户银行"
          prop="Khyh"
          :rules="[
            { required: true, message: '请输入开户银行', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Khyh" />
        </el-form-item>
        <el-form-item
          label="账号/卡号"
          prop="Kh"
          :rules="[
            { required: true, message: '请输入账号/卡号', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Kh"></el-input>
        </el-form-item>
        <el-form-item
          label="收款人"
          prop="Skr"
          :rules="[
            { required: true, message: '请输入收款人', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Skr"></el-input>
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import {
  gongyingshangzhanghu_add,
  gongyingshangzhanghu_detail,
  gongyingshangzhanghu_edit,
  zidian_select,
} from "@/http/api";

export default {
  props: {
    gysId: Number,
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {
        IsQy: true,
      },
      gysData: [],
    });
    onMounted(() => {
      onLoadYh();

      if (props.id) {
        gongyingshangzhanghu_detail(props.id).then((res) => {
          if (res.status == 200) state.model = res.data;
        });
      }
    });

    //加载银行数据
    const onLoadYh = () => {
      zidian_select("银行").then((res) => {
        console.log(res.data);
        if (res.status == 200) state.yhData = res.data;
      });
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            gongyingshangzhanghu_edit(props.id, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          } else {
            gongyingshangzhanghu_add(props.gysId, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          }
        }
      });
    };

    return { state, formDom, onSubmit, props, onLoadYh };
  },
};
</script>
<style scoped></style>
