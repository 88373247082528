<template>
  <div class="treeListBox">
    <div class="lBox">
      <ul style="text-align: right; padding-right: 10px">
        <el-button
          link
          :icon="RefreshRight"
          @click="
            delete state.treeData;
            onLoadTree();
          "
        >
          刷新
        </el-button>
      </ul>
      <ul
        style="overflow: auto; padding-right: 10px; min-width: 200px"
        v-loading="!state.treeData"
      >
        <el-tree
          :data="state.treeData"
          :props="{ label: 'Mc', children: 'Xjs' }"
          accordion
          :highlight-current="true"
          :default-expand-all="true"
          node-key="Id"
          :current-node-key="state.params.Params.XjId"
          :expand-on-click-node="false"
        >
          <template #default="{ data }">
            <div
              style="display: flex; align-items: baseline; color: #a2a2a2"
              v-if="data.Lx == 1"
            >
              <el-icon style="margin-right: 2px" size="12">
                <Avatar />
              </el-icon>
              {{ data.Mc }}
            </div>
            <div
              style="display: flex; align-items: baseline"
              v-else
              @click="
                state.params.Params.JlId = data.Id;
                onLoad();
              "
            >
              <el-icon style="margin-right: 2px" size="12">
                <User />
              </el-icon>
              {{ data.Mc }}
            </div>
          </template>
        </el-tree>
      </ul>
    </div>
    <div class="rBox">
      <ul class="sBox">
        <li style="flex: 1">
          <el-input
            v-model="state.params.Params.Key"
            style="width: 15%"
            placeholder="关键字"
            clearable
            @clear="
              delete state.params.Params.Key;
              onLoadTable();
            "
          ></el-input>
          <el-dropdown
            split-button
            @click="onLoadTable()"
            type="primary"
            style="margin-left: 10px"
          >
            查询
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                  重置
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li style="display: flex; align-items: center">
          <el-button type="success" icon="Select" @click="onClose">
            完成
          </el-button>
        </li>
      </ul>
      <ul class="tBox">
        <el-table
          :data="state.tableData"
          v-loading="!Array.isArray(state.tableData)"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          :default-sort="{
            prop: state.params.Order,
            order: state.params.Asc == false ? 'descending' : 'ascending',
          }"
          @sort-change="onSortChange"
          highlight-current-row
          ref="tableDom"
          @row-click="onRowClick"
        >
          <el-table-column
            fixed
            label="#"
            width="50"
            type="index"
            :index="
              (index) =>
                (state.params.PageIndex - 1) * state.params.PageSize + index + 1
            "
            align="center"
          />
          <el-table-column
            prop="Bh"
            sortable="Bh"
            label="客户编号"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
            width="140"
          />

          <el-table-column
            prop="Mc"
            sortable="Mc"
            label="客户名称"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
            min-width="160"
          />
          <el-table-column
            prop="IsXy"
            sortable="IsXy"
            label="协议客户"
            width="110"
          >
            <template #default="{ row }">
              <text v-if="row.IsXy" style="color: #1779de">是</text>
              <text v-else style="color: #f00">否</text>
            </template>
          </el-table-column>
          <el-table-column
            prop="Lxr"
            label="联系人"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
          <el-table-column
            prop="Sjhm"
            label="联系电话"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
        </el-table>
      </ul>
      <c_page
        :total="state.totalCount"
        :pageIndex="state.params.PageIndex"
        @onLoad="
          (pageIndex) => {
            state.params.PageIndex = pageIndex;
            onLoadTable();
          }
        "
      >
      </c_page>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { yonghu_tree, kehu_list } from "@/http/api";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          Lx: 3,
          TableName: "KeHu_TongHang",
        },
        Order: "Cjsj",
        Asc: false,
      },
    });

    onMounted(() => {
      onLoadTree();
      onLoad();
    });

    //加载用户树
    const onLoadTree = () => {
      yonghu_tree(state.params.Params).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });
    };

    const onLoad = () => {
      delete state.tableData;
      kehu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoadTree();
      onLoad();
    };

    const onRowClick = (row) => {
      state.selectModel = row;
    };

    const onClose = () => {
      emit("close", {
        Id: state.selectModel.Id,
        Mc: state.selectModel.Mc,
        IsXy: state.selectModel.IsXy,
      });
    };

    return {
      state,
      onLoadTree,
      onReset,
      onLoad,
      onRowClick,
      onClose,
    };
  },
};
</script>
<style scoped></style>
