<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
    "
  >
    <div class="infoBox">
      <ul style="position: relative">
        <el-button
          type="primary"
          :icon="Edit"
          link
          @click="state.showCompile = true"
          style="position: absolute; top: 0; right: 0"
          >编辑
        </el-button>
        <li>
          <div class="title">
            <li>状态</li>
            ：
          </div>
          <div class="content">
            <text style="color: #1779de" v-if="state.model.IsQy">启用</text
            ><text style="color: #f00" v-else>停售</text>
          </div>
        </li>
        <li>
          <div class="title">
            <li>品类</li>
            ：
          </div>
          <div class="content">
            {{ state.model.PlMc }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>供应商</li>
            ：
          </div>
          <div class="content" style="padding-right: 50px">
            {{ state.model.GysMc }}
          </div>
        </li>
      </ul>
      <ul>
        <li>
          <div class="title">
            <li>商品名称</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Mc }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>联系电话</li>
            ：
          </div>
          <div>
            {{ state.model.Dh || "-" }}
          </div>
        </li>
        <li>
          <div class="title">
            <li>地址</li>
            ：
          </div>
          <div class="content">
            {{ state.model.Dz || "-" }}
          </div>
        </li>
      </ul>
      <ul>
        <div class="title">
          <li>商品简介</li>
          ：
        </div>
        <div class="content">
          {{ state.model.Jj }}
        </div>
      </ul>
    </div>
    <el-tabs
      v-model="state.tabName"
      :before-leave="onTabsChange"
      class="comTab detailTab"
      style="position: inherit"
    >
      <el-tab-pane label="商品详情" name="xq">
        <el-form
          :model="state.model"
          label-width="100px"
          :scroll-to-error="true"
          ref="formDom"
          inline
          style="position: relative"
        >
          <el-form-item
            label="目的地"
            prop="Mdds"
            :rules="[
              { required: true, message: '请选择目的地', trigger: 'blur' },
            ]"
            style="width: 100%"
          >
            <el-select
              v-model="state.model.Mdds"
              multiple
              placeholder="--请选择目的地--"
              :filter-method="onMddFilter"
              :disabled="!Array.isArray(state.mddData)"
            >
              <el-option
                v-for="item in state.mddData"
                :key="item"
                :label="item.Mc"
                :value="item.Mc"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="商品图片"
            prop="Tps"
            :rules="[
              { required: true, message: '请选择上传图片', trigger: 'blur' },
            ]"
            style="width: 99%; display: flex; align-items: center"
          >
            <el-radio-group
              v-model="state.model.Fm"
              style="display: flex; line-height: normal"
            >
              <div
                v-for="(tp, index) in state.model.Tps"
                :key="tp"
                style="
                  margin-right: 10px;
                  border-radius: 5px;
                  overflow: hidden;
                  position: relative;
                "
                class="uploadTpBox"
              >
                <el-image class="uploadTp" :src="$http.defaults.baseURL + tp" />
                <ul style="text-align: center">
                  <el-radio :label="tp" size="small">设为封面</el-radio>
                </ul>
                <div class="uploadDel">
                  <el-icon
                    size="30"
                    style="margin: auto; cursor: pointer"
                    @click="onDelTp(index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>
            </el-radio-group>
            <el-upload
              :show-file-list="false"
              :auto-upload="false"
              :multiple="true"
              accept=".jpeg,.jpg,.png,.gif"
              :on-change="onUploadChange"
              :on-exceed="onUploadExceed"
            >
              <template #trigger>
                <div class="uploadAdd">
                  <el-icon size="30" style="margin: auto; cursor: pointer"
                    ><Plus
                  /></el-icon>
                </div>
              </template>
            </el-upload>

            <!-- <el-upload
              :action="$http.defaults.baseURL + '/Home/Upload'"
              :show-file-list="false"
              :on-success="onUploadSuccess"
              name="file"
              :headers="{
                Authorization: state.token,
              }"
              class="proUpload"
            >
              <el-radio-group v-model="state.model.Fm" style="display: flex">
                <div
                  v-for="(item, index) in state.model?.Tps"
                  :key="item"
                  class="proUpBox"
                >
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="$http.defaults.baseURL + '/' + item"
                  />
                  <el-radio :label="item" size="small">设为封面</el-radio>
                  <div class="proUpShade">
                    <el-icon
                      color="#fff"
                      size="30"
                      style="margin: auto; cursor: pointer"
                      @click="onDelTp(item, index)"
                      ><Delete
                    /></el-icon>
                  </div>
                </div>
              </el-radio-group>
              <template #trigger>
                <el-icon
                  class="addPic"
                  size="30"
                  :style="{
                    'margin-bottom': state.model?.Tps?.length > 0 ? '28px' : '',
                  }"
                >
                  <Plus />
                </el-icon>
              </template>
            </el-upload> -->

            <!-- <el-upload
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-change="onUploadChange"
              multiple
              ref="uploadDom"
              v-model:file-list="state.model.Tps"
            >
              <el-icon><Plus /></el-icon>
              <template #file="{ file }">
                <el-radio-group
                  v-model="state.model.Fm"
                  style="align-items: normal; flex-wrap: nowrap"
                >
                  <div style="display: flex; flex-direction: column">
                    <ul style="flex: 1; overflow: hidden; position: relative">
                      <el-image
                        class="el-upload-list__item-thumbnail"
                        :src="
                          file.url.includes('/image/product')
                            ? $http.defaults.baseURL + file.url
                            : file.url
                        "
                        style="width: 100%"
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="onHandlePreview(file)"
                        >
                          <el-icon><zoom-in /></el-icon>
                        </span>
                        <span
                          class="el-upload-list__item-delete"
                          @click="onHandleRemove(file)"
                        >
                          <el-icon><Delete /></el-icon>
                        </span>
                      </span>
                    </ul>
                    <ul style="text-align: center">
                      <el-radio :label="file?.uid">设为封面</el-radio>
                    </ul>
                  </div>
                </el-radio-group>
              </template>
            </el-upload> -->
            <el-dialog v-model="state.dialogVisible">
              <el-image fit="cover" :src="state.dialogImageUrl" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="商品详情" style="width: 99%">
            <c_editor :value="state.model.Xq" ref="editorDom"></c_editor>
          </el-form-item>
          <el-form-item label="&nbsp;" style="width: 100%">
            <el-button type="primary" @click="onSubmit()">提交</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="预定须知" name="xz">
        <el-input
          type="textarea"
          v-model="state.model.Xz"
          :autosize="{ minRows: 10 }"
          placeholder="预定须知"
        />
        <el-button type="primary" @click="onSubmit()" style="margin-top: 10px">
          提交
        </el-button>
      </el-tab-pane>
      <el-tab-pane label="价格套餐" name="tc">
        <c_ShangPinTcList
          :spId="props.data?.id"
          :key="state.tcKey"
          @onLoad="onLoadTc"
        ></c_ShangPinTcList>
      </el-tab-pane>
      <el-tab-pane name="gz">
        <template #label>
          <text :style="{ color: state.tcData?.length > 0 ? '' : '#a2a2a2' }">
            销售控制
          </text>
        </template>
        <c_ShangPinGzList
          :spId="props.data?.id"
          :key="state.gzKey"
        ></c_ShangPinGzList>
      </el-tab-pane>
      <el-tab-pane name="lsgz">
        <template #label>
          <text :style="{ color: state.tcData?.length > 0 ? '' : '#a2a2a2' }">
            历史销控
          </text>
        </template>
        <c_ShangPinGzOldList
          :spId="props.data?.id"
          :key="state.lsgzKey"
        ></c_ShangPinGzOldList>
      </el-tab-pane>
      <el-tab-pane label="关联订单" name="dd">
        <c_ShangPinDdList
          :spId="props.data?.id"
          :key="state.ddKey"
        ></c_ShangPinDdList>
      </el-tab-pane>
    </el-tabs>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        title="修改商品"
        @close="state.showCompile = false"
      >
        <c_Compile
          @close="
            state.showCompile = false;
            onLoadDetail();
          "
          :id="props.data?.id"
        ></c_Compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  zidian_select,
  shangpin_detail,
  shangpintaocan_all,
  shangpin_edit,
  shangpin_uploadtp,
} from "@/http/api";
import { ElMessage } from "element-plus";

import c_Compile from "./Compile.vue";
export default {
  props: {
    data: Object,
  },
  name: "SheZhi",
  components: { c_Compile },
  setup(props) {
    const editorDom = ref(null);
    const formDom = ref(null);
    const uploadDom = ref(null);
    const state = reactive({
      tabName: "xq",
      model: {
        Tps: [],
        Mdds: [],
      },
      pics: [],
      tcData: [],
      delPics: [], //删除的图片地址
    });
    onMounted(() => {
      if (props.data?.id) {
        onLoadDetail();
      }
      loadMdd();
      onLoadTc();
    });

    const onLoadDetail = () => {
      state.pics = [];
      state.delPics = [];
      shangpin_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          state.model = res.data;
          if (state.model.Fm) {
            state.model.Tps = [state.model.Fm, ...state.model.Tps];
          }
        }
      });
    };

    //加载品类下拉数据
    const loadMdd = () => {
      zidian_select("目的地").then((res) => {
        if (res.status == 200) {
          state.mddData = res.data;
          state.mddSource = res.data;
        }
      });
    };

    //下拉框自定义筛选
    const onMddFilter = (query) => {
      state.mddData = state.mddSource.filter(
        (w) =>
          w.Py?.toUpperCase().includes(query.toUpperCase()) ||
          w.Sp?.toUpperCase().includes(query.toUpperCase()) ||
          w.Mc?.includes(query.toUpperCase())
      );
    };

    //加载套餐数据
    const onLoadTc = () => {
      shangpintaocan_all(props.data?.id).then((res) => {
        if (res.status == 200) {
          state.tcData = res.data;
        }
      });
    };

    //选项卡切换事件
    const onTabsChange = (value) => {
      if (value == "tc") {
        state.tcKey = Math.random();
      } else if (value == "gz") {
        if (state.tcData?.length <= 0) {
          ElMessage({
            type: "warning",
            message: "请先添加商品套餐！",
            grouping: true,
          });
          return false;
        }
        state.gzKey = Math.random();
      } else if (value == "lsgz") {
        state.lsgzKey = Math.random();
      } else if (value == "dd") {
        state.ddKey = Math.random();
      }
    };

    //选择文件
    const onUploadChange = (file) => {
      let formData = new FormData();
      formData.append("files", file.raw);
      shangpin_uploadtp(formData).then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          state.model.Tps = [...state.model.Tps, ...res.data];
          if (!state.model.Fm) state.model.Fm = state.model.Tps[0];
        }
      });
      // state.pics.push(file.raw);
      // if (!state.model.Fm) state.model.Fm = file.uid;
    };

    //图片预览
    const onHandlePreview = (file) => {
      state.dialogImageUrl = file.url;
      state.dialogVisible = true;
    };

    //删除图片
    const onHandleRemove = (file) => {
      uploadDom.value.handleRemove(file);
      state.pics.splice(state.pics.indexOf(file.raw), 1);
      if (file.url.includes("image/product")) state.delPics.push(file.url);
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (!valid) return false;

        state.model.Mdd = state.model.Mdds.join("、");
        state.model.Xq = editorDom.value?.state?.value;

        // let formData = new FormData();
        // //把接口需要的参数带进去
        // formData.append("Id", state.model.Id);
        // formData.append("IsQy", state.model.IsQy);
        // formData.append("PlId", state.model.PlId);
        // formData.append("GysId", state.model.GysId);
        // formData.append("Mc", state.model.Mc || "");
        // formData.append("Dh", state.model.Dh || "");
        // formData.append("Dz", state.model.Dz || "");
        // formData.append("Mdd", state.model.Mdds.join("、"));
        // formData.append("Jj", state.model.Jj || "");
        // formData.append("Fm", state.model.Fm || "");
        // formData.append("Xq", editorDom.value?.state?.value);
        // formData.append("Xz", state.model.Xz || "");
        // console.log(state.model.Tps);
        // formData.append("Tps", state.model.Tps);

        // state.pics.forEach((file) => {
        //   formData.append(file.uid, file);
        // });
        shangpin_edit(state.model).then((res) => {
          if (res.status == 200) {
            ElMessage.success(res.data);
            onLoadDetail();
          }
        });
      });
    };

    const onDelTp = (index) => {
      state.model.Tps.splice(index, 1);
      if (state.model.Fm) {
        if (state.model.Tps?.length > 0) {
          if (!state.model.Tps.includes(state.model.Fm)) {
            state.model.Fm = state.model.Tps[0];
          }
        } else {
          delete state.model.Fm;
        }
      }
    };

    return {
      state,
      props,
      onLoadDetail,
      onMddFilter,
      onTabsChange,
      uploadDom,
      formDom,
      onUploadChange,
      onHandlePreview,
      onHandleRemove,
      onSubmit,
      editorDom,
      onDelTp,
      onLoadTc,
    };
  },
};
</script>
<style scoped lang="less">
.colorA2 {
  color: rgb(180, 180, 180);
}

.proUpBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  position: relative;

  .proUpShade {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }

  &:hover .proUpShade {
    display: flex !important;
  }
}

.uploadAdd {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px dashed #e2e2e2;
  background-color: #f5f7fa;
  display: flex;
  color: #a2a2a2;

  &:hover {
    background-color: rgba(100, 100, 100, 0.2) !important;
    color: #fff !important;
  }
}

.uploadDel {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  color: #fff;
  display: none;
}

.uploadTp {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.uploadTpBox:hover .uploadDel {
  display: flex !important;
}
</style>
