<template>
  <div class="pageControl">
    <el-pagination
      :total="props.total"
      :page-size="20"
      @current-change="onChangePageIndex"
    />
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  props: {
    total: Number,
    pageIndex: Number,
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {});

    //分页控件点击事件
    const onChangePageIndex = (index) => {
      emit("onLoad", index);
    };

    return { state, props, onChangePageIndex };
  },
};
</script>
<style scoped></style>
