//周数组
export const weekArr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

//证件类型
export const cardTypeArr = [
  {
    Value: "身份证",
    Color: "#fff",
    BgColor: "#409EFF",
  },
  {
    Value: "护照",
    Color: "#fff",
    BgColor: "#409EFF",
  },
  {
    Value: "军官证",
    Color: "#fff",
    BgColor: "#409EFF",
  },
  {
    Value: "台胞证",
    Color: "#fff",
    BgColor: "#409EFF",
  },
  {
    Value: "港澳通行证",
    Color: "#fff",
    BgColor: "#409EFF",
  },
  {
    Value: "其他",
    Color: "#fff",
    BgColor: "#409EFF",
  },
];

//旅游订单分类
export const lyddFlData = [
  { Lx: "线路", Mc: "成人价", Dw1: "人" },
  { Lx: "酒店", Mc: "标准间", Dw1: "间", Dw2: "夜" },
  { Lx: "机票", Mc: "经济舱", Dw1: "张" },
  { Lx: "火车票", Mc: "二等座", Dw1: "张" },
  { Lx: "门票", Mc: "成人票", Dw1: "张" },
  { Lx: "门票套餐", Mc: "套票", Dw1: "套" },
  { Lx: "签证", Mc: "旅游签证", Dw1: "人", Dw2: "夜" },
  { Lx: "租车", Mc: "旅游大巴", Dw1: "辆", Dw2: "天" },
  { Lx: "导游服务", Mc: "金牌导游", Dw1: "人", Dw2: "天" },
  { Lx: "接送机", Mc: "中型车", Dw1: "次" },
  { Lx: "单送关", Mc: "普通送关", Dw1: "次" },
  { Lx: "保险", Mc: "旅游意外险", Dw1: "份" },
  { Lx: "优惠", Mc: "会员优惠", Dw1: "份" },
  { Lx: "其他", Mc: "服务", Dw1: "件" },
];

//旅游订单采购项
export const lyddCgxData = [
  { Lx: "地接综费", Mc: "成人价", Dw1: "人" },
  { Lx: "酒店房费", Mc: "标准间", Dw1: "间", Dw2: "夜" },
  { Lx: "机票", Mc: "经济舱", Dw1: "张" },
  { Lx: "火车票", Mc: "二等座", Dw1: "张" },
  { Lx: "门票", Mc: "成人票", Dw1: "张" },
  { Lx: "门票套餐", Mc: "套票", Dw1: "套" },
  { Lx: "签证", Mc: "旅游签证", Dw1: "人", Dw2: "夜" },
  { Lx: "餐费", Mc: "单人餐", Dw1: "人", Dw2: "餐" },
  { Lx: "租车", Mc: "旅游大巴", Dw1: "辆", Dw2: "天" },
  { Lx: "导游服务", Mc: "金牌导游", Dw1: "人", Dw2: "天" },
  { Lx: "接送机", Mc: "中型车", Dw1: "次" },
  { Lx: "单送关", Mc: "普通送关", Dw1: "次" },
  { Lx: "保险", Mc: "旅游意外险", Dw1: "份" },
  { Lx: "优惠", Mc: "会员优惠", Dw1: "份" },
  { Lx: "其他", Mc: "服务", Dw1: "件" },
];
