<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li
        style="display: flex; align-items: center"
        v-if="
          $store.state.loginUser?.IsAdmin ||
          $store.state.loginUser?.Qxs?.includes('B_LvYouDingDan_List_添加')
        "
      >
        <el-button type="primary" icon="Plus" link @click="onSubmit()">
          申请发放
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
        @selection-change="onSelect"
      >
        <el-table-column fixed width="50" type="selection" />
        <el-table-column label="姓名">
          <template #default="{ row }">
            <text>{{ row.Xm }}</text>
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="LxMc" />
        <el-table-column label="手机号" prop="Sjhm" />
        <el-table-column label="订单号" prop="Ddh" />
        <el-table-column label="订单名称" prop="Ddmc" />
        <el-table-column label="带团日期" prop="Dtsj"> </el-table-column>
        <el-table-column
          label="补助说明"
          prop="Bz"
          :formatter="$tab_TextFormat"
        >
        </el-table-column>
        <el-table-column
          label="补助金额"
          prop="Bzje"
          :formatter="$tab_MoneytFormat"
        />
        <el-table-column label="是否现发">
          <template #default="{ row }">
            <text v-if="row.IsXf"> 是 </text>
            <text v-else>否</text>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showJieKuan"
        title="借款"
        @close="state.showJieKuan = false"
      >
        <c_LvYouDingDanJieKuanCompile
          @close="
            state.showJieKuan = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_LvYouDingDanJieKuanCompile>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showHuanKuan"
        title="还款"
        @close="state.showHuanKuan = false"
      >
        <c_LvYouDingDanHuanKuanCompile
          @close="
            state.showHuanKuan = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_LvYouDingDanHuanKuanCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_dybz, daoyoubuzhu_add } from "@/http/api";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          Zt: null,
        },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      lvyoudingdan_dybz(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    const onSubmit = () => {
      if (!state.selectIds || state.selectIds.length <= 0)
        ElMessage({
          type: "warning",
          grouping: true,
          message: "请选择需要补助记录！",
        });
      daoyoubuzhu_add(state.selectIds).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
        } else {
          ElMessage.error(res.data);
        }
      });
    };

    const onSelect = (e) => {
      state.selectIds = e.map((m) => {
        return m.Id;
      });
      console.log(state.selectIds);
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
      onSubmit,
      onSelect,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
