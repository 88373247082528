<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li
        style="display: flex; align-items: center"
        v-if="
          $store.state.loginUser?.IsAdmin ||
          $store.state.loginUser?.Qxs?.includes('B_KeHu_GeRen_添加')
        "
      >
        <el-button
          type="primary"
          icon="Plus"
          link
          @click="
            delete state.selectId;
            state.showCompile = true;
          "
        >
          添加
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          align="center"
          width="120"
        >
          <template #default="{ row }">
            <div style="text-align: center">
              <ul>
                <text v-if="row.IsQy" style="color: #2879ff">启用</text>
                <text v-else style="color: #f00">停用</text>
              </ul>
              <ul style="font-size: 12px; line-height: 12px">
                {{
                  row.Bh
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Mc"
          sortable="Mc"
          label="客户名称"
          :formatter="
            (row, column, cellValue) =>
              $tab_Highlinght(cellValue, state.params.Params.Key)
          "
          min-width="160"
        />
        <el-table-column
          prop="Sjhm"
          label="联系号码"
          :formatter="
            (row, column, cellValue) =>
              $tab_Highlinght(cellValue, state.params.Params.Key)
          "
        />
        <el-table-column
          prop="Jycs"
          label="交易次数"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          prop="Xfje"
          label="交易总额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Qk"
          label="欠款金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Ye"
          label="账户余额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Cjsj"
          sortable="Cjsj"
          label="创建信息"
          width="160"
          :formatter="(row) => $tab_GroupFormat(row.CjrXm, row.Cjsj)"
        />
        <el-table-column
          label="操作"
          width="220"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'KeHuGeRenDetail',
                  title: '个人客户详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_KeHu_GeRen_管理')
              "
            >
              管理
            </el-button>
            <el-button
              type="primary"
              :icon="Edit"
              link
              @click="
                state.selectId = row.Id;
                state.showCompile = true;
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_KeHu_GeRen_编辑')
              "
              >编辑
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(row.Id)"
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_KeHu_GeRen_删除')
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="(state.selectId ? '修改' : '添加') + '个人客户'"
        @close="state.showCompile = false"
      >
        <c_KeHuGeRenCompile
          @close="
            state.showCompile = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_KeHuGeRenCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { kehu_list, kehu_delete } from "@/http/api";
import { confirm_func } from "@/util/tFormat";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: { Lx: 1, TableName: "KeHu_GeRen" },
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  setup() {
    const state = reactive(new InitFormData());
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      kehu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      if (column.order) {
        state.params.Order = column.prop;
        state.params.Asc = column.order === "ascending";
      } else {
        state.params.Order = "Cjsj";
        state.params.Asc = false;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      confirm_func(kehu_delete, id, onLoad);
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onDelete,
    };
  },
};
</script>
<style lang="less" scoped></style>
