<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="状态"
          prop="IsQy"
          :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        >
          <el-switch v-model="state.model.IsQy" />
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="Xm"
          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Xm"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="Xb">
          <el-radio-group v-model="state.model.Xb" class="ml-4">
            <el-radio :label="1" size="large" style="color: #1779de"
              >男</el-radio
            >
            <el-radio :label="2" size="large" style="color: #f00">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="手机号码"
          prop="Sjhm"
          :rules="[
            { required: true, message: '请输入手机号码', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Sjhm"></el-input>
        </el-form-item>
        <el-form-item
          label="所属部门"
          prop="BmId"
          :rules="[
            { required: true, message: '请选择所属机构', trigger: 'blur' },
          ]"
          class="selectCss"
        >
          <el-tree-select
            v-model="state.model.BmId"
            :data="state.treeData"
            node-key="Id"
            clearable
            accordion
            :props="{ label: 'Mc', children: 'Xjs' }"
            :render-after-expand="false"
            :highlight-current="true"
            :default-expand-all="true"
            :check-strictly="true"
            placeholder="--所属组织--"
          >
            <template #default="{ data }">
              <div
                style="display: flex; align-items: center"
                @click="onBmSelect(data.Bm)"
              >
                <el-icon>
                  <Guide />
                </el-icon>
                {{ data.Mc }}
              </div>
            </template>
          </el-tree-select>
        </el-form-item>
        <el-form-item label="角色权限">
          <el-select v-model="state.model.JsId" style="width: 100%">
            <el-option
              v-for="item in state.jsData"
              :key="item"
              :label="item.Mc"
              :value="item.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="职务">
          <el-input v-model="state.model.Zw"></el-input>
        </el-form-item>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import {
  bumen_tree,
  yonghu_add,
  yonghu_detail,
  yonghu_edit,
  juese_select,
} from "@/http/api";
import { ElMessage, ElMessageBox } from "element-plus";
import store from "@/store";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
      },
      jsData: [],
    });
    onMounted(() => {
      juese_select().then((res) => {
        if (res.status == 200) state.jsData = res.data;
      });

      bumen_tree({}).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
          console.log(res.data);
        }
      });

      if (props.id) {
        store.commit("openLoading");
        yonghu_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          } else ElMessage.error(res.data);
          store.commit("closeLoading");
        });
      }
    });

    //选择部门，赋值部门的编码
    const onBmSelect = (data) => {
      state.model.BmBm = data;
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            yonghu_edit(props.id, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          } else {
            yonghu_add(state.model).then((res) => {
              if (res.status == 200) {
                ElMessageBox.confirm(res.data, "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  emit("close", true);
                });
              }
            });
          }
        }
      });
    };

    return { state, formDom, onSubmit, onBmSelect, props };
  },
};
</script>
<style scoped></style>
