<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="状态"
          prop="IsQy"
          :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        >
          <el-switch v-model="state.model.IsQy" />
        </el-form-item>
        <el-form-item label="所属供应商">
          <el-select v-model="state.model.GysId" style="width: 100%" disabled>
            <el-option
              v-for="item in state.gysData"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="Xm"
          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Xm"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="Xb">
          <el-radio-group v-model="state.model.Xb" class="ml-4">
            <el-radio :label="1" size="large" style="color: #1779de"
              >男</el-radio
            >
            <el-radio :label="2" size="large" style="color: #f00">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="手机号码"
          prop="Sjhm"
          :rules="[
            { required: true, message: '请输入手机号码', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Sjhm"></el-input>
        </el-form-item>
        <el-form-item
          label="管理员"
          prop="IsAdmin"
          :rules="[{ required: true, message: '请选择权限', trigger: 'blur' }]"
        >
          <el-switch
            v-model="state.model.IsAdmin"
            inline-prompt
            active-text="是"
            inactive-text="否"
            style="
              --el-switch-on-color: #1779de;
              --el-switch-off-color: #a2a2a2;
              margin-right: 10px;
            "
          />
        </el-form-item>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import {
  gongyingshang_select,
  gongyingshangyonghu_add,
  gongyingshangyonghu_detail,
  gongyingshangyonghu_edit,
} from "@/http/api";
import { ElMessage } from "element-plus";
import store from "@/store";

export default {
  props: {
    gysid: Number,
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
        IsAdmin: false,
      },
      gysData: [],
    });
    onMounted(() => {
      gongyingshang_select().then((res) => {
        if (res.status == 200) {
          state.gysData = res.data;
          state.model.GysId = props.gysid;
        }
      });

      if (props.id) {
        store.commit("openLoading");
        gongyingshangyonghu_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          } else ElMessage.error(res.data);
          store.commit("closeLoading");
        });
      }
    });

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            gongyingshangyonghu_edit(props.id, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          } else {
            gongyingshangyonghu_add(state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          }
        }
      });
    };

    return { state, formDom, onSubmit, props };
  },
};
</script>
<style scoped></style>
