l
<template>
  <div class="detailBox" style="position: relative">
    <div class="detailContent lvddDetail">
      <li
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
        "
      >
        退款单信息
      </li>
      <el-descriptions class="tk_desc" :column="3" border>
        <el-descriptions-item
          label="状态"
          label-align="right"
          label-class-name="desc-item-label"
        >
          <c_status :model="state.item.Zt"></c_status>
        </el-descriptions-item>
        <el-descriptions-item
          label="退款单号"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Bh }}
        </el-descriptions-item>
        <el-descriptions-item
          label="退款金额"
          label-align="right"
          label-class-name="desc-item-label"
        >
          <c_money
            :value="state.model.Je"
            :size="20"
            style="font-weight: 600"
          />
        </el-descriptions-item>
        <el-descriptions-item
          label="退款备注"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Bz }}
        </el-descriptions-item>
        <el-descriptions-item
          label="创建人"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.CjrXm }}
        </el-descriptions-item>
        <el-descriptions-item
          label="创建时间"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Cjsj }}
        </el-descriptions-item>
      </el-descriptions>
      <li
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 5px;
        "
      >
        订单信息
      </li>
      <el-descriptions class="tk_desc" :column="3" border>
        <el-descriptions-item
          label="订单号"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.DdBh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="订单名称"
          label-align="right"
          label-class-name="desc-item-label"
          :span="2"
        >
          {{ state.item.DdMc || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户编号"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.KhBh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户名称"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.KhMc || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户联系方式"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.KhSjhm || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="订单总额"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ $priceFormat(state.item.Zje) }}
        </el-descriptions-item>
        <el-descriptions-item
          label="已收金额"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ $priceFormat(state.item.Ysje) }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户欠款"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ $priceFormat(state.item.Qkje) }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发起人"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.CjrXm }}
        </el-descriptions-item>
        <el-descriptions-item
          label="发起时间"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.Cjsj }}
        </el-descriptions-item>
      </el-descriptions>
      <li
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 5px;
        "
      >
        审核信息
      </li>
      <el-descriptions class="tk_desc" :column="2" border>
        <el-descriptions-item
          label="审核状态"
          label-align="right"
          label-class-name="desc-item-label"
        >
          <c_status :model="state.item.Zt" />
        </el-descriptions-item>
        <el-descriptions-item
          label="审核人"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.ShrXm }}
        </el-descriptions-item>
        <el-descriptions-item
          label="审核时间"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Shsj }}
        </el-descriptions-item>
        <el-descriptions-item
          label="退款方式"
          label-align="right"
          label-class-name="desc-item-label"
        >
          <c_status :model="state.item.Tkfs" />
        </el-descriptions-item>
        <el-descriptions-item
          label="审核说明"
          label-align="right"
          label-class-name="desc-item-label"
          :span="2"
        >
          {{ state.model.Shsm || "-" }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_getskdetail } from "@/http/api";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const state = reactive({
      model: {},
      item: {},
      checkModel: {},
    });
    onMounted(() => {
      lvyoudingdan_getskdetail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item } = res.data;
          state.model = model;
          state.item = item;
        }
      });
    });

    return {
      state,
    };
  },
};
</script>
<style lang="less">
.desc-item-label {
  width: 200px;
}

.tk_desc {
  .el-descriptions__table {
    table-layout: fixed;
  }
}
</style>
