<template>
  <div class="listBox">
    <ul class="sBox">
      <el-button type="primary" icon="Plus" link @click="onOpenCompile()">
        添加
      </el-button>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          align="center"
          width="80"
        >
          <template #default="scope">
            <text :style="{ color: scope.row.IsQy ? '#080' : '#f00' }">
              {{ scope.row.IsQy ? "启用" : "停用" }}
            </text>
          </template>
        </el-table-column>
        <el-table-column prop="Khyh" label="开户银行" />
        <el-table-column prop="Kh" label="账号/卡号" />
        <el-table-column prop="Skr" label="收款人" />
        <el-table-column
          label="操作"
          width="240"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="scope">
            <el-button
              type="primary"
              :icon="Edit"
              link
              @click="onOpenCompile(scope.row.Id)"
            >
              编辑
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(scope.row.Id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="state.compileTitle + '供应商账户'"
        @close="onCloseCompile(false)"
      >
        <c_compile
          @close="onCloseCompile(true)"
          :id="state.selectId"
          :gysId="props?.gysId"
        ></c_compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  gongyingshangzhanghu_list,
  gongyingshangzhanghu_delete,
} from "@/http/api";
import { ElMessageBox, ElMessage } from "element-plus";

import c_compile from "./ZhCompile.vue";
export default {
  props: {
    gysId: Number,
  },
  components: {
    c_compile,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      gongyingshangzhanghu_list(props.gysId).then((res) => {
        if (res.status == 200) {
          state.tableData = res.data;
        }
      });
    };

    //打开内容页
    const onOpenCompile = (id) => {
      state.compileTitle = id ? "修改" : "添加";
      state.selectId = id;
      state.showCompile = true;
    };

    //关闭内容页
    const onCloseCompile = (refresh) => {
      state.showCompile = false;
      if (refresh) {
        onLoad();
      }
    };

    //删除
    const onDelete = (id) => {
      ElMessageBox.confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        gongyingshangzhanghu_delete(id).then((res) => {
          if (res.status == 200) {
            ElMessageBox.alert("删除成功", "提示", {
              type: "success",
              callback: () => {
                onLoad();
              },
            });
          } else {
            ElMessage({
              message: res.data,
              type: "error",
            });
          }
        });
      });
    };

    return {
      state,
      onLoad,
      onCloseCompile,
      onOpenCompile,
      onDelete,
      props,
    };
  },
};
</script>
<style>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
