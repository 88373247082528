<template>
  <div class="detailBox">
    <ul class="detailContent">
      <div
        style="
          font: 600 14px/14px '';
          color: rgb(50, 50, 50);
          margin-bottom: 10px;
        "
      >
        基本信息
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item label="状态" label-align="right">
          <template #label>
            <div style="width: 5vw">状态</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              <c_status :model="state.model?.Zt"></c_status>
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="编号" label-align="right">
          <template #label>
            <div style="width: 5vw">编号</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.Bh }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="到账方式" label-align="right">
          <template #label>
            <div style="width: 5vw">到账方式</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              <c_status :model="state.model?.Zzfs"></c_status>
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="到账银行" label-align="right">
          {{ state.model.Dzyh }}
        </el-descriptions-item>
        <el-descriptions-item label="付款人" label-align="right">
          {{ state.model.Fkr || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="付款银行" label-align="right">
          {{ state.model.Fkyh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="付款账号" label-align="right">
          {{ state.model.Fkzh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="付款流水号" label-align="right">
          {{ state.model.Fklsh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="到账金额" label-align="right">
          <c_money :value="state.model.Dzje" :size="18"></c_money>
        </el-descriptions-item>
        <el-descriptions-item label="手续费" label-align="right">
          {{ $priceFormat(state.model.Sxf) }}
        </el-descriptions-item>
        <el-descriptions-item label="创建人" label-align="right">
          {{ state.model.CjrXm }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间" label-align="right">
          {{ state.model.Cjsj }}
        </el-descriptions-item>
      </el-descriptions>
      <div
        style="font: 600 14px/14px ''; color: rgb(50, 50, 50); margin: 10px 0"
      >
        认领操作
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item label="到账客户" label-align="right">
          <template #label>
            <div style="width: 5vw">到账客户</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              <el-select
                v-model="state.model.Kh"
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                style="width: 300px"
                value-key="Id"
              >
                <el-option
                  v-for="item in state.khData"
                  :key="item"
                  :label="item.Mc"
                  :value="item"
                />
              </el-select>
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">客户联系人</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.Kh?.Lxr || "-" }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">客户联系方式</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.Kh?.Sjhm || "-" }}
            </div>
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">确认认领</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import {
  daozhanggongshi_detail,
  kehu_selectbyyhid,
  daozhanggongshi_renling,
} from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {},
    });
    onMounted(() => {
      daozhanggongshi_detail(props.id).then((res) => {
        if (res.status == 200) state.model = res.data;
      });

      kehu_selectbyyhid().then((res) => {
        if (res.status == 200) state.khData = res.data;
      });
    });

    const onSubmit = () => {
      const KhId = state.model.Kh?.Id;
      if (!KhId) {
        ElMessage({
          type: "warning",
          message: "请选择客户！",
          grouping: true,
        });
        return false;
      }
      daozhanggongshi_renling(props.id, KhId).then((res) => {
        proxy.$post_response(res, emit);
      });
    };
    return { state, onSubmit };
  },
};
</script>
<style lang="less" scoped>
.infoBox {
  .title {
    width: 80px;
  }
}

.rlBox {
  & > ul {
    align-items: center;

    & > li {
      align-items: center;
    }
  }
}
</style>
