<template>
  <div>
    <el-input
      type="textarea"
      v-model="state.xcxx"
      :autosize="{ minRows: 10 }"
      placeholder="行程信息"
      v-if="!props.search"
    />
    <el-input
      v-else
      type="textarea"
      v-model="state.xcxx"
      placeholder="行程信息"
      readonly
      :autosize="true"
      :resize="false"
      class="sTextarea"
    />
    <el-button
      type="primary"
      @click="onSubmit()"
      style="margin-top: 10px"
      v-if="!props.search"
    >
      提交
    </el-button>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_getxcxx, lvyoudingdan_setxcxx } from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    id: Number,
    search: Boolean,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      console.log(props);
      lvyoudingdan_getxcxx(props.id).then((res) => {
        console.log(res);
        if (res.status == 200) state.xcxx = res.data;
      });
    });

    const onSubmit = () => {
      lvyoudingdan_setxcxx(props.id, state.xcxx).then((res) => {
        if (res.status == 200) ElMessage.success(res.data);
      });
    };
    return { state, onSubmit, props };
  },
};
</script>
<style scoped></style>
