<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="domForm"
        :rules="rules"
      >
        <el-form-item
          label="到账方式"
          prop="Zzfs"
          :rules="[
            { required: true, message: '请输入供应商名称', trigger: 'blur' },
          ]"
        >
          <el-radio-group v-model="state.model.Zzfs">
            <el-radio-button
              :label="item.Key"
              v-for="item in state.zzfsData"
              :key="item"
              >{{ item.Text }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="到账银行"
          prop="Dzyh"
          :rules="[
            { required: true, message: '请输入联系人', trigger: 'blur' },
          ]"
        >
          <el-select
            v-model="state.model.Dzyh"
            filterable
            allow-create
            default-first-option
            :reserve-keyword="false"
          >
            <el-option
              v-for="item in state.yhData"
              :key="item.Mc"
              :label="item.Mc"
              :value="item.Mc"
            />
            <template #footer>
              <el-button size="small" @click="onLoadYh()"> 刷新 </el-button>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="付款银行" prop="Fkyh">
          <el-input v-model="state.model.Fkyh"></el-input>
        </el-form-item>
        <el-form-item label="付款人" prop="Fkr">
          <el-input v-model="state.model.Fkr"></el-input>
        </el-form-item>
        <el-form-item label="付款账号" prop="Fkzh">
          <el-input v-model="state.model.Fkzh"></el-input>
        </el-form-item>
        <el-form-item label="付款流水号" prop="Fklsh">
          <el-input v-model="state.model.Fklsh"></el-input>
        </el-form-item>
        <el-form-item
          label="到账金额"
          prop="Dzje"
          :rules="[
            { required: true, message: '请输入到账金额', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Dzje" type="number"></el-input>
        </el-form-item>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  zidian_select,
  daozhanggongshi_add,
  daozhanggongshi_detail,
  daozhanggongshi_edit,
  daozhanggongshi_getzzfs,
} from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      model: {
        Zzfs: 1,
      },
      zzfsData: [],
      yhData: [],
    });
    onMounted(() => {
      daozhanggongshi_getzzfs().then((res) => {
        if (res.status == 200) state.zzfsData = res.data;
      });
      onLoadYh();

      if (props.id) {
        daozhanggongshi_detail(props.id).then((res) => {
          if (res.status == 200) state.model = res.data;
        });
      }
    });

    const onLoadYh = () => {
      zidian_select("银行").then((res) => {
        if (res.status == 200) {
          state.yhData = res.data;
        }
      });
    };

    const onSubmit = () => {
      if (props.id) {
        daozhanggongshi_edit(props.id, state.model).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });
          if (res.status == 200) {
            setTimeout(() => {
              emit("close", true);
            }, 500);
          }
        });
      } else {
        daozhanggongshi_add(state.model).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });
          if (res.status == 200) {
            setTimeout(() => {
              emit("close", true);
            }, 500);
          }
        });
      }
    };

    return { state, onSubmit, props, onLoadYh };
  },
};
</script>
<style scoped></style>
