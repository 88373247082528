<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    "
  >
    敬请期待
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  setup() {
    const state = reactive({});
    onMounted(() => {});
    return { state };
  },
};
</script>
<style scoped></style>
