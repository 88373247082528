<template>
  <div class="listBox">
    <ul class="sBox">
      <el-date-picker
        v-model="state.params.Params.Date"
        type="month"
        placeholder="日期"
        :size="size"
        style="margin-left: 10px"
        format="YYYY年M月"
        value-format="YYYY-MM-01"
        :disabled-date="onJudgeDate"
        :clearable="false"
        @change="onLoad()"
      />
      <el-radio-group
        v-model="state.params.Params.Zt"
        style="margin-left: 10px"
        @change="onLoad()"
      >
        <el-radio-button :label="null">全部</el-radio-button>
        <el-radio-button
          :label="item.Key"
          v-for="item in state.ztData"
          :key="item"
        >
          <text
            :style="{
              color: state.params.Params.Zt == item.Key ? '' : item.Color,
            }"
          >
            {{ item.Text }}</text
          >
        </el-radio-button>
      </el-radio-group>
      <el-select
        v-model="state.params.Params.KhId"
        placeholder="--客户--"
        style="margin-left: 10px; width: 200px"
        @change="onLoad()"
      >
        <el-option
          v-for="item in state.khData || []"
          :key="item.Id"
          :label="item.Mc"
          :value="item.Id"
        />
      </el-select>
      <el-button style="margin-left: 10px" type="primary" @click="onExport">
        导出
      </el-button>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态/编号"
          width="160"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />
        <el-table-column
          sortable="Xsj"
          label="下单信息"
          width="150"
          :formatter="(row) => $tab_GroupFormat(row.KhLxr, row.Cjsj)"
        />
        <el-table-column prop="Sl" label="商品数" align="center" width="80" />
        <el-table-column prop="Ycps" label="已出票" align="center" width="80" />
        <el-table-column prop="Wcps" label="未出票" align="center" width="80" />
        <el-table-column
          prop="Xsj"
          label="订单金额(元)"
          width="130"
          align="center"
          sortable="Xsj"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Yfje"
          sortable="Ysje"
          label="已付金额(元)"
          width="130"
          align="center"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Qk"
          label="欠款(元)"
          width="110"
          align="center"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Bz"
          label="订单备注"
          :formatter="$tab_TextFormat"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="操作"
          width="120"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Search"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'BaoBiaoXstjXq',
                  title: '订单详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              >订单详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  kehu_select,
  baobiao_xstj,
  shangpindingdan_getzt,
  baobiao_xstjexport,
} from "@/http/api";
import { useRouter } from "vue-router";
import { dayjs } from "element-plus";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          Zt: null,
        },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      state.params.Params.Date = dayjs().format("YYYY-MM-DD");

      kehu_select().then((res) => {
        if (res.status == 200) state.khData = res.data;
      });

      shangpindingdan_getzt().then((res) => {
        if (res.status == 200) state.ztData = res.data;
      });
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      baobiao_xstj(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {
            Zt: null,
          },
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    const onExport = () => {
      baobiao_xstjexport(state.params.Params).then((res) => {
        //通过后台返回 的word文件流设置文件名并下载
        var blob = new Blob([res.data], {
          type: res.data.type,
        }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download =
          dayjs(state.date).format("YYYY年M月") + "销售统计报表.xlsx"; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      });
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
      onExport,
    };
  },
};
</script>
<style></style>
