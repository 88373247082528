<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="120px"
        :scroll-to-error="true"
        ref="formDom"
        inline
      >
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="规则名称"
            prop="Mc"
            :rules="[
              { required: true, message: '请输入规则名称', trigger: 'blur' },
            ]"
            style="display: flex; width: 40%"
          >
            <el-input v-model="state.model.Mc"></el-input>
          </el-form-item>
          <el-form-item
            label="优先级"
            prop="Xh"
            :rules="[
              { required: true, message: '请输入优先级', trigger: 'blur' },
            ]"
          >
            <el-input v-model="state.model.Xh" type="number"></el-input>
          </el-form-item>
        </ul>
        <!-- <ul style="display: flex; align-items: center">
          <el-form-item
            prop="TqKsts"
            style="display: flex; width: 40%"
            :rules="[
              {
                required: state.model.IsTqKs,
                message: '请选择提前开售时间',
                trigger: 'blur',
              },
            ]"
          >
            <template #label>
              <el-checkbox v-model="state.model.IsTqKs">开售时间</el-checkbox>
            </template>
            <li style="width: 180px">
              <el-input
                v-model.number="state.model.TqKsts"
                type="number"
                append="天"
                :disabled="!state.model.IsTqKs"
              >
                <template #prepend>提前</template>
                <template #append>天</template>
              </el-input>
            </li>
            <li style="width: 120px; margin-left: 10px">
              <el-time-select
                v-model="state.model.TqKssj"
                start="00:00"
                step="00:30"
                end="23:45"
                :disabled="!state.model.IsTqKs"
              />
            </li>
          </el-form-item>
          <el-form-item
            prop="TqTsts"
            style="display: flex"
            :rules="[
              {
                required: state.model.IsTqTs,
                message: '请选择提前停售时间',
                trigger: 'blur',
              },
            ]"
          >
            <template #label>
              <el-checkbox v-model="state.model.IsTqTs">停售时间</el-checkbox>
            </template>
            <li style="width: 180px">
              <el-input
                v-model.number="state.model.TqTsts"
                type="number"
                append="天"
                :disabled="!state.model.IsTqTs"
              >
                <template #prepend>提前</template>
                <template #append>天</template>
              </el-input>
            </li>
            <li style="width: 120px; margin-left: 10px">
              <el-time-select
                v-model="state.model.TqTssj"
                start="00:00"
                step="00:30"
                end="23:45"
                :disabled="!state.model.IsTqTs"
              />
            </li>
          </el-form-item>
        </ul> -->
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="使用日期"
            prop="YxsjArr"
            :rules="[
              {
                required: true,
                message: '请选择使用日期',
                trigger: 'blur',
              },
            ]"
            style="display: flex; width: 40%"
          >
            <el-date-picker
              v-model="state.model.YxsjArr"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="截止日期"
              :disabled-date="onJudgeYxq"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item label="日期规则" prop="Sygz">
            <el-radio-group
              v-model="state.model.Sygz"
              size="small"
              @change="onSygzChange"
            >
              <el-radio :label="1">每天都能使用</el-radio>
              <el-radio :label="2">指定每月几号使用</el-radio>
              <el-radio :label="3">指定每周几使用</el-radio>
              <el-radio :label="4">指定哪一天使用</el-radio>
            </el-radio-group>
          </el-form-item>
        </ul>
        <ul style="width: 100%; height: fit-content">
          <el-form-item
            label="指定日期"
            prop="Zdsyr"
            style="width: 100%"
            v-if="state.model.Sygz != 1"
          >
            <div
              style="
                border: 1px solid #e2e2e2;
                height: 70px;
                border-radius: 4px;
                overflow-y: auto;
                z-index: 9999;
                width: 100%;
                top: 0;
                background-color: #fff;
                box-shadow: 0 0 10px 10px #fff;
              "
              @click="onShowZdsyr"
            >
              <el-tag
                v-for="(item, index) in state.model.Zdsyrs"
                :key="item"
                closable
                style="margin-left: 5px"
                @close="onDeleteZdsyr(index)"
              >
                <template v-if="state.model.Sygz == 2">{{
                  item + "号"
                }}</template>
                <template v-else-if="state.model.Sygz == 3">{{
                  weekArr[item]
                }}</template>
                <template v-else-if="state.model.Sygz == 4">{{
                  item
                }}</template>
              </el-tag>
            </div>
            <el-select
              v-if="state.model.Sygz == 2"
              ref="zdsyrDom"
              v-model="state.model.Zdsyr2"
              multiple
              placeholder="指定日期"
              style="
                width: 100%;
                height: calc(100% - 1px);
                position: absolute;
                left: 0;
                top: 30px;
              "
              :filterable="false"
              @change="onZdsyr2Change"
            >
              <el-option
                v-for="item in 31"
                :key="item"
                :label="item + '号'"
                :value="item"
              />
            </el-select>
            <el-select
              v-else-if="state.model.Sygz == 3"
              ref="zdsyrDom"
              v-model="state.model.Zdsyr3"
              multiple
              placeholder="指定日期"
              style="
                width: 100%;
                height: calc(100% - 1px);
                position: absolute;
                left: 0;
                top: 30px;
              "
              :filterable="false"
              @change="onZdsyr3Change"
            >
              <el-option label="周一" :value="1" />
              <el-option label="周二" :value="2" />
              <el-option label="周三" :value="3" />
              <el-option label="周四" :value="4" />
              <el-option label="周五" :value="5" />
              <el-option label="周六" :value="6" />
              <el-option label="周日" :value="0" />
            </el-select>
            <el-date-picker
              v-else-if="state.model.Sygz == 4"
              ref="zdsyrDom"
              v-model="state.model.Zdsyr4"
              type="dates"
              placeholder="--请选择--"
              style="
                width: 100%;
                height: calc(100% - 1px);
                position: absolute;
                left: 0;
              "
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="onZdsyr4Change"
              :disabled-date="onJudgeDate"
              teleported
            />
          </el-form-item>
        </ul>
      </el-form>
      <el-table
        :data="state.tcs"
        v-loading="!Array.isArray(state.tcs)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          align="center"
        />
        <el-table-column prop="TcMc" label="套餐名称" min-width="180" />
        <el-table-column prop="Scj" label="市场价(元)" width="190">
          <template #default="{ row }">
            <el-input
              v-model.number="row.Scj"
              placeholder="--市场价--"
              type="number"
              size="small"
              :disabled="row.IsSc"
            >
              <template #append>元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="Fxythj" label="非协议同行价(元)" width="190">
          <template #default="{ row }">
            <el-input
              v-model.number="row.Fxythj"
              placeholder="--非协议同行价--"
              style="width: 150px"
              type="number"
              size="small"
              :disabled="row.IsSc"
            >
              <template #append>元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="Xythj" label="协议同行价(元)" width="190">
          <template #default="{ row }">
            <el-input
              v-model.number="row.Xythj"
              placeholder="--协议同行价--"
              type="number"
              size="small"
              :disabled="row.IsSc"
            >
              <template #append>元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="Jsj" label="结算价(元)" width="190">
          <template #default="{ row }">
            <el-input
              v-model.number="row.Jsj"
              placeholder="--结算价--"
              type="number"
              size="small"
              :disabled="row.IsSc"
            >
              <template #append>元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="Kc" width="180">
          <template #header>
            单日库存
            <text style="color: #f00">(-1为不限库存)</text>
          </template>
          <template #default="{ row }">
            <el-input
              v-model.number="row.Kc"
              placeholder="-1为不限库存"
              type="number"
              size="small"
              :disabled="row.IsSc"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
          align="center"
          fixed="right"
          className="tabBtn"
          style="z-index: 999999999999999"
        >
          <template #default="{ $index, row }">
            <el-button
              type="danger"
              :icon="Select"
              link
              @click="onChangeTcStatus($index, true)"
              v-if="!row.IsSc"
            >
              删除
            </el-button>
            <el-button
              type="success"
              :icon="Delete"
              link
              @click="onChangeTcStatus($index, false)"
              v-else
            >
              启用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import dayjs from "dayjs";
import {
  xiaoshouguize_detail,
  xiaoshouguize_add,
  xiaoshouguize_edit,
  shangpintaocan_all,
} from "@/http/api";
import { post_response } from "@/util/tFormat.js";
import { weekArr } from "@/util/enum.js";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
    spId: Number,
  },
  setup(props, { emit }) {
    const zdsyrDom = ref(null);
    const formDom = ref(null);
    const state = reactive({
      model: {
        Sygz: 1,
        TqKsts: 0,
        TqKssj: "00:00",
        TqTsts: 0,
        TqTssj: "00:00",
      },
    });
    onMounted(() => {
      if (props.id) {
        xiaoshouguize_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            state.model.YxsjArr = [state.model.YxKssj, state.model.YxJzsj];
          }
        });

        state.model.SpId = props.spId;
        shangpintaocan_all(props.spId).then((tcRes) => {
          if (tcRes.status == 200) {
            xiaoshouguize_detail(props.id).then((gzRes) => {
              if (gzRes.status == 200) {
                state.model = gzRes.data;
                state.model.YxsjArr = [state.model.YxKssj, state.model.YxJzsj];
                const tcIds = state.model.Tcs.map((m) => m.TcId);
                state.tcs = [
                  ...(tcRes.data
                    .filter((w) => !tcIds.includes(w.Id))
                    .map((m) => {
                      return {
                        TcId: m.Id,
                        TcMc: m.Mc,
                        Scj: 0,
                        Fxythj: 0,
                        Xythj: 0,
                        Jsj: 0,
                        Kc: 0,
                        IsSc: true,
                      };
                    }) || []),
                  ...gzRes.data.Tcs,
                ];
              }
            });
          }
        });
      } else {
        state.model.SpId = props.spId;
        shangpintaocan_all(props.spId).then((res) => {
          if (res.status == 200) {
            state.tcs =
              res.data.map((m) => {
                return {
                  TcId: m.Id,
                  TcMc: m.Mc,
                  Scj: 0,
                  Fxythj: 0,
                  Xythj: 0,
                  Jsj: 0,
                  Kc: 0,
                  IsSc: false,
                };
              }) || [];
          }
        });
      }
    });

    //验证有效期区间，有效期只能从当前时间开始，截止时间不超过1年
    const onJudgeYxq = (value) => {
      const yearCount = dayjs(value).diff(dayjs(), "year");
      const dayCount = dayjs(value).diff(dayjs(), "day");
      if (yearCount > 0 || dayCount < 0) {
        return true;
      }
    };

    //指定日期div点击事件，弹出选择日期框
    const onShowZdsyr = () => {
      if (state.model.Sygz == 4) zdsyrDom.value.handleOpen();
      else zdsyrDom.value.toggleMenu();
    };

    //禁用非有效期内的日期
    const onJudgeDate = (value) => {
      if (state.model.YxsjArr) {
        if (
          dayjs(value) < dayjs(state.model.YxsjArr[0]) ||
          dayjs(value) > dayjs(state.model.YxsjArr[1])
        ) {
          return true;
        } else return false;
      } else {
        return false;
      }
    };

    //使用规则切换事件
    const onSygzChange = (value) => {
      if (value == 2) {
        state.model.Zdsyrs = state.model.Zdsyr2;
      } else if (value == 3) {
        state.model.Zdsyrs = state.model.Zdsyr3;
      } else if (value == 4) {
        state.model.Zdsyrs = state.model.Zdsyr4;
      } else {
        state.model.Zdsyrs = [];
      }
    };

    //使用日期删除事件
    const onDeleteZdsyr = (index) => {
      state.model.Zdsyrs.splice(index, 1);
      if (state.model.Sygz == 2) state.model.Zdsyr2 = state.model.Zdsyrs;
      if (state.model.Sygz == 3) state.model.Zdsyr3 = state.model.Zdsyrs;
      if (state.model.Sygz == 4) state.model.Zdsyr4 = state.model.Zdsyrs;
    };

    //按月日期改变事件
    const onZdsyr2Change = (value) => {
      state.model.Zdsyrs = value;
    };

    //按周日期改变事件
    const onZdsyr3Change = (value) => {
      state.model.Zdsyrs = value;
    };

    //按天日期改变事件
    const onZdsyr4Change = (value) => {
      state.model.Zdsyrs = value;
    };

    //使用日期删除事件
    const onChangeTcStatus = (index, status) => {
      state.tcs[index].IsSc = status;
      console.log(state.tcs);
    };

    //提交
    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          state.model.YxKssj = state.model.YxsjArr[0];
          state.model.YxJzsj = state.model.YxsjArr[1];

          state.model.Tcs = state.tcs.filter((w) => !w.IsSc);

          if (state.model.Tcs.length <= 0) {
            ElMessage.warning("请选择套餐！");
            return false;
          }

          // let i = 1;
          // if (i == 1) return false;
          if (props.id) {
            xiaoshouguize_edit(props.id, state.model).then((res) => {
              post_response(res, emit);
            });
          } else {
            xiaoshouguize_add(state.model).then((res) => {
              post_response(res, emit);
            });
          }
        }
      });
    };

    const tableRowClassName = ({ row }) => {
      if (row.IsSc) {
        return "tcShade";
      }
      return "";
    };

    return {
      state,
      formDom,
      weekArr,
      onSubmit,
      zdsyrDom,
      onJudgeYxq,
      onShowZdsyr,
      onSygzChange,
      onZdsyr2Change,
      onZdsyr3Change,
      onZdsyr4Change,
      onJudgeDate,
      onDeleteZdsyr,
      onChangeTcStatus,
      tableRowClassName,
    };
  },
};
</script>
<style>
/* 关键样式 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.tcShade {
  position: relative;
}

.tcShade::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  border-top: 1px solid #e2e2e2;
  margin-top: 25px;
  overflow: hidden;
  pointer-events: none;
}
</style>

<style>
.tabBtn {
  z-index: 100 !important;
}
</style>
