<template>
  <div class="detailBox" style="position: relative">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
        inline
      >
        <ul style="display: flex">
          <el-form-item
            label="状态"
            prop="IsQy"
            :rules="[
              { required: true, message: '请选择角色状态', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-switch v-model="state.model.IsQy" />
          </el-form-item>
          <el-form-item
            label="客户经理"
            prop="JlId"
            :rules="[
              { required: true, message: '请选择客户经理', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <div style="display: flex; width: 100%; overflow: hidden">
              <el-input
                v-model="state.item.JlXm"
                class="shade"
                style="margin-right: 10px"
                readonly
              ></el-input>
              <el-button type="warning" @click="state.showUser = true">
                选择
              </el-button>
            </div>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="姓名"
            prop="Mc"
            :rules="[
              { required: true, message: '请输入客户姓名', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Mc"></el-input>
          </el-form-item>
          <el-form-item label="性别" style="flex: 1">
            <el-radio-group v-model="state.model.Xb">
              <el-radio :label="1" style="color: #1779de"> 男 </el-radio>
              <el-radio :label="2" style="color: #f00"> 女 </el-radio>
            </el-radio-group>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="手机号码"
            prop="Sjhm"
            :rules="[
              { required: true, message: '请输入手机号码', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Sjhm"></el-input>
          </el-form-item>
          <el-form-item label="出生日期" style="flex: 1">
            <el-date-picker
              v-model="state.model.Sr"
              type="date"
              placeholder="--出生日期--"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :disabled-date="
                (data) => {
                  return $dayjs(data) > $dayjs();
                }
              "
            />
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="来源" style="flex: 1">
            <el-select v-model="state.model.Ly" :clearable="false">
              <el-option label="网络" :value="1" />
              <el-option label="现场" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="职务" style="flex: 1">
            <el-input v-model="state.model.Zw"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="QQ账号" style="flex: 1">
            <el-input v-model="state.model.Qq"></el-input>
          </el-form-item>
          <el-form-item label="微信账号" style="flex: 1">
            <el-input v-model="state.model.Wxh"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="微博账号" style="flex: 1">
            <el-input v-model="state.model.Wb"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" style="flex: 1">
            <el-input v-model="state.model.Yx"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="现住地址" style="flex: 1">
            <el-input v-model="state.model.Dz"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="备注说明" style="flex: 1">
            <el-input
              type="textarea"
              v-model="state.model.Bz"
              :autosize="{ minRows: 4 }"
            />
          </el-form-item>
        </ul>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showUser"
        title="选择客户经理"
        @close="state.showUser = false"
      >
        <c_KeHuSelectUser @close="onUserClose" :id="state.model.JlId">
        </c_KeHuSelectUser>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { kehu_add, kehu_detail, kehu_edit } from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);

    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
        Lx: 1,
        Ly: 1,
      },
      item: {},
    });
    onMounted(() => {
      if (props.id) {
        kehu_detail(props.id).then((res) => {
          if (res.status == 200) {
            const { model, item } = res.data;
            state.model = model;
            state.item = item;
          }
        });
      }
    });

    const onUserClose = (row) => {
      state.model.JlId = row.Id;
      state.item.JlXm = row.Xm;
      state.showUser = false;
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            kehu_edit(props.id, state.model).then((res) => {
              if (res.status == 200) {
                ElMessage.success(res.data);
                setTimeout(() => {
                  emit("close");
                }, 500);
              }
            });
          } else {
            kehu_add(state.model).then((res) => {
              if (res.status == 200) {
                ElMessage.success("添加成功");
                setTimeout(() => {
                  emit("close");
                }, 500);
              }
            });
          }
        }
      });
    };

    return {
      state,
      onSubmit,
      props,
      formDom,
      onUserClose,
    };
  },
};
</script>
<style scoped></style>
