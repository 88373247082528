<template>
  <div
    style="
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 24px;
    "
    id="divDom"
  >
    <ul
      style="
        position: relative;
        text-align: center;
        font-weight: 600;
        padding: 10px 0;
        border-bottom: 1px solid rgba(100, 100, 100, 0.1);
      "
    >
      <el-image
        :src="require('@/assets/images/logo.png')"
        style="position: absolute; left: 20px"
      />
      <text>肥西文旅集团发团通知书</text>
      <text>NO:{{ state.model.Bh }}</text>
    </ul>
    <ul style="display: flex; align-items: center; padding: 10px">
      <li style="font-weight: 600; flex: 1; font-size: 18px">团队信息</li>
    </ul>
    <div style="padding: 0 10px; overflow: hidden; color: #000">
      <div style="border: 1px solid #000; border-radius: 10px">
        <ul
          style="
            border-bottom: 1px solid #000;
            display: flex;
            align-items: center;
            padding: 10px;
          "
        >
          <text style="font-weight: 600">{{ state.model.Mc }}</text>
          <text style="font-size: 18px; margin-left: 10px; color: #000">{{
            state.model.Bh
          }}</text>
          <c_status :model="state.model.Zt"></c_status>
        </ul>
        <ul style="display: flex; align-items: center; padding: 10px">
          <li style="display: flex; align-items: center">
            <text style="color: #a2a2a2">计调:</text>
            <text>
              {{
                state.item.JdyXm +
                (state.model.JdyBmMc ? " - " + state.model.JdyBmMc : "")
              }}
            </text>
          </li>
          <li style="display: flex; align-items: center; margin: 0 20px">
            <text style="color: #a2a2a2">日期:</text>
            <text>
              {{ state.item.Ftsj + "至" + state.item.Htsj }}
            </text>
          </li>
          <li style="display: flex; align-items: center">
            <text style="color: #a2a2a2">天数:</text>
            <text>
              {{ state.model.Ts + "天" + state.model.Ys + "晚" }}
            </text>
          </li>
          <li style="display: flex; align-items: center">
            <text style="color: #a2a2a2">人数:</text>
            <text>
              {{ state.model.Zrs }}
            </text>
          </li>
        </ul>
      </div>
      <div style="font-weight: 600; margin: 20px 0; font-size: 18px">
        费用明细
      </div>
      <table
        style="
          text-align: right;
          border-collapse: collapse;
          width: 100%;
          border-radius: 10px;
          border-style: hidden;
          border-color: #000;
        "
        border="1"
        cellpadding="20"
      >
        <tr style="font-size: 18px">
          <th>销售金额</th>
          <th>已收</th>
          <th>未收</th>
          <th>采购成本</th>
          <th>导游补助</th>
          <th>成本合计</th>
          <th>利润</th>
        </tr>
        <tr>
          <td>{{ $priceFormat(state.model.Zje || 0) }}</td>
          <td>{{ $priceFormat(state.model.Ysje || 0) }}</td>
          <td>{{ $priceFormat(state.model.Qkje || 0) }}</td>
          <td>{{ $priceFormat(state.model.Cgcb || 0) }}</td>
          <td>{{ $priceFormat(state.model.Dbhj || 0) }}</td>
          <td>
            {{ $priceFormat(state.model.Cgcb + state.model.Dbhj || 0) }}
          </td>
          <td>
            {{
              $priceFormat(
                state.model.Zje - state.model.Cgcb - state.model.Dbhj || 0
              )
            }}
          </td>
        </tr>
      </table>
      <div style="font-weight: 600; margin: 20px 0; font-size: 18px">
        采购信息
      </div>
      <table
        style="
          text-align: left;
          border-collapse: collapse;
          width: 100%;
          border-radius: 10px;
          border-style: hidden;
          border-color: #000;
        "
        border="1"
        cellpadding="20"
      >
        <tr style="font-size: 18px">
          <th>类别</th>
          <th>采购项</th>
          <th>供应商</th>
          <th>合计</th>
          <th>预计现付</th>
          <th>实际现付</th>
          <th>已付</th>
          <th>未付</th>
        </tr>
        <tr v-for="item in state.cgs" :key="item">
          <td>{{ item.LbMc }}</td>
          <td style="flex: 2">
            <ul v-for="mx in item.Mxs" :key="mx">
              {{
                mx.Lx +
                " - " +
                mx.Mc +
                " " +
                $priceFormat(mx.Dj || 0) +
                " * " +
                mx.Sl1 +
                (mx.Sl2 > 0 ? " * " + mx.Sl2 : "") +
                " = " +
                $priceFormat(mx.Zj || 0) +
                " 元"
              }}
            </ul>
          </td>
          <td>{{ item.GysMc }}</td>
          <td>{{ $priceFormat(item.Zje || 0) }}</td>
          <td>{{ $priceFormat(item.Yjxf || 0) }}</td>
          <td>{{ $priceFormat(item.Sjxf || 0) }}</td>
          <td>{{ $priceFormat(item.Yf || 0) }}</td>
          <td>{{ $priceFormat(item.Qk || 0) }}</td>
        </tr>
      </table>
      <div style="font-weight: 600; margin: 20px 0; font-size: 18px">
        导游信息
      </div>
      <table
        style="
          text-align: left;
          border-collapse: collapse;
          width: 100%;
          border-radius: 10px;
          border-style: hidden;
          border-color: #000;
        "
        border="1"
        cellpadding="20"
      >
        <tr style="font-size: 18px">
          <th style="widows: 40%">带团日期</th>
          <th>导游姓名</th>
          <th>带团说明</th>
          <th style="text-align: center">补助是否现发</th>
          <th style="text-align: right">补助金额</th>
        </tr>
        <tr v-for="item in state.dys" :key="item">
          <td>{{ item.Dtksrq + " ~ " + item.Dtjsrq }}</td>
          <td>
            {{ item.Xm }}
          </td>
          <td>{{ item.Sm || "-" }}</td>
          <td style="text-align: center">{{ item.Isxf ? "是" : "否" }}</td>
          <td style="text-align: right">{{ $priceFormat(item.Bzje) }}</td>
        </tr>
        <tr>
          <td colspan="4" style="text-align: right; font-weight: 600">
            合计：
          </td>
          <td style="text-align: right">
            {{ $priceFormat(state.model.Dbhj) }}
          </td>
        </tr>
      </table>
      <div style="font-weight: 600; margin: 20px 0; font-size: 18px">
        借还款信息
      </div>
      <div style="border: 1px solid #000; margin-bottom: 20px">
        <ul style="display: flex; overflow: hidden">
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1.23;
              padding: 10px;
              border-right: 1px solid #000;
            "
          >
            借款金额
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Jkje) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1;
              padding: 10px;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            "
          >
            导游现收
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Dyxs) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1;
              padding: 10px;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            "
          >
            导游现付
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Dyxf) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1;
              padding: 10px;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            "
          >
            导补现发
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Bzxf) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              padding: 10px;
              flex: 1;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            "
          >
            导游应还
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{
              $priceFormat(
                state.model.Jkje +
                  state.model.Dyxs -
                  state.model.Dyxf -
                  state.model.Bzxf
              )
            }}
          </li>
        </ul>
        <ul
          style="
            display: flex;
            align-items: center;
            overflow: hidden;
            border-top: 1px solid #000;
          "
        >
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1;
              padding: 10px;
              border-right: 1px solid #000;
            "
          >
            借款说明:
          </li>
          <li style="flex: 11; padding: 10px">
            {{ state.model.Jksm || "-" }}
          </li>
        </ul>
        <ul
          style="
            display: flex;
            align-items: center;
            overflow: hidden;
            border-top: 1px solid #000;
          "
        >
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1;
              padding: 10px;
              border-right: 1px solid #000;
            "
          >
            领用物资:
          </li>
          <li style="flex: 11; padding: 10px">
            {{ state.model.FtLywz || "-" }}
          </li>
        </ul>
        <ul
          style="
            display: flex;
            align-items: center;
            overflow: hidden;
            border-top: 1px solid #000;
          "
        >
          <li
            style="
              background-color: #f5f7fa;
              text-align: right;
              flex: 1;
              padding: 10px;
              border-right: 1px solid #000;
            "
          >
            其他注意事项:
          </li>
          <li style="flex: 11; padding: 10px">
            {{ state.model.FtZysx || "-" }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { lvyoudingdan_detail } from "@/http/api";
export default {
  props: {
    id: Number,
  },
  setup(props) {
    const divDom = ref(null);
    const state = reactive({
      model: {},
      item: {},
      mxs: [],
      dys: [],
      cgs: [],
    });
    onMounted(() => {
      lvyoudingdan_detail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs, dys, cgs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;
          state.dys = dys;
          state.cgs = cgs;
        }
      });
    });
    return { state, divDom };
  },
};
</script>
<style scoped></style>
