<template>
  <div class="tabsBox" style="position: relative; padding: 10px">
    <ul
      style="
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0 10px 0 #e2e2e2;
        border-radius: 7px;
        padding: 10px 20px;
        align-items: center;
      "
    >
      <div style="flex: 1.2; overflow: hidden">
        <ul style="display: flex">
          <text style="font-size: 14px; margin-right: 20px">
            {{ state.model.Bh }}
          </text>
          <c_status :model="state.model.Zt"></c_status>
        </ul>
        <ul style="margin: 10px 0 0 20px">
          <text style="color: #a2a2a2">下单信息：</text>
          {{
            state.model.KhLxr + "      " + state.model.Cjsj
          }}
        </ul>
        <ul style="margin: 10px 0 0 20px">
          <text style="color: #a2a2a2">下单备注：</text>
          {{
            state.model.Bz || "-"
          }}
        </ul>
        <ul style="margin: 10px 0 0 20px" v-if="state.model?.Zt?.Key == 4">
          <text style="color: #a2a2a2">取消原因：</text>
          {{
            state.model.Shbz
          }}
        </ul>
      </div>
      <div style="flex: 1; display: flex; align-items: center">
        <ul
          style="
            display: flex;
            flex-direction: column;
            margin-right: 100px;
            align-items: center;
          "
        >
          <c_money :value="state.model.Xsj" :size="20"></c_money>
          <li style="font-size: 12px; color: #a2a2a2">订单金额</li>
        </ul>
        <ul style="display: flex; flex-direction: column; align-items: center">
          <c_money :value="state.model.Yfje" :size="20"></c_money>
          <li style="font-size: 12px; color: #a2a2a2">已付</li>
        </ul>
        <el-button
          type="danger"
          link
          :icon="List"
          @click="state.showZfjl = true"
          style="margin: 0 40px"
        >
          支付记录
        </el-button>
        <ul
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 20px;
          "
        >
          <c_money :value="state.model.Qk" :size="20"></c_money>
          <li style="font-size: 12px; color: #a2a2a2">欠款</li>
        </ul>
      </div>
    </ul>
    <ul style="display: flex; align-items: center; margin: 10px">
      <li style="color: #a2a2a2; font: 600 14px/24px ''; flex: 1">商品信息</li>
      <el-button
        type="warning"
        link
        icon="List"
        @click="state.showBgjlList = true"
        v-if="state.model.Bgjls.length > 0"
        >变更记录</el-button
      >
    </ul>
    <ul style="" v-for="item in state.model.Mxs" :key="item" class="product">
      <li style="flex: 1.5">
        <text class="title">使用日期：</text>{{ item.Syrq }}
      </li>
      <li
        style="
          border-radius: 30px;
          border-width: 1px;
          border-style: solid;
          padding: 5px 15px;
          flex: none;
        "
        :style="{ color: item.Zt?.Color, borderColor: item.Zt.Color }"
      >
        {{ item.Zt?.Text }}
      </li>
      <li style="text-align: center">{{ item.SpMc }}</li>
      <li>{{ item.SpPlMc }}</li>
      <li>{{ item.SpTcMc }}</li>
      <li style="display: flex; align-items: center">
        <c_money :value="state.model.Xsdj" />{{ "/" + item.SpTcDw + " x " }}
        <text style="color: #ffa500; font-size: 14px">{{ item.Sl }}</text>
      </li>
      <li style="display: flex">
        合计：<c_money :value="state.model.Xszj"></c_money>
      </li>
      <li style="display: flex">
        已付： <c_money :value="state.model.Yfje" />
      </li>
      <li style="color: #f00; display: flex">
        <text class="title">欠款：</text><c_money :value="state.model.Qk" />
      </li>
      <li>
        <text class="title">核验状态：</text
        ><text :style="{ color: item.Hyzt?.Color }">{{ item.Hyzt?.Text }}</text>
      </li>
    </ul>
    <ul style="display: flex; align-items: center; margin: 10px">
      <li style="color: #a2a2a2; font: 600 14px/24px ''; flex: 1">名单信息</li>
    </ul>
    <ul
      style="padding: 15px"
      v-for="item in state.ykData"
      :key="item"
      class="product"
    >
      <li style="flex: none; margin-right: 20px; width: 100px">
        {{ item.Xm }}
      </li>
      <li style="flex: 2">
        <text class="title">联系电话：</text>{{ item.Sjhm || "-" }}
      </li>
      <li style="display: flex; align-items: center; flex: 2">
        <div
          style="
            border-radius: 20px;
            padding: 3px 5px;
            width: fit-content;
            font-size: 10px;
            margin-right: 5px;
          "
          :style="{
            backgroundColor: cardTypeArr.find((w) => w.Value == item.Zjlx)
              ?.BgColor,
            color: cardTypeArr.find((w) => w.Value == item.Zjlx)?.Color,
          }"
        >
          {{ cardTypeArr.find((w) => w.Value == item.Zjlx)?.Value }}
        </div>
        {{ item.Zjh }}
      </li>
      <li :style="{ color: item.Xb?.Color }">
        <text class="title">性别：</text>{{ item.Xb?.Text || "-" }}
      </li>
      <li style="flex: 1.5">
        <text class="title">出生日期：</text>{{ item.Csrq || "-" }}
      </li>
      <li><text class="title">年龄：</text>{{ item.Nl || "-" }}</li>
      <li style="flex: 4">
        <text class="title">备注：</text>{{ item.Bz || "-" }}
      </li>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showBgjlList"
        title="变更记录"
        @close="state.showBgjlList = false"
      >
        <c_bgjllist
          @close="state.showBgjlList = false"
          :id="props.data?.id"
        ></c_bgjllist>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showZfjl"
        title="支付记录"
        @close="state.showZfjl = false"
      >
        <c_ShangPinDingDanMingXiZhiFuJiLuList
          @close="state.showZfjl = false"
          :ddId="props.data?.id"
        ></c_ShangPinDingDanMingXiZhiFuJiLuList>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpindingdan_detail, shangpindingdan_getyk } from "@/http/api";
import { cardTypeArr } from "@/util/enum";

import c_bgjllist from "./XstjBgjl.vue";
export default {
  components: {
    c_bgjllist,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      model: {},
      tabsName: "sp",
      bgjl: {}, //变更记录
      ykData: [],
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      shangpindingdan_detail(props.data?.id).then((res) => {
        if (res.status == 200) state.model = res.data;
      });
      onLoadYk();
    };

    const onLoadYk = () => {
      shangpindingdan_getyk(props.data?.id).then((res) => {
        console.log(res.data);
        if (res.status == 200) state.ykData = res.data;
      });
    };

    return {
      state,
      cardTypeArr,
      props,
    };
  },
};
</script>
<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  box-shadow: 0 0 10px 0 #e2e2e2;
  margin-top: 10px;
  border-radius: 7px;
  padding: 0 10px 10px 10px;

  & > li {
    width: calc(33% - 10px);
    margin-top: 10px;
    margin-right: 10px;
  }

  .title {
    color: #a2a2a2;
  }
}

.product {
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 #e2e2e2;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  .title {
    color: #a2a2a2;
  }

  li {
    flex: 1;
  }
}
</style>
<style lang="less">
.bgSp {
  max-height: 90vh;
  overflow-y: auto;
  /*关闭弹窗头部 */
  .el-dialog__header {
    display: none;
  }
  .title {
    color: #a2a2a2;
  }
}

.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, #ffa500, rgb(243, 214, 134));
  color: #fff;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #ffa500, rgb(243, 214, 134));
  right: 0;
}
</style>
