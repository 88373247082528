<template>
  <div>
    <ul
      style="
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: flex-end;
      "
      v-if="!props.search"
    >
      <el-button
        type="primary"
        icon="Plus"
        link
        @click="
          delete state.selectId;
          state.showCompile = true;
        "
      >
        添加
      </el-button>
    </ul>
    <el-table :data="state.tabData">
      <el-table-column type="index" label="#"> </el-table-column>
      <el-table-column label="导游">
        <template #default="{ row }">
          <text>{{ row.DyXm + "(" + row.DySjhm + ")" }}</text>
        </template>
      </el-table-column>
      <el-table-column label="带团日期">
        <template #default="{ row }">
          <text>{{ row.Dtksrq + "~" + row.Dtjsrq }}</text>
        </template>
      </el-table-column>
      <el-table-column label="带团说明" prop="Bz" :formatter="$tab_TextFormat">
      </el-table-column>
      <el-table-column label="补助">
        <template #default="{ row }">
          <li class="flex-row ai-center" style="font-weight: 600">
            <c_money :value="row.Bzje" :size="24"></c_money>
            <text v-if="row.IsXf" style="margin-left: 10px; color: #080">
              现发
            </text>
          </li>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" v-if="!props.search">
        <template #default="{ row }">
          <el-button
            type="primary"
            :icon="Edit"
            link
            @click="
              state.selectId = row.Id;
              state.showCompile = true;
            "
            >编辑
          </el-button>
          <el-button
            type="danger"
            :icon="Delete"
            link
            @click="onDelete(row.Id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="(state.selectId ? '修改' : '添加') + '导游派遣'"
        @close="state.showCompile = false"
      >
        <c_LvYouDingDanDaoYouCompile
          @close="
            state.showCompile = false;
            onLoad();
          "
          :ddId="props.id"
          :id="state.selectId"
          :model="props.model"
          :dates="props.dates"
        ></c_LvYouDingDanDaoYouCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_dypqlist, lvyoudingdan_deldypq } from "@/http/api";
import { confirm_func } from "@/util/tFormat";
export default {
  props: {
    id: Number,
    model: Object,
    search: Boolean,
    dates: Array,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_dypqlist(props.id).then((res) => {
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    };

    const onDelete = (id) => {
      confirm_func(lvyoudingdan_deldypq, id, onLoad);
    };

    return {
      state,
      onDelete,
      props,
      onLoad,
    };
  },
};
</script>
<style scoped></style>
