import $http from "./index";
import qs from "qs";
//加载动画及错误信息提示    1：表示显示加载动画，不提示错误信息   2：不显示加载动画，提示错误信息  3：表示即显示加载动画，又错误提示
export const buildHeaders = (type, loadMsg = "加载中...") => {
  let data = {};
  if (type === 1) {
    //表示显示加载动画，不提示错误信息
    data = {
      LoadMsg: encodeURIComponent(loadMsg),
    };
  } else if (type === 2) {
    //不显示加载动画，提示错误信息
    data = {
      ShowError: true,
    };
  } else if (type === 3) {
    //表示即显示加载动画，又错误提示
    data = {
      LoadMsg: encodeURIComponent(loadMsg),
      ShowError: true,
    };
  }
  return data;
};

//登录验证
export const judgeLogin = (account, password) => {
  return $http.get("/Login/" + account + "/" + password, {
    headers: buildHeaders(3, "正在验证登录信息，请稍后……"),
  });
};

//获取首页数据
export const home_get = () => {
  return $http.get("/Home");
};

//获取登录用户信息
export const home_getuser = () => {
  return $http.get("/Home/GetUser");
};

//修改用户信息
export const home_setuser = (data) => {
  return $http.put("/Home/SetUser", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改密码
export const home_editpass = (data) => {
  return $http.put("/Home/EditPass", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取证件类型通用下拉数据
export const home_getzjlx = () => {
  return $http.get("/Home/GetZjlx");
};

//获取自动生成任务程序在线状态
export const home_applicationStatus = () => {
  return $http.get("/Home/ApplicationStatus");
};

//首页统计
export const home_tj = () => {
  return $http.get("/Home/Tj");
};

//首页旅游统计
export const home_lytj = () => {
  return $http.get("/Home/LyTj");
};

//首页商品
export const home_sp = () => {
  return $http.get("/Home/Sp");
};

//首页客户
export const home_kh = () => {
  return $http.get("/Home/Kh");
};

//首页订单
export const home_dd = () => {
  return $http.get("/Home/Dd");
};

//上传附件
export const fujian_add = (data) => {
  let headers = buildHeaders(3, "正在上传，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.post("FuJian", data, {
    headers,
  });
};

//删除部门
export const fujian_delete = (id) => {
  return $http.delete("/FuJian/" + id, {
    headers: buildHeaders(3, "正在删除文件，请稍后……"),
  });
};

//获取部门列表
export const bumen_list = (params) => {
  return $http.get("/BuMen", { params });
};

//获取部门列表
export const bumen_detail = (id) => {
  return $http.get("/BuMen/" + id);
};

//获取部门树
export const bumen_tree = (params) => {
  return $http.get("/BuMen/Tree", { params });
};

//获取序号
export const bumen_getxh = (sjId) => {
  return $http.get("/BuMen/GetXh?sjId=" + (!sjId ? "" : sjId));
};

//添加部门
export const bumen_add = (data) => {
  return $http.post("/BuMen", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改部门
export const bumen_edit = (id, data) => {
  return $http.put("/BuMen/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除部门
export const bumen_delete = (id) => {
  return $http.delete("/BuMen/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取人员列表
export const yonghu_list = (params) => {
  return $http.get("/YongHu", { params });
};

//获取人员列表
export const yonghu_detail = (id) => {
  return $http.get("/YongHu/" + id);
};

//获取人员下拉框数据
export const yonghu_select = () => {
  return $http.get("/YongHu/Select");
};

//获取用户树
export const yonghu_tree = (params) => {
  return $http.get("/YongHu/Tree", { params });
};

//添加人员
export const yonghu_add = (data) => {
  return $http.post("/YongHu", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改人员
export const yonghu_edit = (id, data) => {
  return $http.put("/YongHu/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//重置人员密码
export const yonghu_resetpass = (id) => {
  return $http.put("/YongHu/CzMm/" + id, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//配置权限
export const yonghu_bdjs = (id, roleId) => {
  return $http.post("/YongHu/BdJs/" + id, roleId, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除人员
export const yonghu_delete = (id) => {
  return $http.delete("/YongHu/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取角色列表
export const juese_list = (params) => {
  return $http.get("/JueSe", { params });
};

//获取角色下拉框数据
export const juese_select = () => {
  return $http.get("/JueSe/Select");
};

//获取角色详情
export const juese_detail = (id) => {
  return $http.get("/JueSe/" + id);
};

//添加角色
export const juese_add = (data) => {
  return $http.post("/JueSe", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取权限
export const juese_getqx = (id) => {
  return $http.get("/JueSe/GetQx/" + id);
};

//配置权限
export const juese_setqx = (id, data) => {
  return $http.post("/JueSe/SetQx/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改角色
export const juese_edit = (id, data) => {
  return $http.put("/JueSe/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除角色
export const juese_delete = (id) => {
  return $http.delete("/JueSe/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取字典列表
export const zidian_list = (params) => {
  return $http.get("/ZiDian", { params });
};

//获取字典详情
export const zidian_detail = (id) => {
  return $http.get("/ZiDian/" + id);
};

//获取字典树
export const zidian_tree = (params) => {
  return $http.get("/ZiDian/Tree", { params });
};

/**
 * 获取供应商下拉框数据
 * @param {上级名称} sjmc
 * @returns
 */
export const zidian_select = (sjmc) => {
  return $http.get("/ZiDian/Select/" + sjmc);
};

//根据上级Id获取字典下拉数据
export const zidian_selectbysjid = (sjid) => {
  return $http.get("/ZiDian/SelectBySjId/" + sjid);
};

//添加字典
export const zidian_add = (data) => {
  return $http.post("/ZiDian", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改字典
export const zidian_edit = (id, data) => {
  return $http.put("/ZiDian/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改字典状态
export const zidian_editstatus = (id) => {
  return $http.post(
    "/ZiDian/Status/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//删除字典
export const zidian_delete = (id) => {
  return $http.delete("/ZiDian/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取供应商列表
export const gongyingshang_list = (params) => {
  return $http.get("/GongYingShang", { params });
};

//获取供应商详情
export const gongyingshang_detail = (id) => {
  return $http.get("/GongYingShang/" + id);
};

//获取供应商订单
export const gongyingshang_getspdd = (id, params) => {
  return $http.get("/GongYingShang/GetSpDd/" + id, { params });
};

//获取供应商旅游采购订单
export const gongyingshang_getlycg = (id) => {
  return $http.get("/GongYingShang/GetLycg/" + id);
};

//获取供应商树
export const gongyingshang_tree = (params) => {
  return $http.get("/GongYingShang/Tree", { params });
};

//添加供应商
export const gongyingshang_add = (data) => {
  return $http.post("/GongYingShang", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改供应商
export const gongyingshang_edit = (id, data) => {
  return $http.put("/GongYingShang/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改供应商状态
export const gongyingshang_editstatus = (id) => {
  return $http.post(
    "/GongYingShang/Status/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//删除供应商
export const gongyingshang_delete = (id) => {
  return $http.delete("/GongYingShang/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取供应商下拉框数据
export const gongyingshang_select = () => {
  return $http.get("/GongYingShang/Select");
};

//获取供应商用户列表
export const gongyingshangyonghu_list = (params) => {
  return $http.get("/GongYingShangYongHu", { params });
};

//获取供应商用户列表
export const gongyingshangyonghu_detail = (id) => {
  return $http.get("/GongYingShangYongHu/" + id);
};

//添加供应商用户
export const gongyingshangyonghu_add = (data) => {
  return $http.post("/GongYingShangYongHu", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改供应商用户
export const gongyingshangyonghu_edit = (id, data) => {
  return $http.put("/GongYingShangYongHu/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除供应商用户
export const gongyingshangyonghu_delete = (id) => {
  return $http.delete("/GongYingShangYongHu/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取供应商用户列表
export const gongyingshangzhanghu_list = (gysId) => {
  return $http.get("/GongYingShangZhangHu/" + gysId);
};

//获取供应商账户下拉框数据
export const gongyingshangzhanghu_selectbygysid = (gysId) => {
  return $http.get("/GongYingShangZhangHu/SelectByGysId/" + gysId);
};

//获取供应商用户列表
export const gongyingshangzhanghu_detail = (id) => {
  return $http.get("/GongYingShangZhangHu/Detail/" + id);
};

//添加供应商用户
export const gongyingshangzhanghu_add = (gysId, data) => {
  return $http.post("/GongYingShangZhangHu/" + gysId, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改供应商用户
export const gongyingshangzhanghu_edit = (id, data) => {
  return $http.put("/GongYingShangZhangHu/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除供应商用户
export const gongyingshangzhanghu_delete = (id) => {
  return $http.delete("/GongYingShangZhangHu/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取商品列表
export const shangpin_list = (params) => {
  return $http.get("/ShangPin", { params });
};

//获取商品详情
export const shangpin_detail = (id) => {
  return $http.get("/ShangPin/" + id, {
    headers: buildHeaders(3),
  });
};

//获取商品对应的订单
export const shangpin_getddbyid = (id) => {
  return $http.get("/ShangPin/Dd/" + id, {
    headers: buildHeaders(3),
  });
};

//添加商品
export const shangpin_add = (data) => {
  let headers = buildHeaders(3, "正在提交数据，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.post("ShangPin", data, {
    headers,
  });
};

//上传图片
export const shangpin_uploadtp = (data) => {
  let headers = buildHeaders(3, "正在加载图片，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.post("ShangPin/UploadTp", data, {
    headers,
  });
};

//修改商品
export const shangpin_edit = (data) => {
  let headers = buildHeaders(3, "正在提交数据，请稍后……");
  return $http.put("ShangPin", data, {
    headers,
  });
};

//获取商品列表(用于手工下单)
export const shangpin_xdget = (params) => {
  return $http.get("/ShangPin/Xd", { params });
};

//获取商品详情(用于手工下单)
export const shangpin_xddetail = (id, params) => {
  return $http.get("/ShangPin/Xd/" + id, {
    params,
    headers: buildHeaders(3),
  });
};

//删除商品
export const shangpin_delete = (id) => {
  return $http.delete("/ShangPin/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//根据商品Id获取商品套餐
export const shangpintaocan_all = (spId) => {
  return $http.get("/ShangPin/Tc/" + spId);
};

//根据Id获取商品套餐详情
export const shangpintaocan_detail = (id) => {
  return $http.get("/ShangPin/Tc/Detail/" + id, {
    headers: buildHeaders(3),
  });
};

//添加商品套餐
export const shangpintaocan_add = (spId, data) => {
  return $http.post("/ShangPin/Tc/" + spId, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改商品套餐
export const shangpintaocan_edit = (id, data) => {
  return $http.put("/ShangPin/Tc/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//库存变更
export const shangpintaocan_changekc = (id, kc) => {
  return $http.put("/ShangPin/Tc/ChangeKc/" + id, kc, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除商品套餐
export const shangpintaocan_delete = (id) => {
  return $http.delete("/ShangPin/Tc/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//根据商品Id获取规则集合
export const xiaoshouguize_getbyspid = (spid, isSc = false) => {
  return $http.get("/XiaoShouGuiZe/GetBySpId/" + spid + "?isSc=" + isSc, {
    headers: buildHeaders(3),
  });
};

//根据Id获取销售规则详情
export const xiaoshouguize_detail = (id) => {
  return $http.get("/XiaoShouGuiZe/" + id, {
    headers: buildHeaders(3),
  });
};

//添加销售规则
export const xiaoshouguize_add = (data) => {
  return $http.post("/XiaoShouGuiZe/", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//销售规则启用/停用
export const xiaoshouguize_status = (id, res) => {
  return $http.post(`/XiaoShouGuiZe/${id}`, res, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改销售规则
export const xiaoshouguize_edit = (id, data) => {
  return $http.put("/XiaoShouGuiZe/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除销售规则
export const xiaoshouguize_delete = (id) => {
  return $http.delete("/XiaoShouGuiZe/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取客户列表
export const kehu_list = (params) => {
  return $http.get("/KeHu", { params });
};

//获取客户详情
export const kehu_detail = (id) => {
  return $http.get("/KeHu/" + id);
};

//获取客户下拉框数据
export const kehu_select = () => {
  return $http.get("/KeHu/Select");
};

//添加客户
export const kehu_add = (data) => {
  return $http.post("/KeHu", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改客户
export const kehu_edit = (id, data) => {
  return $http.put("/KeHu/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改同行客户状态
export const kehu_editstatus = (id) => {
  return $http.post(
    "/KeHu/Status/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//删除同行客户
export const kehu_delete = (id) => {
  return $http.delete("/KeHu/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//根据用户Id获取客户经理为该id的同行客户下拉框数据
export const kehu_selectbyyhid = () => {
  return $http.get("/KeHu/SelectByYhId");
};

//获取同行客户交易记录
export const kehu_getjyjl = (id, params) => {
  return $http.get("/KeHu/GetJyjl/" + id, { params });
};

//获取同行客户订单
export const kehu_getspdd = (id, params) => {
  return $http.get("/KeHu/GetSpDd/" + id, { params });
};

//获取客户旅游订单
export const kehu_getlydd = (id, params) => {
  return $http.get("/KeHu/GetLydd/" + id, { params });
};

//获取同行客户订单状态集合
export const shangpindingdan_getzt = () => {
  return $http.get("/ShangPinDingDan/GetZt");
};

//获取同行客户订单状态集合
export const shangpindingdan_export = (params) => {
  return $http.get("/ShangPinDingDan/Export", {
    params,
    responseType: "blob",
    headers: buildHeaders(3, "正在生成文件，请稍后……"),
  });
};

//获取同行客户订单列表
export const shangpindingdan_list = (params) => {
  return $http.get("/ShangPinDingDan", { params });
};

//获取同行客户订单列表
export const shangpindingdan_detail = (id) => {
  return $http.get("/ShangPinDingDan/" + id, {
    headers: buildHeaders(3),
  });
};

//创建同行客户订单
export const shangpindingdan_add = (data) => {
  return $http.post("/ShangPinDingDan", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取同行客户订单明细
export const shangpindingdan_getmx = (ddId) => {
  return $http.get("/ShangPinDingDan/GetMx/" + ddId);
};

//获取同行客户订单游客列表
export const shangpindingdan_getyk = (ddId) => {
  return $http.get("/ShangPinDingDan/GetYk/" + ddId);
};

//获取同行客户变更记录
export const shangpindingdan_getbgjl = (ddId) => {
  return $http.get("/ShangPinDingDan/GetBgjl/" + ddId);
};

//获取商品订单支付记录
export const shangpindingdan_getzfjl = (ddId) => {
  return $http.get("ShangPinDingDan/GetZfjl/" + ddId, {
    headers: buildHeaders(3),
  });
};

//获取同行客户订单日志
export const shangpindingdan_getrz = (ddId) => {
  return $http.get("/ShangPinDingDan/GetRz/" + ddId);
};

//确认同行客户订单列表
export const shangpindingdan_confirm = (id) => {
  return $http.post(
    "/ShangPinDingDan/Confirm/" + id,
    {},
    {
      headers: buildHeaders(3, "正在确认订单，请稍后……"),
    }
  );
};

//取消同行客户订单列表
export const shangpindingdan_cancel = (id, bz) => {
  return $http.post("/ShangPinDingDan/Cancel/" + id, bz, {
    headers: buildHeaders(3, "正在取消订单，请稍后……"),
  });
};

//获取订单明细
export const shangpindingdanmingxi_dz_list = (params) => {
  return $http.get("/ShangPinDingDanMingXi/Dz_List/", { params });
};

/**
 * 获取同行客户订单日志列表
 * @param {*} ddId 订单Id
 * @returns
 */
export const shangpindingdanrizhi_all = (ddId) => {
  return $http.get("/ShangPinDingDanRiZhi/All/" + ddId);
};

//根据订单Id获取游客信息
export const shangpindingdanyouke_getbyddid = (ddId) => {
  return $http.get("/ShangPinDingDanYouKe/GetByDdId/" + ddId);
};

//获取订单变更记录
export const shangpindingdanbiangengjilu_list = (params) => {
  return $http.get("/ShangPinDingDanBianGengJiLu", { params });
};

//获取订单变更记录
export const shangpindingdanbiangengjilu_listbyddid = (ddId) => {
  return $http.get("/ShangPinDingDanBianGengJiLu/GetByDdId/" + ddId);
};

//审核变更记录
export const shangpindingdanbiangengjilu_check = (id, result, bz = "") => {
  return $http.post(
    "/ShangPinDingDanBianGengJiLu/Check/" + id,
    {
      result,
      bz,
    },
    {
      headers: buildHeaders(3, "正在审核变更记录，请稍后……"),
    }
  );
};

/**
 * 获取订单关联的名单
 * @param {*} ddId 订单Id
 * @returns
 */
export const youkemingdan_all = (ddId) => {
  return $http.get("/ShangPinDingDanYouKe/GetByDdId/" + ddId);
};

//获取到账公示列表
export const daozhanggongshi_list = (params) => {
  return $http.get("/DaoZhangGongShi", { params });
};

//获取到账公示转账类型
export const daozhanggongshi_getzzfs = () => {
  return $http.get("/DaoZhangGongShi/Zzfs");
};

//获取到账公示详情
export const daozhanggongshi_detail = (id) => {
  return $http.get("/DaoZhangGongShi/" + id, {
    headers: buildHeaders(3),
  });
};

//添加到账公示
export const daozhanggongshi_add = (data) => {
  return $http.post("/DaoZhangGongShi", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//到账公示认领
export const daozhanggongshi_renling = (id, KhId) => {
  return $http.post(
    `/DaoZhangGongShi/${id}/${KhId}`,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//到账公式撤回
export const daozhanggongshi_refund = (id, data) => {
  return $http.post("/DaoZhangGongShi/Refund/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改到账公示
export const daozhanggongshi_edit = (id, data) => {
  return $http.put("/DaoZhangGongShi/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除到账公示
export const daozhanggongshi_delete = (id) => {
  return $http.delete("/DaoZhangGongShi/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取公告列表
export const gonggao_list = (params) => {
  return $http.get("/GongGao", { params });
};

//获取公告明细
export const gonggao_detail = (id) => {
  return $http.get("/GongGao/" + id);
};

//添加公告
export const gonggao_add = (data) => {
  let headers = buildHeaders(3, "正在提交数据，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.post("GongGao", data, {
    headers,
  });
};

//改变公告显示状态
export const gonggao_change = (id, data) => {
  return $http.post("/GongGao/Change/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改公告
export const gonggao_edit = (id, data) => {
  let headers = buildHeaders(3, "正在提交数据，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.put("GongGao/" + id, data, {
    headers,
  });
};

//删除公告
export const gonggao_delete = (id) => {
  return $http.delete("/GongGao/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取销售统计报表数据
export const baobiao_xstj = (params) => {
  return $http.get("BaoBiao/Xstj", { params });
};

//导出统计报表
export const baobiao_xstjexport = (data) => {
  return $http.post("BaoBiao/XstjExport", data, {
    responseType: "blob",
    headers: buildHeaders(3, "正在生成文件，请稍后……"),
  });
};

//获取可对账的旅游订单采购单
export const duizhangdan_lydd_cglist = (params) => {
  return $http.get("/DuiZhangDan/Lydd_CgList", { params });
};

//设置旅游采购单返利
export const duizhangdan_setlyddcgfl = (data) => {
  return $http.post("/DuiZhangDan/SetLyddCgFl", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取对账单列表
export const duizhangdan_list = (params) => {
  return $http.get("/DuiZhangDan", { params });
};

//获取对账单详情
export const duizhangdan_detail = (id) => {
  return $http.get("/DuiZhangDan/" + id, {
    headers: buildHeaders(3),
  });
};

//获取对账单关联的订单明细
export const duizhangdan_getddmxs = (id) => {
  return $http.get("/DuiZhangDan/GetDdMxs/" + id);
};

//添加对账单
export const duizhangdan_add = (data) => {
  return $http.post("/DuiZhangDan", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//审核对账单
export const duizhangdan_check = (id, data) => {
  return $http.post("/DuiZhangDan/Check/" + id, data, {
    headers: buildHeaders(3, "正在审核变更记录，请稍后……"),
  });
};

//对账单付款
export const duizhangdan_pay = (id, data) => {
  return $http.post("/DuiZhangDan/Pay/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//汇付-根据单号获取订单明细
export const huifu_getdetail = (ddbh) => {
  return $http.get("/HuiFu/" + ddbh);
};

//汇付-下单
export const huifu_create = (type, data) => {
  let url = "";
  if (type == "A_JSAPI") url = "Zfb";
  else if (type == "T_JSAPI") url = "Wx";
  else url = "Yl";
  return $http.post("/HuiFu/" + url, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//汇付-微信下单
export const huifu_wx = (openid) => {
  return $http.post(
    "/HuiFu/Wx/" + openid,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//汇付-获取用户的openid
export const huifu_getopenid = (code) => {
  return $http.get("/HuiFu/GetOpenId/" + code);
};

//汇付-根据单号获取订单明细
export const huifu_lydd_detail = (id) => {
  return $http.get("/HuiFu/Lydd_Detail/" + id);
};

//汇付-下单
export const huifu_lydd_create = (type, data) => {
  let url = "";
  if (type == "A_JSAPI") url = "Lydd_Zfb";
  else if (type == "T_JSAPI") url = "Lydd_Wx";
  else url = "Yl";
  return $http.post("/HuiFu/" + url, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取旅游订单列表
export const lvyoudingdan_list = (params) => {
  return $http.get("/LvYouDingDan", { params });
};

//获取同行客户订单状态集合
export const lvyoudingdan_export = (params) => {
  return $http.get("/LvYouDingDan/Export", {
    params,
    responseType: "blob",
    headers: buildHeaders(3, "正在生成文件，请稍后……"),
  });
};

//获取旅游订单详情
export const lvyoudingdan_detail = (id) => {
  return $http.get("/LvYouDingDan/" + id, {
    headers: buildHeaders(3),
  });
};

//获取旅游订单状态下拉数据
export const lvyoudingdan_getztselect = () => {
  return $http.get("/LvYouDingDan/GetZtSelect");
};

//获取旅游订单编号
export const lvyoudingdan_getbh = () => {
  return $http.get("/LvYouDingDan/GetBh");
};

//创建同行客户订单
export const lvyoudingdan_add = (data) => {
  return $http.post("/LvYouDingDan", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改旅游订单
export const lvyoudingdan_edit = (id, data) => {
  return $http.put("/LvYouDingDan/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//取消旅游订单
export const lvyoudingdan_cancel = (id) => {
  return $http.post(
    "/LvYouDingDan/Cancel/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//获取旅游订单行程信息
export const lvyoudingdan_getxcxx = (id) => {
  return $http.get("/LvYouDingDan/GetXcxx/" + id, {
    headers: buildHeaders(3),
  });
};

//设置旅游订单行程信息
export const lvyoudingdan_setxcxx = (id, data) => {
  return $http.post("/LvYouDingDan/SetXcxx/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取旅游订单预定信息
export const lvyoudingdan_getydxx = (id) => {
  return $http.get("/LvYouDingDan/GetYdxx/" + id, {
    headers: buildHeaders(3),
  });
};

//设置旅游订单预定信息
export const lvyoudingdan_setydxx = (id, data) => {
  return $http.post("/LvYouDingDan/SetYdxx/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取旅游订单附件
export const lvyoudingdan_getfj = (id) => {
  return $http.get("/LvYouDingDan/GetFj/" + id, {
    headers: buildHeaders(3),
  });
};

//上传附件
export const lvyoudingdan_addfj = (id, data) => {
  let headers = buildHeaders(3, "正在提交数据，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.post("LvYouDingDan/AddFj/" + id, data, {
    headers,
  });
};

//删除附件
export const lvyoudingdan_delfj = (id) => {
  return $http.delete("/LvYouDingDan/DelFj/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取旅游订单游客
export const lvyoudingdan_getyk = (id) => {
  return $http.get("/LvYouDingDan/GetYk/" + id, {
    headers: buildHeaders(3),
  });
};

//导入游客
export const lvyoudingdan_importyk = (id, data) => {
  let headers = buildHeaders(3, "正在提交数据，请稍后……");
  headers["Content-Type"] = "multipart/form-data";
  return $http.post("/LvYouDingDan/ImportYk/" + id, data, {
    headers,
  });
};

//清空游客
export const lvyoudingdan_clearyk = (id) => {
  return $http.post(
    "/LvYouDingDan/ClearYk/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//获取旅游订单详情
export const lvyoudingdan_getykdetail = (id) => {
  return $http.get("/LvYouDingDan/GetYkDetail/" + id, {
    headers: buildHeaders(3),
  });
};

//添加游客
export const lvyoudingdan_addyk = (id, data) => {
  return $http.post("/LvYouDingDan/AddYk/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改游客
export const lvyoudingdan_edityk = (id, data) => {
  return $http.put("/LvYouDingDan/EditYk/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除游客
export const lvyoudingdan_delyk = (ids) => {
  return $http.delete("/LvYouDingDan/DeleteYk", {
    params: { ids },
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    },
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取收退款分页数据
export const lvyoudingdan_getskpage = (params) => {
  return $http.get("/LvYouDingDan/GetSkPage", { params });
};

//获取旅游订单收款记录
export const lvyoudingdan_getsk = (id) => {
  return $http.get("/LvYouDingDan/GetSk/" + id, {
    headers: buildHeaders(3),
  });
};

//获取旅游订单收款记录
export const lvyoudingdan_getskdetail = (id) => {
  return $http.get("/LvYouDingDan/GetSkDetail/" + id, {
    headers: buildHeaders(3),
  });
};

//添加收款记录
export const lvyoudingdan_addsk = (id, data) => {
  return $http.post("/LvYouDingDan/AddSk/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改收款记录
export const lvyoudingdan_editsk = (id, data) => {
  return $http.put("/LvYouDingDan/EditSk/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除收款记录
export const lvyoudingdan_delsk = (id) => {
  return $http.delete("/LvYouDingDan/DelSk/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//审核退款记录
export const lvyoudingdan_checksk = (id, data) => {
  return $http.post("/LvYouDingDan/CheckSk/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取旅游订单采购记录
export const lvyoudingdan_getcg = (id) => {
  return $http.get("/LvYouDingDan/GetCg/" + id, {
    headers: buildHeaders(3),
  });
};

//获取旅游订单采购记录
export const lvyoudingdan_getcgdetail = (id) => {
  return $http.get("/LvYouDingDan/GetCgDetail/" + id, {
    headers: buildHeaders(3),
  });
};

//添加采购记录
export const lvyoudingdan_addcg = (id, data) => {
  return $http.post("/LvYouDingDan/AddCg/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改采购记录
export const lvyoudingdan_editcg = (id, data) => {
  return $http.put("/LvYouDingDan/EditCg/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除采购记录
export const lvyoudingdan_delcg = (id) => {
  return $http.delete("/LvYouDingDan/DelCg/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//旅游订单确认回团
export const lvyoudingdan_qrht = (id) => {
  return $http.post(
    "/LvYouDingDan/Qrht/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//旅游订单确认回团
export const lvyoudingdan_qrbz = (id, data) => {
  return $http.post("/LvYouDingDan/Qrbz/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取旅游订单借还款记录
export const lvyoudingdan_jhk = () => {
  return $http.get("/LvYouDingDan/GetJhk", {
    headers: buildHeaders(3),
  });
};

//借款发放
export const lvyoudingdan_jkff = (id, data) => {
  return $http.post("/LvYouDingDan/Jkff/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取旅游订单借还款记录
export const lvyoudingdan_dybz = (params) => {
  return $http.get("/LvYouDingDan/GetDybz", {
    params,
    headers: buildHeaders(3),
  });
};

//确认还款
export const lvyoudingdan_qrhk = (id, data) => {
  return $http.post("/LvYouDingDan/Qrhk/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//提交结算
export const lvyoudingdan_tjjs = (id) => {
  return $http.post(
    "/LvYouDingDan/Tjjs/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//结算审核
export const lvyoudingdan_jssh = (id, data) => {
  return $http.post("/LvYouDingDan/Jssh/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取同行客户订单日志
export const lvyoudingdan_rz_list = (ddId) => {
  return $http.get("/LvYouDingDan/Rz_List/" + ddId);
};

//获取旅游订单发团记录
export const lvyoudingdanfatuanjilu_list = (params) => {
  return $http.get("/LvYouDingDanFaTuanJiLu", {
    params,
    headers: buildHeaders(3),
  });
};

//获取旅游订单发团记录
export const lvyoudingdanfatuanjilu_detail = (id) => {
  return $http.get("/LvYouDingDanFaTuanJiLu/" + id, {
    headers: buildHeaders(3),
  });
};

//添加采购记录
export const lvyoudingdan_addftjl = (id, data) => {
  return $http.post("/LvYouDingDan/AddFtjl/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//添加采购记录
export const lvyoudingdanfatuanjilu_check = (id, data) => {
  return $http.post("/LvYouDingDanFaTuanJiLu/Check/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取导游列表
export const daoyou_list = (params) => {
  return $http.get("/DaoYou", { params });
};

//获取导游列表
export const daoyou_detail = (id) => {
  return $http.get("/DaoYou/" + id, {
    headers: buildHeaders(3),
  });
};

//获取人员下拉框数据
export const daoyou_select = () => {
  return $http.get("/DaoYou/Select");
};

//添加导游
export const daoyou_add = (data) => {
  return $http.post("/DaoYou", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改导游
export const daoyou_edit = (id, data) => {
  return $http.put("/DaoYou/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除导游
export const daoyou_delete = (id) => {
  return $http.delete("/DaoYou/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取旅游订单合同
export const lvyoudingdan_ht_getlist = (ddid) => {
  return $http.get("/LvYouDingDan/Ht_GetList/" + ddid, {
    headers: buildHeaders(3),
  });
};

//获取旅游订单合同
export const lvyoudingdan_ht_getdetail = (id) => {
  return $http.get("/LvYouDingDan/Ht_GetDetail/" + id, {
    headers: buildHeaders(3),
  });
};

//添加合同
export const lvyoudingdan_ht_add = (ddId, data) => {
  return $http.post("/LvYouDingDan/Ht_Add/" + ddId, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改合同
export const lvyoudingdan_ht_edit = (id, data) => {
  return $http.post("/LvYouDingDan/Ht_Edit/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除合同
export const lvyoudingdan_ht_delete = (id) => {
  return $http.delete("/LvYouDingDan/Ht_Delete/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取合同保险购买方式
export const lvyoudingdan_ht_getbxgmfs = () => {
  return $http.get("/LvYouDingDan/Ht_GetBxgmfs");
};

//获取合同支付方式
export const lvyoudingdan_ht_getzffs = () => {
  return $http.get("/LvYouDingDan/Ht_GetZffs");
};

//获取合同争议解决方式
export const lvyoudingdan_ht_getjjfs = () => {
  return $http.get("/LvYouDingDan/Ht_GetJjfs");
};

//获取合同证件类型
export const lvyoudingdan_ht_getzjlx = () => {
  return $http.get("/LvYouDingDan/Ht_GetZjlx");
};

//合同申报
export const lvyoudingdan_ht_shenbao = (id) => {
  return $http.post(
    "/LvYouDingDan/Ht_ShenBao/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//重发合同签署短信
export const lvyoudingdan_ht_resend = (id) => {
  return $http.post(
    "/LvYouDingDan/Ht_Resend/" + id,
    {},
    {
      headers: buildHeaders(3, "正在提交数据，请稍后……"),
    }
  );
};

//获取供应商列表
export const youke_list = (params) => {
  return $http.get("/YouKe", { params });
};

//获取供应商详情
export const youke_detail = (id) => {
  return $http.get("/YouKe/" + id);
};

//添加供应商
export const youke_add = (data) => {
  return $http.post("/YouKe", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改供应商
export const youke_edit = (id, data) => {
  return $http.put("/YouKe/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除游客
export const youke_delete = (id) => {
  return $http.delete("/YouKe/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//拉黑游客
export const youke_lahei = (id, data) => {
  return $http.post("/YouKe/LaHei/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//洗白游客
export const youke_xibai = (id, data) => {
  return $http.post("/YouKe/XiBai/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//获取供应商列表
export const lvyoudingdan_dypqlist = (id) => {
  return $http.get("/LvYouDingDan/GetDypq/" + id);
};

//获取供应商详情
export const lvyoudingdan_dypqdetail = (id) => {
  return $http.get("/LvYouDingDan/GetDypqDetail/" + id);
};

//添加供应商
export const lvyoudingdan_adddypq = (id, data) => {
  return $http.post("/LvYouDingDan/AddDypq/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改供应商
export const lvyoudingdan_editdypq = (id, data) => {
  return $http.put("/LvYouDingDan/EditDypq/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除供应商
export const lvyoudingdan_deldypq = (id) => {
  return $http.delete("/LvYouDingDan/DelDypq/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取账户列表
export const zhanghu_list = (params) => {
  return $http.get("/ZhangHu", { params });
};

//获取账户列表
export const zhanghu_detail = (id) => {
  return $http.get("/ZhangHu/" + id);
};

//获取账户下拉框数据
export const zhanghu_select = () => {
  return $http.get("/ZhangHu/Select");
};

//添加账户
export const zhanghu_add = (data) => {
  return $http.post("/ZhangHu", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//修改账户
export const zhanghu_edit = (id, data) => {
  return $http.put("/ZhangHu/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//删除账户
export const zhanghu_delete = (id) => {
  return $http.delete("/ZhangHu/" + id, {
    headers: buildHeaders(3, "正在删除数据，请稍后……"),
  });
};

//获取导游补助列表
export const daoyoubuzhu_list = (params) => {
  return $http.get("/DaoYouBuZhu", { params });
};

//获取导游补助列表
export const daoyoubuzhu_detail = (id) => {
  return $http.get("/DaoYouBuZhu/" + id);
};

//构建导游补助单
export const daoyoubuzhu_build = (ids) => {
  return $http.get("/DaoYouBuZhu/Build", {
    params: { ids },
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    },
  });
};

//添加导游补助
export const daoyoubuzhu_add = (data) => {
  return $http.post("/DaoYouBuZhu", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};

//审核导游补助
export const daoyoubuzhu_check = (id, data) => {
  return $http.post("/DaoYouBuZhu/Check/" + id, data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};
