<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态/编号"
          align="center"
          width="140"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />

        <el-table-column
          prop="Lx"
          sortable="Lx"
          label="类型"
          :formatter="$tab_StatusFormat"
          width="120"
        />
        <el-table-column
          prop="Cjsj"
          sortable="Cjsj"
          label="申请时间"
          min-width="140"
        />
        <el-table-column
          prop="Yfje"
          sortable="Yfje"
          label="付款金额(元)"
          width="130"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Bz"
          label="备注"
          min-width="200"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          label="操作"
          width="280"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Edit"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'DuiZhangDanDetail',
                  title: '对账单详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              >审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { duizhangdan_list, gongyingshang_delete } from "@/http/api";
import { ElMessageBox, ElMessage } from "element-plus";

export default {
  setup() {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: { Zt: 0, TableName: "DuiZhangDan_ShenHe" },
        Order: "Cjsj",
        Asc: false,
      },
    });

    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      duizhangdan_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //打开内容页
    const onOpenCompile = (id) => {
      state.compileTitle = id ? "修改" : "添加";
      state.selectId = id;
      state.showCompile = true;
    };

    //关闭内容页
    const onCloseCompile = (refresh) => {
      state.showCompile = false;
      if (refresh) {
        onLoad();
      }
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      ElMessageBox.confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        gongyingshang_delete(id).then((res) => {
          if (res.status == 200) {
            ElMessageBox.alert("删除成功", "提示", {
              type: "success",
              callback: () => {
                onLoad();
              },
            });
          } else {
            ElMessage({
              message: res.data,
              type: "error",
            });
          }
        });
      });
    };

    const onShowAuthorize = (id) => {
      state.key = Math.random();
      state.showAuthorize = true;
      state.selectId = id;
    };

    const onShowUser = (id) => {
      state.showUser = true;
      state.selectId = id;
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onCloseCompile,
      onOpenCompile,
      onDelete,
      onShowAuthorize,
      onShowUser,
    };
  },
};
</script>
<style>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
