<template>
  <div class="listBox">
    <el-table
      :data="state.tableData"
      v-loading="!Array.isArray(state.tableData)"
      element-loading-background="rgba(0, 0, 0, 0.2)"
      :default-sort="{
        prop: state.params.Order,
        order: state.params.Asc == false ? 'descending' : 'ascending',
      }"
      @sort-change="onSortChange"
    >
      <el-table-column
        fixed
        label="#"
        width="50"
        type="index"
        :index="
          (index) =>
            (state.params.PageIndex - 1) * state.params.PageSize + index + 1
        "
        align="center"
      />
      <el-table-column
        prop="Lx"
        sortable="Lx"
        label="交易类型"
        width="140"
        :formatter="$tab_StatusFormat"
      >
        <template #default="{ row }">
          <text :style="{ color: row.Lx?.BgColor }">{{ row.Lx?.Text }}</text>
        </template>
      </el-table-column>
      <el-table-column prop="Je" sortable="Je" label="交易金额" width="140" />
      <el-table-column prop="Ye" sortable="Ye" label="交易后余额" width="140" />
      <el-table-column prop="Bz" label="备注" :formatter="$tab_TextFormat" />
      <el-table-column
        label="操作人"
        :formatter="(row) => $tab_GroupFormat(row.CzrXm, row.Czsj)"
      />
    </el-table>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { kehu_getjyjl } from "@/http/api";
import { useRouter } from "vue-router";

export default {
  props: {
    khId: Number,
  },
  components: {},
  setup(props) {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Czsj",
        Asc: false,
      },
    });
    onMounted(() => {
      console.log(4444);
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      kehu_getjyjl(props.khId).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          console.log(list);
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      state.params.PageIndex = 1;
      state.params.PageSize = 20;
      state.params.Params = {};
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
    };
  },
};
</script>
<style></style>
