<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li
        style="display: flex; align-items: center"
        v-if="
          $store.state.loginUser?.IsAdmin ||
          $store.state.loginUser?.Qxs?.includes('B_ShangPin_List_添加')
        "
      >
        <el-button
          type="primary"
          icon="Plus"
          link
          @click="
            delete state.selectId;
            state.showCompile = true;
          "
        >
          添加
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          align="center"
          width="80"
        >
          <template #default="scope">
            <text :style="{ color: scope.row.IsQy ? '#080' : '#f00' }">
              {{ scope.row.IsQy ? "售卖中" : "停售" }}
            </text>
          </template>
        </el-table-column>
        <el-table-column
          prop="PlMc"
          sortable="PlMc"
          label="商品品类"
          min-width="110"
        />
        <el-table-column
          prop="Mc"
          sortable="Mc"
          label="商品名称/供应商"
          :formatter="(row) => $tab_GroupFormat(row.Mc, row.GysMc)"
          :show-overflow-tooltip="true"
          min-width="150"
        />
        <el-table-column
          label="目的地/地址"
          :show-overflow-tooltip="true"
          :formatter="(row) => $tab_GroupFormat(row.Mdd || '-', row.Dz || '')"
          min-width="120"
        />
        <el-table-column
          prop="Dh"
          label="联系电话"
          :formatter="$tab_TextFormat"
          min-width="130"
        />
        <el-table-column
          prop="Jj"
          label="商品简介"
          :formatter="$tab_TextFormat"
          :show-overflow-tooltip="true"
          min-width="200"
        />
        <el-table-column
          prop="Cjsj"
          sortable="Cjsj"
          label="创建信息"
          width="160"
          :formatter="(row) => $tab_GroupFormat(row.CjrXm, row.Cjsj)"
        />
        <el-table-column
          label="操作"
          width="220"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'ShangPinDetail',
                  title: '商品详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_ShangPin_List_管理')
              "
              >管理
            </el-button>
            <el-button
              type="primary"
              :icon="Edit"
              link
              @click="
                state.selectId = row.Id;
                state.showCompile = true;
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_ShangPin_List_编辑')
              "
              >编辑
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(row.Id)"
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes('B_ShangPin_List_删除')
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="(state.selectId ? '修改' : '添加') + '商品'"
        @close="state.showCompile = false"
      >
        <c_ShangPinCompile
          @close="
            state.showCompile = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_ShangPinCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpin_list, shangpin_delete } from "@/http/api";
import { confirm_func } from "@/util/tFormat";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: {},
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  setup() {
    const state = reactive(new InitFormData());
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      shangpin_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      if (column.order) {
        state.params.Order = column.prop;
        state.params.Asc = column.order === "ascending";
      } else {
        state.params.Order = "Cjsj";
        state.params.Asc = false;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      confirm_func(shangpin_delete, id, onLoad);
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onDelete,
    };
  },
};
</script>
<style>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
