<template>
  <el-select
    v-model="state.value"
    :placeholder="props.placeholder"
    @change="onChange"
    :clearable="false"
  >
    <el-option
      v-for="item in props.dataArr"
      :key="item"
      :label="item.Mc"
      :value="item[props.valueKey]"
    />
    <template #footer v-if="props.showRefresh">
      <el-button size="small" @click="onLoad()"> 刷新 </el-button>
    </template>
  </el-select>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  props: {
    value: Object, //绑定对象
    dataArr: Array, //下拉数据集合
    placeholder: String, //占位文本
    labelKey: {
      type: String,
      default: "Mc",
    },
    valueKey: String, //取值字段
    showRefresh: {
      //是否显示刷新按钮
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {
      state.value = props.value;
    });

    const onLoad = () => {
      emit("Refresh");
    };

    const onChange = (item) => {
      emit("Value-Change", item);
    };
    return { state, onChange, props, onLoad };
  },
};
</script>
<style lang="less">
.numberLeft {
  & .el-input__inner {
    text-align: left !important;
  }
}

.com_number_box {
  .el-input__wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
</style>
