<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
    "
    ref="boxDom"
  >
    <div
      style="
        box-shadow: 0 0 10px 0 #e2e2e2;
        padding: 30px 10px;
        border-radius: 7px;
        line-height: 18px;
      "
    >
      <ul style="display: flex; align-items: center">
        <li style="flex: 1; color: #a2a2a2; text-align: center">
          共：<text style="font-weight: 600; font-size: 16px; color: #000">{{
            state.model.Dds
          }}</text
          >笔
        </li>
        <li
          style="
            flex: 1.5;
            color: #a2a2a2;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          合计应付：
          <c_money :value="state.model.Yfje"></c_money>
        </li>
        <li style="flex: 3" v-if="state.model.Shzt == null">
          <el-button type="primary" @click="onCheck(true)">审核通过</el-button>
          <el-button type="danger" @click="onCheck(false)"
            >审核不通过</el-button
          >
        </li>
        <li v-else style="flex: 3">
          <text v-if="state.model.Shzt == true" style="color: #080"
            >审核通过</text
          >
          <template v-else>
            <text style="color: #080">审核不通过通过</text>
            <text style="color: #080">{{ state.model.Shbz }}</text>
          </template>
        </li>
      </ul>
    </div>
    <div
      style="
        margin-top: 10px;
        padding: 10px;
        border-radius: 7px;
        line-height: 30px;
      "
    >
      <ul style="display: flex; align-items: center">
        <li style="flex: 1; display: flex">
          <text style="color: #a2a2a2">状态：</text
          ><c_status :model="state.model.Zt"></c_status>
        </li>
        <li style="flex: 1">
          <text style="color: #a2a2a2">申请单号：</text
          >{{ state.model.Dh || "-" }}
        </li>
        <li style="flex: 1">
          <text style="color: #a2a2a2">申请人：</text
          >{{ state.model.Cjr || "-" }}
        </li>
      </ul>
      <ul style="display: flex; align-items: center">
        <li style="flex: 1; display: flex">
          <text style="color: #a2a2a2">申请时间：</text
          >{{ state.model.Cjsj || "-" }}
        </li>
        <li style="flex: 1">
          <text style="color: #a2a2a2">供应商：</text
          >{{ state.model.GysMc || "-" }}
        </li>
        <li style="flex: 1; display: flex">
          <text style="color: #a2a2a2">类型：</text
          ><c_status :model="state.model.Lx"></c_status>
        </li>
      </ul>
      <ul style="display: flex; align-items: center">
        <li style="flex: 1; display: flex">
          <text style="color: #a2a2a2">付款方式：</text
          ><c_status :model="state.model.Fkfs"></c_status>
        </li>
      </ul>
      <ul style="display: flex; align-items: center; margin-top: 5px">
        <text style="color: #a2a2a2">到账银行：</text>
        {{
          "卡号：" +
          state.model.Dzkh +
          ",收款人：" +
          state.model.Skr +
          "，开户行：" +
          state.model.Dzyh
        }}
      </ul>
      <ul style="display: flex; align-items: center; margin-top: 5px">
        <text style="color: #a2a2a2">备注：</text>
        {{
          state.model.Bz || "-"
        }}
      </ul>
    </div>
    <div>
      <el-table
        :data="state.mxData"
        v-loading="!Array.isArray(state.mxData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          align="center"
        />
        <el-table-column label="商品信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul>
                <text style="color: #000">{{ row.SpMc }}</text>
                <text style="color: #a2a2a2">[{{ row.GysMc }}]</text>
              </ul>
              <ul style="font-size: 12px; line-height: 12px; color: #a2a2a2">
                {{
                  row.SpPlMc +
                  "  " +
                  row.Syrq +
                  "  " +
                  row.SpTcMc +
                  "  ￥" +
                  row.Xsdj +
                  "/" +
                  row.SpTcDw +
                  " x " +
                  row.Sl
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul>
                <text style="color: #000">{{ row.Dh }}</text>
                <text style="color: #a2a2a2">[{{ row.KhMc }}]</text>
              </ul>
              <ul style="font-size: 12px; line-height: 12px; color: #a2a2a2">
                {{
                  row.KhLxr + "  " + row.KhSjhm + "  " + row.Xdsj
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Jszj" label="结算金额" width="100">
          <template #default="{ row }">
            <c_money :value="row.Jszj"></c_money>
          </template>
        </el-table-column>
        <el-table-column prop="Yfje" label="已付金额" width="100">
          <template #default="{ row }">
            <c_money :value="row.Yfje"></c_money>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  duizhangdan_detail,
  duizhangdan_getddmxs,
  duizhangdan_check,
} from "@/http/api";
import http from "@/http";
import { ElMessage } from "element-plus";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const tabsDom = ref(null);
    const boxDom = ref(null);
    const state = reactive({
      model: {},
      mxData: [],
    });
    onMounted(() => {
      if (props.data?.id) {
        onLoadDetail();
      }
    });

    const onLoadDetail = () => {
      duizhangdan_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          state.model = res.data;
        }
      });

      duizhangdan_getddmxs(props.data?.id).then((res) => {
        if (res.status == 200) state.mxData = res.data;
      });
    };

    const onCheck = (res) => {
      duizhangdan_check(props.data?.id, res).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoadDetail();
        }
      });
    };

    return {
      state,
      props,
      onLoadDetail,
      boxDom,
      tabsDom,
      http,
      onCheck,
    };
  },
};
</script>
<style lang="less">
.gysTab {
  .el-tabs__content {
    position: inherit;
  }
}
</style>
