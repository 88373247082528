<template>
  <div>
    <ul
      style="
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: flex-end;
      "
      v-if="!props.search && props.model?.Zt < 4"
    >
      <el-button
        type="primary"
        icon="Plus"
        link
        @click="
          delete state.selectId;
          state.showCompile = true;
        "
      >
        添加
      </el-button>
    </ul>
    <el-table
      :data="state.tabData"
      :summary-method="getSummaries"
      show-summary
      class="lycg_tab"
    >
      <el-table-column type="index" label="#"> </el-table-column>
      <el-table-column
        label="编号/类型"
        width="160"
        :formatter="(row) => $tab_GroupFormat(row.Lb1 + '-' + row.Lb2, row.Bh)"
      />
      <el-table-column
        label="供应商"
        width="160"
        prop="GysMc"
        :formatter="$tab_TextFormat"
      />
      <el-table-column label="采购项" min-width="400">
        <template #default="{ row }">
          <div
            v-for="item in row.Mxs"
            :key="item"
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              width: fit-content;
            "
          >
            {{ item.Lx + item.Mc }}
            <li
              style="
                display: flex;
                align-items: center;
                color: #a2a2a2;
                margin-left: 10px;
                width: fit-content;
              "
            >
              <text>
                {{ $priceFormat(item.Dj) + " * " + item.Sl1 }}
              </text>
              <text v-if="item.Sl2">
                {{ " * " + item.Sl2 }}
              </text>
              <text>
                {{ " = " + $priceFormat(item.Zj) + "元" }}
              </text>
              <text style="color: #606266; margin-left: 10px">预计现付：</text>
              <text>
                {{ $priceFormat(item.Yjxf) + "元" }}
              </text>
            </li>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="总金额"
        prop="Zje"
        :formatter="$tab_MoneyFormat"
      />
      <el-table-column
        label="实际现付"
        prop="Sjxf"
        :formatter="$tab_MoneyFormat"
      />
      <el-table-column
        label="预计现付"
        prop="Yjxf"
        :formatter="$tab_MoneyFormat"
      />
      <el-table-column
        label="已付金额"
        prop="Yf"
        :formatter="$tab_MoneyFormat"
      />
      <el-table-column
        label="应付金额"
        prop="Qk"
        :formatter="$tab_MoneyFormat"
      />
      <el-table-column label="备注" prop="Sm" :formatter="$tab_TextFormat" />
      <el-table-column
        label="操作"
        fixed="right"
        v-if="!props.search"
        width="160"
        align="center"
      >
        <template #default="{ row }">
          <el-button
            type="primary"
            :icon="Edit"
            link
            @click="
              state.selectId = row.Id;
              state.showCompile = true;
            "
            :disabled="props.model?.Zt >= 4"
            >编辑
          </el-button>
          <el-button
            type="danger"
            :icon="Delete"
            link
            @click="onDelete(row.Id)"
            :disabled="props.model?.Zt >= 4"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="(state.selectId ? '修改' : '添加') + '采购'"
        @close="state.showCompile = false"
      >
        <c_LvYouDingDanCaiGouCompile
          @close="
            state.showCompile = false;
            onLoad();
            onRefreshDd();
          "
          :ddId="props.id"
          :id="state.selectId"
          :model="props.model"
        ></c_LvYouDingDanCaiGouCompile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, h } from "vue";
import { lvyoudingdan_getcg, lvyoudingdan_delcg } from "@/http/api";
import { confirm_func, tab_MoneyFormat } from "@/util/tFormat";

export default {
  props: {
    id: Number,
    model: Object,
    search: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_getcg(props.id).then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    };

    const onRefresh = () => {
      onLoad();
      emit("onLoad");
    };

    const onDelete = (id) => {
      confirm_func(lvyoudingdan_delcg, id, onRefresh);
      emit("onLoad");
    };

    //刷新订单
    const onRefreshDd = () => {
      emit("onLoad");
    };

    const getSummaries = () => {
      let sums = [];
      sums[3] = h("text", { style: "font-weight:600;float:right" }, "合计");
      sums[4] = tab_MoneyFormat(null, null, props.model?.Cgcb);
      sums[5] = tab_MoneyFormat(null, null, props.model?.Cgsjxf);
      sums[6] = tab_MoneyFormat(null, null, props.model?.Cgyjxf);
      sums[7] = tab_MoneyFormat(null, null, props.model?.Cgyf);
      sums[8] = tab_MoneyFormat(null, null, props.model?.Cgqk);
      return sums;
    };

    return {
      state,
      onDelete,
      props,
      onLoad,
      onRefreshDd,
      getSummaries,
    };
  },
};
</script>
<style lang="less">
.lycg_tab {
  .el-table__footer {
    .el-table__cell {
      background-color: #fff !important;
    }
  }
}
</style>
