<template>
  <div style="height: 100%; overflow-y: auto; padding: 10px 20px; z-index: 2">
    <ul style="position: relative; text-align: center">
      <el-image
        :src="require('@/assets/images/logo.png')"
        style="position: absolute; left: 20px; height: 30px; top: 10px"
      />
      <li style="font-size: 24px; font-weight: 600">肥西文旅集团发团通知书</li>
      <li style="font-size: 18px">NO:{{ state.model.Bh }}</li>
      <el-button
        icon="printer"
        style="position: absolute; right: 20px; top: 20px"
        ref="printDom"
        @click="onPrint"
        >打印</el-button
      >
    </ul>
    <ul style="display: flex; margin-top: 20px; padding: 0 10px">
      <li style="display: flex; flex: 1; align-items: flex-end">
        <text style="color: #a2a2a2; font-size: 30">团队名称：</text
        ><text style="font-weight: 600">{{ state.model.Mc }}</text>
      </li>
      <li style="display: flex; align-items: flex-end">
        <text style="color: #a2a2a2; font-size: 30">计调：</text
        ><text style="">{{
          state.model.JdyXm + " - " + state.model.JdyBmMc
        }}</text>
      </li>
    </ul>
    <div class="fttzs_box1">
      <ul>
        <li>团期</li>
        <li>{{ state.item.Ftsj + " 至 " + state.item.Htsj }}</li>
        <li>行程天数</li>
        <li>{{ state.model.Ts + "天" + state.model.Ys + "晚" }}</li>
      </ul>
      <ul>
        <li>客户</li>
        <li>{{ state.item.KhMc }}</li>
        <li>预计现付</li>
        <li>{{ $priceFormat(state.model.Cgyjxf) }}</li>
      </ul>
      <ul>
        <li>导游借款</li>
        <li>{{ $priceFormat(state.model.Jkje) }}</li>
      </ul>
      <ul>
        <li
          style="
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          借款说明
        </li>
        <li style="display: flex; align-items: center">
          {{ state.model.Jksm || "-" }}
        </li>
      </ul>
      <ul>
        <li
          style="
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          领用物资
        </li>
        <li style="display: flex; align-items: center">
          {{ state.model.FtLywz }}
        </li>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      团队行程
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      {{ state.model.Xcxx }}
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      导游信息
    </ul>
    <div
      style="border: 1px solid #e2e2e2; border-radius: 10px; overflow: hidden"
    >
      <ul style="display: flex; font-weight: 600; background-color: #f7f7f7">
        <li
          style="
            flex: 2;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          带团日期
        </li>
        <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
          导游姓名
        </li>
        <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
          带团说明
        </li>
        <li
          style="
            flex: 1;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          补助是否现发
        </li>
        <li style="flex: 1; text-align: right; padding: 10px">补助金额</li>
      </ul>
      <ul
        style="display: flex; border-top: 1px solid #e2e2e2"
        v-for="dy in state.dys"
        :key="dy"
      >
        <li
          style="
            flex: 2;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ dy.Dtksrq + " ~ " + dy.Dtjsrq }}
        </li>
        <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
          {{ dy.Xm }}
        </li>
        <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
          {{ dy.Bz || "-" }}
        </li>
        <li
          style="
            flex: 1;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ dy.IsXf ? "是" : "否" }}
        </li>
        <li style="flex: 1; text-align: right; padding: 10px">
          {{ $priceFormat(dy.Bzje) }}
        </li>
      </ul>
      <ul
        style="border-top: 1px solid #e2e2e2; display: flex; font-weight: 600"
      >
        <li
          style="
            flex: 7;

            text-align: right;
            border-right: 1px solid #e2e2e2;
            padding: 10px 10px 10px 70px;
          "
        >
          合计
        </li>
        <li style="flex: 1; padding: 10px; text-align: right">
          {{ $priceFormat(state.model.Dbhj) }}
        </li>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      其他注意事项
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      {{ state.model.FtZysx }}
    </div>
    <div style="display: flex; margin: 30px 0">
      <li style="flex: 1">计调员：</li>
      <li style="flex: 1">财务：</li>
      <li style="flex: 1">导游/领队：</li>
      <li style="flex: 1">部门经理：</li>
    </div>
  </div>
  <div id="divDom" style="z-index: 1">
    <div
      style="
        height: 100%;
        overflow-y: auto;
        padding: 10px 20px;
        font-size: 35px;
      "
    >
      <ul style="position: relative; text-align: center">
        <el-image
          :src="require('@/assets/images/logo.png')"
          style="position: absolute; left: 20px; height: 50px; top: 20px"
        />
        <li style="font-size: 50px; font-weight: 600">
          肥西文旅集团发团通知书
        </li>
        <li style="font-size: 30px; margin-top: 10px">
          NO:{{ state.model.Bh }}
        </li>
      </ul>
      <ul style="display: flex; margin-top: 40px; padding: 0 10px">
        <li style="display: flex; flex: 1; align-items: flex-end">
          <text style="color: #a2a2a2; font-size: 30px">团队名称：</text
          ><text style="font-weight: 600">{{ state.model.Mc }}</text>
        </li>
        <li style="display: flex; align-items: flex-end">
          <text style="color: #a2a2a2; font-size: 30px">计调：</text
          ><text style="">{{
            state.model.JdyXm + " - " + state.model.JdyBmMc
          }}</text>
        </li>
      </ul>
      <div class="fttzs_box_print">
        <ul>
          <li>团期</li>
          <li>{{ state.item.Ftsj + " 至 " + state.item.Htsj }}</li>
          <li>行程天数</li>
          <li>{{ state.model.Ts + "天" + state.model.Ys + "晚" }}</li>
        </ul>
        <ul>
          <li>客户</li>
          <li>{{ state.item.KhMc }}</li>
          <li>预计现付</li>
          <li>{{ $priceFormat(state.model.Cgyjxf) }}</li>
        </ul>
        <ul>
          <li>导游借款</li>
          <li>{{ $priceFormat(state.model.Jkje) }}</li>
        </ul>
        <ul>
          <li
            style="
              min-height: 100px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            借款说明
          </li>
          <li style="display: flex; align-items: center">
            {{ state.model.Jksm || "-" }}
          </li>
        </ul>
        <ul>
          <li
            style="
              min-height: 100px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            领用物资
          </li>
          <li style="display: flex; align-items: center">
            {{ state.model.FtLywz }}
          </li>
        </ul>
      </div>
      <ul style="font-weight: 600; font-size: 24px; margin: 30px 0">
        团队行程
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 10px;
          min-height: 100px;
        "
      >
        {{ state.model.Xcxx }}
      </div>
      <ul style="font-weight: 600; font-size: 24px; margin: 30px 0">
        导游信息
      </ul>
      <div
        style="border: 1px solid #e2e2e2; border-radius: 10px; overflow: hidden"
      >
        <ul style="display: flex; font-weight: 600; background-color: #f7f7f7">
          <li
            style="
              flex: 2;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
              font-size: 24px;
            "
          >
            带团日期
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
              font-size: 24px;
            "
          >
            导游姓名
          </li>
          <li
            style="
              flex: 3;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
              font-size: 24px;
            "
          >
            带团说明
          </li>
          <li
            style="
              flex: 1;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
              font-size: 24px;
            "
          >
            补助是否现发
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              white-space: nowrap;
              font-size: 24px;
            "
          >
            补助金额
          </li>
        </ul>
        <ul
          style="display: flex; border-top: 1px solid #e2e2e2"
          v-for="dy in state.dys"
          :key="dy"
        >
          <li
            style="
              flex: 2;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              font-size: 24px;
            "
          >
            {{ dy.Dtksrq + " ~ " + dy.Dtjsrq }}
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              font-size: 24px;
            "
          >
            {{ dy.Xm }}
          </li>
          <li
            style="
              flex: 3;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              font-size: 24px;
            "
          >
            {{ dy.Bz || "-" }}
          </li>
          <li
            style="
              flex: 1;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              font-size: 24px;
            "
          >
            {{ dy.IsXf ? "是" : "否" }}
          </li>
          <li
            style="flex: 1; text-align: right; padding: 10px; font-size: 24px"
          >
            {{ $priceFormat(dy.Bzje) }}
          </li>
        </ul>
        <ul
          style="border-top: 1px solid #e2e2e2; display: flex; font-weight: 600"
        >
          <li
            style="
              flex: 7;
              text-align: right;
              border-right: 1px solid #e2e2e2;
              padding: 10px 10px 10px 70px;
              font-size: 24px;
            "
          >
            合计
          </li>
          <li
            style="flex: 1; padding: 10px; text-align: right; font-size: 24px"
          >
            {{ $priceFormat(state.model.Dbhj) }}
          </li>
        </ul>
      </div>
      <ul style="font-weight: 600; font-size: 24px; margin: 30px 0">
        其他注意事项
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 10px;
          min-height: 200px;
        "
      >
        {{ state.model.FtZysx }}
      </div>
      <div style="display: flex; margin: 30px 0; font-size: 24px">
        <li style="flex: 1">计调员：</li>
        <li style="flex: 1">财务：</li>
        <li style="flex: 1">导游/领队：</li>
        <li style="flex: 1">部门经理：</li>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_detail } from "@/http/api";
import html2canvas from "html2canvas";
import printJS from "print-js";
import { ElLoading } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const state = reactive({
      model: {},
      item: {},
      mxs: [],
      dys: [],
      cgs: [],
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_detail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs, dys, cgs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;
          state.dys = dys;
          state.cgs = cgs;
        }
      });
    };

    //打印发团通知书
    const onPrint = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("divDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      printJS({
        printable: imgData,
        type: "image",
        style: `@media print { @page {size: auto; margin: 0; } body{margin:0;padding:0}}`,
        imageStyle: "width: 100%; margin:0; padding: 0;",
        documentTitle: "",
      });

      loading.close();
    };

    return { state, onPrint };
  },
};
</script>
<style scoped lang="less">
.btnPrint {
  position: absolute;
  right: 20px;
  top: 10px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-size: 26px;
  &:hover {
    background-color: #f6f6f6;
    color: #a2a2a2;
  }
}
.fttzs_box1 {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  & > ul {
    display: flex;

    & > li {
      padding: 10px;
    }

    & > li:not(:last-child) {
      border-right: 1px solid #e2e2e2;
    }
  }

  & li:nth-child(2n + 1) {
    background: #f7f7f7;
    text-align: right;
    font-weight: 600;
    width: 200px;
  }

  & li:nth-child(2n) {
    flex: 1;
  }

  & > ul:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}

.fttzs_box_print {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 40px;
  & > ul {
    display: flex;

    & > li {
      padding: 10px;
      font-size: 28px;
    }

    & > li:not(:last-child) {
      border-right: 1px solid #e2e2e2;
    }
  }

  & li:nth-child(2n + 1) {
    background: #f7f7f7;
    text-align: right;
    font-weight: 600;
    width: 200px;
  }

  & li:nth-child(2n) {
    flex: 1;
  }

  & > ul:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}
</style>
