<template>
  <div class="detailBox" style="position: relative">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
        inline
      >
        <ul style="display: flex; align-items: baseline">
          <el-form-item
            label="状态"
            prop="IsQy"
            :rules="[
              { required: true, message: '请选择角色状态', trigger: 'blur' },
            ]"
            style="flex: 1; display: flex; align-items: center"
          >
            <div style="display: flex; align-items: center">
              <el-switch v-model="state.model.IsQy" />
              <el-radio-group
                v-model="state.model.IsXy"
                style="margin-left: 20px"
              >
                <el-radio :label="true" size="large" style="color: #1779de">
                  协议客户
                </el-radio>
                <el-radio :label="false" size="large" style="color: #f00">
                  非协议客户
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="客户经理"
            prop="JlId"
            :rules="[
              { required: true, message: '请选择客户经理', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <div style="display: flex; width: 100%; overflow: hidden">
              <el-input
                v-model="state.item.JlXm"
                class="shade"
                style="margin-right: 10px"
                readonly
              ></el-input>
              <el-button type="warning" @click="state.showUser = true">
                选择
              </el-button>
            </div>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="单位名称"
            prop="Mc"
            :rules="[
              { required: true, message: '请输入单位名称', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Mc"></el-input>
          </el-form-item>
          <el-form-item label="单位简称" style="flex: 1">
            <el-input v-model="state.model.Jc"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="联系人"
            style="flex: 1"
            prop="Lxr"
            :rules="[
              { required: true, message: '请输入联系人', trigger: 'blur' },
            ]"
          >
            <el-input v-model="state.model.Lxr"></el-input>
          </el-form-item>
          <el-form-item label="性别" style="flex: 1">
            <el-radio-group v-model="state.model.Xb">
              <el-radio :label="1" style="color: #1779de"> 男 </el-radio>
              <el-radio :label="2" style="color: #f00"> 女 </el-radio>
            </el-radio-group>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="手机号码"
            prop="Sjhm"
            :rules="[
              {
                required: true,
                message: '请输入联系人手机号码',
                trigger: 'blur',
              },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Sjhm"></el-input>
          </el-form-item>
          <el-form-item label="来源" style="flex: 1">
            <el-select v-model="state.model.Ly" :clearable="false">
              <el-option label="网络" :value="1" />
              <el-option label="现场" :value="2" />
            </el-select>
          </el-form-item>
        </ul>
        <ul v-if="!props.id" style="display: flex">
          <el-form-item
            :label="!props.id ? '登录密码' : ''"
            prop="Mm"
            :rules="[
              {
                required: true,
                message: '请输入登录密码',
                trigger: 'blur',
              },
            ]"
            style="width: calc(50% - 30px)"
          >
            <el-input
              v-if="!props.id"
              v-model="state.model.Mm"
              type="password"
            ></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="所属板块" style="flex: 1">
            <el-select
              v-model="state.model.BkId"
              placeholder="--请选择所属板块--"
              :filter-method="onBkFilter"
            >
              <el-option
                v-for="item in state.bkData || []"
                :key="item.Id"
                :label="item.Mc"
                :value="item.Id"
              />
              <template #footer>
                <el-button size="small" @click="onLoadBk()"> 刷新 </el-button>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="所属行业" style="flex: 1">
            <el-select
              v-model="state.model.HyId"
              placeholder="--请选择所属板块--"
              :filter-method="onHyFilter"
            >
              <el-option
                v-for="item in state.hyData || []"
                :key="item.Mc"
                :label="item.Mc"
                :value="item.Id"
              />
              <template #footer>
                <el-button size="small" @click="onLoadHy()"> 刷新 </el-button>
              </template>
            </el-select>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="所在地址" style="flex: 1">
            <el-input v-model="state.model.Dz"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="备注说明" style="flex: 1">
            <el-input
              type="textarea"
              v-model="state.model.Bz"
              :autosize="{ minRows: 4 }"
            />
          </el-form-item>
        </ul>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showUser"
        title="选择客户经理"
        @close="state.showUser = false"
      >
        <c_KeHuSelectUser @close="onUserClose" :id="state.model.JlId">
        </c_KeHuSelectUser>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { kehu_add, kehu_detail, kehu_edit, zidian_select } from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);

    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
        Lx: 3,
        Ly: 1,
        IsXy: true,
      },
      item: {},
    });
    onMounted(() => {
      onLoadBk();
      onLoadHy();
      if (props.id) {
        kehu_detail(props.id).then((res) => {
          if (res.status == 200) {
            const { model, item } = res.data;
            state.model = model;
            state.item = item;
          }
        });
      }
    });

    //加载品类下拉数据
    const onLoadBk = () => {
      zidian_select("板块").then((res) => {
        if (res.status == 200) {
          state.bkData = res.data;
          state.bkSource = res.data;
        }
      });
    };

    //加载品类下拉数据
    const onLoadHy = () => {
      zidian_select("行业").then((res) => {
        if (res.status == 200) {
          state.hyData = res.data;
          state.hySource = res.data;
        }
      });
    };

    //下拉框自定义筛选
    const onBkFilter = (query) => {
      state.bkData = state.bkSource.filter(
        (w) =>
          w.Py?.toUpperCase().includes(query.toUpperCase()) ||
          w.Sp?.toUpperCase().includes(query.toUpperCase()) ||
          w.Mc?.includes(query.toUpperCase())
      );
    };

    //下拉框自定义筛选
    const onHyFilter = (query) => {
      state.hyData = state.hySource.filter(
        (w) =>
          w.Py?.toUpperCase().includes(query.toUpperCase()) ||
          w.Sp?.toUpperCase().includes(query.toUpperCase()) ||
          w.Mc?.includes(query.toUpperCase())
      );
    };

    const onUserClose = (row) => {
      state.model.JlId = row.Id;
      state.item.JlXm = row.Xm;
      state.showUser = false;
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            kehu_edit(props.id, state.model).then((res) => {
              if (res.status == 200) {
                ElMessage.success(res.data);
                setTimeout(() => {
                  emit("close");
                }, 500);
              }
            });
          } else {
            kehu_add(state.model).then((res) => {
              if (res.status == 200) {
                ElMessage.success("添加成功");
                setTimeout(() => {
                  emit("close");
                }, 500);
              }
            });
          }
        }
      });
    };

    return {
      state,
      onSubmit,
      props,
      formDom,
      onLoadBk,
      onLoadHy,
      onBkFilter,
      onHyFilter,
      onUserClose,
    };
  },
};
</script>
<style scoped></style>
