<template>
  <div style="display: flex; align-items: center">
    <li style="flex: 1; padding-left: 20px; font: 600 16px/16px ''">
      肥西文旅集团旅游ERP综合管理平台
    </li>
    <li class="userCss">
      <el-dropdown
        type="primary"
        style="margin-left: 10px; cursor: pointer; outline: none"
      >
        <template #default>
          <div style="font-size: 13px; display: flex; align-items: center">
            <li>
              <el-icon size="17"><Avatar /></el-icon>
            </li>
            <li style="margin-left: 5px; margin-right: 20px">
              {{ $store.state.loginUser?.Xm }}<br />
              <text style="color: #a2a2a2; font-size: 12px; line-height: 16px">
                {{ $store.state.loginUser?.BmMc }}
              </text>
            </li>
          </div>
        </template>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              @click="
                $store.commit('openMenu', {
                  path: 'HomeEditInfo',
                  title: '个人信息',
                  refresh: true,
                })
              "
            >
              个人信息
            </el-dropdown-item>
            <el-dropdown-item
              @click="
                $store.commit('openMenu', {
                  path: 'HomeEditPass',
                  title: '修改密码',
                  refresh: true,
                })
              "
            >
              修改密码
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </li>
    <li @click="onLogout">
      <div
        style="
          display: flex;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;
        "
      >
        <el-icon size="17"><SwitchButton /></el-icon>&nbsp;
        <text>退出</text>
      </div>
    </li>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { ElLoading } from "element-plus";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({});
    onMounted(() => {});

    const onLogout = () => {
      const loading = ElLoading.service({
        lock: true,
        text: "正在退出……",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        localStorage.removeItem("token");
        router.go(0);
        loading.close();
      }, 1000);
    };

    return { state, onLogout, router };
  },
};
</script>
<style>
.userCss :focus-visible {
  outline: none !important;
}

.logOutCss {
  width: 80px;
  background-color: #1779de;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left: 20px;
  font-size: 12px;
  cursor: pointer;
}

.logOutCss:hover div {
  transform: scale(1.2);
  font-weight: 600;
}
</style>
