<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="Zt"
          sortable="Zt"
          label="状态"
          align="center"
          width="120"
          :formatter="$tab_StatusFormat"
        >
        </el-table-column>
        <el-table-column prop="Bh" sortable="Bh" label="申请编号" />
        <el-table-column prop="CjrXm" label="申请人" />
        <el-table-column prop="Cjsj" label="申请时间" />
        <el-table-column prop="DyXm" label="导游姓名" />
        <el-table-column prop="DySjhm" label="联系电话" />
        <el-table-column prop="Bzje" label="补助金额" />
        <el-table-column prop="Bz" label="备注" :formatter="$tab_TextFormat" />
        <el-table-column
          label="操作"
          width="160"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Search"
              link
              @click="
                state.selectId = row.Id;
                state.showSearch = true;
              "
              >查看
            </el-button>
            <el-button
              type="primary"
              :icon="Check"
              link
              @click="
                state.selectId = row.Id;
                state.showCompile = true;
              "
              v-if="row.IsSh"
              >审核
            </el-button>
            <!-- <el-button
              type="primary"
              :icon="Check"
              link
              @click="
                state.selectId = row.Id;
                state.showCompile = true;
              "
              >审核
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showSearch"
        title="查看导游补助申请单"
        @close="state.showSearch = false"
      >
        <c_DaoYouBuZhuSearch
          @close="
            state.showSearch = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_DaoYouBuZhuSearch>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        title="导游补助发放审核"
        @close="state.showCompile = false"
      >
        <c_DaoYouBuZhuDetail
          @close="
            state.showCompile = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_DaoYouBuZhuDetail>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { daoyoubuzhu_list } from "@/http/api";

export default {
  setup() {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          TableName: "DaoYouBuZhu_Sh",
        },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      daoyoubuzhu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
    };
  },
};
</script>
<style scoped lang="less"></style>
