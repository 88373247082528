<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="姓名"
          prop="Xm"
          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Xm"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号码"
          prop="Sjhm"
          :rules="[
            { required: true, message: '请输入手机号码', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Sjhm"></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="Zjlx">
          <el-select
            v-model="state.model.Zjlx"
            style="width: 100%"
            :filterable="false"
          >
            <el-option
              v-for="item in cardTypeArr"
              :key="item.Value"
              :label="item.Value"
              :value="item.Value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="证件号"
          prop="Zjh"
          :rules="[
            { required: true, message: '请输入证件号', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Zjh"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="Bz">
          <el-input
            v-model="state.model.Bz"
            type="textarea"
            :autosize="{ minRows: 3 }"
          />
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { youke_detail, youke_add, youke_edit } from "@/http/api";
import { cardTypeArr } from "@/util/enum";
import { post_response } from "@/util/tFormat";

export default {
  props: {
    id: Number,
    ddId: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);

    const state = reactive({
      model: {
        Xb: 1,
        Zjlx: "身份证",
      },
      jsData: [],
    });
    onMounted(() => {
      if (props.id) {
        youke_detail(props.id).then((res) => {
          if (res.status == 200) state.model = res.data;
        });
      }
    });

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            youke_edit(props.id, state.model).then((res) => {
              post_response(res, emit);
            });
          } else {
            youke_add(state.model).then((res) => {
              post_response(res, emit);
            });
          }
        }
      });
    };

    return { state, formDom, onSubmit, props, cardTypeArr };
  },
};
</script>
<style scoped></style>
