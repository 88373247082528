<template>
  <div style="flex: 1; display: flex; flex-direction: column; overflow: hidden">
    <ul
      style="
        width: 100%;
        border: 1px solid #e2e2e2;
        border-bottom: 0;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
      "
    >
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul
      style="
        overflow: hidden;
        display: flex;
        padding: 10px;
        padding-left: 0;
        border-top: 1px solid #e2e2e2;
      "
    >
      <li style="width: 250px; margin-right: 10px">
        <el-tree
          :data="[
            {
              Mc: '肥西文旅集团旅游ERP综合管理平台',
              Xjs: state.treeData,
              Type: 0,
            },
          ]"
          :props="{ label: 'Mc', children: 'Xjs' }"
          accordion
          :highlight-current="true"
          :default-expand-all="true"
          node-key="Id"
          @node-click="onTreeClick"
          :current-node-key="state.params.Params.XjId"
        >
          <template #default="{ data }">
            <div
              style="display: flex; align-items: baseline"
              v-if="data.Type == 0"
            >
              <el-icon style="margin-right: 2px" size="12">
                <Avatar />
              </el-icon>
              {{ data.Mc }}
            </div>
            <div style="display: flex; align-items: baseline" v-else>
              <el-icon style="margin-right: 2px" size="12">
                <Avatar />
              </el-icon>
              {{ data.Mc }}
            </div>
          </template>
        </el-tree>
      </li>
      <li
        style="flex: 1; display: flex; flex-direction: column; overflow: hidden"
      >
        <el-table
          :data="state.tableData"
          v-loading="!Array.isArray(state.tableData)"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          :default-sort="{
            prop: state.params.Order,
            order: state.params.Asc == false ? 'descending' : 'ascending',
          }"
          @sort-change="onSortChange"
          size="small"
          style="height: fit-content !important"
        >
          <el-table-column width="60" align="center" fixed="left">
            <template #header>选择</template>
            <template #default="{ row }">
              <el-checkbox
                :checked="row.JsId == props.id"
                @change="onSelect(row.Id, $event)"
                :key="state.keys"
              />
            </template>
          </el-table-column>
          <el-table-column
            fixed
            label="#"
            width="50"
            type="index"
            :index="
              (index) =>
                (state.params.PageIndex - 1) * state.params.PageSize + index + 1
            "
            align="center"
          />
          <el-table-column
            prop="IsQy"
            sortable="IsQy"
            label="状态"
            align="center"
            width="80"
          >
            <template #default="scope">
              <text :style="{ color: scope.row.IsQy ? '#080' : '#f00' }">
                {{ scope.row.IsQy ? "启用" : "停用" }}
              </text>
            </template>
          </el-table-column>
          <el-table-column
            prop="Xm"
            sortable="Xm"
            label="姓名"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
          <el-table-column
            prop="Xb"
            label="性别"
            :formatter="$tab_StatusFormat"
          />
          <el-table-column prop="BmMc" label="所属部门" min-width="200" />
          <el-table-column prop="JsMc" label="角色" min-width="120" />
          <el-table-column prop="Zw" label="职务" min-width="120" />
          <el-table-column prop="Sjhm" label="手机号码" min-width="140" />
        </el-table>
        <c_page
          :total="state.totalCount"
          :pageIndex="state.params.PageIndex"
          @onLoad="
            (pageIndex) => {
              state.params.PageIndex = pageIndex;
              onLoad();
            }
          "
        >
        </c_page>
      </li>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { yonghu_bdjs, yonghu_list, bumen_tree } from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
      selectArr: [],
    });
    onMounted(() => {
      console.log(props);
      onLoadTree();
      onLoad();
    });

    //加载部门树
    const onLoadTree = () => {
      bumen_tree(state.params.Params).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });
    };

    //树节点点击事件
    const onTreeClick = (data) => {
      state.params.Params.BuMenId = data.Id;
      onLoad();
    };

    const onLoad = () => {
      delete state.tableData;
      yonghu_list(state.params).then((res) => {
        console.log(res);
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      state.params.PageIndex = 1;
      state.params.PageSize = 20;
      state.params.Params = {};
      onLoad();
    };

    const onSelect = (id, e) => {
      if (e) {
        yonghu_bdjs(id, props.id).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });
        });
      } else {
        yonghu_bdjs(id, -1).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });
        });
      }
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      props,
      onSelect,
      onTreeClick,
    };
  },
};
</script>
<style scoped></style>
