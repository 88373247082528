<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        style="position: relative"
      >
        <el-form-item
          label="是否显示"
          prop="IsXs"
          :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        >
          <el-switch
            v-model="state.model.IsXs"
            inline-prompt
            active-text="显示"
            inactive-text="不显示"
          />
        </el-form-item>
        <el-form-item
          label="标题"
          prop="Bt"
          :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Bt" />
        </el-form-item>
        <el-form-item label="摘要">
          <el-input
            type="textarea"
            v-model="state.model.Zy"
            :autosize="{ minRows: 4 }"
          />
        </el-form-item>
        <el-form-item label="正文">
          <c_editor :value="state.model.Zw" ref="editorDom"></c_editor>
        </el-form-item>
        <el-form-item label="附件" prop="Fjs">
          <div>
            <el-upload
              action="#"
              :show-file-list="false"
              ref="uploadDom"
              :auto-upload="false"
              :on-change="onChangeFile"
              :on-exceed="onExceedFile"
            >
              <template #default>
                <el-button type="primary" icon="UploadFilled">
                  选择文件
                </el-button>
              </template>
            </el-upload>
            <div
              v-for="(item, index) in state.model.Fjs"
              :key="item"
              style="
                display: flex;
                align-items: center;
                line-height: 18px;
                margin-top: 10px;
              "
            >
              <li style="width: 300px">
                {{ item.Mc }}
              </li>
              <el-icon
                class="gg_compile_close"
                @click="state.model.Fjs.splice(index, 1)"
                color="#a0a0a0"
                ><Close
              /></el-icon>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import {
  gonggao_detail,
  gonggao_add,
  gonggao_edit,
  fujian_add,
} from "@/http/api";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const editorDom = ref(null);
    const { proxy } = getCurrentInstance();
    const formDom = ref(null);
    const uploadDom = ref(null);
    const state = reactive({
      model: {
        IsXs: true,
        Fjs: [],
      },
      files: [],
      delFiles: [],
    });
    onMounted(() => {
      if (props.id) {
        gonggao_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          }
        });
      }
    });

    //选择文件
    const onUploadChange = (file) => {
      state.files.push(file.raw);
    };

    const onChangeFile = (file) => {
      let formData = new FormData();
      formData.append("file", file.raw);
      fujian_add(formData).then((res) => {
        if (res.status == 200) state.model.Fjs.push(res.data);
      });
    };

    const onExceedFile = (files) => {
      files.forEach((file) => {
        let formData = new FormData();
        formData.append("file", file);
        fujian_add(formData).then((res) => {
          if (res.status == 200) state.model.Fjs.push(res.data);
        });
      });
    };

    //删除图片
    const onHandleRemove = (file) => {
      uploadDom.value.handleRemove(file);
      state.files.splice(state.files.indexOf(file.raw), 1);
      if (file.url.includes("file/gonggao")) state.delFiles.push(file.url);
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (!valid) return false;

        state.model.Zw = editorDom.value?.state?.value;
        state.model.Fjids = state.model.Fjs.map((m) => m.Id);

        // let formData = new FormData();
        // //把接口需要的参数带进去
        // formData.append("IsXs", state.model.IsXs);
        // formData.append("Bt", state.model.Bt);
        // formData.append("Zy", state.model.Zy);
        // formData.append("Zw", editorDom.value?.state?.value);
        // formData.append("DelFj", state.delFiles.join(","));

        // state.files.forEach((file) => {
        //   formData.append(file.uid, file);
        // });

        if (props.id) {
          gonggao_edit(props.id, state.model).then((res) => {
            proxy.$post_response(res, emit);
          });
        } else {
          gonggao_add(state.model).then((res) => {
            proxy.$post_response(res, emit);
          });
        }
      });
    };

    return {
      state,
      formDom,
      onSubmit,
      props,
      uploadDom,
      onUploadChange,
      onHandleRemove,
      editorDom,
      onChangeFile,
      onExceedFile,
    };
  },
};
</script>
<style scoped lang="less">
.gg_compile_close {
  cursor: pointer;
  &:hover {
    transform: scale(1.3);
    color: #a2a2a2 !important;
    font-weight: 600;
  }
}
</style>
