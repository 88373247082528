<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          sortable="Bh"
          label="状态/编号"
          width="160"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />
        <el-table-column prop="Mc" sortable="Mc" label="名称/类型">
          <template #default="{ row }">
            <div style="text-align: left">
              <ul>
                {{
                  row.Mc
                }}
              </ul>
              <ul style="font-size: 12px; line-height: 12px">
                {{
                  "[" + row.Lx?.Text + "]" + row.Ts + "天" + row.Ys + "晚"
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Ftsj"
          sortable="Ftsj"
          label="发团/回团"
          :formatter="
            (row) => $tab_GroupFormat(row.Ftsj || '-', row.Htsj || '')
          "
        />
        <el-table-column
          label="计调"
          :formatter="(row) => $tab_GroupFormat(row.JdyXm, row.JdyBmMc)"
        />
        <el-table-column
          label="申请信息"
          :formatter="(row) => $tab_GroupFormat(row.TjjsrXm, row.Tjjssj)"
        />
        <el-table-column prop="Zrs" sortable="Zrs" label="人数" />
        <el-table-column
          prop="Zje"
          sortable="Zrs"
          label="金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Ysje"
          sortable="Ysje"
          label="已收"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Cgcb"
          sortable="Cgcb"
          label="采购金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Cgsjxf"
          sortable="Cgsjxf"
          label="采购已付"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          label="操作"
          width="100"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'LvYouDingDanJieSuanDetail',
                  title: '旅游订单结算审核',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              >审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_list, shangpindingdan_getzt } from "@/http/api";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          Zt: 5,
          TableName: "LvYouDingDan_Js",
        },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      shangpindingdan_getzt().then((res) => {
        if (res.status == 200) state.ztData = res.data;
      });

      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      lvyoudingdan_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
