<template>
  <div
    style="
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0, 0.5);
      padding: 40px;
      position: absolute;
      z-index: 9999000;
      top: 0;
      left: 0;
    "
    class="transition-box"
    @contextmenu.prevent.enter="close"
  >
    <div
      style="
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: 0.1px solid #1779de;
      "
      @click.stop=""
      @contextmenu.prevent.stop=""
    >
      <ul
        style="
          height: 30px;
          background-color: #1779de;
          padding: 0 10px;
          font-size: 14px;
          line-height: 30px;
          color: #fff;
          display: flex;
          align-items: center;
        "
      >
        <li style="flex: 1">
          {{ state?.title }}
        </li>
        <li class="closeIcon">
          <el-icon style="color: #fff" size="18" @click="close()"
            ><Close
          /></el-icon>
        </li>
      </ul>
      <ul style="flex: 1; overflow: hidden">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from "vue";
export default {
  props: {
    title: String,
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {
      state.title = props.title;
    });

    watch(
      () => props.title,
      (value) => {
        state.title = value;
      }
    );

    const close = () => {
      emit("close");
    };

    return { state, close };
  },
};
</script>
<style scoped>
.closeIcon {
  display: flex;
  align-items: center;
}

.closeIcon .el-icon {
  cursor: pointer;
  margin-left: 5px;
}
.closeIcon .el-icon:hover {
  transform: scale(1.3);
}
</style>
