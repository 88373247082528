<template>
  <div class="listBox">
    <el-table
      :data="state.tableData"
      v-loading="!Array.isArray(state.tableData)"
      element-loading-background="rgba(0, 0, 0, 0.2)"
      :default-sort="{
        prop: state.params.Order,
        order: state.params.Asc == false ? 'descending' : 'ascending',
      }"
      @sort-change="onSortChange"
    >
      <el-table-column
        fixed
        label="#"
        width="50"
        type="index"
        :index="
          (index) =>
            (state.params.PageIndex - 1) * state.params.PageSize + index + 1
        "
        align="center"
      />
      <el-table-column
        prop="IsQy"
        sortable="IsQy"
        label="状态/编号"
        width="160"
        :formatter="
          (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
        "
      />
      <el-table-column sortable="Mc" label="名称/类型" width="160">
        <template #default="{ row }">
          <div>
            <u>
              {{ row.Mc }}
            </u>
            <ul>
              {{
                "[" + row.LxMc + "]  " + row.Ts + "天" + row.Ys + "晚"
              }}
            </ul>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="发团/回团"
        :formatter="(row) => $tab_GroupFormat(row.Ftsj, row.Htsj)"
      />
      <el-table-column label="人数">
        <template #default="{ row }">
          <div style="line-height: 16px">
            <ul>
              {{
                row.Zrs
              }}
            </ul>
            <ul
              style="color: #a2a2a2; font-size: 12px"
              v-if="row.Crs || row.Ets"
            >
              <text v-if="row.Crs">{{ "成" + row.Crs }}</text>
              <text v-if="row.Crs && row.Ets" style="margin: 0 5px">/</text>
              <text v-if="row.Crs">{{ "童" + row.Ets }}</text>
            </ul>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="Zje"
        sortable="Zje"
        label="金额"
        width="120"
        :formatter="$tab_MoneyFormat"
        align="center"
      />
      <el-table-column
        prop="Ysje"
        sortable="Ysje"
        label="已收"
        width="120"
        :formatter="$tab_MoneyFormat"
        align="center"
      />
      <el-table-column
        prop="Qkje"
        sortable="Qkje"
        label="欠款"
        width="120"
        :formatter="$tab_MoneyFormat"
        align="center"
      />
      <el-table-column
        label="计调"
        :formatter="(row) => $tab_GroupFormat(row.JdyXm, row.JdyBm)"
      />
      <el-table-column
        label="下单人/时间"
        :formatter="
          (row) =>
            $tab_GroupFormat(
              (row.BmMc ? row.BmMc + ' - ' : '') + row.CjrXm,
              row.Cjsj
            )
        "
      />
      <el-table-column
        label="操作"
        width="100"
        align="center"
        fixed="right"
        className="tabBtn"
      >
        <template #default="{ row }">
          <el-button
            type="primary"
            :icon="Edit"
            link
            @click="
              $store.commit('openMenu', {
                path: 'LvYouDingDanDetail',
                title: '旅游订单详情',
                data: { id: row.Id },
              })
            "
            :disabled="
              !(
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes(
                  'B_LvYouDingDanDetail_管理'
                )
              )
            "
          >
            管理
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { kehu_getlydd } from "@/http/api";
import { useRouter } from "vue-router";

export default {
  props: {
    khId: Number,
  },
  components: {},
  setup(props) {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      kehu_getlydd(props.khId, state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      state.params.PageIndex = 1;
      state.params.PageSize = 20;
      state.params.Params = {};
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
    };
  },
};
</script>
<style></style>
