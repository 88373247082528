<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="状态"
            prop="IsQy"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-switch v-model="state.model.IsQy" />
          </el-form-item>
          <el-form-item
            label="姓名"
            prop="Xm"
            :rules="[
              { required: true, message: '请输入姓名', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Xm"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item label="性别" prop="Xb" style="flex: 1">
            <el-radio-group v-model="state.model.Xb" class="ml-4">
              <el-radio :label="1" size="large" style="color: #1779de"
                >男</el-radio
              >
              <el-radio :label="2" size="large" style="color: #f00"
                >女</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="手机号码"
            prop="Sjhm"
            :rules="[
              { required: true, message: '请输入手机号码', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Sjhm"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="类型"
            prop="LxId"
            :rules="[
              { required: true, message: '请选择类型', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-select
              v-model="state.model.LxId"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
            >
              <el-option
                v-for="item in state.lxData"
                :key="item"
                :label="item.Mc"
                :value="item.Id"
              />
              <template #footer>
                <el-button size="small" @click="onLoadLx()"> 刷新 </el-button>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            label="语种"
            prop="Yzs"
            :rules="[
              { required: true, message: '请选择语种', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-select
              v-model="state.model.Yzs"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              multiple
            >
              <el-option
                v-for="item in state.yzData"
                :key="item"
                :label="item.Mc"
                :value="item.Mc"
              />
              <template #footer>
                <el-button size="small" @click="onLoadYz()"> 刷新 </el-button>
              </template>
            </el-select>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="等级"
            prop="DjId"
            :rules="[
              { required: true, message: '请选择等级', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-select
              v-model="state.model.DjId"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
            >
              <el-option
                v-for="item in state.djData"
                :key="item"
                :label="item.Mc"
                :value="item.Id"
              />
              <template #footer>
                <el-button size="small" @click="onLoadDj()"> 刷新 </el-button>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            label="导游证编号"
            prop="Zjh"
            :rules="[
              { required: true, message: '请输入导游证编号', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Zjh"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="有效期"
            prop="Yxq"
            :rules="[
              { required: true, message: '请选择有效期', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-date-picker
              v-model="state.model.Yxq"
              type="date"
              placeholder="--有效期--"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="开户行" style="flex: 1">
            <el-select
              v-model="state.model.Khh"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
            >
              <el-option
                v-for="item in state.yhData"
                :key="item"
                :label="item.Mc"
                :value="item.Mc"
              />
              <template #footer>
                <el-button size="small" @click="onLoadKhh()"> 刷新 </el-button>
              </template>
            </el-select>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item label="银行账户" style="flex: 1">
            <el-input v-model="state.model.Hh"></el-input>
          </el-form-item>
          <div style="flex: 1"></div>
        </ul>
        <el-form-item label="备注" prop="Remark" style="flex: 1">
          <el-input
            type="textarea"
            v-model="state.model.Bz"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
        <ul style="display: flex; align-items: center">
          <template v-if="props.id">
            <el-form-item label="创建人">
              <el-input v-model="state.model.CjrXm" disabled></el-input>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-input v-model="state.model.Cjsj" disabled></el-input>
            </el-form-item>
          </template>
        </ul>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  daoyou_add,
  daoyou_detail,
  daoyou_edit,
  zidian_select,
} from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
      },
    });
    onMounted(() => {
      onLoadLx();
      onLoadYz();
      onLoadDj();
      onLoadYh();

      if (props.id) {
        daoyou_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            state.model.Yzs = res.model.Yz.split(",");
          }
        });
      }
    });

    //加载导游类型下拉数据
    const onLoadLx = () => {
      zidian_select("导游类型").then((res) => {
        console.log(res.data);
        if (res.status == 200) state.lxData = res.data;
      });
    };

    //加载语种下拉数据
    const onLoadYz = () => {
      zidian_select("语种").then((res) => {
        if (res.status == 200) state.yzData = res.data;
      });
    };

    //加载导游等级下拉数据
    const onLoadDj = () => {
      zidian_select("导游等级").then((res) => {
        if (res.status == 200) state.djData = res.data;
      });
    };

    //加载银行下拉数据
    const onLoadYh = () => {
      zidian_select("银行").then((res) => {
        if (res.status == 200) {
          state.yhData = res.data;
        }
      });
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          state.model.Yz = state.model.Yzs.join(",");
          if (props.id) {
            daoyou_edit(props.id, state.model).then((res) => {
              ElMessage({
                message: res.data,
                showClose: true,
                dangerouslyUseHTMLString: true,
                type: res.status ? "success" : "error",
              });
              if (res.status == 200) {
                setTimeout(() => {
                  emit("close", true);
                }, 500);
              }
            });
          } else {
            daoyou_add(state.model).then((res) => {
              ElMessage({
                message: res.data,
                showClose: true,
                dangerouslyUseHTMLString: true,
                type: res.status ? "success" : "error",
              });
              if (res.status == 200) {
                setTimeout(() => {
                  emit("close", true);
                }, 500);
              }
            });
          }
        }
      });
    };

    return {
      state,
      onSubmit,
      props,
      formDom,
      onLoadLx,
      onLoadDj,
      onLoadYz,
      onLoadYh,
    };
  },
};
</script>
<style scoped></style>
