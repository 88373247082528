l
<template>
  <div class="detailBox" style="position: relative">
    <ul class="detailContent lvddDetail">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
        inline
      >
        <ul style="display: flex">
          <el-form-item
            label="订单类型"
            prop="Lx"
            :rules="[
              { required: true, message: '请选择订单类型', trigger: 'blur' },
            ]"
            style="display: flex; align-items: center; flex: 1"
          >
            <el-radio-group v-model="state.model.Lx">
              <el-radio :label="1">国内短线</el-radio>
              <el-radio :label="2">国内长线</el-radio>
              <el-radio :label="3">出境线</el-radio>
            </el-radio-group>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="订单名称"
            prop="Mc"
            :rules="[
              { required: true, message: '请输入订单名称', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.Mc"></el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="发团日期"
            prop="Ftsj"
            :rules="[
              { required: true, message: '请选择发团日期', trigger: 'blur' },
            ]"
            style="flex: 1; margin-right: 0"
          >
            <el-date-picker
              v-model="state.model.Ftsj"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item
            label="天数"
            prop="Ts"
            :rules="[
              { required: true, message: '请输入天数', trigger: 'blur' },
            ]"
            style="display: flex; align-items: center; flex: 1"
          >
            <el-input
              v-model.number="state.model.Ts"
              style="width: 120px"
              input-style="text-align:right"
            >
              <template #append>天</template>
            </el-input>
            <el-input
              v-model="state.model.Ys"
              style="width: 120px; margin-left: 10px"
              input-style="text-align:right"
            >
              <template #append>晚</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="人数"
            prop="Zrs"
            :rules="[
              { required: true, message: '请选择人数', trigger: 'blur' },
            ]"
            style="display: flex; align-items: center; flex: 1"
          >
            <template #label>
              人数
              <el-tooltip content="人数请从名单界面调整">
                <el-icon>
                  <QuestionFilled />
                </el-icon>
              </el-tooltip>
            </template>
            <el-input v-model="state.model.Zrs" disabled>
              <template #append>人</template>
            </el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item
            label="销售"
            prop="XsyId"
            :rules="[
              { required: true, message: '请选择销售员', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input
              :model-value="
                (state.model.XsyXm || '') +
                (state.model.XsyBmMc ? '[' + state.model.XsyBmMc + ']' : '')
              "
              placeholder="--请选择销售员--"
              class="shade"
              style="margin-right: 10px"
              readonly
            >
              <template #append>
                <el-button
                  :icon="UserFilled"
                  style="width: 60px"
                  @click="onSelectXs"
              /></template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="计调"
            prop="JdyId"
            :rules="[
              { required: true, message: '请选择计调员', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input
              :model-value="
                (state.model.JdyXm || '') +
                (state.model.JdyBmMc ? '[' + state.model.JdyBmMc + ']' : '')
              "
              class="shade"
              readonly
              placeholder="--请选择计调员--"
            >
              <template #append>
                <el-button
                  :icon="UserFilled"
                  @click="onSelectJd"
                  style="width: 60px"
              /></template>
            </el-input>
          </el-form-item>
        </ul>
        <ul style="display: flex">
          <el-form-item label="备注" style="flex: 1">
            <el-input
              type="textarea"
              v-model="state.model.Bz"
              :autosize="{ minRows: 2 }"
            />
          </el-form-item>
        </ul>
        <ul
          style="
            font-weight: 600;
            font-size: 14px;
            padding-left: 10px;
            display: flex;
            align-items: flex-end;
            color: rgb(60, 60, 60);
          "
        >
          客户信息
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="客户"
            style="flex: 1"
            prop="KhId"
            :rules="[
              { required: true, message: '请选择客户', trigger: 'blur' },
            ]"
          >
            <div style="width: 100%; display: flex; align-items: center">
              <el-select
                v-model="state.model.KhId"
                placeholder="请输入客户名称或编号进行筛选"
                filterable
                remote
                reserve-keyword
                :remote-method="onKhSelect"
                @change="onKhChange"
                value-key="Id"
                style="flex: 1; margin-right: 10px"
              >
                <el-option
                  v-for="item in state.khData"
                  :key="item"
                  :value="item.Id"
                  :label="'[' + item.Bh + ']' + item.Mc"
                >
                </el-option>
              </el-select>
              <el-button
                icon="Plus"
                type="primary"
                @click="state.showKeHu = true"
                >新增</el-button
              >
            </div>
          </el-form-item>
        </ul>
        <ul style="display: flex; align-items: center">
          <el-form-item
            label="订单联系人"
            style="flex: 1"
            prop="LxrXm"
            :rules="[
              { required: true, message: '请输入订单联系人', trigger: 'blur' },
            ]"
          >
            <el-input v-model="state.model.LxrXm" />
          </el-form-item>
          <el-form-item
            label="联系电话"
            style="flex: 1"
            prop="LxrDh"
            :rules="[
              { required: true, message: '请输入联系电话', trigger: 'blur' },
            ]"
          >
            <el-input v-model="state.model.LxrDh" />
          </el-form-item>
        </ul>
        <ul
          style="
            font-weight: 600;
            font-size: 14px;
            padding-left: 10px;
            display: flex;
            align-items: flex-end;
            color: rgb(60, 60, 60);
          "
        >
          订单金额
        </ul>
        <ul
          style="
            display: flex;
            align-items: center;
            text-align: center;
            padding: 10px 0;
            border-bottom: 2px solid #e2e2e2;
            font-weight: 600;
          "
        >
          <li style="flex: 1">价格类型</li>
          <li style="flex: 3">价格名称</li>
          <li style="flex: 1">单价</li>
          <li style="flex: 1.5">数量</li>
          <li style="flex: 0.5">订单金额</li>
          <li style="width: 80px">操作</li>
        </ul>
        <ul
          v-for="(item, index) in state.mxs"
          :key="item"
          style="display: flex; align-items: center; margin-top: 10px"
        >
          <li style="flex: 1">
            <el-select
              v-model="item.Lx"
              value-key="Mc"
              :clearable="false"
              @change="onLxChange($event, index)"
            >
              <el-option
                v-for="item in lxData"
                :key="item.Lx"
                :label="item.Lx"
                :value="item.Lx"
              />
            </el-select>
          </li>
          <li style="flex: 3; margin: 0 10px">
            <el-input
              v-model="item.Mc"
              :placeholder="item.Mmc"
              :value="item.Mc"
            ></el-input>
          </li>
          <li style="flex: 1">
            <c_inputnumber
              :value="item.Dj"
              :suffix="
                '元' +
                (item.Mc ? '/' : '') +
                (item.Dw1 || '') +
                (item.Dw2 || '')
              "
              @Value-Change="
                (val) => {
                  item.Dj = val;
                  onMxChange();
                }
              "
            />

            <!-- <el-input
              v-model.number="item.Dj"
              placeholder="销售价"
              type="number"
              input-style="text-align:right"
              @change="onMxChange"
            >
              <template #append> {{}} </template>
            </el-input> -->
          </li>
          <li
            style="
              flex: 1.5;
              margin: 0 10px;
              display: flex;
              align-items: center;
            "
            v-if="item.Dw2"
          >
            <el-input
              v-model.number="item.Sl1"
              :placeholder="item.Dw1"
              type="number"
              input-style="text-align:right"
            >
              <template #append>{{ item.Dw1 }}</template></el-input
            >
            <el-input
              v-model.number="item.Sl2"
              :placeholder="item.Dw2"
              type="number"
              input-style="text-align:right"
              @change="onMxChange"
            >
              <template #append>{{ item.Dw2 }}</template></el-input
            >
          </li>
          <li v-else style="flex: 1.5; margin: 0 10px">
            <el-input
              v-model.number="item.Sl1"
              :placeholder="item.Dw1"
              type="number"
              input-style="text-align:right"
              @change="onMxChange"
            >
              <template #append>{{ item.Dw1 }}</template></el-input
            >
          </li>
          <li style="flex: 0.5; text-align: center">
            <c_money
              :value="item.Zj"
              style="text-align: center; margin: 0 auto; font-weight: 600"
            ></c_money>
          </li>
          <li
            v-if="index == 0"
            style="width: 80px; text-align: center; cursor: pointer"
            @click="
              state.mxs.push({
                Lx: '线路',
                Mc: '成人价',
                Dw1: '人',
              })
            "
          >
            <el-icon size="20"><Plus /></el-icon>
          </li>
          <li
            v-else
            style="width: 80px; text-align: center; cursor: pointer"
            @click="
              state.mxs.splice(index, 1);
              onMxChange();
            "
          >
            <el-icon size="20" color="#f00"><Delete /></el-icon>
          </li>
        </ul>
        <ul
          style="
            margin-top: 10px;
            border-top: 1px solid #e2e2e2;
            display: flex;
            align-items: center;
            padding-top: 10px;
          "
        >
          <li style="flex: 1; text-align: center; font-weight: 600">合计</li>
          <li style="flex: 5.5; margin-right: 40px"></li>
          <li style="flex: 0.5; text-align: center">
            <c_money
              :value="state.model.Zje || 0"
              style="text-align: center; margin: 0 auto; font-weight: 600"
            ></c_money>
          </li>
          <li style="width: 80px"></li>
        </ul>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showYongHu"
        :title="state.title"
        @close="state.showYongHu = false"
      >
        <c_LvYouDingDanSelectUser
          @close="onCloseYongHu"
          :id="state.selectId"
        ></c_LvYouDingDanSelectUser>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showKeHu"
        title="添加客户"
        @close="state.showKeHu = false"
      >
        <c_LvYouDingDanAddKh
          @close="
            state.showCompile = false;
            loadKh();
          "
          :id="state.selectId"
        ></c_LvYouDingDanAddKh>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_detail,
  lvyoudingdan_edit,
  zidian_select,
  kehu_select,
} from "@/http/api";
import { ElMessage } from "element-plus";
import { numberIsNull2 } from "@/util/tFormat";

export default {
  props: {
    id: Number,
  },

  setup(props, { emit }) {
    const formDom = ref(null);

    let lxData = [
      { Lx: "线路", Mc: "成人价", Dw1: "人" },
      { Lx: "酒店", Mc: "标准间", Dw1: "间", Dw2: "夜" },
      { Lx: "机票", Mc: "经济舱", Dw1: "张" },
      { Lx: "火车票", Mc: "二等座", Dw1: "张" },
      { Lx: "门票", Mc: "成人票", Dw1: "张" },
      { Lx: "门票套餐", Mc: "套票", Dw1: "套" },
      { Lx: "签证", Mc: "旅游签证", Dw1: "人", Dw2: "夜" },
      { Lx: "租车", Mc: "旅游大巴", Dw1: "辆", Dw2: "天" },
      { Lx: "导游服务", Mc: "金牌导游", Dw1: "人", Dw2: "天" },
      { Lx: "接送机", Mc: "中型车", Dw1: "次" },
      { Lx: "单送关", Mc: "普通送关", Dw1: "次" },
      { Lx: "保险", Mc: "旅游意外险", Dw1: "份" },
      { Lx: "优惠", Mc: "会员优惠", Dw1: "份" },
      { Lx: "其他", Mc: "服务", Dw1: "件" },
    ];
    const state = reactive({
      model: {},
      item: {},
      mxs: [],
    });
    onMounted(() => {
      lvyoudingdan_detail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;

          loadLx();
          loadKh();
        }
      });
    });

    //加载类型下拉数据
    const loadLx = () => {
      zidian_select("旅游订单类型").then((res) => {
        if (res.status == 200) {
          state.lxData = res.data;
        }
      });
    };

    //客户数据
    let khSource = [];

    //加载客户
    const loadKh = () => {
      kehu_select().then((res) => {
        if (res.status == 200) {
          khSource = res.data;
          state.khData = khSource.filter((w) => w.Id == state.model.KhId);
        }
      });
    };

    //选择销售员
    const onSelectXs = () => {
      state.title = "选择销售员";
      state.selectId = state.model.XsyId;
      state.showYongHu = true;
    };

    //选择计调员
    const onSelectJd = () => {
      state.title = "选择计调员";
      state.selectId = state.model.JdyId;
      state.showYongHu = true;
    };

    //销售/计调选择完成事件
    const onCloseYongHu = (e) => {
      if (state.title == "选择销售员") {
        state.model.XsyId = e.Id;
        state.model.XsyXm = e.Xm;
        state.model.XsyBmMc = e.BmMc;
      } else if (state.title == "选择计调员") {
        state.model.JdyId = e.Id;
        state.model.JdyXm = e.Xm;
        state.model.JdyBmMc = e.BmMc;
      }
      state.showYongHu = false;
    };

    const onMxChange = () => {
      state.model.Zje = 0;
      for (let i = 0; i < state.mxs.length; i++) {
        if (!numberIsNull2(state.mxs[i].Dj) && !numberIsNull2(state.mxs[i].Sl1))
          state.mxs[i].Zj =
            state.mxs[i].Dj * state.mxs[i].Sl1 * (state.mxs[i].Sl2 || 1);
        state.model.Zje += state.mxs[i].Zj || 0;
      }
    };

    const onKhSelect = (query) => {
      if (query) {
        setTimeout(() => {
          state.khData = khSource.filter((item) => {
            return (
              item.Mc.toLowerCase().includes(query.toLowerCase()) ||
              item.Bh.toLowerCase().includes(query.toLowerCase())
            );
          });
        }, 200);
      } else {
        state.khData = [];
      }
    };

    const onKhChange = (e) => {
      const kh = state.khData.find((w) => w.Id == e);
      state.model.KhId = kh.Id;
      state.model.LxrXm = kh.Lxr;
      state.model.LxrDh = kh.Sjhm;
    };

    const onLxChange = (item, index) => {
      const lx = lxData.find((w) => w.Lx == item);
      state.mxs[index].Mc = lx.Mc;
      state.mxs[index].Dw1 = lx.Dw1;
      state.mxs[index].Dw2 = lx.Dw2 || "";
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          for (let i = 0; i < state.mxs.length; i++) {
            if (!state.mxs[i].Mc) {
              ElMessage.warning(`第${i + 1}项价格项名称不能为空！`);
              return false;
            }
            if (!state.mxs[i].Dj) {
              ElMessage.warning(`第${i + 1}项价格项单价不能为空！`);
              return false;
            }
            if (!state.mxs[i].Sl1) {
              ElMessage.warning(`第${i + 1}项价格项数量不能为空！`);
              return false;
            }
            if (state.mxs[i].Dw2) {
              if (!state.mxs[i].Sl2) {
                ElMessage.warning(`第${i + 1}项价格项数量不能为空！`);
                return false;
              }
            }
          }
          state.model.Mxs = state.mxs;
          lvyoudingdan_edit(props.id, state.model).then((res) => {
            if (res.status == 200) {
              ElMessage.success(res.data);
              emit("Close");
            }
          });
        }
      });
    };

    return {
      state,
      onSubmit,
      props,
      formDom,
      onSelectXs,
      onSelectJd,
      onCloseYongHu,
      onKhSelect,
      onKhChange,
      lxData,
      onLxChange,
      loadKh,
      onMxChange,
    };
  },
};
</script>
<style lang="less">
.lvddDetail {
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 10px !important;
  }
}
</style>
