<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <el-form-item
          label="账户名称"
          prop="Mc"
          :rules="[
            { required: true, message: '请输入账户名称', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Mc"></el-input>
        </el-form-item>
        <el-form-item label="户号">
          <el-input v-model="state.model.Hh"></el-input>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            v-model="state.model.Sm"
            type="textarea"
            :autosize="{ minRows: 5 }"
          />
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { zhanghu_add, zhanghu_detail, zhanghu_edit } from "@/http/api";
import { post_response } from "@/util/tFormat";
export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);

    const state = reactive({
      model: {
        IsQy: true,
      },
    });
    onMounted(() => {
      if (props.id) {
        zhanghu_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          }
        });
      }
    });

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            zhanghu_edit(props.id, state.model).then((res) => {
              post_response(res, emit);
            });
          } else {
            zhanghu_add(state.model).then((res) => {
              post_response(res, emit);
            });
          }
        }
      });
    };

    return {
      state,
      onSubmit,
      props,
      formDom,
    };
  },
};
</script>
<style scoped></style>
