<template>
  <div class="detailBox">
    <el-form
      :model="state.model"
      label-width="100px"
      style="width: 40%; margin-top: 10px"
      :scroll-to-error="true"
      ref="domForm"
      :rules="rules"
    >
      <el-form-item
        label="原密码"
        prop="OldPassword"
        :rules="[{ required: true, message: '请输入原密码', trigger: 'blur' }]"
      >
        <el-input type="password" v-model="state.model.OldPassword" />
      </el-form-item>
      <el-form-item
        label="新密码"
        prop="NewPassword"
        :rules="[{ required: true, message: '请输入新密码', trigger: 'blur' }]"
      >
        <el-input type="password" v-model="state.model.NewPassword" />
      </el-form-item>
      <el-form-item
        label="确认密码"
        prop="ConfirmPassword"
        :rules="[
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
        ]"
      >
        <el-input type="password" v-model="state.model.ConfirmPassword" />
      </el-form-item>
      <el-form-item>
        <el-button style="width: 80px" type="primary" @click="onSubmit()">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { home_editpass } from "@/http/api";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const domForm = ref(null);
    const state = reactive({
      model: {},
      roleList: [],
    });
    onMounted(() => {});

    const validatePassword = (rule, value, callback) => {
      if (value !== state.model.NewPassword) {
        callback(new Error("两次密码不一致"));
      }

      return callback();
    };

    //自定义校验必须放在校验方法后面
    const rules = {
      OldPassword: [
        { required: true, message: "请输入原密码", trigger: "blur" },
      ],
      NewPassword: [
        { required: true, message: "请输入新密码", trigger: "blur" },
      ],
      ConfirmPassword: [
        { required: true, message: "请再次输入新密码", trigger: "blur" },
        { validator: validatePassword, trigger: "blur" },
      ],
    };

    const onSubmit = () => {
      domForm.value.validate((valid) => {
        if (!valid) return false;

        home_editpass(state.model).then((res) => {
          if (res.status == 200) {
            ElMessageBox.alert("修改成功,请重新登录", "提示", {
              type: "success",
              confirmButtonText: "确定",
              callback: () => {
                localStorage.removeItem("token");
                router.go(0);
              },
            });
          } else {
            ElMessage({
              message: res.data,
              type: "error",
            });
          }
        });
      });
    };
    return { state, onSubmit, domForm, rules };
  },
};
</script>
<style scoped></style>
