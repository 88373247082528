<template>
  <div class="el-input el-input-group el-input-group--append">
    <!-- input --><!-- prepend slot --><!--v-if-->
    <el-input-number
      v-model="state.value"
      :min="props.min"
      :precision="2"
      :controls="false"
      class="com_number_box"
      :class="props.align == 'left' ? 'numberLeft' : ''"
      @change="onChange()"
    />
    <!-- append slot -->
    <div class="el-input-group__append">{{ props.suffix }}</div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    suffix: {
      type: String,
      default: "元",
    },
    align: {
      type: String,
      default: "left",
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    console.log(111);
    console.log(props);
    const state = reactive({});
    onMounted(() => {
      state.value = props.value;
    });

    const onChange = () => {
      emit("Value-Change", state.value);
    };
    return { state, onChange, props };
  },
};
</script>
<style lang="less">
.numberLeft {
  & .el-input__inner {
    text-align: left !important;
  }
}

.com_number_box {
  .el-input__wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
</style>
