<template>
  <div style="overflow-y: auto; padding: 0 10px 10px 10px; height: 100%">
    <div
      v-for="item in state.listData"
      :key="item"
      style="
        margin-top: 10px;
        box-shadow: 0 0 10px 1px #e2e2e2;
        padding: 10px;
        border-radius: 7px;
      "
    >
      <ul style="display: flex; align-items: center">
        <li style="flex: 1">
          <text style="font: 600 13px/13px ''; margin-right: 10px">{{
            item.Bgsj?.split(" ")[0]
          }}</text>
          <text>{{ item.Bgsj?.split(" ")[1] }}</text>
        </li>
        <li
          style="
            flex: 2;
            display: flex;
            align-items: end;
            color: #f00;
            font-size: 11px;
            justify-content: center;
          "
        >
          <text style="color: #a2a2a2">原金额：</text>
          <c_money :value="item.Yj"></c_money>
          <text style="color: #a2a2a2; margin-left: 20px">变动金额：</text>
          <c_money :value="item.Bgj"></c_money>
          <text style="color: #a2a2a2; margin-left: 20px">变更后金额：</text>
          <c_money :value="item.Xj"></c_money>
        </li>
        <li style="flex: 1; justify-content: end; display: flex">
          {{ (item.Shsj || "") + "   " + (item.ShrXm || "") }}
          <text v-if="item.Shzt == true" style="color: #080">已通过</text>
          <text v-else-if="item.Shzt == false" style="color: #f00">未通过</text>
          <text v-else style="color: #1779de">待审核</text>
        </li>
      </ul>
      <ul style="margin: 10px 0">
        变更备注：{{
          item.Bz
        }}
      </ul>
      <ul style="display: flex; overflow: hidden">
        <li style="white-space: nowrap">变更明细：</li>
        <div style="overflow: hidden; flex: 1">
          <el-table :data="item.SpMxs" size="small">
            <el-table-column prop="Syrq" label="使用日期"></el-table-column>
            <el-table-column prop="SpMc" label="商品名称"></el-table-column>
            <el-table-column
              prop="SpPlMc"
              label="商品品类名称"
            ></el-table-column>
            <el-table-column
              prop="SpTcMc"
              label="商品套餐名称"
            ></el-table-column>
            <el-table-column prop="Xsdj" label="单价"></el-table-column>
            <el-table-column prop="Ysl" label="原数量"></el-table-column>
            <el-table-column prop="Yj" label="变更前总额"></el-table-column>
            <el-table-column prop="Bgsl" label="变更数量">
              <template #default="{ row }">
                <text
                  v-if="row.Bgsl < 0"
                  style="color: #f00; font: 600 16px/16px ''; margin-left: 4px"
                >
                  {{ row.Bgsl }}</text
                >
                <text
                  v-else-if="row.Bgsl > 0"
                  style="color: #080; font: 600 16px/16px ''"
                  >+{{ row.Bgsl }}</text
                >
                <text v-else style="font: 600 16px/16px ''">{{
                  row.Bgsl
                }}</text>
              </template>
            </el-table-column>
            <el-table-column prop="Bgj" label="变更金额">
              <template #default="{ row }">
                <text
                  v-if="row.Bgj < 0"
                  style="color: #f00; font: 600 16px/16px ''; margin-left: 4px"
                >
                  {{ row.Bgj }}</text
                >
                <text
                  v-else-if="row.Bgj > 0"
                  style="color: #080; font: 600 16px/16px ''"
                  >+{{ row.Bgj }}</text
                >
                <text v-else style="font: 600 16px/16px ''">{{ row.Bgj }}</text>
              </template>
            </el-table-column>
            <el-table-column prop="Xj" label="变更后总额">
              <template #default="{ row }">
                <text style="color: #1779de; font: 600 16px/16px ''">
                  {{ row.Xj }}
                </text>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpindingdan_getbgjl } from "@/http/api";

export default {
  props: {
    id: Number,
  },
  components: {},
  setup(props) {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      shangpindingdan_getbgjl(props.id).then((res) => {
        if (res.status == 200) {
          state.listData = res.data;
        }
      });
    };

    return {
      state,
    };
  },
};
</script>
<style lang="sass" scoped></style>
