<template>
  <div class="detailBox">
    <div class="detailContent numberForm">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
      >
        <ul class="htTitle">
          合同主体
        </ul>
        <el-form-item label="合同主体">
          <!-- <el-select v-model="state.model.GysId">
            <el-option
              v-for="item in state.gysData"
              :key="item"
              :value="item.Id"
              :label="item.Mc"
            ></el-option>
            <template #footer>
              <el-button size="small" @click="onLoadGys()"> 刷新 </el-button>
            </template>
          </el-select> -->
          肥西县灵西文化科技有限公司
        </el-form-item>
        <div style="display: flex; align-items: center">
          <el-form-item
            label="经办人"
            prop="JbrXm"
            :rules="[
              { required: true, message: '请输入经办人', trigger: 'blur' },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.JbrXm"></el-input>
          </el-form-item>
          <el-form-item
            label="经办人电话"
            prop="JbrDh"
            :rules="[
              {
                required: true,
                message: '请输入经办人电话',
                trigger: 'blur',
              },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.JbrDh"></el-input>
          </el-form-item>
        </div>
        <ul class="htTitle">
          线路信息
        </ul>
        <el-form-item label="线路名称" style="flex: 1">
          <el-input v-model="state.model.Xlmc"></el-input>
        </el-form-item>
        <div style="display: flex; align-items: center">
          <el-form-item label="出发日期" style="flex: 1">
            <el-date-picker
              v-model="state.model.Cfrq"
              type="date"
              placeholder="--出发日期--"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item label="结束日期" style="flex: 1">
            <el-date-picker
              v-model="state.model.Jsrq"
              type="date"
              placeholder="--结束日期--"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </div>
        <ul class="htTitle">
          旅游费用及支付
        </ul>
        <div style="display: flex; align-items: center">
          <el-form-item label="成人费用" style="flex: 1">
            <el-input-number
              v-model="state.model.Crfy"
              :min="0"
              :precision="2"
              :controls="false"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="儿童费用" style="flex: 1">
            <el-input-number
              v-model="state.model.Etfy"
              :min="0"
              :precision="2"
              :controls="false"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="导服费" style="flex: 1">
            <el-input-number
              v-model="state.model.Dffy"
              :min="0"
              :precision="2"
              :controls="false"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="单房差" style="flex: 1">
            <el-input-number
              v-model="state.model.Dfc"
              :min="0"
              :precision="2"
              :controls="false"
              style="width: 100%"
            />
          </el-form-item>
        </div>
        <div style="display: flex; align-items: center">
          <el-form-item label="合计" style="flex: 1">
            <el-input-number
              v-model="state.model.Hjfy"
              :min="0"
              :precision="2"
              :controls="false"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item label="支付时间" style="flex: 1">
            <el-date-picker
              v-model="state.model.Zfsj"
              type="date"
              placeholder="--支付时间--"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item label="支付方式" style="flex: 1">
            <el-select v-model="state.model.Zffs">
              <el-option
                v-for="item in state.zffsData"
                :key="item"
                :label="item.Text"
                :value="item.Key"
              />
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="费用支付说明" prop="Fybhx" style="flex: 1">
          <el-input v-model="state.model.Fybhx" type="textarea"></el-input>
        </el-form-item>
        <ul class="htTitle">
          联系人/游客信息
        </ul>
        <div style="display: flex; align-items: center">
          <el-form-item
            label="主签署人"
            prop="LxrXm"
            :rules="[
              {
                required: state.model.IsQsr,
                message: '请选择主签署人',
                trigger: 'blur',
              },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.LxrXm"></el-input>
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="LxrDh"
            :rules="[
              {
                required: state.model.IsQsr,
                message: '请选择手机号',
                trigger: 'blur',
              },
            ]"
            style="flex: 1"
          >
            <el-input v-model="state.model.LxrDh"></el-input>
          </el-form-item>
          <el-form-item
            label="证件号"
            prop="LxrZjlx"
            :rules="[
              {
                required: state.model.IsQsr,
                message: '请选择证件号',
                trigger: 'blur',
              },
            ]"
            style="flex: 2; display: flex; align-items: center"
          >
            <div style="display: flex; align-items: center">
              <el-select
                v-model="state.model.LxrZjlx"
                style="width: 200px"
                :clearable="false"
              >
                <el-option
                  v-for="item in state.zjlxData"
                  :key="item"
                  :label="item.Text"
                  :value="item.Key"
                />
              </el-select>
              <el-input
                v-model="state.model.LxrZjh"
                placeholder="--证件号码--"
                style="margin: 0 10px"
              ></el-input>
              <el-checkbox
                v-model="state.model.IsQsr"
                label="第三方签署人"
                size="large"
                @change="
                  delete state.model.QsrId;
                  state.key = Math.random();
                "
                :key="state.key"
              />
              <!-- <el-checkbox
                v-model="state.model.IsXxhy"
                label="是否已线下核验"
                size="large"
              /> -->
            </div>
          </el-form-item>
        </div>
        <el-table :data="state.ykData" size="small">
          <el-table-column type="index" label="#"> </el-table-column>
          <el-table-column prop="Xm" label="游客姓名"> </el-table-column>
          <el-table-column
            prop="Sjhm"
            label="手机号码"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Zjlx"
            label="证件类型"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Zjh"
            label="证件号"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Xb"
            label="性别"
            :formatter="$tab_StatusFormat"
          />
          <el-table-column
            prop="Csrq"
            label="出生日期"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Bz"
            label="备注"
            :formatter="$tab_TextFormat"
          />
          <el-table-column label="签署人" align="center" width="100">
            <template #default="{ row }">
              <el-checkbox
                :checked="state.model.QsrId == row.Id"
                size="large"
                @change="onQsrChange($event, row.Id)"
                :key="state.key"
                :disabled="state.model.IsQsr"
              />
            </template>
          </el-table-column>
        </el-table>
        <ul class="htTitle" style="margin-top: 10px">
          保险/成团人数与不成团约定/拼团约定/争议的解决方式
        </ul>
        <div>
          <ul style="display: flex; align-items: center">
            <el-form-item label="旅行者" prop="Bxgmfs" style="flex: 1">
              <el-select v-model="state.model.Bxgmfs" :clearable="false">
                <el-option
                  v-for="item in state.bxgmfsData"
                  :key="item"
                  :label="item.Text"
                  :value="item.Key"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="保险产品"
              prop="Bx"
              :rules="[
                {
                  required: state.model.Bxgmfs == 1,
                  message: '请输入保险产品',
                  trigger: 'blur',
                },
              ]"
              style="flex: 1"
            >
              <el-input v-model="state.model.Bx"></el-input>
            </el-form-item>
          </ul>
        </div>
        <div
          style="
            border-top: 1px dashed #e2e2e2;
            border-bottom: 1px dashed #e2e2e2;
            padding: 10px 0;
            color: #a2a2a2;
            line-height: 40px;
          "
        >
          <ul style="background-color: #f6f6f6; padding: 0 20px">
            最低成团人数：<el-input
              v-model="state.model.Zdrs"
              style="width: 50px; margin-right: 10px"
              size="small"
            />如不能成团，旅行者是否同意按下列第<el-select
              v-model="state.model.Jjfs"
              style="width: 50px; margin: 0 10px"
              size="small"
              :clearable="false"
            >
              <el-option
                v-for="item in 4"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
            方式解决：
          </ul>
          <div style="padding-left: 40px">
            <ul>
              1、旅行社委托具备境内游合法旅游资质的第三方<el-input
                v-model="state.model.LxsMc"
                style="width: 100px; margin: 0 10px"
                size="small"
                :disabled="state.model.Jjfs != 1"
              />旅行社履行合同；
            </ul>
            <ul>
              2、延期出团
            </ul>
            <ul>
              3、改签其他线路出团
            </ul>
            <ul>
              4、解除合同
            </ul>
          </div>
        </div>
        <div style="color: #a2a2a2; line-height: 40px">
          <ul
            style="
              display: flex;
              align-items: center;
              padding-left: 20px;
              border-bottom: 1px dashed #e2e2e2;
              margin-bottom: 10px;
            "
          >
            旅行者
            <el-select
              v-model="state.model.IsPt"
              style="width: 100px; margin: 0 10px"
              size="small"
            >
              <el-option label="同意" :value="true" />
              <el-option label="不同意" :value="false" />
            </el-select>
            采用拼团的方式拼至<el-input
              v-model="state.model.Ptlxs"
              style="width: 100px; margin: 0 10px"
              size="small"
              :disabled="!state.model.IsPt"
            />旅行社成团
          </ul>
          <ul style="background-color: #f6f6f6; padding: 0 20px">
            发生争议不能解决的，按下列第<el-select
              v-model="state.model.Ptjjfs"
              style="width: 50px; margin: 0 10px"
              size="small"
            >
              <el-option
                v-for="item in 2"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
            方式解决：
          </ul>
          <ul style="padding-left: 40px">
            1、提交<el-input
              v-model="state.model.ZcwyhMc"
              style="width: 100px; margin: 0 10px"
              size="small"
            />仲裁委员会仲裁
          </ul>
          <ul style="padding-left: 40px">
            2、依法向<el-input
              v-model="state.model.FyMc"
              style="width: 100px; margin: 0 10px"
              size="small"
              :disabled="state.model.Ptjjfs == 1"
            />人民法院起诉
          </ul>
        </div>
        <ul class="htTitle">
          联系人/游客信息
        </ul>
        <ul style="color: #a2a2a2; padding-left: 10px; padding-bottom: 10px">
          未尽事宜，经旅游者与旅行社双方协商一致，可以列入补充条款
        </ul>
        <ul>
          <el-input
            type="textarea"
            v-model="state.model.Bctk"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </ul>
        <ul class="htTitle" style="margin-top: 10px">
          附件上传
        </ul>
        <ul style="display: flex; align-items: center; margin-bottom: 10px">
          <el-upload
            v-model:file-list="state.files"
            multiple
            :auto-upload="false"
            :on-change="onFileChange"
            :show-file-list="false"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
          <text style="color: #a2a2a2; margin-left: 20px">
            (提示：文件大小不得超过4096KB)
          </text>
        </ul>
        <el-table :data="state.model.Fjs" size="small">
          <el-table-column type="index" label="#" />
          <el-table-column prop="Mc" label="名称" />
          <el-table-column
            prop="Dz"
            label="路径"
            :formatter="$tab_TextFormat"
          />
          <el-table-column label="操作" fixed="right">
            <template #default="{ row }">
              <el-button
                type="success"
                :icon="Download"
                link
                @click="onFileDownLoad(row)"
                v-if="row.Id"
              >
                下载
              </el-button>
              <el-button
                type="danger"
                :icon="Delete"
                link
                @click="onFileDelete(row.Id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, watch, ref } from "vue";
import {
  lvyoudingdan_ht_getbxgmfs,
  lvyoudingdan_ht_getzffs,
  lvyoudingdan_ht_getjjfs,
  lvyoudingdan_ht_getzjlx,
  gongyingshang_select,
  lvyoudingdan_ht_add,
  lvyoudingdan_ht_edit,
  lvyoudingdan_getyk,
  lvyoudingdan_ht_getdetail,
  fujian_add,
  fujian_delete,
} from "@/http/api";
import { numberIsNull2, post_response } from "@/util/tFormat";
import { ElMessage } from "element-plus";
import $http from "@/http/index";
import store from "@/store";
export default {
  props: {
    ddId: Number,
    model: Object,
    id: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {
        Bxgmfs: 1,
        Zdrs: 0,
        Jjfs: 4,
        IsPt: false,
        Ptjjfs: 1,
        IsXshy: true,
        Fjs: [],
      },
    });
    onMounted(() => {
      const user = store.state?.loginUser;
      if (user) {
        state.model.JbrXm = user.Xm;
        state.model.JbrDh = user.Sjhm;
      }

      state.model.Cfrq = props.model?.Ftsj;
      state.model.Jsrq = props.model?.Htsj;

      onLoadGys();
      onLoadYk();

      lvyoudingdan_ht_getbxgmfs().then((res) => {
        if (res.status == 200) state.bxgmfsData = res.data;
      });

      lvyoudingdan_ht_getzffs().then((res) => {
        if (res.status == 200) state.zffsData = res.data;
      });

      lvyoudingdan_ht_getjjfs().then((res) => {
        if (res.status == 200) state.jjfsData = res.data;
      });

      lvyoudingdan_ht_getzjlx().then((res) => {
        if (res.status == 200) state.zjlxData = res.data;
      });

      if (props.id) {
        lvyoudingdan_ht_getdetail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            state.key = Math.random();
          }
        });
      }

      state.model.Xlmc = props.model.Mc;
    });

    const onLoadGys = () => {
      gongyingshang_select().then((res) => {
        if (res.status == 200) state.gysData = res.data;
      });
    };

    const onLoadYk = () => {
      lvyoudingdan_getyk(props.ddId).then((res) => {
        if (res.status == 200) state.ykData = res.data;
      });
    };

    watch(
      [
        () => state.model.Crfy,
        () => state.model.Etfy,
        () => state.model.Dffy,
        () => state.model.Dfc,
      ],
      ([newCrfy, newEtfy, newDffy, newDfc]) => {
        if (
          !numberIsNull2(newCrfy) &&
          !numberIsNull2(newEtfy) &&
          !numberIsNull2(newDffy) &&
          !numberIsNull2(newDfc)
        )
          state.model.Hjfy = newCrfy + newEtfy + newDffy + newDfc;
        else delete state.model.Hjfy;
      }
    );

    //选择文件
    const onFileChange = (file) => {
      let formData = new FormData();
      formData.append("file", file.raw);
      fujian_add(formData).then((res) => {
        if (res.status == 200) state.model.Fjs.push(res.data);
      });
    };

    //下载文件
    const onFileDownLoad = (item) => {
      $http
        .get(item.Dz, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.Mc);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          ElMessage.error("文件下载失败！");
        });
    };

    //删除文件
    const onFileDelete = (id, index) => {
      fujian_delete(id).then((res) => {
        if (res.status == 200) state.model.Fjs.splice(index, 1);
      });
    };

    //选择签署人
    const onQsrChange = (e, id) => {
      if (e) {
        state.model.QsrId = id;
        state.key = Math.random();
      } else delete state.model.QsrId;
      state.key = Math.random();

      // if (!Array.isArray(state.model.QsrIds)) state.model.QsrIds = [];
      // if (e) {
      //   if (!state.model.QsrIds.includes(id)) state.model.QsrIds.push(id);
      // } else {
      //   const index = state.model.QsrIds.indexOf(id);
      //   if (index > -1) state.model.QsrIds.splice(index, 1);
      // }
    };

    //提交
    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            lvyoudingdan_ht_edit(props.id, state.model).then((res) => {
              post_response(res, emit);
            });
          } else {
            lvyoudingdan_ht_add(props.ddId, state.model).then((res) => {
              post_response(res, emit);
            });
          }
        }
      });
    };

    return {
      state,
      onFileChange,
      onSubmit,
      onLoadGys,
      onQsrChange,
      onFileDelete,
      onFileDownLoad,
      formDom,
    };
  },
};
</script>
<style scoped lang="less">
.htTitle {
  background-color: #f6f6f6;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
</style>
