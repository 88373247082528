<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #dcdfe6; width: 100%; border-radius: 4px"
      :editor="editorRef"
      mode="default"
    />
    <Editor
      style="height: 300px; overflow-y: hidden"
      v-model="state.value"
      @onCreated="handleCreated"
      :default-config="editorConfig"
      :key="state.key"
    />
  </div>
</template>

<script>
import { onMounted, reactive, shallowRef, ref } from "vue";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import http from "@/http";

export default {
  props: {
    value: {
      type: String,
    },
  },
  components: {
    Editor,
    Toolbar,
  },
  setup(props) {
    const state = reactive({});
    const editorRef = shallowRef();
    const handleCreated = (editor) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };

    onMounted(() => {
      setTimeout(() => {
        state.value = props.value;
      }, 1500);
    });

    const editorConfig = ref({
      placeholder: "请输入内容...",
      readOnly: false, // 只读
      MENU_CONF: {
        // 配置上传图片
        uploadImage: {
          server: http.defaults.baseURL + "/Home/UploadImages", // 配置图片上传地址
          maxFileSize: 2 * 1024 * 1024, // 10M  图片大小限制
          fieldName: "multipartFile", // 上传名字
          allowedFileTypes: [], // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
          // 插入图片到富文本编辑器回显
          customInsert(res, insertFn) {
            insertFn(http.defaults.baseURL + res[0]);

            // // res 即服务端的返回结果
            // getPhotoUrl(res.data[0]).then((res) => {
            //   const url = res.data;
            //   const alt = "";
            //   const href = res.data;
            //   // 从 res 中找到 url alt href ，然后插入图片
            //   insertFn(url, alt, href);
            // });
          },
        },
      },
    });

    return { state, props, handleCreated, editorRef, editorConfig };
  },
};
</script>
<style scoped></style>
