<template>
  <div class="listBox">
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          align="center"
        />
        <el-table-column label="采购信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul>
                <text style="color: #000">{{ row.Bh }}</text>
                <text style="color: #a2a2a2"
                  >[{{ row.Lb1Mc + " - " + row.Lb2Mc }}]</text
                >
              </ul>
              <ul
                v-for="mx in row.Mxs"
                :key="mx"
                style="
                  font-size: 12px;
                  line-height: 12px;
                  color: #a2a2a2;
                  white-space: nowrap;
                "
              >
                {{
                  mx.Lx +
                  " - " +
                  mx.Mc +
                  "  " +
                  $priceFormat(mx.Dj) +
                  " * " +
                  mx.Sl1
                }}
                <text v-if="mx.Sl2">{{ " * " + mx.Sl2 }}</text>
                <text>{{ " = " + $priceFormat(mx.Zj) + " 元 " }}</text>
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="GysMc" label="供应商" width="120">
        </el-table-column>
        <el-table-column label="订单信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul style="white-space: nowrap">
                <text :style="{ color: row.DdZt.Color }"
                  >[{{ row.DdZt.Text }}]</text
                >
                <text style="color: #000">{{ row.DdMc }}</text>
                <text style="color: #a2a2a2">[{{ row.DdBh }}]</text>
              </ul>
              <ul
                style="
                  color: #a2a2a2;
                  white-space: nowrap;
                  font-size: 12px;
                  line-height: 12px;
                "
              >
                <text> [{{ row.DdLx.Text }}] </text>
                <text>
                  {{ row.DdTs + "天" }}
                </text>
                <text v-if="row.DdYs">
                  {{ row.DdYs + "晚" }}
                </text>
                <text>
                  {{ row.DdFtsj + " ~ " + row.DdHtsj }}
                </text>
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="采购金额"
          label="预计/实际现付"
          width="120"
          :formatter="$tab_MoneyFormat"
          align="right"
        >
          <template #default="{ row }">
            <div style="color: #ffa500; text-align: right">
              <ul>
                {{
                  $priceFormat(row.Yjxf)
                }}
              </ul>
              <ul>
                {{
                  $priceFormat(row.Sjxf)
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="已付/未付"
          width="120"
          :formatter="$tab_MoneyFormat"
          align="right"
        >
          <template #default="{ row }">
            <div style="color: #ffa500; text-align: right">
              <ul>
                {{
                  $priceFormat(row.Yf)
                }}
              </ul>
              <ul>
                {{
                  $priceFormat(row.Qk)
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="state.compileTitle + '供应商账户'"
        @close="onCloseCompile(false)"
      >
        <c_compile
          @close="onCloseCompile(true)"
          :id="state.selectId"
          :gysId="props?.gysId"
        ></c_compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { gongyingshang_getlycg } from "@/http/api";

import c_compile from "./ZhCompile.vue";
export default {
  props: {
    gysId: Number,
  },
  components: {
    c_compile,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      gongyingshang_getlycg(props.gysId).then((res) => {
        if (res.status == 200) {
          state.tableData = res.data;
        }
      });
    };

    return {
      state,
      onLoad,
      props,
    };
  },
};
</script>
<style>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
