<template>
  <el-table
    :data="state.tableData"
    v-loading="!Array.isArray(state.tableData)"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <el-table-column label="#" type="index"></el-table-column>
    <el-table-column prop="Czsj" label="操作事件"></el-table-column>
    <el-table-column prop="Czr" label="操作人"></el-table-column>
    <el-table-column prop="Nr" label="操作内容"></el-table-column>
  </el-table>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpindingdan_getrz } from "@/http/api";
export default {
  props: {
    ddId: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      shangpindingdan_getrz(props.ddId).then((res) => {
        if (res.status == 200) state.tableData = res.data;
      });
    });
    return { state };
  },
};
</script>
<style scoped></style>
