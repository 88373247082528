import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

//关键字高亮
export const tab_Highlinght = (text, key) => {
  if (text != null && text != undefined) {
    let reg = new RegExp("(" + key + ")", "g");
    let newStr = text
      .toString()
      .replace(reg, "<font style='color:#f00'>$1</font>");
    return h("text", { innerHTML: newStr });
  }
};

//状态字段格式化，添加颜色
export const tab_StatusFormat = (row, column, cellValue) => {
  return h(
    "text",
    { style: "color:" + cellValue?.Color },
    cellValue?.Text || "-"
  );
};

//内容格式化，如果为空则显示"-"
export const tab_TextFormat = (row, column, cellValue) => {
  return cellValue || "-";
};

//金额格式化
export const tab_MoneyFormat = (row, column, cellValue) => {
  // return h(
  //   "text",
  //   { style: "color:#f00;font-weight:600" },
  //   "￥" + (cellValue || 0).toFixed(2)
  // );

  return h("text", {}, priceFormat(cellValue));
};

// //组合内容格式化
// export const tab_GroupFormat = (value1, value2, key) => {
//   if (key) {
//     let reg = new RegExp("(" + key + ")", "g");
//     value1 = value1
//       .toString()
//       .replace(reg, "<font style='color:#f00'>$1</font>");
//   }

//   const str =
//     value1 +
//     "<br /><text style='color:#a2a2a2;font-size:12px;white-space: nowrap;'>" +
//     value2 +
//     "</text>";
//   return h("div", {
//     innerHTML: str,
//     style: "overflow:hidden;line-height: 14px;",
//   });
// };

//组合内容格式化
export const tab_GroupFormat = (
  value1,
  value2,
  color1 = "#000",
  color2 = "#a2a2a2"
) => {
  let str =
    "<text title='" +
    value1 +
    "' style='color:" +
    color1 +
    ";font-size:14px;white-space: nowrap;'>" +
    value1 +
    "</text><br /><text title='" +
    value2 +
    "' style='color:" +
    color2 +
    ";font-size:12px;white-space: nowrap;'>" +
    value2 +
    "</text>";

  // if (key) {
  //   let reg = new RegExp("(" + key + ")", "g");
  //   str = str.toString().replace(reg, "<font style='color:#f00'>$1</font>");
  // }

  return h("div", {
    innerHTML: str,
    style: "overflow:hidden;line-height: 18px;",
  });
};

//提交接口处理函数
export const post_response = (res, emit) => {
  ElMessage({
    message: res.data,
    showClose: true,
    dangerouslyUseHTMLString: true,
    type: res.status ? "success" : "error",
  });
  if (res.status == 200) {
    setTimeout(() => {
      emit("close", true);
    }, 500);
  }
};

/* 带提示框的通用请求接口函数
 * func：请求接口
 * id：数据Id
 * backFunc：回调
 * message：提示语
 */
export const confirm_func = (func, id, backFunc, message = "确认删除?") => {
  ElMessageBox.confirm(message, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    dangerouslyUseHTMLString: true,
    type: "warning",
  }).then(() => {
    func(id).then((res) => {
      if (res.status == 200) {
        ElMessageBox.alert(res.data, "提示", {
          dangerouslyUseHTMLString: true,
          type: "success",
          callback: () => {
            backFunc();
          },
        });
      } else {
        ElMessage({
          message: res.data,
          type: "error",
        });
      }
    });
  });
};

/**
 * 不带提示框的通用请求接口
 */
export const common_func = (func, id, backFunc) => {
  func(id).then((res) => {
    if (res.status == 200) {
      ElMessageBox.alert(res.data, "提示", {
        dangerouslyUseHTMLString: true,
        type: "success",
        callback: () => {
          backFunc();
        },
      });
    } else {
      ElMessage({
        message: res.data,
        type: "error",
      });
    }
  });
};

//判断数字是否为空
export const numberIsNull = (value) => {
  if (value == null || value == undefined || value === "" || value == 0)
    return true;
  else return false;
};

//判断数字是否为空，可以为0
export const numberIsNull2 = (value) => {
  if (value == null || value == undefined || value === "") return true;
  else return false;
};

//判断字符串是否为空
export const stringIsNull = (value) => {
  if (value == null || value == undefined || value === "") return true;
  else return false;
};

/**金额格式化 */
export const priceFormat = (num, n) => {
  if (n != 0) n = n || 2;
  let symbol = ",";
  if (num === null || num == undefined) return "0.00";
  if (typeof num !== "number")
    throw new TypeError("num参数应该是一个number类型");
  if (n < 0) throw new Error("参数n不应该小于0");
  let hasDot = parseInt(num) != num; //这里检测num是否为小数，true表示小数
  let m = n != undefined && n != null ? n : 1;
  num =
    m == 0
      ? num.toFixed(m) + "."
      : hasDot
      ? n
        ? num.toFixed(n)
        : num
      : num.toFixed(m);
  symbol = symbol || ",";
  num = num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function (match, p1) {
    return p1 + symbol;
  });
  if (n == 0 || (!hasDot && !n)) {
    //如果n为0或者传入的num是整数并且没有指定整数的保留位数，则去掉前面操作中的小数位
    num = num.substring(0, num.indexOf("."));
  }
  return num;
};

//数值转为%
export const numToPercentFormat = (cellValue) => {
  return (cellValue * 100).toFixed(2) + "%";
};
