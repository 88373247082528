<template>
  <div class="detailBox">
    <ul class="detailContent" style="display: flex">
      <div style="flex: 1">
        <ul style="font-size: 14px; font-weight: 600">
          订单信息
        </ul>
        <el-descriptions :column="2" border style="margin: 10px 0">
          <el-descriptions-item
            label="导游姓名"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.item.DyXm }}
          </el-descriptions-item>
          <el-descriptions-item
            label="联系电话"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.item.DySjhm }}
          </el-descriptions-item>
          <el-descriptions-item
            label="证件号"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.item.DyZjh }}
          </el-descriptions-item>
          <el-descriptions-item
            label="发放合计"
            label-align="right"
            class-name="lydd_model_item"
          >
            <c_money :value="state.model.Bzje"></c_money>
          </el-descriptions-item>

          <el-descriptions-item
            label="申请人"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.CjrXm || "-" }}
          </el-descriptions-item>
          <el-descriptions-item
            label="申请时间"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.Cjsj || "-" }}
          </el-descriptions-item>
          <el-descriptions-item
            label="申请说明"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.Bz || "-" }}
          </el-descriptions-item>
          <el-descriptions-item
            label="业务审核人"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.YwrXm }}
          </el-descriptions-item>
          <el-descriptions-item
            label="业务审核状态"
            label-align="right"
            class-name="lydd_model_item"
          >
            <text v-if="state.model.Ywzt == null" style="color: #a2a2a2">
              未审核
            </text>
            <template v-else>
              <text v-if="state.model.Ywzt == true" style="color: #1e90ff">
                通过
              </text>
              <text v-else-if="state.model.Ywzt == false" style="color: #f00">
                不通过
              </text>
              <text style="color: #a2a2a2">
                {{ state.model.Ywsj }}
              </text>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            label="业务审核备注"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.Ywbz || "-" }}
          </el-descriptions-item>
          <el-descriptions-item
            label="财务审核人"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.CwrXm }}
          </el-descriptions-item>
          <el-descriptions-item
            label="财务审核状态"
            label-align="right"
            class-name="lydd_model_item"
          >
            <text v-if="state.model.Cwzt == null" style="color: #a2a2a2">
              未审核
            </text>
            <template v-else>
              <text v-if="state.model.Cwzt == true" style="color: #1e90ff">
                通过
              </text>
              <text v-else-if="state.model.Cwzt == false" style="color: #f00">
                不通过
              </text>
              <text style="color: #a2a2a2">
                {{ state.model.Cwsj }}
              </text>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            label="财务审核备注"
            label-align="right"
            class-name="lydd_model_item"
          >
            {{ state.model.Cwbz || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <template v-if="state.model.Zt == 0 || state.model.Zt == 2">
          <ul style="font-size: 14px; font-weight: 600">
            审核
          </ul>
          <el-form
            :model="state.shModel"
            label-width="100px"
            :scroll-to-error="true"
            ref="domForm"
          >
            <el-form-item
              label="审核状态"
              prop="Result"
              :rules="[
                { required: true, message: '请选择审核状态', trigger: 'blur' },
              ]"
            >
              <el-radio-group
                v-model="state.shModel.Result"
                :fill="state.shModel.Result == true ? '#1e90ff' : '#f00'"
              >
                <el-radio-button :label="true">通过</el-radio-button>
                <el-radio-button :label="false">不通过</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="审核备注"
              prop="Bz"
              :rules="[
                {
                  required: state.shModel.Result == false,
                  message: '请输入审核备注',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input type="textarea" v-model="state.shModel.Bz" />
            </el-form-item>
          </el-form>
        </template>
      </div>
      <div style="flex: 1; margin-left: 20px">
        <ul style="font-size: 14px; font-weight: 600">
          带团信息
        </ul>
        <div
          style="
            background-color: #f6f6f6;
            display: flex;
            flex-direction: column;
            padding: 10px;
            padding-top: 0;
            margin-top: 10px;
            border-radius: 8px;
          "
        >
          <div
            style="
              margin-top: 10px;
              background-color: #fff;
              border-radius: 8px;
              padding: 10px 20px;
              position: relative;
            "
            v-for="item in state.dypqs"
            :key="item"
          >
            <li
              style="
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px 20px;
                color: #fff;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 8px;
              "
              :style="{ background: item.DdZt?.Color }"
            >
              {{ item.DdZt?.Text }}
            </li>
            <ul style="display: flex; position: relative">
              <li style="display: flex; align-items: center">
                <text style="font-size: 16px; font-weight: 600">{{
                  item.DdMc
                }}</text>
                <text style="font-size: 12px; margin-left: 5px">{{
                  item.DdBh
                }}</text>
                <text
                  style="font-size: 12px; font-weight: 600; margin-left: 100px"
                  >{{ item.DdRq }}</text
                >
              </li>
            </ul>
            <ul style="display: flex; align-items: center; margin: 20px 0">
              <li style="flex: 1">
                <text style="color: #a2a2a2">服务日期：</text>{{ item.Fwrq }}
              </li>
              <li style="display: flex">
                <text style="color: #a2a2a2">补助：</text
                ><c_money :value="item.Bzje"></c_money>
              </li>
            </ul>
            <ul>
              <text style="color: #a2a2a2">补助说明：</text>
              {{
                item.Bz || "-"
              }}
            </ul>
          </div>
        </div>
      </div>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { daoyoubuzhu_check, daoyoubuzhu_detail } from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
      },
      item: {},
      shModel: {},
    });
    onMounted(() => {
      if (props.id) {
        daoyoubuzhu_detail(props.id).then((res) => {
          if (res.status == 200) {
            const { model, item, dypqs } = res.data;
            state.model = model;
            state.item = item;
            state.dypqs = dypqs;
            state.shModel.Type = model.Zt == 0 ? 1 : 2;
          }
        });
      }
    });

    const onSubmit = () => {
      daoyoubuzhu_check(props.id, state.shModel).then((res) => {
        ElMessage({
          message: res.data,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: res.status ? "success" : "error",
        });
        if (res.status == 200) {
          setTimeout(() => {
            emit("close", true);
          }, 500);
        }
      });
    };

    return { state, onSubmit, props };
  },
};
</script>
<style scoped></style>
