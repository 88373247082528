<template>
  <div class="ydxx_box">
    <ul
      class="ydxx_title"
      style="margin-top: 0; display: flex; align-items: center"
    >
      <li style="flex: 1">费用包含</li>
      <template v-if="!props.search">
        <el-button
          type="primary"
          icon="Edit"
          v-if="!state.edit"
          link
          @click="state.edit = true"
        >
          编辑
        </el-button>
        <el-button
          type="info"
          v-else
          icon="Close"
          link
          @click="state.edit = false && !props.search"
        >
          取消
        </el-button>
      </template>
    </ul>
    <el-input
      v-if="state.edit"
      type="textarea"
      v-model="state.model.Fybk"
      :autosize="{ minRows: 5 }"
      placeholder="费用包含"
    />
    <div v-else style="white-space: pre-wrap">{{ state.model.Fybk }}</div>
    <!-- <el-input
      v-else
      type="textarea"
      v-model="state.model.Fybk"
      :autosize="true"
      readonly
      :resize="false"
      class="sTextarea"
    /> -->
    <ul class="ydxx_title">
      费用不含
    </ul>
    <el-input
      v-if="state.edit"
      type="textarea"
      v-model="state.model.Fybh"
      :autosize="{ minRows: 5 }"
      placeholder="费用不含"
    />
    <!-- <el-input
      v-else
      type="textarea"
      v-model="state.model.Fybh"
      :autosize="true"
      readonly
      :resize="false"
      class="sTextarea"
    /> -->
    <div v-else style="white-space: pre-wrap">{{ state.model.Fybh }}</div>
    <ul class="ydxx_title">
      注意事项
    </ul>
    <el-input
      v-if="state.edit"
      type="textarea"
      v-model="state.model.Zysx"
      :autosize="{ minRows: 5 }"
      placeholder="注意事项"
    />
    <div v-else style="white-space: pre-wrap">{{ state.model.Zysx }}</div>
    <!-- <el-input
      v-else
      type="textarea"
      v-model="state.model.Zysx"
      placeholder="费用不含"
      readonly
      :autosize="true"
      :resize="false"
      class="sTextarea"
    /> -->
    <el-button
      type="primary"
      @click="onSubmit()"
      style="margin-top: 10px"
      v-if="state.edit"
    >
      提交
    </el-button>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_getydxx, lvyoudingdan_setydxx } from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    id: Number,
    search: Boolean,
  },
  setup(props) {
    const state = reactive({
      model: {},
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_getydxx(props.id).then((res) => {
        if (res.status == 200) state.model = res.data;
        state.edit = false;
      });
    };

    const onSubmit = () => {
      lvyoudingdan_setydxx(props.id, state.model).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
        }
      });
    };
    return { state, onSubmit, props };
  },
};
</script>
<style>
.ydxx_box {
  .ydxx_title {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .sTextarea {
    textarea {
      box-shadow: none !important;
    }
  }
}
</style>
