<template>
  <div style="height: 100%; overflow-y: auto; padding: 10px 20px; z-index: 2">
    <ul style="position: relative; text-align: center">
      <el-image
        :src="require('@/assets/images/logo.png')"
        style="position: absolute; left: 20px; height: 30px; top: 10px"
      />
      <li style="font-size: 24px; font-weight: 600">肥西文旅集团结算单</li>
      <li style="font-size: 18px">NO:{{ state.model.Bh }}</li>
      <el-button
        icon="printer"
        style="position: absolute; right: 20px; top: 20px"
        ref="printDom"
        @click="onPrint"
        >打印</el-button
      >
    </ul>
    <ul
      style="font-weight: 600; font-size: 13px; margin: 10px 0; display: flex"
    >
      <li style="flex: 1">行程信息</li>
      <text>{{ state.model.TjjsrXm }}</text>
      <text style="color: #a2a2a2; margin-left: 10px">{{
        state.model.Jsshsj + " 结算"
      }}</text>
    </ul>
    <div
      style="
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 20px;
      "
    >
      <ul
        style="
          border-bottom: 1px solid #e2e2e2;
          padding: 10px;
          display: flex;
          align-items: center;
        "
      >
        <text style="font-weight: 600">{{ state.model.Mc }}</text>
        <text style="color: #a2a2a2; margin: 0 10px">{{
          state.item.Lx?.Text
        }}</text>
        <c_status :model="state.item.Zt"></c_status>
      </ul>
      <ul style="padding: 10px">
        <text style="color: #a2a2a2">计调：</text>
        <text>{{ state.model.JdyXm + "-" + state.model.JdyBmMc }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">团期：</text>
        <text>{{ state.item.Ftsj + " 至 " + state.item.Htsj }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">天数：</text>
        <text>{{ state.model.Ts + "天" + state.model.Ys + "晚" }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">人数：</text>
        <text>{{ state.model.Zrs }}</text>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      金额明细
    </ul>
    <div
      style="
        white-space: pre-wrap;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
        padding: 7px;
        min-height: 100px;
      "
    >
      <ul style="padding: 10px 0">
        团队总产值 = 销售金额<text style="color: #f00; font-weight: 600">{{
          $priceFormat(state.model.Zje) + "元"
        }}</text>
      </ul>
      <ul style="padding: 10px 0">
        团队总成本 = 团队采购成本{{
          $priceFormat(state.model.Cgcb)
        }}
        + 导游补助({{
          $priceFormat(state.model.Dbhj)
        }}) =
        <text style="color: #f00; font-weight: 600">{{
          $priceFormat(state.model.Cgcb + state.model.Dbhj) + "元"
        }}</text>
      </ul>
      <ul style="padding: 10px 0">
        团队毛利 = 团队总产值{{
          $priceFormat(state.model.Zje)
        }}
        - 团队总成本({{
          $priceFormat(state.model.Cgcb + state.model.Dbhj)
        }}) =
        <text style="color: #f00; font-weight: 600">{{
          $priceFormat(state.model.Zje - state.model.Cgcb - state.model.Dbhj) +
          "元"
        }}</text>
      </ul>
      <ul style="padding: 10px 0">
        团队毛利率：<text style="color: #f00; font-weight: 600">{{
          state.model.Cgcb + state.model.Dbhj == 0
            ? "0%"
            : $numToPercentFormat(
                $priceFormat(
                  (state.model.Zje - state.model.Cgcb - state.model.Dbhj) /
                    state.model.Zje,
                  4
                )
              )
        }}</text>
        人均毛利：<text style="color: #f00; font-weight: 600">{{
          state.model.Zrs == 0
            ? "0"
            : $priceFormat(
                (state.model.Zje - state.model.Cgcb - state.model.Dbhj) /
                  state.model.Zrs
              )
        }}</text>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      采购信息
    </ul>
    <div
      style="border: 1px solid #e2e2e2; border-radius: 10px; overflow: hidden"
    >
      <ul style="display: flex; font-weight: 600; background-color: #f7f7f7">
        <li
          style="
            flex: 2;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
            white-space: nowrap;
          "
        >
          类别
        </li>
        <li
          style="
            flex: 3;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
            white-space: nowrap;
          "
        >
          采购项
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
            white-space: nowrap;
          "
        >
          供应商
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            white-space: nowrap;
            border-right: 1px solid #e2e2e2;
          "
        >
          合计
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            white-space: nowrap;
            border-right: 1px solid #e2e2e2;
          "
        >
          预计现付
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            white-space: nowrap;
            border-right: 1px solid #e2e2e2;
          "
        >
          实际现付
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            white-space: nowrap;
            border-right: 1px solid #e2e2e2;
          "
        >
          已付
        </li>
        <li
          style="flex: 1; text-align: right; padding: 10px; white-space: nowrap"
        >
          未付
        </li>
      </ul>
      <ul
        style="display: flex; border-top: 1px solid #e2e2e2"
        v-for="cg in state.cgs"
        :key="cg"
      >
        <li
          style="
            flex: 2;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ cg.LbMc }}
        </li>
        <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
          <ul v-for="mx in cg.Mxs" :key="mx">
            {{
              mx.Lx +
              " - " +
              mx.Mc +
              " " +
              $priceFormat(mx.Dj || 0) +
              " * " +
              mx.Sl1 +
              (mx.Sl2 > 0 ? " * " + mx.Sl2 : "") +
              " = " +
              $priceFormat(mx.Zj || 0) +
              " 元"
            }}
          </ul>
        </li>
        <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
          {{ cg.GysMc || "-" }}
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(cg.Zje) }}
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(cg.Yjxf) }}
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(cg.Sjxf) }}
        </li>
        <li
          style="
            flex: 1;
            text-align: right;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(cg.Yf) }}
        </li>
        <li style="flex: 1; text-align: right; padding: 10px">
          {{ $priceFormat(cg.Qk) }}
        </li>
      </ul>
      <ul
        style="border-top: 1px solid #e2e2e2; display: flex; font-weight: 600"
      >
        <li
          style="
            flex: 6;
            text-align: right;
            border-right: 1px solid #e2e2e2;
            padding: 10px 10px 10px 55px;
          "
        >
          合计
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            text-align: right;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(state.model.Cgcb) }}
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            text-align: right;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(state.model.Cgyjxf) }}
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            text-align: right;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(state.model.Cgsjxf) }}
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            text-align: right;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(state.model.Cgyf) }}
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            text-align: right;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ $priceFormat(state.model.Cgqk) }}
        </li>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      导游信息
    </ul>
    <div
      style="border: 1px solid #e2e2e2; border-radius: 10px; overflow: hidden"
    >
      <ul style="display: flex; font-weight: 600; background-color: #f7f7f7">
        <li
          style="
            flex: 2;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          带团日期
        </li>
        <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
          导游姓名
        </li>
        <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
          带团说明
        </li>
        <li
          style="
            flex: 1;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          补助是否现发
        </li>
        <li style="flex: 1; text-align: right; padding: 10px">补助金额</li>
      </ul>
      <ul
        style="display: flex; border-top: 1px solid #e2e2e2"
        v-for="dy in state.dys"
        :key="dy"
      >
        <li
          style="
            flex: 2;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ dy.Dtksrq + " ~ " + dy.Dtjsrq }}
        </li>
        <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
          {{ dy.Xm }}
        </li>
        <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
          {{ dy.Bz || "-" }}
        </li>
        <li
          style="
            flex: 1;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          {{ dy.IsXf ? "是" : "否" }}
        </li>
        <li style="flex: 1; text-align: right; padding: 10px">
          {{ $priceFormat(dy.Bzje) }}
        </li>
      </ul>
      <ul
        style="border-top: 1px solid #e2e2e2; display: flex; font-weight: 600"
      >
        <li
          style="
            flex: 7;

            text-align: right;
            border-right: 1px solid #e2e2e2;
            padding: 10px 10px 10px 70px;
          "
        >
          合计
        </li>
        <li style="flex: 1; padding: 10px; text-align: right">
          {{ $priceFormat(state.model.Dbhj) }}
        </li>
      </ul>
    </div>
    <ul style="font-weight: 600; font-size: 13px; margin: 10px 0">
      借还款信息
    </ul>
    <div
      style="border: 1px solid #e2e2e2; border-radius: 10px; overflow: hidden"
    >
      <ul style="display: flex">
        <li
          style="
            background-color: #f5f7fa;
            font-weight: 600;
            text-align: right;
            width: 150px;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          借款金额：
        </li>
        <li style="flex: 1.5; padding: 10px">
          {{ $priceFormat(state.model.Jkje) }}
        </li>
        <li
          style="
            background-color: #f5f7fa;
            font-weight: 600;
            text-align: right;
            width: 150px;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          导游现收：
        </li>
        <li style="flex: 1.5; padding: 10px">
          {{ $priceFormat(state.model.Dyxs) }}
        </li>
        <li
          style="
            background-color: #f5f7fa;
            font-weight: 600;
            text-align: right;
            width: 150px;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          导游现付：
        </li>
        <li style="flex: 1.5; padding: 10px">
          {{ $priceFormat(state.model.Dyxf) }}
        </li>
        <li
          style="
            background-color: #f5f7fa;
            font-weight: 600;
            text-align: right;
            width: 150px;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          导补现发：
        </li>
        <li style="flex: 1.5; padding: 10px">
          {{ $priceFormat(state.model.Bzxf) }}
        </li>
        <li
          style="
            background-color: #f5f7fa;
            font-weight: 600;
            text-align: right;
            width: 150px;
            padding: 10px;
            border-right: 1px solid #e2e2e2;
          "
        >
          导游应还：
        </li>
        <li style="flex: 1.5; padding: 10px">
          {{
            $priceFormat(
              state.model.Jkje +
                state.model.Dyxs -
                state.model.Dyxf -
                state.model.Bzxf
            )
          }}
        </li>
      </ul>
      <ul
        style="
          display: flex;
          align-items: center;
          overflow: hidden;
          border-top: 1px solid #e2e2e2;
        "
      >
        <li
          style="
            background-color: #f5f7fa;
            font-weight: 600;
            text-align: right;
            width: 150px;
            padding: 10px;
            min-height: 100px;
            border-right: 1px solid #e2e2e2;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
        >
          借款说明：
        </li>
        <li
          style="
            flex: 1;
            padding: 10px;
            min-height: 100px;
            display: flex;
            align-items: center;
          "
        >
          {{ state.model.Jksm || "-" }}
        </li>
      </ul>
    </div>
  </div>
  <div id="divDom">
    <div
      style="
        height: 100%;
        overflow-y: auto;
        padding: 10px 20px;
        font-size: 30px;
      "
    >
      <ul style="position: relative; text-align: center">
        <el-image
          :src="require('@/assets/images/logo.png')"
          style="position: absolute; left: 20px; height: 50px; top: 20px"
        />
        <li style="font-size: 50px; font-weight: 600">
          肥西文旅集团团队结算单
        </li>
        <li style="font-size: 30px; margin-top: 10px">
          NO:{{ state.model.Bh }}
        </li>
      </ul>
      <ul style="font-weight: 600; margin: 10px 0; display: flex">
        <li style="flex: 1">行程信息</li>
        <text>{{ state.model.TjjsrXm }}</text>
        <text style="color: #a2a2a2; margin-left: 10px">{{
          state.model.Tjjssj + " 结算"
        }}</text>
      </ul>
      <div
        style="
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          overflow: hidden;
          margin-top: 20px;
        "
      >
        <ul
          style="
            border-bottom: 1px solid #e2e2e2;
            padding: 10px;
            display: flex;
            align-items: center;
          "
        >
          <text style="font-weight: 600">{{ state.model.Mc }}</text>
          <text style="color: #a2a2a2; margin: 0 10px">{{
            state.item.Lx?.Text
          }}</text>
          <c_status :model="state.item.Zt"></c_status>
        </ul>
        <ul style="padding: 10px">
          <text style="color: #a2a2a2">计调：</text>
          <text>{{ state.model.JdyXm + "-" + state.model.JdyBmMc }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">团期：</text>
          <text>{{ state.item.Ftsj + " 至 " + state.item.Htsj }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">天数：</text>
          <text>{{ state.model.Ts + "天" + state.model.Ys + "晚" }}</text>
          <text style="color: #a2a2a2; margin-left: 10px">人数：</text>
          <text>{{ state.model.Zrs }}</text>
        </ul>
      </div>
      <ul style="font-weight: 600; margin: 30px 0">
        金额明细
      </ul>
      <div
        style="
          white-space: pre-wrap;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          padding: 7px;
          min-height: 100px;
        "
      >
        <ul style="padding: 10px 0">
          团队总产值 = 销售金额<text style="color: #f00; font-weight: 600">{{
            $priceFormat(state.model.Zje) + "元"
          }}</text>
        </ul>
        <ul style="padding: 10px 0">
          团队总成本 = 团队采购成本{{
            $priceFormat(state.model.Cgcb)
          }}
          + 导游补助({{
            $priceFormat(state.model.Dbhj)
          }}) =
          <text style="color: #f00; font-weight: 600">{{
            $priceFormat(state.model.Cgcb + state.model.Dbhj) + "元"
          }}</text>
        </ul>
        <ul style="padding: 10px 0">
          团队毛利 = 团队总产值{{
            $priceFormat(state.model.Zje)
          }}
          - 团队总成本({{
            $priceFormat(state.model.Cgcb + state.model.Dbhj)
          }}) =
          <text style="color: #f00; font-weight: 600">{{
            $priceFormat(
              state.model.Zje - state.model.Cgcb - state.model.Dbhj
            ) + "元"
          }}</text>
        </ul>
        <ul style="padding: 10px 0">
          团队毛利率：<text style="color: #f00; font-weight: 600">{{
            state.model.Cgcb + state.model.Dbhj == 0
              ? "0%"
              : $numToPercentFormat(
                  $priceFormat(
                    (state.model.Zje - state.model.Cgcb - state.model.Dbhj) /
                      state.model.Zje,
                    4
                  )
                )
          }}</text>
          人均毛利：<text style="color: #f00; font-weight: 600">{{
            state.model.Zrs == 0
              ? "0"
              : $priceFormat(
                  (state.model.Zje - state.model.Cgcb - state.model.Dbhj) /
                    state.model.Zrs
                )
          }}</text>
        </ul>
      </div>
      <ul style="font-weight: 600; margin: 30px 0">
        采购信息
      </ul>
      <div
        style="
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          overflow: hidden;
          font-size: 24px;
        "
      >
        <ul style="display: flex; font-weight: 600; background-color: #f7f7f7">
          <li
            style="
              flex: 2;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
            "
          >
            类别
          </li>
          <li
            style="
              flex: 3;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
            "
          >
            采购项
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
              white-space: nowrap;
            "
          >
            供应商
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              white-space: nowrap;
              border-right: 1px solid #e2e2e2;
            "
          >
            合计
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              white-space: nowrap;
              border-right: 1px solid #e2e2e2;
            "
          >
            预计现付
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              white-space: nowrap;
              border-right: 1px solid #e2e2e2;
            "
          >
            实际现付
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              white-space: nowrap;
              border-right: 1px solid #e2e2e2;
            "
          >
            已付
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              white-space: nowrap;
            "
          >
            未付
          </li>
        </ul>
        <ul
          style="display: flex; border-top: 1px solid #e2e2e2"
          v-for="cg in state.cgs"
          :key="cg"
        >
          <li
            style="
              flex: 2;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ cg.LbMc }}
          </li>
          <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
            <ul v-for="mx in cg.Mxs" :key="mx">
              {{
                mx.Lx +
                " - " +
                mx.Mc +
                " " +
                $priceFormat(mx.Dj || 0) +
                " * " +
                mx.Sl1 +
                (mx.Sl2 > 0 ? " * " + mx.Sl2 : "") +
                " = " +
                $priceFormat(mx.Zj || 0) +
                " 元"
              }}
            </ul>
          </li>
          <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
            {{ cg.GysMc || "-" }}
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(cg.Zje) }}
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(cg.Yjxf) }}
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(cg.Sjxf) }}
          </li>
          <li
            style="
              flex: 1;
              text-align: right;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(cg.Yf) }}
          </li>
          <li style="flex: 1; text-align: right; padding: 10px">
            {{ $priceFormat(cg.Qk) }}
          </li>
        </ul>
        <ul
          style="border-top: 1px solid #e2e2e2; display: flex; font-weight: 600"
        >
          <li
            style="
              flex: 6;
              text-align: right;
              border-right: 1px solid #e2e2e2;
              padding: 10px 10px 10px 55px;
            "
          >
            合计
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              text-align: right;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(state.model.Cgcb) }}
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              text-align: right;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(state.model.Cgyjxf) }}
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              text-align: right;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(state.model.Cgsjxf) }}
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              text-align: right;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(state.model.Cgyf) }}
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              text-align: right;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ $priceFormat(state.model.Cgqk) }}
          </li>
        </ul>
      </div>
      <ul style="font-weight: 600; margin: 30px 0">
        导游信息
      </ul>
      <div
        style="
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          overflow: hidden;
          font-size: 24px;
        "
      >
        <ul style="display: flex; font-weight: 600; background-color: #f7f7f7">
          <li
            style="
              flex: 2;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            带团日期
          </li>
          <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
            导游姓名
          </li>
          <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
            带团说明
          </li>
          <li
            style="
              flex: 1.5;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            补助是否现发
          </li>
          <li style="flex: 1; text-align: right; padding: 10px">补助金额</li>
        </ul>
        <ul
          style="display: flex; border-top: 1px solid #e2e2e2"
          v-for="dy in state.dys"
          :key="dy"
        >
          <li
            style="
              flex: 2;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ dy.Dtksrq + " ~ " + dy.Dtjsrq }}
          </li>
          <li style="flex: 1; padding: 10px; border-right: 1px solid #e2e2e2">
            {{ dy.Xm }}
          </li>
          <li style="flex: 3; padding: 10px; border-right: 1px solid #e2e2e2">
            {{ dy.Bz || "-" }}
          </li>
          <li
            style="
              flex: 1.5;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            {{ dy.IsXf ? "是" : "否" }}
          </li>
          <li style="flex: 1; text-align: right; padding: 10px">
            {{ $priceFormat(dy.Bzje) }}
          </li>
        </ul>
        <ul
          style="border-top: 1px solid #e2e2e2; display: flex; font-weight: 600"
        >
          <li
            style="
              flex: 7;

              text-align: right;
              border-right: 1px solid #e2e2e2;
              padding: 10px 10px 10px 70px;
            "
          >
            合计
          </li>
          <li style="flex: 1; padding: 10px; text-align: right">
            {{ $priceFormat(state.model.Dbhj) }}
          </li>
        </ul>
      </div>
      <ul style="font-weight: 600; margin: 30px 0">
        借还款信息
      </ul>
      <div
        style="
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          overflow: hidden;
          font-size: 24px;
        "
      >
        <ul style="display: flex">
          <li
            style="
              background-color: #f5f7fa;
              font-weight: 600;
              text-align: right;
              width: 150px;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            借款金额：
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Jkje) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              font-weight: 600;
              text-align: right;
              width: 150px;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            导游现收：
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Dyxs) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              font-weight: 600;
              text-align: right;
              width: 150px;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            导游现付：
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Dyxf) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              font-weight: 600;
              text-align: right;
              width: 150px;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            导补现发：
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{ $priceFormat(state.model.Bzxf) }}
          </li>
          <li
            style="
              background-color: #f5f7fa;
              font-weight: 600;
              text-align: right;
              width: 150px;
              padding: 10px;
              border-right: 1px solid #e2e2e2;
            "
          >
            导游应还：
          </li>
          <li style="flex: 1.5; padding: 10px">
            {{
              $priceFormat(
                state.model.Jkje +
                  state.model.Dyxs -
                  state.model.Dyxf -
                  state.model.Bzxf
              )
            }}
          </li>
        </ul>
        <ul
          style="
            display: flex;
            align-items: center;
            overflow: hidden;
            border-top: 1px solid #e2e2e2;
          "
        >
          <li
            style="
              background-color: #f5f7fa;
              font-weight: 600;
              text-align: right;
              width: 150px;
              padding: 10px;
              min-height: 100px;
              border-right: 1px solid #e2e2e2;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            借款说明：
          </li>
          <li
            style="
              flex: 1;
              padding: 10px;
              min-height: 100px;
              display: flex;
              align-items: center;
            "
          >
            {{ state.model.Jksm || "-" }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_detail } from "@/http/api";
import html2canvas from "html2canvas";
import printJS from "print-js";
import { ElLoading } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const state = reactive({
      model: {},
      item: {},
      mxs: [],
      dys: [],
      cgs: [],
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_detail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs, dys, cgs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;
          state.dys = dys;
          state.cgs = cgs;
        }
      });
    };

    //打印
    const onPrint = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("divDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      printJS({
        printable: imgData,
        type: "image",
        style: `@media print { @page {size: auto; margin: 0; } body{margin:0;padding:0}}`,
        imageStyle: "width: 100%; margin:0; padding: 0;",
        documentTitle: "",
      });

      loading.close();
    };

    return { state, onPrint };
  },
};
</script>
<style scoped></style>
