<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      padding: 10px;
    "
  >
    <div
      style="flex: 3; overflow: hidden; display: flex; flex-direction: column"
    >
      <ul
        style="
          width: 100%;
          border: 1px solid #e2e2e2;
          border-bottom: 0;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
        "
      >
        <el-space wrap>
          <el-select
            v-model="state.params.Params.GysId"
            placeholder="--请选择供应商--"
            value-key="Mc"
            :disabled="!Array.isArray(state.gysData)"
            @change="
              delete state.model.GysZh;
              onLoadGysZh();
            "
          >
            <el-option
              v-for="item in state.gysData"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
            <template #footer>
              <el-button size="small" @click="onLoadGys"> 刷新 </el-button>
            </template>
          </el-select>
          <el-input
            v-model="state.params.Params.Key"
            placeholder="关键字"
            clearable
            @clear="delete state.params.Params.Key"
          ></el-input>
          <el-select
            v-model="state.params.Params.Lb1Id"
            placeholder="--采购类别--"
            value-key="Mc"
            :disabled="!Array.isArray(state.lb1Data)"
            @change="onLoadLb2"
          >
            <el-option
              v-for="item in state.lb1Data"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
            <template #footer>
              <el-button size="small" @click="onLoadLb1"> 刷新 </el-button>
            </template>
          </el-select>
          <el-select
            v-model="state.params.Params.Lb2Id"
            placeholder="--采购类别--"
            value-key="Mc"
            :disabled="!Array.isArray(state.lb2Data)"
          >
            <el-option
              v-for="item in state.lb2Data"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
            <template #footer>
              <el-button size="small" @click="onLoadLb2"> 刷新 </el-button>
            </template>
          </el-select>
          <el-dropdown split-button @click="onLoad" type="primary">
            查询
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                  重置
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-space>
      </ul>
      <ul style="overflow: hidden; display: flex; flex-direction: column">
        <el-table
          :data="state.tableData"
          v-loading="!Array.isArray(state.tableData)"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          :default-sort="{
            prop: state.params.Order,
            order: state.params.Asc == false ? 'descending' : 'ascending',
          }"
          @sort-change="onSortChange"
        >
          <el-table-column width="60" align="center" fixed="left">
            <template #header>选择</template>
            <template #default="{ row }">
              <el-checkbox
                :checked="row.IsXz"
                @change="onSelect(row, $event)"
              />
            </template>
          </el-table-column>
          <el-table-column
            fixed
            label="#"
            width="50"
            type="index"
            :index="
              (index) =>
                (state.params.PageIndex - 1) * state.params.PageSize + index + 1
            "
            align="center"
          />
          <el-table-column label="采购信息" min-width="200">
            <template #default="{ row }">
              <div>
                <ul>
                  <text style="color: #000">{{ row.Bh }}</text>
                  <text style="color: #a2a2a2"
                    >[{{ row.Lb1Mc + " - " + row.Lb2Mc }}]</text
                  >
                </ul>
                <ul
                  v-for="mx in row.Mxs"
                  :key="mx"
                  style="
                    font-size: 12px;
                    line-height: 12px;
                    color: #a2a2a2;
                    white-space: nowrap;
                  "
                >
                  {{
                    mx.Lx +
                    " - " +
                    mx.Mc +
                    "  " +
                    $priceFormat(mx.Dj) +
                    " * " +
                    mx.Sl1
                  }}
                  <text v-if="mx.Sl2">{{ " * " + mx.Sl2 }}</text>
                  <text>{{ " = " + $priceFormat(mx.Zj) + " 元 " }}</text>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="GysMc" label="供应商" width="120">
          </el-table-column>
          <el-table-column label="订单信息" min-width="200">
            <template #default="{ row }">
              <div>
                <ul style="white-space: nowrap">
                  <text :style="{ color: row.DdZt.Color }"
                    >[{{ row.DdZt.Text }}]</text
                  >
                  <text style="color: #000">{{ row.DdMc }}</text>
                  <text style="color: #a2a2a2">[{{ row.DdBh }}]</text>
                </ul>
                <ul
                  style="
                    color: #a2a2a2;
                    white-space: nowrap;
                    font-size: 12px;
                    line-height: 12px;
                  "
                >
                  <text> [{{ row.DdLx.Text }}] </text>
                  <text>
                    {{ row.DdTs + "天" }}
                  </text>
                  <text v-if="row.DdYs">
                    {{ row.DdYs + "晚" }}
                  </text>
                  <text>
                    {{ row.DdFtsj + " ~ " + row.DdHtsj }}
                  </text>
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="采购金额"
            label="预计/实际现付"
            width="120"
            :formatter="$tab_MoneyFormat"
          >
            <template #default="{ row }">
              <div style="color: #ffa500; text-align: right">
                <ul>
                  {{
                    $priceFormat(row.Yjxf)
                  }}
                </ul>
                <ul>
                  {{
                    $priceFormat(row.Sjxf)
                  }}
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Fl"
            label="返利"
            align="center"
            width="130"
            :formatter="$tab_MoneyFormat"
          />
          <el-table-column
            label="已付/未付"
            width="120"
            :formatter="$tab_MoneyFormat"
          >
            <template #default="{ row }">
              <div style="color: #ffa500; text-align: right">
                <ul>
                  {{
                    $priceFormat(row.Yf)
                  }}
                </ul>
                <ul>
                  {{
                    $priceFormat(row.Qk)
                  }}
                </ul>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="本次付款"
            align="center"
            width="130"
            :formatter="$tab_MoneyFormat"
          >
            <template #default="{ row }">
              <el-input-number
                v-model="row.Fkje"
                v-if="row.IsXz"
                :min="0"
                :precision="2"
                :controls="false"
                @change="handleChange"
                style="width: 100%"
              />
              <text v-else>{{ $priceFormat(row.Fkje) }}</text>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="Fl"
            label="返利"
            width="100"
            :formatter="$tab_MoneyFormat"
          >
            <template #default="{ row }">
              <el-input
                v-if="row.IsXz"
                v-model="row.Fl"
                type="number"
              ></el-input>
              <c_money v-else :value="row.Fl"></c_money>
            </template>
          </el-table-column> -->
        </el-table>
      </ul>
      <c_page
        :total="state.totalCount"
        :pageIndex="state.params.PageIndex"
        @onLoad="
          (pageIndex) => {
            state.params.PageIndex = pageIndex;
            onLoad();
          }
        "
      >
      </c_page>
    </div>
    <div
      style="
        flex: 1;
        margin-left: 10px;
        box-shadow: 0 0 10px 1px #e2e2e2;
        border-radius: 7px;
      "
    >
      <div style="padding: 30px 20px">
        <ul style="display: flex; border-bottom: 1px solid #e2e2e2">
          <li
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <text style="color: #080">{{ state.selectArr?.length }}笔</text>
            <text style="color: #a2a2a2; font-size: 12px">已勾选</text>
          </li>
          <li
            style="
              flex: 1;
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-left: 1px solid #e2e2e2;
            "
          >
            <c_money :value="state.yfje"></c_money>
            <text style="color: #a2a2a2; font-size: 12px">合计付款金额</text>
          </li>
        </ul>
        <ul>
          <li>付款方式：</li>
          <el-radio-group v-model="state.model.Fkfs">
            <el-radio :label="1">转账</el-radio>
            <el-radio :label="2">现金</el-radio>
          </el-radio-group>
          <li style="margin-top: 10px">选择到账银行：</li>
          <el-select
            v-model="state.model.GysZh"
            placeholder="--请选择到账银行：--"
            value-key="Id"
            style="margin-top: 10px"
          >
            <el-option
              v-for="item in state.zhData || []"
              :key="item"
              :label="
                '卡号：' +
                item.Kh +
                '，收款人：' +
                item.Skr +
                '，开户行：' +
                item.Khyh
              "
              :value="item"
            />
            <template #footer>
              <el-button size="small" @click="onLoadGysZh()"> 刷新 </el-button>
            </template>
          </el-select>
          <el-input
            type="textarea"
            v-model="state.model.Bz"
            :autosize="{ minRows: 4 }"
            style="margin: 10px 0"
            placeholder="备注"
          />
          <el-button type="primary" @click="onSubmit()">提交对账</el-button>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  duizhangdan_lydd_cglist,
  gongyingshang_select,
  gongyingshangzhanghu_selectbygysid,
  duizhangdan_add,
  zidian_select,
} from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  components: {},
  setup() {
    const state = reactive({
      tableData: [],
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: { TableName: "DuiZhangDan_List", IsDz: true },
      },
      model: { Fkfs: 1 },
      yfje: 0, //已付金额
      selectArr: [],
    });
    onMounted(() => {
      onLoadGys();
      onLoadLb1();
    });

    //加载供应商下拉数据
    const onLoadGys = () => {
      gongyingshang_select().then((res) => {
        if (res.status == 200) state.gysData = res.data;
      });
    };

    //加载采购类别
    const onLoadLb1 = () => {
      zidian_select("采购类别").then((res) => {
        if (res.status == 200) {
          state.lb1Data = res.data;
        }
      });
    };

    //采购类别1改变事件
    const onLoadLb2 = () => {
      if (!state.params.Params.Lb1Id) {
        delete state.params.Params.Lb2Id;
        delete state.lb2Data;
        return false;
      }
      zidian_select(
        state.lb1Data.find((w) => w.Id == state.params.Params.Lb1Id)?.Mc
      ).then((res) => {
        if (res.status == 200) {
          state.lb2Data = res.data;
        }
      });
    };

    const onLoad = () => {
      if (!state.params.Params.GysId) {
        ElMessage({
          type: "warning",
          message: "请选择供应商",
          grouping: true,
        });
        return false;
      }

      delete state.tableData;
      duizhangdan_lydd_cglist(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });

      onLoadGysZh();
    };

    const onLoadGysZh = () => {
      console.log(123);
      if (!state.params.Params.GysId) {
        ElMessage({
          type: "warning",
          message: "请选择供应商",
          grouping: true,
        });
        return false;
      }
      gongyingshangzhanghu_selectbygysid(state.params.Params.GysId).then(
        (res) => {
          if (res.status == 200) state.zhData = res.data;
        }
      );
    };

    const onSubmit = () => {
      const mxs = state.selectArr.map((m) => {
        return { Id: m.Id, Yfje: m.Qk, Je: m.Fkje };
      });
      if (mxs.length <= 0) {
        ElMessage.warning("请选择订单!");
        return false;
      }

      if (!state.model.Fkfs) {
        ElMessage.warning("请选择付款方式!");
        return false;
      }

      if (!state.model.GysZh) {
        ElMessage.warning("请选择到账银行!");
        return false;
      }

      const data = {
        GysId: state.params.Params.GysId,
        Fkfs: state.model.Fkfs,
        Dzyh: state.model.GysZh.Khyh,
        Dzkh: state.model.GysZh.Kh,
        Skr: state.model.GysZh.Skr,
        Zje: state.yfje,
        Bz: state.model.Bz,
        LyDdCgs: mxs,
      };
      duizhangdan_add(data).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
        }
      });
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    const onSelect = (row, checked) => {
      row.IsXz = true;
      if (checked) {
        state.selectArr.push(row);
      } else {
        const index = state.selectArr.findIndex((w) => w.Id == row.Id);
        state.selectArr.splice(index, 1);
      }
      onBuildModel();

      // row.IsXz = true;
      // state.model.Sl += 1;
      // state.model.Zje += row.Zje;
      // state.model.Fl += row.Fl || 0;
      // state.model.Yfje;
    };
    const onBuildModel = () => {
      state.yfje = 0;

      state.selectArr.forEach((e) => {
        console.log(e);
        state.yfje += e.Qk;
      });
    };

    const onBuildTotal = () => {
      let totalCount = 0,
        totalJszj = 0,
        totalFlje = 0,
        totalYfje = 0;
      state.tableData?.forEach((e, index) => {
        if (e.IsXz) {
          totalCount += index;
          totalJszj += e.Jszj;
          totalFlje += e.Fl;
          totalYfje += e.Yfje;
        }
      });

      return {
        totalCount,
        totalJszj,
        totalFlje,
        totalYfje,
      };
    };

    return {
      state,
      onLoad,
      onReset,
      onBuildTotal,
      onSubmit,
      onLoadGysZh,
      onLoadGys,
      onLoadLb2,
      onSelect,
    };
  },
};
</script>
<style scoped></style>
