<template>
  <div>
    <ul
      style="display: flex; align-items: center; margin-bottom: 10px"
      v-if="!props.search"
    >
      <el-upload
        v-model:file-list="state.files"
        multiple
        :auto-upload="false"
        :on-change="onFileChange"
        :show-file-list="false"
      >
        <el-button type="primary">上传文件</el-button>
      </el-upload>
      <!-- <el-button type="success" @click="onSubmit" style="margin-left: 10px">
        立即提交
      </el-button> -->
    </ul>
    <el-table :data="state.tabData">
      <el-table-column type="index" label="#" />
      <el-table-column prop="Mc" label="名称" />
      <el-table-column prop="Dz" label="路径" :formatter="$tab_TextFormat" />
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="{ row }">
          <el-button
            type="success"
            :icon="Download"
            link
            @click="onDownLoad(row)"
            v-if="row.Id"
          >
            下载
          </el-button>
          <el-button
            type="danger"
            :icon="Delete"
            link
            @click="onDelete(row.Id)"
            v-if="!props.search"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  lvyoudingdan_getfj,
  lvyoudingdan_addfj,
  lvyoudingdan_delfj,
} from "@/http/api";
import $http from "@/http/index";
import { ElMessage } from "element-plus";
import { confirm_func } from "@/util/tFormat";
export default {
  props: {
    id: Number,
    search: Boolean,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_getfj(props.id).then((res) => {
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    };

    const onDownLoad = (item) => {
      $http
        .get(item.Dz, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.Mc + "." + item.Hz);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          ElMessage.error("文件下载失败！");
        });
    };

    const onFileChange = (file, files) => {
      let formData = new FormData();

      files.forEach((file) => {
        formData.append("files", file.raw);
      });

      lvyoudingdan_addfj(props.id, formData).then((res) => {
        // ElMessageBox.confirm(res.data, "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   dangerouslyUseHTMLString: true,
        //   type: "warning",
        // }).then(() => {
        //   state.files = [];
        //   onLoad();
        // });
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
        }
      });

      // let arr = JSON.parse(JSON.stringify(tabSource));

      // files.forEach((e) => {
      //   let item = {};
      //   item.Mc = e.Mc = e.name.substring(0, e.name.lastIndexOf("."));
      //   item.Hz = e.name.substring(e.name.lastIndexOf(".") + 1);
      //   arr.push(item);
      // });
      // state.tabData = arr;
    };

    // const onSubmit = () => {
    //   let formData = new FormData();

    //   state.files.forEach((file) => {
    //     formData.append("files", file.raw);
    //   });

    //   lvyoudingdan_addfj(props.id, formData).then((res) => {
    //     ElMessageBox.confirm(res.data, "提示", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       dangerouslyUseHTMLString: true,
    //       type: "warning",
    //     }).then(() => {
    //       state.files = [];
    //       onLoad();
    //     });
    //   });
    // };

    //删除
    const onDelete = (id) => {
      confirm_func(lvyoudingdan_delfj, id, onLoad);
    };

    return {
      state,
      onDownLoad,
      onDelete,
      onFileChange,
      props,
    };
  },
};
</script>
<style scoped></style>
