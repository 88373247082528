<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <el-image
      v-if="!state.model.Je"
      :src="require('@/assets/images/loading.gif')"
      style="margin: auto; margin-top: 15vh"
    ></el-image>
    <template v-else>
      <!-- <div style="display: flex; align-items: center; padding: 10px">
        <c_money :value="state.value" size="30" style="margin: 0 auto" />
      </div> -->
      <div style="padding: 20px; font-size: 14px; color: rgba(0, 0, 0, 0.8)">
        <ul style="display: flex">
          <el-image
            :src="require('@/assets/images/logo.png')"
            style="margin: auto"
          ></el-image>
        </ul>
        <ul
          style="
            margin-top: 20px;
            padding: 15px 0;
            border-bottom: 1px solid #f7f7f7;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          收款单位：肥西县灵西文化科技有限公司
        </ul>
        <ul
          style="
            padding: 15px 0;
            border-bottom: 1px solid #f7f7f7;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          收款金额：{{
            $priceFormat(state.model.Je)
          }}元
        </ul>
        <ul
          style="
            padding: 15px 0;
            border-bottom: 1px solid #f7f7f7;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          收款业务订单号：{{
            state.model?.Bh
          }}
        </ul>
        <ul
          style="
            padding: 15px 0;
            border-bottom: 1px solid #f7f7f7;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          收款人：{{
            state.model?.CjrXm
          }}
        </ul>
        <ul
          style="
            padding: 15px 0;
            border-bottom: 1px solid #f7f7f7;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          收款摘要：{{
            state.model?.Mc
          }}
        </ul>
      </div>
      <div style="margin: 20px">
        <el-button
          type="success"
          v-if="props.type == 'T_JSAPI'"
          style="width: 100%"
          size="large"
          @click="onSubmit"
        >
          立即支付￥{{ $priceFormat(state.model.Je) }}
        </el-button>
        <el-button
          type="primary"
          v-else-if="props.type == 'A_JSAPI'"
          style="width: 100%"
          size="large"
          @click="onSubmit"
        >
          立即支付￥{{ $priceFormat(state.model.Je) }}
        </el-button>
        <!-- <el-button
          type="danger"
          v-else
          style="width: 100%"
          size="large"
          @click="onSubmit"
        >
          支付￥{{ (state.model?.Xsj || 0).toFixed(2) }}
        </el-button> -->
        <!-- <iframe :src="state.payUrl" style="width: 50vw; height: 50vh"></iframe> -->
      </div>
    </template>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { huifu_lydd_detail } from "@/http/api";

export default {
  props: {
    id: Number,
    type: String,
  },
  setup(props, { emit }) {
    console.log(props);
    const state = reactive({
      model: {},
    });
    onMounted(() => {
      document.title = "灵西文化科技有限公司";
      if (props.id) {
        huifu_lydd_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            console.log(state.model);
          }
        });
      }
    });

    const onSubmit = () => {
      //   huifu_create(props.type, { ddId: state.model.Id }).then((res) => {
      //       if (res.status == 200)
      //       {
      //           if (props.type == "A_JSAPI")
      //           {
      //             window.location.href = res.data;
      //           } else {

      //         }
      //     }
      //   });
      emit("onSubmit", { ddId: props.id });

      //   huifu_create("123", { type: state.type }).then((res) => {
      //     // state.payUrl = res.data;
      //     window.location.href = res.data;
      //     // const iframe = document.createElement("iframe");
      //     // iframe.style.display = "none";
      //     // iframe.setAttribute("src", res.data);
      //     // iframe.setAttribute(
      //     //   "sandbox",
      //     //   "allow-top-navigation-by-user-activation allow-scripts"
      //     // );
      //     // iframe.onload = function () {
      //     //   // 4秒后删除 iframe，否则会跳回上一个页面
      //     //   setTimeout(() => {
      //     //     document.body.removeChild(iframe);
      //     //   }, 4000);
      //     // };

      //     // document.body.appendChild(iframe);

      //     // var iframe = document.createElement("iframe");
      //     // iframe.src = res.data;
      //     // iframe.sandbox = "allow-scripts allow-same-origin";
      //     // document.body.appendChild(iframe);
      //     // console.log(123);
      //   });
    };
    return { state, props, onSubmit, emit };
  },
};
</script>
<style scoped></style>
