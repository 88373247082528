<template>
  <div>
    <el-button
      type="primary"
      icon="Plus"
      link
      style="margin-bottom: 10px"
      @click="
        delete state.selectId;
        state.showTc = true;
      "
    >
      添加套餐
    </el-button>
    <el-table :data="state.tcData">
      <el-table-column fixed label="#" width="50" type="index" align="center" />
      <el-table-column prop="Mc" label="套餐名称" min-width="120" />
      <el-table-column prop="Sykc" label="剩余库存" width="90">
        <template #default="{ row }">
          <text v-if="row.Sykc == -1">不限库存</text>
          <text v-else>{{ row.Sykc + row.Dw }}</text>
        </template>
      </el-table-column>
      <el-table-column prop="Yssl" label="已售数量" width="90">
        <template #default="{ row }">
          <text>{{ row.Yssl + row.Dw }}</text>
        </template>
      </el-table-column>
      <el-table-column
        prop="Ysje"
        label="已售金额(元)"
        :formatter="$tab_MoneyFormat"
        width="120"
      />
      <el-table-column
        prop="Yfje"
        label="已付金额(元)"
        :formatter="$tab_MoneyFormat"
        width="120"
      />
      <el-table-column prop="Mds" label="名单" min-width="120">
        <template #default="{ row }">
          <span v-if="row.IsMd">
            需要
            <text style="color: #080; font-weight: 600">{{ row.Mds }}</text>
            个名单
          </span>
          <text v-else style="color: #f00">不需要名单</text>
        </template>
      </el-table-column>
      <el-table-column
        prop="Sm"
        label="套餐说明"
        min-width="200"
        :formatter="$tab_TextFormat"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        width="250"
        align="center"
        fixed="right"
        className="tabBtn"
      >
        <template #default="{ row }">
          <el-button
            type="primary"
            :icon="Edit"
            link
            @click="onOpenCompile(row.Id)"
            >编辑
          </el-button>
          <!-- <el-button
            type="primary"
            :icon="Plus"
            link
            @click="onChangeKu(row.Id, row.Sykc)"
          >
            调整库存
          </el-button> -->
          <el-button type="danger" :icon="Delete" link @click="onDelete(row.Id)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="anLeft"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
      "
      v-if="state.showChange"
    >
      <div
        style="
          width: 50vw;
          max-height: 80vh;
          background-color: #fff;
          border-radius: 3px;
          padding: 10px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        "
      >
        <ul
          style="
            padding-bottom: 10px;
            border-bottom: 1px solid #e2e2e2;
            display: flex;
            align-items: center;
          "
        >
          <li style="flex: 1">变更库存</li>
          <el-icon
            :size="16"
            color="#a2a2a2"
            class="c-icon"
            style="cursor: pointer"
            @click="state.showGwc = false"
          >
            <CloseBold />
          </el-icon>
        </ul>
        <ul style="padding: 20px">
          <li>原库存：{{ state.selectItem?.Ykc }}</li>
          <li style="margin-top: 30px">变更数：{{ state.selectItem?.Kc }}</li>
        </ul>
      </div>
    </div>
  </div>
  <transition name="el-zoom-in-center">
    <c_popup
      v-if="state.showTc"
      :title="state.selectId ? '编辑套餐' : '添加套餐'"
      @close="state.showTc = false"
    >
      <c_ShangPinTcCompile
        @close="onConfirm"
        :id="state.selectId"
        :spId="props.spId"
      ></c_ShangPinTcCompile>
    </c_popup>
  </transition>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  shangpintaocan_all,
  shangpintaocan_delete,
  shangpintaocan_changekc,
} from "@/http/api";
import { confirm_func } from "@/util/tFormat";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  props: {
    spId: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      selectItem: {},
    });
    onMounted(() => {
      onLoad();
    });

    //加载套餐数据
    const onLoad = () => {
      shangpintaocan_all(props.spId).then((res) => {
        if (res.status == 200) {
          state.tcData = res.data;
        }
      });
    };

    //删除套餐
    const onDelete = (id) => {
      confirm_func(shangpintaocan_delete, id, onLoad);
    };

    const onChangeKu = (id) => {
      ElMessageBox.prompt(
        "请输入您需要调整的库存数，支持负数<br /><strong style='color:#f00'>调整到-1时,则不限制库存</strong>",
        "调整库存",
        {
          confirmButtonText: "提交",
          cancelButtonText: "取消",
          inputType: "number",
          dangerouslyUseHTMLString: true,
        }
      ).then(({ value }) => {
        shangpintaocan_changekc(id, value).then((res) => {
          if (res.status == 200) {
            ElMessage.success(res.data);
            onLoad();
          }
        });
      });
    };

    //打开编辑框
    const onOpenCompile = (id) => {
      state.selectId = id;
      state.showTc = true;
    };

    const onConfirm = () => {
      onLoad();
      state.showTc = false;
      emit("onLoad");
    };

    return {
      state,
      onDelete,
      onChangeKu,
      props,
      onLoad,
      onOpenCompile,
      onConfirm,
    };
  },
};
</script>
<style scoped></style>
