<template>
  <el-table
    :data="state.tableData"
    v-loading="!Array.isArray(state.tableData)"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <el-table-column label="#" type="index"></el-table-column>
    <el-table-column prop="Lx" label="操作事件"></el-table-column>
    <el-table-column prop="Nr" label="操作内容"></el-table-column>
    <el-table-column prop="CjrXm" label="操作人"></el-table-column>
    <el-table-column prop="Cjsj" label="操作时间"></el-table-column>
  </el-table>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_rz_list } from "@/http/api";
export default {
  props: {
    id: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      lvyoudingdan_rz_list(props.id).then((res) => {
        if (res.status == 200) state.tableData = res.data;
      });
    });
    return { state };
  },
};
</script>
<style scoped></style>
