<template>
  <el-date-picker
    v-model="state.value"
    type="date"
    placeholder="--出生日期--"
    format="YYYY-MM-DD"
    value-format="YYYY-MM-DD"
    :disabled-date="props.disabled"
    @change="onChange()"
  />
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  props: {
    value: Object,
    disabled: Function,
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {
      state.value = props.value;
    });

    const onChange = () => {
      emit("Value-Change", state.value);
    };
    return { state, onChange, props };
  },
};
</script>
<style lang="less">
.numberLeft {
  & .el-input__inner {
    text-align: left !important;
  }
}

.com_number_box {
  .el-input__wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
</style>
