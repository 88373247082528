import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//加载动画样式
import "animate.css";

const app = createApp(App);

//全局配置组件
import { ElTable, ElPagination, ElSelect, ElInput } from "element-plus";
ElInput.emits.focus = (e) => {
  e.target.select();
};

// ElTable.props.size = { type: String, default: "small" }; //设置列表尺寸
ElTable.props.className = { type: String, default: "tabCss" }; //设置列表样式
ElTable.props.headerCellClassName = { type: String, default: "tabHeader" }; //设置列表表头样式
ElTable.props.size = { type: String, default: "large" }; //设置分页控件尺寸
// ElTable.props.border = { type: Boolean, default: true }; //设置列表边框

ElPagination.props.small = { type: Boolean, default: true }; //设置分页控件尺寸
ElPagination.props.background = { type: Boolean, default: true }; //设置分页控件背景
ElPagination.props.layout = {
  type: String,
  default: "total, prev, pager, next",
}; //设置分页控件背景

ElSelect.props.filterable = { type: Boolean, default: true }; //设置下拉框可输入筛选
ElSelect.props.clearable = { type: Boolean, default: true }; //设置可清空

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

//#region 进度条配置
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({
  showSpinner: false,
});
//#endregion

//配置element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.config.globalProperties[key] = key;
  app.component(key, component);
}
//#endregion

//#region 路由拦截
router.beforeEach((to, from, next) => {
  // if (store.state.include.includes(to.path)) {
  //   to.meta.keepAlive = true;
  // } else {
  //   to.meta.keepAlive = false;
  // }
  if (
    to.path === "/login" ||
    to.name === "HuiFu" ||
    to.name === "WeChat" ||
    to.name === "HuiFuLyddPay" ||
    to.name === "HuiFuLyddPayWx"
  ) {
    next();
  } else {
    let token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  }
});

router.afterEach(() => {});
//#endregion

//#region 注入弹出框模板
import c_popup from "@/components/Popup.vue";
app.component("c_popup", c_popup);
//#endregion

//#region 注入分页控件模板
import c_page from "@/components/Page.vue";
app.component("c_page", c_page);
//#endregion

//#region 注入金额显示控件模板
import c_money from "@/components/Money.vue";
app.component("c_money", c_money);
//#endregion

//#region 注入状态控件模板
import c_status from "@/components/Status.vue";
app.component("c_status", c_status);
//#endregion

//#region 注入富文本编辑器模板
import c_editor from "@/components/WangEditor.vue";
app.component("c_editor", c_editor);
//#endregion

//#region 注入数字文本框模板
import c_inputnumber from "@/components/InputNumber.vue";
app.component("c_inputnumber", c_inputnumber);
//#endregion

//#region 全局方法
app.config.globalProperties.$store = store;

import dayjs from "dayjs";
app.config.globalProperties.$dayjs = dayjs;

import {
  tab_Highlinght,
  tab_StatusFormat,
  post_response,
  common_func,
  confirm_func,
  tab_TextFormat,
  tab_GroupFormat,
  tab_MoneyFormat,
  priceFormat,
  numToPercentFormat,
} from "@/util/tFormat";
app.config.globalProperties.$tab_Highlinght = tab_Highlinght;
app.config.globalProperties.$tab_StatusFormat = tab_StatusFormat;
app.config.globalProperties.$tab_TextFormat = tab_TextFormat;
app.config.globalProperties.$tab_MoneyFormat = tab_MoneyFormat;
app.config.globalProperties.$tab_GroupFormat = tab_GroupFormat;
app.config.globalProperties.$post_response = post_response;
app.config.globalProperties.$common_func = common_func;
app.config.globalProperties.$confirm_func = confirm_func;
app.config.globalProperties.$priceFormat = priceFormat;
app.config.globalProperties.$numToPercentFormat = numToPercentFormat;
app.config.globalProperties.$focus = (e) => {
  e.target.select();
};
//#endregion

import http from "@/http";
app.config.globalProperties.$http = http;

// //#region 注册全局组件
// import c_main from "./views/Home/Main.vue";
// app.component("c_main", c_main);

// import c_yh from "./views/YongHu/List.vue";
// app.component("c_yh", c_yh);

// import c_js from "./views/JueSe/List.vue";
// app.component("c_js", c_js);

// import c_bm from "./views/BuMen/List.vue";
// app.component("c_bm", c_bm);
// //#endregion

// const changeStr = (str) => {
//   return str.charAt(0).toUpperCase() + str.slice(1);
// };

const requireComponent = require.context("./views", true, /\.vue$/);
requireComponent.keys().forEach((fileName) => {
  // 当前组件
  const config = requireComponent(fileName);
  //获取组件名
  const componentName =
    "c_" +
    fileName
      .replace(/^\.\//, "")
      .replace(/\.\w+$/, "")
      .replace("/", ""); // 第一个replace(/^\.\//, '')去掉前面的./   第二个replace(/\.W+$/)是去掉后面的.vue
  // 参数一： 组件名
  // 参数二： config：是一整个组件的内容；  config.default：是组件中export.default里面的内容
  app.component(componentName, config.default || config);
});

app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .mount("#app");
