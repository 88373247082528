<template>
  <div class="listBox" ref="boxDom">
    <div
      style="
        font: 600 14px/14px '';
        color: rgb(50, 50, 50);
        margin-bottom: 10px;
      "
    >
      基本信息
    </div>
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item label="状态" label-align="right">
        <template #label>
          <div style="width: 5vw">状态</div>
        </template>
        <template #default>
          <div style="width: 23vw">
            <c_status :model="state.model?.Zt"></c_status>
          </div>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="编号" label-align="right">
        <template #label>
          <div style="width: 5vw">编号</div>
        </template>
        <template #default>
          <div style="width: 23vw">
            {{ state.model.Bh }}
          </div>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="到账方式" label-align="right">
        <template #label>
          <div style="width: 5vw">到账方式</div>
        </template>
        <template #default>
          <div style="width: 23vw">
            <c_status :model="state.model?.Zzfs"></c_status>
          </div>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="到账银行" label-align="right">
        {{ state.model.Dzyh }}
      </el-descriptions-item>
      <el-descriptions-item label="付款人" label-align="right">
        {{ state.model.Fkr || "-" }}
      </el-descriptions-item>
      <el-descriptions-item label="付款银行" label-align="right">
        {{ state.model.Fkyh || "-" }}
      </el-descriptions-item>
      <el-descriptions-item label="付款账号" label-align="right">
        {{ state.model.Fkzh || "-" }}
      </el-descriptions-item>
      <el-descriptions-item label="付款流水号" label-align="right">
        {{ state.model.Fklsh || "-" }}
      </el-descriptions-item>
      <el-descriptions-item label="到账金额" label-align="right">
        <c_money :value="state.model.Dzje" :size="18"></c_money>
      </el-descriptions-item>
      <el-descriptions-item label="手续费" label-align="right">
        {{ $priceFormat(state.model.Sxf) }}
      </el-descriptions-item>
      <el-descriptions-item label="创建人" label-align="right">
        {{ state.model.CjrXm }}
      </el-descriptions-item>
      <el-descriptions-item label="创建时间" label-align="right">
        {{ state.model.Cjsj }}
      </el-descriptions-item>
    </el-descriptions>
    <template v-if="state.model.RlrXm">
      <div
        style="font: 600 14px/14px ''; color: rgb(50, 50, 50); margin: 10px 0"
      >
        认领信息
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item label="到账客户" label-align="right">
          <template #label>
            <div style="width: 5vw">到账客户</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.KhMc }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">客户联系人</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.KhLxr || "-" }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">客户联系方式</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.KhSjhm || "-" }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">认领人</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.RlrXm || "-" }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">认领时间</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.Rlsj || "-" }}
            </div>
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </template>
    <template v-if="state.model.Zt?.Key == 2">
      <div
        style="font: 600 14px/14px ''; color: rgb(50, 50, 50); margin: 10px 0"
      >
        撤回信息
      </div>
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">撤回操作人</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.ChrXm }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">撤回时间</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.Chsj || "-" }}
            </div>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
          <template #label>
            <div style="width: 5vw">撤回说明</div>
          </template>
          <template #default>
            <div style="width: 23vw">
              {{ state.model.Chsm || "-" }}
            </div>
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </template>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { daozhanggongshi_detail } from "@/http/api";
export default {
  props: {
    id: Number,
  },
  setup(props) {
    const state = reactive({
      model: {},
    });
    onMounted(() => {
      daozhanggongshi_detail(props.id).then((res) => {
        if (res.status == 200) state.model = res.data;
        console.log(res.data);
      });
    });
    return { state };
  },
};
</script>
<style lang="less" scoped>
.infoBox {
  .title {
    width: 80px;
  }
}
</style>
