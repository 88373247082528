l
<template>
  <div class="detailBox" style="position: relative">
    <div class="detailContent lvddDetail">
      <li
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
        "
      >
        退款单信息
      </li>
      <el-descriptions class="tk_desc" :column="3" border>
        <el-descriptions-item
          label="状态"
          label-align="right"
          label-class-name="desc-item-label"
        >
          <c_status :model="state.item.Zt"></c_status>
        </el-descriptions-item>
        <el-descriptions-item
          label="退款单号"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Bh }}
        </el-descriptions-item>
        <el-descriptions-item
          label="退款金额"
          label-align="right"
          label-class-name="desc-item-label"
        >
          <c_money
            :value="state.model.Je"
            :size="20"
            style="font-weight: 600"
          />
        </el-descriptions-item>
        <el-descriptions-item
          label="退款备注"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Bz }}
        </el-descriptions-item>
        <el-descriptions-item
          label="创建人"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.CjrXm }}
        </el-descriptions-item>
        <el-descriptions-item
          label="创建时间"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.model.Cjsj }}
        </el-descriptions-item>
      </el-descriptions>
      <li
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 5px;
        "
      >
        订单信息
      </li>
      <el-descriptions class="tk_desc" :column="3" border>
        <el-descriptions-item
          label="订单号"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.DdBh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="订单名称"
          label-align="right"
          label-class-name="desc-item-label"
          :span="2"
        >
          {{ state.item.DdMc || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户编号"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.KhBh || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户名称"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.KhMc || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户联系方式"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ state.item.KhSjhm || "-" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="订单总额"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ $priceFormat(state.item.Zje) }}
        </el-descriptions-item>
        <el-descriptions-item
          label="已收金额"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ $priceFormat(state.item.Ysje) }}
        </el-descriptions-item>
        <el-descriptions-item
          label="客户欠款"
          label-align="right"
          label-class-name="desc-item-label"
        >
          {{ $priceFormat(state.item.Qkje) }}
        </el-descriptions-item>
      </el-descriptions>
      <li
        style="
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 5px;
        "
      >
        审核信息
      </li>
      <el-form
        :model="state.checkModel"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        :rules="rules"
        inline
        :inline-message="true"
      >
        <el-descriptions class="tk_desc" :column="2" border>
          <el-descriptions-item
            label="审核状态"
            label-align="right"
            label-class-name="desc-item-label"
          >
            <el-form-item
              label=""
              prop="Zt"
              :rules="[
                { required: true, message: '请选择审核状态', trigger: 'blur' },
              ]"
              style="display: flex; align-items: center; flex: 1; margin: 0"
            >
              <el-radio-group v-model="state.checkModel.Zt">
                <el-radio-button :label="true">通过</el-radio-button>
                <el-radio-button :label="false">不通过</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item
            label="退款方式"
            label-align="right"
            label-class-name="desc-item-label"
          >
            <el-form-item
              label=""
              prop="Tkfs"
              :rules="[
                {
                  required: state.checkModel.Zt == true,
                  message: '请选择退款方式',
                  trigger: 'blur',
                },
              ]"
              style="display: flex; align-items: center; flex: 1; margin: 0"
            >
              <el-radio-group v-model="state.checkModel.Tkfs">
                <el-radio-button :label="1">退回账户</el-radio-button>
                <el-radio-button :label="2">线下退回</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item
            label="审核备注"
            label-align="right"
            label-class-name="desc-item-label"
            :span="2"
          >
            <el-form-item
              label=""
              prop="Bz"
              :rules="[
                {
                  required: state.checkModel.Zt == false,
                  message: '请填写审核备注',
                  trigger: 'blur',
                },
              ]"
              style="width: 100%; margin: 0"
            >
              <el-input
                type="textarea"
                v-model="state.checkModel.Bz"
                :autosize="{ minRows: 2 }"
              />
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
    </div>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { lvyoudingdan_getskdetail, lvyoudingdan_checksk } from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
  },

  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {},
      item: {},
      checkModel: {},
    });
    onMounted(() => {
      console.log(formDom);
      lvyoudingdan_getskdetail(props.id).then((res) => {
        if (res.status == 200) {
          const { model, item } = res.data;
          state.model = model;
          state.item = item;
        }
      });
    });

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          lvyoudingdan_checksk(props.id, state.checkModel).then((res) => {
            if (res.status == 200) {
              ElMessage.success(res.data);
              setTimeout(() => {
                emit("Close");
              }, 500);
            }
          });
        }
      });
    };

    return {
      state,
      formDom,
      onSubmit,
    };
  },
};
</script>
<style lang="less">
.desc-item-label {
  width: 200px;
}

.tk_desc {
  .el-descriptions__table {
    table-layout: fixed;
  }
}
</style>
