<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li style="display: flex; align-items: center">
        <el-button type="primary" icon="Plus" link @click="onOpenCompile()">
          添加
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          align="center"
          width="80"
        >
          <template #default="scope">
            <text :style="{ color: scope.row.IsQy ? '#080' : '#f00' }">
              {{ scope.row.IsQy ? "启用" : "停用" }}
            </text>
          </template>
        </el-table-column>
        <el-table-column
          prop="Xm"
          sortable="Xm"
          label="姓名"
          min-width="120"
          :formatter="
            (row, column, cellValue) =>
              $tab_Highlinght(cellValue, state.params.Params.Key)
          "
        />
        <el-table-column
          prop="Xb"
          label="性别"
          :formatter="$tab_StatusFormat"
        />
        <el-table-column prop="GysMc" label="所属供应商" min-width="200" />
        <el-table-column prop="Sjhm" label="手机号码" min-width="140" />
        <el-table-column
          prop="IsAdmin"
          label="是否管理员"
          align="center"
          width="120"
        >
          <template #default="scope">
            <text :style="{ color: scope.row.IsAdmin ? '#080' : '#f00' }">
              {{ scope.row.IsAdmin ? "是" : "否" }}
            </text>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="280"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="scope">
            <el-button
              type="primary"
              :icon="Edit"
              link
              @click="onOpenCompile(scope.row.Id)"
            >
              编辑
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(scope.row.Id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="state.compileTitle + '用户'"
        @close="onCloseCompile(false)"
      >
        <c_compile
          @close="onCloseCompile(true)"
          :id="state.selectId"
          :gysid="state.params?.Params?.GysId"
        ></c_compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  gongyingshangyonghu_list,
  gongyingshangyonghu_delete,
} from "@/http/api";
import { ElMessageBox, ElMessage } from "element-plus";

import c_compile from "./YhCompile.vue";
export default {
  props: {
    gysId: Number,
  },
  components: {
    c_compile,
  },
  setup(props) {
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      state.params.Params.GysId = parseInt(props.gysId);
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      gongyingshangyonghu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //打开内容页
    const onOpenCompile = (id) => {
      state.compileTitle = id ? "修改" : "添加";
      state.selectId = id;
      state.showCompile = true;
    };

    //关闭内容页
    const onCloseCompile = (refresh) => {
      state.showCompile = false;
      if (refresh) {
        onLoad();
      }
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      state.params.PageIndex = 1;
      state.params.PageSize = 20;
      state.params.Params = {};
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      ElMessageBox.confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        gongyingshangyonghu_delete(id).then((res) => {
          if (res.status == 200) {
            ElMessageBox.alert("删除成功", "提示", {
              type: "success",
              callback: () => {
                onLoad();
              },
            });
          } else {
            ElMessage({
              message: res.data,
              type: "error",
            });
          }
        });
      });
    };

    const onShowAuthorize = (id) => {
      state.key = Math.random();
      state.showAuthorize = true;
      state.selectId = id;
    };

    const onShowUser = (id) => {
      state.showUser = true;
      state.selectId = id;
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onCloseCompile,
      onOpenCompile,
      onDelete,
      onShowAuthorize,
      onShowUser,
    };
  },
};
</script>
<style>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
