<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      background-color: #f6f6f6;
      padding: 10px;
    "
  >
    <div style="display: flex">
      <ul class="firstBox" style="position: relative; flex: 1">
        <div
          style="
            position: absolute;
            left: 10px;
            top: 10px;
            display: flex;
            align-items: center;
            flex-direction: row;
          "
        >
          <text style="font-size: 12px; font-weight: 600">
            商品订单销售统计
          </text>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="仅统计《非已取消》的订单"
            placement="top-start"
          >
            <el-icon color="#a2a2a2"><QuestionFilled /></el-icon>
          </el-tooltip>
        </div>
        <div>
          <ul class="content" style="color: #ffa500">
            {{
              state.tj?.ljje || 0
            }}<text style="font-size: 12px">￥</text>
          </ul>
          <ul class="title">
            累计交易金额
          </ul>
        </div>
        <div>
          <ul class="content">
            {{
              state.tj?.ljs || 0
            }}
          </ul>
          <ul class="title">
            累计订单数
          </ul>
        </div>
        <div>
          <ul class="content" style="color: #ffa500">
            {{
              state.tj?.jrje || 0
            }}<text style="font-size: 12px">￥</text>
          </ul>
          <ul class="title">
            今日销售金额
          </ul>
        </div>
        <div>
          <ul class="content">
            {{
              state.tj?.jrs || 0
            }}
          </ul>
          <ul class="title">
            今日订单数
          </ul>
        </div>
      </ul>
      <ul
        class="firstBox"
        style="position: relative; flex: 1; margin-left: 20px"
      >
        <div
          style="
            position: absolute;
            left: 10px;
            top: 10px;
            display: flex;
            align-items: center;
            flex-direction: row;
          "
        >
          <text style="font-size: 12px; font-weight: 600">
            旅游订单销售统计
          </text>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="仅统计《非已取消》的订单"
            placement="top-start"
          >
            <el-icon color="#a2a2a2"><QuestionFilled /></el-icon>
          </el-tooltip>
        </div>
        <div>
          <ul class="content" style="color: #ffa500">
            {{
              state.lytj?.ljje || 0
            }}<text style="font-size: 12px">￥</text>
          </ul>
          <ul class="title">
            累计交易金额
          </ul>
        </div>
        <div>
          <ul class="content">
            {{
              state.lytj?.ljs || 0
            }}
          </ul>
          <ul class="title">
            累计订单数
          </ul>
        </div>
        <div>
          <ul class="content" style="color: #ffa500">
            {{
              state.lytj?.jrje || 0
            }}<text style="font-size: 12px">￥</text>
          </ul>
          <ul class="title">
            今日销售金额
          </ul>
        </div>
        <div>
          <ul class="content">
            {{
              state.lytj?.jrs || 0
            }}
          </ul>
          <ul class="title">
            今日订单数
          </ul>
        </div>
      </ul>
    </div>
    <ul style="display: flex; margin-top: 10px; width: 100%; overflow: hidden">
      <div class="secondBox" style="margin-right: 20px; flex: 1">
        <ul class="title">
          产品排行榜（{{
            state.date
          }}）
        </ul>
        <el-table
          :data="state.spData"
          v-loading="!Array.isArray(state.spData)"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          />
          <el-table-column label="产品名称" prop="SpMc" />
          <el-table-column label="销售额" prop="Xse" />
          <el-table-column label="销售数" prop="Xss" />
          <el-table-column label="订单数" prop="Dds" />
        </el-table>
      </div>
      <div class="secondBox">
        <ul class="title">
          同行风云榜（{{
            state.date
          }}）
        </ul>
        <el-table :data="state.khData" v-loading="!Array.isArray(state.khData)">
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          />
          <el-table-column label="同行门店名称" prop="KhMc" />
          <el-table-column
            label="销售额"
            prop="Xse"
            :formatter="$tab_MoneyFormat"
          />
          <el-table-column label="销售数" prop="Xss" />
          <el-table-column label="订单数" prop="Dds" />
        </el-table>
      </div>
    </ul>
    <div class="secondBox" style="margin-top: 10px">
      <ul class="title">
        最新订单
      </ul>
      <el-table :data="state.ddData" v-loading="!Array.isArray(state.ddData)">
        <el-table-column type="index" label="序号" width="60" align="center" />
        <el-table-column label="订单编号" prop="Bh" />
        <el-table-column label="同行客户" prop="KhMc" />
        <el-table-column label="订单金额" prop="Xsj" />
        <el-table-column label="商品数量" prop="Sl" />
        <el-table-column label="下单时间" prop="Cjsj" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { home_tj, home_lytj, home_sp, home_kh, home_dd } from "@/http/api";
import dayjs from "dayjs";
export default {
  setup() {
    const state = reactive({
      tj: {},
      lytj: {},
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      state.date = dayjs().format("YYYY年M月");
      delete state.tj;
      home_tj().then((res) => {
        if (res.status == 200) state.tj = res.data;
      });

      home_lytj().then((res) => {
        if (res.status == 200) state.lytj = res.data;
      });

      delete state.spData;
      home_sp().then((res) => {
        if (res.status == 200) state.spData = res.data;
      });

      delete state.khData;
      home_kh().then((res) => {
        if (res.status == 200) state.khData = res.data;
      });

      delete state.ddData;
      home_dd().then((res) => {
        if (res.status == 200) state.ddData = res.data;
      });
    };

    //重置查询条件
    const onReset = () => {
      onLoad();
    };

    return { state, onReset };
  },
};
</script>
<style scoped lang="less">
.firstBox {
  width: 100%;
  padding: 30px;
  background-color: #fff;
  border-radius: 7px;
  display: flex;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  & .content {
    font-size: 28px;
    font-weight: 600;
  }

  & .title {
    font-size: 14px;
    color: #a2a2a2;
    margin-top: 10px;
  }
}

.secondBox {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;

  & > .title {
    width: 100%;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
