<template>
  <el-input
    v-model="state.value"
    :placeholder="props.placeholder"
    @change="onChange()"
  >
    <template #prepend v-if="props.prepend">{{ props.prepend }}</template>
    <template #append v-if="props.append">{{ props.append }}</template>
  </el-input>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  props: {
    value: Object,
    placeholder: String,
    prefixIcon: String,
    suffixIcon: String,
    prepend: String,
    append: String,
  },
  setup(props, { emit }) {
    const state = reactive({});
    onMounted(() => {
      state.value = props.value;
    });

    const onChange = () => {
      emit("Value-Change", state.value);
    };
    return { state, onChange, props };
  },
};
</script>
<style lang="less">
.numberLeft {
  & .el-input__inner {
    text-align: left !important;
  }
}

.com_number_box {
  .el-input__wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
</style>
