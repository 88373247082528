<template>
  <div class="treeListBox">
    <div class="lBox">
      <ul style="text-align: right; padding-right: 10px">
        <el-button
          link
          :icon="RefreshRight"
          @click="
            delete state.treeData;
            onLoadTree();
          "
        >
          刷新
        </el-button>
      </ul>
      <ul
        style="flex: 1; overflow: auto; padding-right: 10px"
        v-loading="!state.treeData"
      >
        <el-tree
          :data="state.treeData"
          :props="{ label: 'Mc', children: 'Xjs' }"
          accordion
          :highlight-current="true"
          :default-expand-all="true"
          node-key="Id"
          @node-click="onTreeClick"
          @node-contextmenu="onTreeRightClick"
          :expand-on-click-node="false"
          :current-node-key="state.params.Params.XjId"
        >
          <template #default="{ data }">
            <div
              style="display: flex; align-items: baseline"
              v-if="data.Type == 0"
            >
              <el-icon style="margin-right: 2px" size="12">
                <Avatar />
              </el-icon>
              {{ data.Mc }}
            </div>
            <div style="display: flex; align-items: baseline" v-else>
              <el-icon style="margin-right: 2px" size="12">
                <Avatar />
              </el-icon>
              {{ data.Mc }}
            </div>
          </template>
        </el-tree>
      </ul>
    </div>
    <div class="rBox">
      <ul class="sBox">
        <li style="flex: 1">
          <el-input
            v-model="state.params.Params.Key"
            style="width: 15%"
            placeholder="关键字"
            clearable
            @clear="
              delete state.params.Params.Key;
              onLoadTable();
            "
          ></el-input>
          <el-dropdown
            split-button
            @click="onLoadTable()"
            type="primary"
            style="margin-left: 10px"
          >
            查询
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                  重置
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li
          style="display: flex; align-items: center"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('B_YongHu_List_添加')
          "
        >
          <el-button type="primary" icon="Plus" link @click="onOpenCompile()">
            添加
          </el-button>
        </li>
      </ul>
      <ul class="tBox">
        <el-table
          :data="state.tableData"
          v-loading="!Array.isArray(state.tableData)"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          :default-sort="{
            prop: state.params.Order,
            order: state.params.Asc == false ? 'descending' : 'ascending',
          }"
          @sort-change="onSortChange"
        >
          <el-table-column
            fixed
            label="#"
            width="50"
            type="index"
            :index="
              (index) =>
                (state.params.PageIndex - 1) * state.params.PageSize + index + 1
            "
            align="center"
          />
          <el-table-column
            prop="IsQy"
            sortable="IsQy"
            label="状态"
            align="center"
            width="80"
          >
            <template #default="{ row }">
              <text :style="{ color: row.IsQy ? '#080' : '#f00' }">
                {{ row.IsQy ? "启用" : "停用" }}
              </text>
            </template>
          </el-table-column>
          <el-table-column
            prop="Xm"
            sortable="Xm"
            label="姓名"
            min-width="120"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
          <el-table-column
            prop="Sjhm"
            label="手机号码"
            min-width="140"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
          <el-table-column
            prop="Xb"
            label="性别"
            :formatter="$tab_StatusFormat"
          />
          <el-table-column prop="BmMc" label="所属部门" />
          <el-table-column
            prop="JsMc"
            label="角色"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Zw"
            label="职务"
            :formatter="$tab_TextFormat"
          />
          <el-table-column
            prop="Cjsj"
            sortable="Cjsj"
            label="创建信息"
            width="160"
            :formatter="(row) => $tab_GroupFormat(row.CjrXm, row.Cjsj)"
          />
          <el-table-column
            label="操作"
            width="260"
            align="center"
            fixed="right"
            className="tabBtn"
          >
            <template #default="{ row }">
              <el-button
                type="primary"
                :icon="Edit"
                link
                @click="onOpenCompile(row.Id)"
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes('B_YongHu_List_编辑')
                "
              >
                编辑
              </el-button>
              <el-button
                type="primary"
                :icon="RefreshRight"
                link
                @click="OnResetPass(row.Id, row.Xm)"
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes(
                    'B_YongHu_List_重置密码'
                  )
                "
              >
                重置密码
              </el-button>
              <el-button
                type="danger"
                :icon="Delete"
                link
                @click="onDelete(row.Id)"
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes('B_YongHu_List_删除')
                "
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </ul>
      <c_page
        :total="state.totalCount"
        :pageIndex="state.params.PageIndex"
        @onLoad="
          (pageIndex) => {
            state.params.PageIndex = pageIndex;
            onLoadTable();
          }
        "
      >
      </c_page>
    </div>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="state.compileTitle + '人员'"
        @close="onCloseCompile(false)"
      >
        <c_compile
          @close="onCloseCompile(true)"
          :id="state.selectId"
        ></c_compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import {
  yonghu_list,
  bumen_tree,
  yonghu_resetpass,
  yonghu_delete,
} from "@/http/api";

import c_compile from "./Compile.vue";
export default {
  components: {
    c_compile,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
    });

    onMounted(() => {
      onLoadTree();
      onLoadTable();
    });

    //加载部门树
    const onLoadTree = () => {
      bumen_tree(state.params.Params).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });
    };

    //加载表格
    const onLoadTable = () => {
      delete state.tableData;
      yonghu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //打开内容页
    const onOpenCompile = (id) => {
      state.compileTitle = id ? "修改" : "添加";
      state.selectId = id;
      state.showCompile = true;
    };

    //关闭内容页
    const onCloseCompile = (refresh) => {
      state.showCompile = false;
      if (refresh) {
        onLoadTable();
      }
    };

    //树节点点击事件
    const onTreeClick = (data) => {
      delete state.params.Params.BmId;
      state.params.Params.BmBm = data.Bm;
      onLoadTable();
    };

    //树节点右击事件
    const onTreeRightClick = (event, data) => {
      event.preventDefault();
      if (data.Id) {
        state.params.Params.BmId = data.Id;
        onLoadTable();
      }
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoadTable();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoadTree();
      onLoadTable();
    };

    //重置密码
    const OnResetPass = (id, xm) => {
      proxy.$confirm_func(
        yonghu_resetpass,
        id,
        onLoadTable,
        `确定重置用户<span style='color:#f00;font-size:15px'>${xm}</span>的密码?`
      );
    };

    //删除
    const onDelete = (id) => {
      proxy.$confirm_func(yonghu_delete, id, onLoadTable);
    };

    return {
      state,
      onLoadTree,
      onLoadTable,
      onSortChange,
      onReset,
      onCloseCompile,
      onOpenCompile,
      OnResetPass,
      onDelete,
      onTreeClick,
      onTreeRightClick,
    };
  },
};
</script>
<style scoped></style>
