<template>
  <div class="money">
    <text :style="{ fontSize: props.size - 4 + 'px' }">￥</text>
    <text :style="{ fontSize: props.size + 'px' }">
      {{ (props.value || 0)?.toFixed(2) }}
    </text>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 14,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>
<style lang="less" scoped>
.money {
  display: flex;
  width: fit-content;
  align-items: flex-end;
  color: #f00;
}
</style>
