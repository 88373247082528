<template>
  <div style="width: 100%; height: 100%; overflow-y: auto">
    <ul
      v-if="state.listData?.length <= 0"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #a2a2a2;
      "
    >
      <el-image :src="require('@/assets/images/nodata.png')"></el-image>

      暂无数据~
    </ul>
    <template v-for="item in state.listData" :key="item">
      <div
        style="
          box-shadow: 0 0 10px 1px #e2e2e2;
          margin: 10px;
          padding: 10px;
          border-radius: 5px;
          font-size: 14px;
        "
      >
        <ul style="display: flex; align-items: center">
          <li style="flex: 1">
            {{ item.Czsj }}
          </li>
        </ul>
        <ul
          style="
            display: flex;
            margin-top: 10px;
            box-shadow: 0 0 10px 1px #e2e2e2;
            padding: 10px;
            border-radius: 7px;
            display: flex;
            align-items: center;
          "
        >
          <li><text style="color: #a2a2a2">使用日期：</text>{{ item.Syrq }}</li>
          <li style="margin-left: 30px">{{ item.SpMc }}</li>
          <li
            style="
              color: #fff;
              background-color: #4881ff;
              width: fit-content;
              font-size: 12px;
              line-height: 10px;
              border-radius: 3px;
              margin-right: 10px;
              padding: 5px;
              margin-left: 30px;
            "
          >
            {{ item.SpPlMc }}
          </li>
          <li style="margin-left: 30px">{{ item.SpTcMc }}</li>
          <li style="display: flex; align-items: center; margin-left: 30px">
            <text style="color: #a2a2a2">合计：</text
            ><c_money :value="item.Ddze"></c_money>
          </li>
          <li style="display: flex; align-items: center; margin-left: 30px">
            <text style="color: #a2a2a2">已付：</text>
            <c_money :value="item.Yfje"></c_money>
          </li>
          <li
            style="
              display: flex;
              align-items: center;
              margin-left: 30px;
              color: #f00;
            "
          >
            <text style="color: #a2a2a2">本次支付：</text
            ><c_money :value="item.Zfje"></c_money>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpindingdan_getzfjl } from "@/http/api";
export default {
  props: {
    ddId: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onload();
    });

    const onload = () => {
      shangpindingdan_getzfjl(props.ddId).then((res) => {
        if (res.status == 200) state.listData = res.data;
      });
    };

    return { state };
  },
};
</script>
<style scoped></style>
