<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1; display: flex; align-items: center">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="编号、名称、客户名称、销售员、计调员"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-select
          v-model="state.params.Params.Zt"
          placeholder="--状态--"
          style="width: fit-content; margin: 0 10px"
        >
          <el-option
            v-for="item in state.ztData"
            :key="item"
            :label="item.Text"
            :value="item.Key"
          />
        </el-select>
        <el-date-picker
          v-model="state.params.Params.Ftsjs"
          type="daterange"
          start-placeholder="--发团开始时间--"
          end-placeholder="--发团截止时间--"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD 00:00:00"
          style="flex-grow: unset"
        />
        <!-- <el-radio-group
          v-model="state.params.Params.Zt"
          style="margin-left: 10px"
          @change="onLoad()"
        >
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button
            :label="item.Key"
            v-for="item in state.ztData"
            :key="item"
          >
            <text
              :style="{
                color: state.params.Params.Zt == item.Key ? '' : item.Color,
              }"
            >
              {{ item.Text }}</text
            >
          </el-radio-button>
        </el-radio-group> -->
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin: 0 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button type="success" :icon="Download" @click="onExport">
          导出
        </el-button>
      </li>
      <li
        style="display: flex; align-items: center"
        v-if="
          $store.state.loginUser?.IsAdmin ||
          $store.state.loginUser?.Qxs?.includes('B_LvYouDingDan_List_添加')
        "
      >
        <el-button
          type="primary"
          icon="Plus"
          link
          @click="
            $store.commit('openMenu', {
              path: 'LvYouDingDanAdd',
              title: '创建旅游订单',
              refresh: true,
            })
          "
        >
          添加
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          sortable="Bh"
          label="状态/编号"
          width="110"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />
        <el-table-column
          prop="Mc"
          sortable="Mc"
          label="名称/类型"
          min-width="200"
        >
          <template #default="{ row }">
            <div style="text-align: left">
              <ul>
                {{
                  row.Mc
                }}
              </ul>
              <ul style="font-size: 12px; line-height: 12px">
                {{
                  "[" + row.Lx?.Text + "]" + row.Ts + "天" + row.Ys + "晚"
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Ftsj"
          sortable="Ftsj"
          label="发团/回团"
          :formatter="
            (row) => $tab_GroupFormat(row.Ftsj || '-', row.Htsj || '')
          "
          width="120"
        />

        <el-table-column sortable="KhMc" label="客户" min-width="160">
          <template #default="{ row }">
            <div style="display: flex; flex-wrap: wrap">
              <text :title="row.KhMc">{{ row.KhMc }}</text>
              <text :style="{ color: row.KhLx?.Color }">
                {{ "[" + row.KhLx?.Text + "]" }}
              </text>
              <text style="color: #a2a2a2; font-size: 12px">
                {{ row.KhBh }}
              </text>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Zrs" sortable="Zrs" label="人数" width="80" />
        <el-table-column
          prop="Zje"
          sortable="Zje"
          label="金额"
          :formatter="$tab_MoneyFormat"
          width="80"
        />
        <el-table-column
          prop="Ysje"
          sortable="Ysje"
          label="已收"
          :formatter="$tab_MoneyFormat"
          width="80"
        />
        <el-table-column
          prop="Qkje"
          sortable="Qkje"
          label="欠款"
          :formatter="$tab_MoneyFormat"
          width="80"
        />
        <el-table-column
          label="计调"
          :formatter="(row) => $tab_GroupFormat(row.JdyXm, row.JdyBmMc)"
          width="140"
        />
        <el-table-column
          sortable="XsyXm"
          label="销售员"
          width="140"
          :formatter="(row) => $tab_GroupFormat(row.XsyXm, row.XsyBmMc)"
        />
        <el-table-column
          label="操作"
          width="160"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Search"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'LvYouDingDanSearch',
                  title: '旅游订单详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              >查看
            </el-button>
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'LvYouDingDanDetail',
                  title: '旅游订单详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes(
                  'B_LvYouDingDan_List_管理'
                )
              "
              >管理
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  lvyoudingdan_list,
  lvyoudingdan_getztselect,
  lvyoudingdan_export,
} from "@/http/api";
import { useRouter } from "vue-router";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: {
      Zt: null,
      TableName: "LvYouDingDan_List",
    },
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive(new InitFormData());

    onMounted(() => {
      lvyoudingdan_getztselect().then((res) => {
        if (res.status == 200) state.ztData = res.data;
      });

      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      lvyoudingdan_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = ({ column }) => {
      if (column.order) {
        state.params.Order = column.sortable;
        state.params.Asc = column.order === "ascending";
      } else {
        const { params } = new InitFormData();
        state.params.Order = params.Order;
        state.params.Asc = params.Asc;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    const onExport = () => {
      lvyoudingdan_export(state.params).then((res) => {
        if (res.status == 200) {
          console.log(res);
          //通过后台返回 的word文件流设置文件名并下载
          var blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = "旅游订单表.xlsx"; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
      onExport,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
