<template>
  <div class="treeListBox">
    <div class="lBox">
      <ul style="text-align: right; padding-right: 10px">
        <el-button
          link
          :icon="RefreshRight"
          @click="
            delete state.treeData;
            onLoadTree();
          "
        >
          刷新
        </el-button>
      </ul>
      <ul
        style="flex: 1; overflow: auto; padding-right: 10px"
        v-loading="!state.treeData"
      >
        <el-tree
          :data="state.treeData"
          :props="{ label: 'Mc', children: 'Xjs' }"
          accordion
          :highlight-current="true"
          :default-expand-all="true"
          node-key="Id"
          @node-click="onTreeClick"
          @node-contextmenu="onTreeRightClick"
          :expand-on-click-node="false"
          :current-node-key="state.params.Params.XjId"
        >
          <template #default="{ data }">
            <div
              style="display: flex; align-items: baseline"
              v-if="data.Type == 0"
            >
              <el-icon style="margin-right: 2px" size="12"><Avatar /> </el-icon>
              {{ data.Mc }}
            </div>
            <div style="display: flex; align-items: baseline" v-else>
              <el-icon style="margin-right: 2px" size="12"><Avatar /> </el-icon>
              {{ data.Mc }}
            </div>
          </template>
        </el-tree>
      </ul>
    </div>
    <div class="rBox">
      <ul class="sBox">
        <li style="flex: 1">
          <el-input
            v-model="state.params.Params.Key"
            style="width: 15%"
            placeholder="关键字"
            clearable
            @clear="
              delete state.params.Params.Key;
              onLoadTable();
            "
          ></el-input>
          <el-dropdown
            split-button
            @click="onLoadTable()"
            type="primary"
            style="margin-left: 10px"
          >
            查询
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                  重置
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li
          style="display: flex; align-items: center"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('B_BuMen_List_添加')
          "
        >
          <el-button type="primary" icon="Plus" link @click="onOpenCompile()">
            添加
          </el-button>
        </li>
      </ul>
      <ul class="tBox">
        <el-table
          :data="state.tableData"
          v-loading="!Array.isArray(state.tableData)"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          :default-sort="{
            prop: state.params.Order,
            order: state.params.Asc == false ? 'descending' : 'ascending',
          }"
          @sort-change="onSortChange"
        >
          <el-table-column
            fixed
            label="#"
            width="50"
            type="index"
            :index="
              (index) =>
                (state.params.PageIndex - 1) * state.params.PageSize + index + 1
            "
            align="center"
          />
          <el-table-column
            prop="Bm"
            sortable="BmXh"
            label="编码"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
          <el-table-column
            prop="Mc"
            sortable="Mc"
            label="名称"
            :formatter="
              (row, column, cellValue) =>
                $tab_Highlinght(cellValue, state.params.Params.Key)
            "
          />
          <el-table-column prop="Xjs" label="下级部门" align="center">
            <template #default="scope">
              <el-button
                link
                @click="
                  state.params.Params.SjId = scope.row.Id;
                  onLoadTable();
                "
              >
                {{ scope.row.XjCount }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="CjrXm" label="创建人" />
          <el-table-column prop="Cjsj" sortable="Cjsj" label="创建时间" />
          <el-table-column
            label="操作"
            width="160"
            fixed="right"
            align="center"
            className="tabBtn"
          >
            <template #default="scope">
              <el-button
                type="primary"
                :icon="Edit"
                link
                @click="onOpenCompile(scope.row.Id)"
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes('B_BuMen_List_编辑')
                "
                >编辑</el-button
              >
              <el-button
                type="danger"
                :icon="Delete"
                link
                @click="onDelete(scope.row.Id)"
                v-if="
                  $store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes('B_BuMen_List_删除')
                "
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <c_page
          :total="state.totalCount"
          :pageIndex="state.params.PageIndex"
          @onLoad="
            (pageIndex) => {
              state.params.PageIndex = pageIndex;
              onLoadTable();
            }
          "
        >
        </c_page>
      </ul>
    </div>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showCompile"
        :title="state.compileTitle + '部门'"
        @close="onCloseCompile(false)"
      >
        <c_compile
          @close="onCloseCompile(true)"
          :id="state.selectId"
        ></c_compile>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import { bumen_list, bumen_tree, bumen_delete } from "@/http/api";

import c_compile from "./Compile.vue";
export default {
  components: {
    c_compile,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          SjId: null,
        },
        Order: "BmXh",
        Asc: true,
      },
    });

    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      onLoadTree();
      onLoadTable();
    };

    //加载部门树
    const onLoadTree = () => {
      bumen_tree(state.params.Params).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });
    };

    //加载表格
    const onLoadTable = () => {
      delete state.tableData;
      bumen_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //打开内容页
    const onOpenCompile = (id) => {
      state.compileTitle = id ? "修改" : "添加";
      state.selectId = id;
      state.showCompile = true;
    };

    //关闭内容页
    const onCloseCompile = (refresh) => {
      state.showCompile = false;
      if (refresh) {
        onLoad();
      }
    };

    //树节点点击事件
    const onTreeClick = (data) => {
      delete state.params.Params.BmId;
      state.params.Params.BmBm = data.Bm;
      onLoadTable();
    };

    //树节点右击事件
    const onTreeRightClick = (event, data) => {
      event.preventDefault();
      if (data.Id) {
        state.params.Params.BmId = data.Id;
        onLoadTable();
      }
    };
    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoadTable();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {
            SjId: null,
          },
          Order: "BmXh",
          Asc: true,
        },
      });
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      proxy.$confirm_func(bumen_delete, id, onLoad);
    };

    return {
      state,
      onLoadTree,
      onLoadTable,
      onSortChange,
      onReset,
      onTreeClick,
      onTreeRightClick,
      onDelete,
      onOpenCompile,
      onCloseCompile,
    };
  },
};
</script>
<style></style>
