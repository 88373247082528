<template>
  <div
    style="width: fit-content"
    :style="{
      backgroundColor: props.model?.BgColor,
      color: props.model?.Color,
      fontSize: props.size + 'px',
    }"
  >
    {{ props.model?.Text || "-" }}
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
    },
    size: {
      type: Number,
      default: 14,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>
<style lang="less" scoped></style>
