<template>
  <div class="detailBox">
    <ul class="detailContent" style="display: flex">
      <div style="flex: 1.3">
        <el-form
          :model="state.model"
          label-width="100px"
          :scroll-to-error="true"
          ref="formDom"
          :rules="rules"
          size="small"
        >
          <el-form-item label="订单状态">
            <c_status :model="state.item.Zt"></c_status>
          </el-form-item>
          <el-form-item label="订单名称">
            {{ state.model.Mc }}
          </el-form-item>
          <el-form-item label="订单编号">
            {{ state.model.Bh }}
            <!-- {{ state.model.Bh + " | " + state.item.Lx?.Text }} -->
          </el-form-item>
          <el-form-item label="订单团期">
            {{ state.item.Ftsj + " ~ " + state.item.Htsj }}
          </el-form-item>
          <el-form-item label="借款金额">
            <c_money :value="state.model.Jkje"></c_money>
          </el-form-item>
          <el-form-item label="借款说明">
            <el-input
              type="textarea"
              :model-value="state.model.Jksm || '-'"
              :autosize="true"
              readonly
              :resize="false"
              class="sTextarea"
            />
          </el-form-item>
          <ul
            style="
              position: relative;
              border-top: 1px solid #e2e2e2;
              margin-bottom: 10px;
            "
          >
            <li
              style="
                position: absolute;
                margin-left: calc(50% - 40px);
                width: fit-content;
                padding: 0 20px;
                color: #a2a2a2;
                top: -10px;
                background-color: #fff;
              "
            >
              发放信息
            </li>
          </ul>
          <el-form-item label="发放方式">
            <c_status :model="state.item.Jkfs"></c_status>
          </el-form-item>
          <el-form-item label="收款账户" v-if="state.item.Jkfs == 1">
            {{ state.model.Skzh }}
          </el-form-item>
          <el-form-item label="借款说明">
            {{ state.model.Jksm }}
          </el-form-item>
          <ul
            style="
              position: relative;
              border-top: 1px solid #e2e2e2;
              margin-bottom: 10px;
            "
          >
            <li
              style="
                position: absolute;
                margin-left: calc(50% - 40px);
                width: fit-content;
                padding: 0 20px;
                color: #a2a2a2;
                top: -10px;
                background-color: #fff;
              "
            >
              还款信息
            </li>
          </ul>
          <ul style="display: flex; align-items: center">
            <el-form-item label="导游现收" style="flex: 1">
              {{ state.model.Dyxs }}
            </el-form-item>
            <el-form-item label="导游现付" style="flex: 1">
              {{ state.model.Dyxf }}
            </el-form-item>
            <el-form-item label="导补合计" style="flex: 1">
              {{ state.model.Dbhj }}
            </el-form-item>
            <el-form-item label="补助现发" style="flex: 1">
              {{ state.model.Bzxf }}
            </el-form-item>
            <el-form-item label="导游交款" style="flex: 1">
              {{ state.model.Dyjk }}
            </el-form-item>
          </ul>
          <el-form-item label="还款说明">
            <el-input
              type="textarea"
              :model-value="state.model.Hksm || '-'"
              :autosize="true"
              readonly
              :resize="false"
              class="sTextarea"
            />
          </el-form-item>
          <el-form-item label="收款银行">
            {{ state.item.HkzhMc + "-" + state.item.HkzhHh }}
          </el-form-item>
        </el-form>
      </div>
      <div style="flex: 1; margin-left: 20px">
        <ul style="font-size: 14px; font-weight: 600; margin-bottom: 10px">
          导游信息
        </ul>
        <div
          style="
            background-color: #f6f6f6;
            padding: 10px;
            padding-top: 0;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            color: rgba(0, 0, 0, 0.8);
            font-size: 12px;
          "
        >
          <ul
            style="
              margin-top: 10px;
              background-color: #fff;
              border-radius: 8px;
              display: flex;
              padding: 10px 20px;
            "
            v-for="item in state.dys"
            :key="item"
          >
            <div style="flex: 2; font-size: 12px">
              <ul style="font-size: 14px; font-weight: 600">
                {{
                  item.Xm
                }}
              </ul>
              <ul style="margin: 10px 0">
                {{
                  item.Sjhm
                }}
              </ul>
              <ul>
                {{
                  item.Dtksrq + " ~ " + item.Dtjsrq
                }}
              </ul>
              <ul style="margin: 10px 0">
                {{
                  item.Bz || "-"
                }}
              </ul>
            </div>
            <div
              style="
                flex: 1;
                font-size: 12px;
                display: flex;
                align-items: center;
              "
            >
              补助：<c_money :value="item.Bzje"></c_money
              ><text
                v-if="item.IsXf"
                style="color: #a2a2a2; font-size: 12px; margin-left: 10px"
                >现发</text
              >
            </div>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_detail,
  zhanghu_select,
  zidian_select,
  lvyoudingdan_qrhk,
} from "@/http/api";
import { post_response } from "@/util/tFormat";

export default {
  props: {
    id: Number,
    ddId: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {},
      item: {},
      hkModel: {},
      zhData: [],
      jkfsData: [],
    });
    onMounted(() => {
      zhanghu_select().then((res) => {
        if (res.status == 200) state.zhData = res.data;
      });

      zidian_select("借款发放方式").then((res) => {
        if (res.status == 200) {
          state.jkfsData = res.data;
          if (res.data.length > 0) state.jkModel.JkfsId = res.data[0].Id;
        }
      });

      if (props.id) {
        lvyoudingdan_detail(props.id).then((res) => {
          if (res.status == 200) {
            const { model, item, dys } = res.data;
            console.log(item);
            state.model = model;
            state.item = item;
            state.dys = dys;
          }
        });
      }
    });

    const onSubmit = () => {
      lvyoudingdan_qrhk(props.id, state.hkModel).then((res) => {
        post_response(res, emit);
      });
    };

    return { state, formDom, props, onSubmit };
  },
};
</script>
<style>
.sTextarea {
  textarea {
    box-shadow: none !important;
    padding: 0 !important;
    padding-top: 3px !important;
  }
}
</style>
