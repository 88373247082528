<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
    "
    ref="boxDom"
  >
    <div class="listBox" style="overflow-y: auto">
      <div class="infoBox">
        <div class="firstBox">
          <ul>
            <li>
              订单金额：<c_money
                :value="state.lydd.Zje"
                size="30"
                style="font-weight: 600"
              />
            </li>
            <li>
              已收金额：<c_money
                :value="state.lydd.Ysje"
                size="30"
                style="font-weight: 600; color: #080"
              />
            </li>
            <li>
              欠款金额：<c_money
                :value="state.lydd.Qkje"
                size="30"
                style="font-weight: 600; color: #ffa500"
              />
            </li>
            <li>
              采购成本：<c_money
                :value="state.lydd.Cgcb"
                size="30"
                style="font-weight: 600; color: #409eff"
              />
            </li>
            <li>
              借款金额：<c_money
                :value="state.model.Jkje"
                size="30"
                style="font-weight: 600; color: #409eff"
              />
            </li>
          </ul>
          <template v-if="state.model.Zt == 0">
            <el-button type="primary" @click="onSubmit(true)">
              审核通过
            </el-button>
            <el-button type="danger" @click="onSubmit(false)">
              审核不通过
            </el-button>
          </template>
          <c_status :model="state.item.Zt" v-else></c_status>
        </div>
      </div>
      <el-tabs
        v-model="state.tabsName"
        style="margin-top: 10px; position: inherit"
        class="comTab detailTab"
        @tab-change="onTabsChange"
      >
        <el-tab-pane label="订单信息" name="mx">
          <div class="modelBox">
            <ul>
              <li>
                <text>订单编号/状态：</text
                ><text>{{ state.lydd.Bh + "/" }}</text
                ><c_status :model="state.lydd.Zt"></c_status>
              </li>
              <li><text>订单类型：</text>{{ state.lydd?.Lx?.Text }}</li>
            </ul>
            <ul>
              <li><text>订单名称：</text>{{ state.lydd.Mc }}</li>
            </ul>
            <ul>
              <li><text>发团日期：</text>{{ state.lydd.Ftsj || "-" }}</li>
              <li>
                <text>行程天数：</text>
                {{ state.lydd.Ts + "天" + state.lydd.Ys + "晚" }}
              </li>
            </ul>
            <ul>
              <li><text>订单认数：</text>{{ state.lydd.Zrs }}</li>
              <li>
                <text>销售：</text>
                {{ state.lydd.XsyXm }}
                <text style="color: #a2a2a2">{{
                  "[" + state.lydd.XsyBmMc + "]"
                }}</text>
              </li>
            </ul>
            <ul>
              <li>
                <text>计调信息：</text> {{ state.lydd.JdyXm }}
                <text style="color: #a2a2a2">{{
                  "[" + state.lydd.JdyBmMc + "]"
                }}</text>
              </li>
              <li>
                <text>创建人：</text>
                {{ state.lydd.CjrXm }}
                <text style="color: #a2a2a2" v-if="state.lydd.CjrBmMc">{{
                  "[" + state.lydd.CjrBmMc + "]"
                }}</text
                >{{ state.lydd.Cjsj }}
              </li>
            </ul>
            <ul>
              <li><text>客户信息：</text> {{ state.lydd.KhMc }}</li>
              <li>
                <text>游客联系人：</text>
                {{ state.lydd.LxrXm + "-" + state.lydd.LxrDh }}
              </li>
            </ul>
            <ul>
              <li><text>订单备注：</text> {{ state.lydd.Bz || "-" }}</li>
            </ul>
            <el-table
              :data="state.lydd.Mxs"
              size="small"
              :summary-method="onTableSum"
              show-summary
            >
              <el-table-column
                label="#"
                type="index"
                width="50"
                align="center"
              />
              <el-table-column
                prop="Lx"
                label="类型"
                :formatter="$tab_TextFormat"
                align="center"
              />
              <el-table-column
                prop="Mc"
                label="名称"
                :formatter="$tab_TextFormat"
                align="center"
              />
              <el-table-column
                prop="Dj"
                label="单价"
                :formatter="$tab_MoneyFormat"
                align="center"
              />
              <el-table-column
                prop="Sl1"
                label="数量"
                :formatter="$tab_TextFormat"
                align="center"
              />
              <el-table-column
                prop="Zj"
                label="金额"
                :formatter="$tab_MoneyFormat"
                align="center"
              />
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="行程信息" name="xcxx" v-if="state.lydd.Id">
          <c_LvYouDingDanXcxx :id="state.lydd.Id" search></c_LvYouDingDanXcxx>
        </el-tab-pane>
        <el-tab-pane label="预定信息" name="ydxx" v-if="state.lydd.Id">
          <c_LvYouDingDanYdxx :id="state.lydd.Id" search></c_LvYouDingDanYdxx>
        </el-tab-pane>
        <el-tab-pane label="附件信息" name="fj" v-if="state.lydd.Id">
          <c_LvYouDingDanFuJian
            :id="state.lydd.Id"
            search
          ></c_LvYouDingDanFuJian>
        </el-tab-pane>
        <el-tab-pane label="名单管理" name="yk" v-if="state.lydd.Id">
          <c_LvYouDingDanYouKe :id="state.lydd.Id" search></c_LvYouDingDanYouKe>
        </el-tab-pane>
        <el-tab-pane label="电子合同" name="ht" v-if="state.lydd.Id">
          <c_LvYouDingDanHeTong
            :id="state.lydd.Id"
            search
          ></c_LvYouDingDanHeTong>
        </el-tab-pane>
        <el-tab-pane label="订单收款" name="sk" v-if="state.lydd.Id">
          <c_LvYouDingDanShouKuan :id="state.lydd.Id" search>
          </c_LvYouDingDanShouKuan>
        </el-tab-pane>
        <el-tab-pane label="采购管理" name="cg" v-if="state.lydd.Id">
          <c_LvYouDingDanCaiGou
            :id="state.lydd.Id"
            search
            :model="state.lydd"
          ></c_LvYouDingDanCaiGou>
        </el-tab-pane>
        <el-tab-pane label="导游派遣" name="dy" v-if="state.lydd.Id">
          <c_LvYouDingDanDaoYou
            :id="state.lydd.Id"
            search
          ></c_LvYouDingDanDaoYou>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="rz" v-if="state.lydd.Id">
          <c_LvYouDingDanRiZhi :id="state.lydd.Id" search></c_LvYouDingDanRiZhi>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  lvyoudingdanfatuanjilu_detail,
  lvyoudingdanfatuanjilu_check,
} from "@/http/api";
import { tab_MoneyFormat } from "@/util/tFormat";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      model: {},
      item: {},
      lydd: {},
      tabsName: "mx",
      ftModel: { Jkje: 0 }, //发团操作对象
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdanfatuanjilu_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          const { model, item, lydd } = res.data;
          state.model = model;
          console.log(1111);
          console.log(res.data);
          state.item = item;
          state.lydd = lydd;
        }
      });
    };

    const onTableSum = () => {
      let res = [];
      res[4] = "合计";

      const zj = (state.lydd.Mxs || []).reduce((x, y) => {
        return x + y.Zj;
      }, 0);
      res[5] = tab_MoneyFormat(null, null, zj);
      //   res[5] = (state.lydd.Mxs || [])
      //     .map((m) => {
      //       return m.Zj;
      //     })
      //     .reduce((x, y) => {
      //       return x + y;
      //     });

      return res;
    };

    const onSubmit = (zt) => {
      if (zt) {
        lvyoudingdanfatuanjilu_check(props.data?.id, { Shzt: true }).then(
          (res) => {
            if (res.status == 200) {
              ElMessage.success(res.data);
              onLoad();
            }
          }
        );
      } else {
        ElMessageBox.prompt("审核说明", "提示", {
          confirmButtonText: "提交",
          cancelButtonText: "取消",
          inputType: "textarea",
        }).then(({ value }) => {
          lvyoudingdanfatuanjilu_check(props.data?.id, {
            Shzt: false,
            Shbz: value,
          }).then((res) => {
            if (res.status == 200) {
              ElMessage.success(res.data);
              onLoad();
            }
          });
        });
      }
    };

    return {
      state,
      props,
      onTableSum,
      onSubmit,
    };
  },
};
</script>
<style lang="less" scoped>
.infoBox {
  .title {
    width: 80px;
  }
}

.firstBox {
  display: flex;
  align-items: center;
  padding: 20px;

  & > ul:first-child {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    font-size: 16px;
    color: #a2a2a2;

    & > li {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }
}

.modelBox {
  & > ul {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;

    & > li {
      display: flex;
      align-items: center;
      flex: 1;
      & > text:first-child {
        color: #a2a2a2;
      }
    }
  }
}
</style>
