<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 40%"
        :scroll-to-error="true"
        ref="domForm"
        :rules="rules"
      >
        <el-form-item
          label="上级字典"
          prop="SjId"
          :rules="[
            { required: true, message: '请选择上级组织', trigger: 'blur' },
          ]"
        >
          <el-tree-select
            v-model="state.model.SjId"
            :data="state.treeData"
            node-key="Id"
            clearable
            accordion
            :props="{ label: 'Mc', children: 'Xjs' }"
            :render-after-expand="false"
            :highlight-current="true"
            :default-expand-all="true"
            :check-strictly="true"
            style="width: 100%"
            placeholder="--上级组织--"
          >
            <template #default="{ data }">
              <div style="display: flex; align-items: center">
                <el-icon>
                  <Menu />
                </el-icon>
                {{ data.Mc }}
              </div>
            </template>
          </el-tree-select>
        </el-form-item>
        <el-form-item
          label="名称"
          prop="Mc"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <el-input v-model="state.model.Mc"></el-input>
        </el-form-item>
        <template v-if="props.id">
          <el-form-item label="创建人">
            <el-input v-model="state.model.CjrXm" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="state.model.Cjsj" disabled></el-input>
          </el-form-item>
        </template>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, getCurrentInstance } from "vue";
import {
  zidian_tree,
  zidian_add,
  zidian_edit,
  zidian_detail,
} from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {},
    });
    onMounted(() => {
      zidian_tree({}).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });

      if (props.id) {
        proxy.$store.commit("openLoading");
        zidian_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          } else ElMessage.error(res.data);
          proxy.$store.commit("closeLoading");
        });
      }
    });

    const onSubmit = () => {
      if (props.id) {
        zidian_edit(props.id, state.model).then((res) => {
          proxy.$post_response(res, emit);
        });
      } else {
        zidian_add(state.model).then((res) => {
          proxy.$post_response(res, emit);
        });
      }
    };

    return { state, onSubmit, props };
  },
};
</script>
<style scoped></style>
