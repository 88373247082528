<template>
  <div class="detailBox">
    <ul class="detailContent" style="display: flex">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
      >
        <el-form-item label="订单状态">
          <c_status :model="state.item.Zt"></c_status>
        </el-form-item>
        <el-form-item label="订单名称">
          {{ state.model.Mc }}
        </el-form-item>
        <el-form-item label="订单编号">
          {{ state.model.Bh + " | " + state.item.Lx?.Text }}
        </el-form-item>
        <el-form-item label="订单团期">
          {{ state.item.Ftsj + " ~ " + state.item.Htsj }}
        </el-form-item>
        <el-form-item label="借款金额">
          <c_money :value="state.model.Jkje"></c_money>
        </el-form-item>
        <el-form-item label="借款说明">
          {{ state.model.Jksm }}
        </el-form-item>
        <ul
          style="
            position: relative;
            border-top: 1px solid #e2e2e2;
            margin-bottom: 10px;
          "
        >
          <li
            style="
              position: absolute;
              margin-left: calc(50% - 40px);
              width: fit-content;
              padding: 0 20px;
              color: #a2a2a2;
              top: -10px;
              background-color: #fff;
            "
          >
            发放信息
          </li>
        </ul>
        <el-form-item label="发放方式">
          <el-radio-group v-model="state.jkModel.Jkfs" @change="onJkfsChange">
            <el-radio-button :label="1">转账</el-radio-button>
            <el-radio-button :label="2">现金</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择导游">
          <el-radio-group
            v-model="state.jkModel.JkdyId"
            style="width: 100%"
            @change="onDyChange"
          >
            <div
              style="
                background-color: #f6f6f6;
                padding: 10px;
                padding-top: 0;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                width: 100%;
                overflow: auto;
              "
            >
              <ul
                style="
                  margin-top: 10px;
                  background-color: #fff;
                  border-radius: 8px;
                  display: flex;
                  padding: 5px;
                  align-items: center;
                  font-size: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  width: 100%;
                "
                v-for="item in state.dys"
                :key="item"
              >
                <li>
                  <el-radio :label="item.Id" size="large">&nbsp;</el-radio>
                </li>
                <li
                  style="
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    width: 50px;
                  "
                >
                  {{ item.Xm }}
                </li>
                <li style="display: flex; align-items: center; width: 100px">
                  {{ item.Sjhm }}
                </li>
                <li>
                  {{ item.Dtksrq + " ~ " + item.Dtjsrq }}
                </li>
                <li style="display: flex; align-items: center; width: 130px">
                  <c_money :value="item.Bzje"></c_money>
                  <text
                    v-if="item.IsXf"
                    style="color: #a2a2a2; font-size: 12px; margin-left: 10px"
                  >
                    现发
                  </text>
                </li>
                <li style="width: 100px; overflow: hidden">
                  {{ item.Bz || "-" }}
                </li>
              </ul>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收款账户" v-if="state.jkModel.Jkfs == 1">
          <el-input v-model="state.jkModel.Skzh" />
        </el-form-item>
        <el-form-item label="借款说明">
          <el-input
            v-model="state.jkModel.Jksm"
            type="textarea"
            style="margin-bottom: 20px"
          />
        </el-form-item>
      </el-form>
    </ul>
    <ul
      class="detailBtn"
      style="justify-content: flex-end; padding-right: 10px"
    >
      <el-button type="primary" @click="onSubmit()">借款发放</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_detail,
  zhanghu_select,
  zidian_select,
  lvyoudingdan_jkff,
} from "@/http/api";
import { post_response } from "@/util/tFormat";

export default {
  props: {
    id: Number,
    ddId: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {},
      item: {},
      jkModel: { Jkfs: 1 },
      zhData: [],
      jkfsData: [],
    });
    onMounted(() => {
      zhanghu_select().then((res) => {
        if (res.status == 200) state.zhData = res.data;
      });

      zidian_select("借款发放方式").then((res) => {
        if (res.status == 200) {
          state.jkfsData = res.data;
          if (res.data.length > 0) state.jkModel.JkfsId = res.data[0].Id;
        }
      });

      if (props.id) {
        lvyoudingdan_detail(props.id).then((res) => {
          if (res.status == 200) {
            const { model, item, dys } = res.data;
            state.model = model;
            state.item = item;
            state.dys = dys;
            if (dys.length > 0) {
              state.jkModel.JkdyId = dys[0].Id;
              if (dys[0].Khh && dys[0].Hh) {
                state.jkModel.Skzh = dys[0].Khh + " " + dys[0].Hh;
              }
            }
          }
        });
      }
    });

    const onDyChange = (id) => {
      const dy = state.dys.find((w) => w.Id == id);
      if (dy.Khh && dy.Hh) {
        state.jkModel.Skzh = dy.Khh + " " + dy.Hh;
      } else {
        state.jkModel.Skzh = "";
      }
    };

    const onJkfsChange = (value) => {
      if (value == 2) delete state.jkModel.Skzh;
      else {
        const dy = state.dys.find((w) => w.Id == state.jkModel.JkdyId);
        if (dy.Khh && dy.Hh) state.jkModel.Skzh = dy.Khh + " " + dy.Hh;
      }
    };

    const onSubmit = () => {
      lvyoudingdan_jkff(props.id, state.jkModel).then((res) => {
        post_response(res, emit);
      });
    };

    return { state, formDom, props, onDyChange, onJkfsChange, onSubmit };
  },
};
</script>
<style>
.sTextarea {
  textarea {
    box-shadow: none !important;
  }
}
</style>
