import { createStore } from "vuex";

export default createStore({
  state: {
    navIndex: 0,
    navData: [{ path: "HomeMain", title: "首页" }],
  },
  mutations: {
    setLoginUser(state, data) {
      console.log(data);
      state.loginUser = data;
    },

    onNavInit(state) {
      const nav = localStorage.getItem("navData");
      if (nav) {
        state.navData = [{ path: "HomeMain", title: "首页" }, JSON.parse(nav)];
        state.navIndex = 1;
      }
    },

    //打开菜单
    openMenu(state, data) {
      let index = state.navData.findIndex(
        (w) =>
          w.path == data.path &&
          JSON.stringify(w.data) == JSON.stringify(data.data)
      );
      if (index < 0) {
        state.navData.splice(state.navIndex + 1, 0, data);
        state.navIndex = state.navIndex + 1;
      } else {
        state.navIndex = index;
        console.log(data);
        if (data.refresh) {
          state.navData[index].key = Math.random();
        }
      }

      localStorage.setItem("navData", JSON.stringify(data));
    },

    //关闭当前菜单
    onCloseMy(state, index) {
      state.navData.splice(index, 1);
      state.navIndex = state.navIndex - 1;
    },

    //关闭当前菜单
    onCloseCurrent(state) {
      state.navData.splice(state.navIndex, 1);
      state.navIndex = state.navIndex - 1;
    },

    //关闭其他菜单
    onCloseOther(state, index) {
      if (index == 0) {
        state.navData = [{ path: "HomeMain", title: "首页" }];
        state.navIndex = 0;
      } else {
        state.navData = [
          { path: "HomeMain", title: "首页" },
          state.navData[index],
        ];
        state.navIndex = 1;
      }
    },

    //刷新当前标签
    onRefresh(state, index) {
      state.navData[index].key = Math.random();
    },

    //刷新当前标签
    onRefreshCurrent(state) {
      state.navData[state.navIndex].key = Math.random();
    },
  },
  actions: {},
  modules: {},
});
