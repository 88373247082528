<template>
  <div style="width: 100%; height: 100%; overflow: hidden; display: flex">
    <ul style="flex: 2; overflow-y: auto; height: 100%">
      <div style="background-color: #fff; padding: 10px; border-radius: 5px">
        <ul style="display: flex; flex: 1; background-color: #fff">
          <div style="flex: 2">
            <el-carousel>
              <el-carousel-item v-for="item in state.model.Tps" :key="item">
                <el-image
                  :src="$http.defaults.baseURL + item"
                  style="width: 100%; height: 100%"
                  fit="contain"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            style="
              flex: 1;
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding-right: 10px;
              font-size: 13px;
            "
          >
            <ul
              style="
                border-bottom: 1px solid #e2e2e2;
                flex: 2;
                display: flex;
                align-items: center;
              "
            >
              <li style="flex: 1; font-size: 20px; cursor: pointer">
                {{ state.model.Mc }}
              </li>
            </ul>
            <ul style="flex: 1; display: flex; align-items: center">
              <li
                style="
                  color: #fff;
                  padding: 2px 5px;
                  background-color: #7ab1e7;
                  width: fit-content;
                  font-size: 13px;
                "
              >
                {{ state.model.PlMc }}
              </li>
            </ul>
            <ul style="flex: 1; display: flex; align-items: center">
              <el-icon size="16" color="#080" style="margin-right: 10px">
                <PhoneFilled />
              </el-icon>
              {{
                state.model.Dh
              }}
            </ul>
            <ul style="flex: 1; display: flex; align-items: center">
              <el-icon size="16" color="#4169E1" style="margin-right: 10px">
                <LocationFilled />
              </el-icon>
              {{
                state.model.Dz
              }}
            </ul>
            <ul style="flex: 1; display: flex; align-items: center">
              <el-icon size="16" color="#ffa500" style="margin-right: 10px">
                <Menu />
              </el-icon>
              {{
                state.model.Mdd
              }}
            </ul>
          </div>
        </ul>
        <ul>
          <el-tabs v-model="state.tableName">
            <el-tab-pane label="商品详情" name="xq">
              <div style="display: flex">
                <div
                  style="margin: auto; width: 100%; overflow-x: hidden"
                  v-html="state.model.Xq"
                ></div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="预定须知" name="xz" class="xzCss">
              <el-input
                v-model="state.model.Xz"
                type="textarea"
                :autosize="{ minRows: 3 }"
                input-style="border: 0"
                readonly
                :textareaStyle="
                  () => {
                    return { border: 0 };
                  }
                "
                resize="none"
              />
            </el-tab-pane>
          </el-tabs>
        </ul>
      </div>
      <div
        style="
          padding-top: 10px;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;
        "
        v-if="state.tableData?.length > 0"
      >
        <div
          class="product"
          v-for="item in state.tableData"
          :key="item"
          @click="
            $store.commit('openMenu', {
              path: 'ShangPinDetail',
              title: '商品详情',
              data: {
                id: item.Id,
                date: state.date,
              },
              refresh: true,
            })
          "
        >
          <li style="width: 100px; height: 100px; overflow: hidden">
            <el-image
              :src="$http.defaults.baseURL + item.Fm"
              style="width: 100px; min-height: 100px"
            >
              <template #error>
                <div
                  style="
                    width: 100%;
                    height: 100px;
                    background-color: #f6f6f6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #a2a2a2;
                  "
                >
                  暂无图片
                </div>
              </template>
            </el-image>
          </li>
          <div style="flex: 1; margin-left: 10px">
            <ul style="display: flex; align-items: center">
              <li style="flex: 1">{{ item.Mc }}</li>
              <!-- <li
                @mouseover="state.tableData[index].starActive = true"
                @mouseleave="state.tableData[index].starActive = false"
                style="color: #a2a2a2"
              >
                <el-icon size="16" class="cIco">
                  <StarFilled v-if="state.tableData[index].starActive" />
                  <Star v-else />
                </el-icon>
              </li>
              <li
                @mouseover="state.tableData[index].shopActive = true"
                @mouseleave="state.tableData[index].shopActive = false"
                style="color: #a2a2a2"
              >
                <el-icon size="16" class="cIco">
                  <ShoppingCartFull v-if="state.tableData[index].shopActive" />
                  <ShoppingCart v-else />
                </el-icon>
              </li> -->
            </ul>
            <ul>
              <li
                style="
                  color: #fff;
                  padding: 2px 5px;
                  background-color: #7ab1e7;
                  width: fit-content;
                  font-size: 10px;
                "
              >
                {{ item.PlMc }}
              </li>
            </ul>
            <ul style="font-size: 12px; margin-top: 5px">
              <span style="color: #a2a2a2; font-size: 10px">联系电话:</span>
              {{
                item.Dh
              }}
            </ul>
            <ul style="display: flex; font-size: 10px; margin-top: 5px">
              <li
                style="flex: 1; height: 30px; overflow: hidden; color: #a2a2a2"
              >
                {{ item.Jj }}
              </li>
              <li
                style="display: flex; align-items: end; margin-left: 5px"
                class="oldPrice"
              >
                <span style="font-size: 8px">￥</span
                >{{ item.Jgs[0].toFixed(2) }}
              </li>
            </ul>
            <ul
              style="
                text-align: right;
                margin-top: 5px;
                color: #f00;
                font-weight: 600;
              "
            >
              <span style="font-size: 8px">￥</span>
              {{
                item.Jgs[1].toFixed(2)
              }}
            </ul>
          </div>
        </div>
      </div>
    </ul>
    <ul class="tcs">
      <div
        style="
          border-bottom: 1px solid rgb(240, 240, 240);
          font: 600 12px/30px '';
          padding-bottom: 10px;
          padding-left: 10px;
        "
      >
        商品套餐
      </div>
      <div style="display: flex; align-items: center; margin: 10px 0">
        <el-icon size="20" color="#a2a2a2" v-if="$dayjs(state.date) <= $dayjs()"
          ><ArrowLeftBold
        /></el-icon>
        <el-icon size="20" color="#1779DE" @click="onChangeDate(-1)" v-else
          ><ArrowLeftBold
        /></el-icon>
        <div
          style="
            flex: 1;
            display: flex;
            white-space: nowrap;
            align-items: center;
            justify-content: center;
          "
        >
          <el-button
            link
            style="font-weight: 600"
            @click="onChangeDate(-1)"
            :disabled="$dayjs(state.date) <= $dayjs()"
          >
            前一天
          </el-button>
          <el-date-picker
            v-model="state.date"
            type="date"
            :clearable="false"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            style="width: 110px; margin: 0 10px"
            @change="onLoad()"
            :disabled-date="onJudgeDate"
          />
          <el-button link style="font-weight: 600" @click="onChangeDate(1)">
            后一天
          </el-button>
        </div>
        <el-icon size="20" color="#1779DE" @click="onChangeDate(1)"
          ><ArrowRightBold
        /></el-icon>
      </div>
      <div
        style="
          border-radius: 5px;
          background-color: #f6f6f6;
          flex: 1;
          padding: 10px;
          overflow-y: auto;
        "
      >
        <template v-if="state.model?.Tcs?.length">
          <div
            v-for="item in state.model.Tcs"
            :key="item"
            style="
              background-color: #fff;
              border-radius: 5px;
              margin-bottom: 10px;
              padding: 10px;
            "
          >
            <ul style="display: flex; align-items: center">
              <li style="font: 600 13px/13px ''">{{ item.Mc }}</li>
              <li
                style="
                  color: #f00;
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div class="oldPrice" style="font-size: 11px">
                  ￥{{ $priceFormat(item.Scdj) }}
                </div>
                <div
                  style="font-size: 14px; font-weight: 600; margin-left: 5px"
                >
                  <span style="font-size: 10px">￥</span
                  >{{ $priceFormat(item.Xsdj) + "/" + item.Dw }}
                </div>
              </li>
              <li>
                <span style="color: #a2a2a2">数量：</span>
                <el-input-number
                  v-model="item.Sl"
                  :min="0"
                  :max="item.Drxss == -1 ? 'Infinity' : item.Drxss"
                  size="small"
                  style="width: 100px"
                  @change="onSlChange"
                />
              </li>
            </ul>
            <ul
              style="
                color: #a2a2a2;
                font-size: 11px;
                padding-left: 10px;
                margin-top: 10px;
              "
              class="xzCss"
            >
              套餐说明：
              <el-input
                v-model="item.Sm"
                type="textarea"
                :autosize="{ minRows: 1 }"
                input-style="border: 0;color:#a2a2a2;padding:0"
                readonly
                :textareaStyle="
                  () => {
                    return { border: 0 };
                  }
                "
                resize="none"
              />
            </ul>
          </div>
        </template>
        <div
          v-else
          style="
            text-align: center;
            margin-top: 20px;
            color: #a2a2a2;
            font-size: 15px;
          "
        >
          暂无可选套餐~
        </div>
      </div>
      <div
        style="
          text-align: right;
          display: flex;
          justify-content: end;
          margin: 10px 0;
        "
      >
        已选{{ $priceFormat(state.item.Sl, 0) }}件，合计
        <li style="font-size: 16px; color: #f00">
          <span style="font-size: 12px">￥</span>
          {{ $priceFormat(state.item.Xsj) }}
        </li>
      </div>
      <div style="text-align: right">
        <el-button type="danger" @click="onConfirm">立即下单</el-button>
      </div>
    </ul>
    <el-dialog v-model="state.showConfirm" title="确认订单" center>
      <div style="font-size: 12px">
        <ul style="font: 600 11px/11px ''; margin-bottom: 10px">
          下单信息
        </ul>
        <div style="display: flex; align-items: center; padding-left: 10px">
          <li>{{ $store.state.loginUser?.Xm }}</li>
          <li style="flex: 1; text-align: center">
            {{ $store.state.loginUser?.BmMc }}
          </li>
          <li>{{ $store.state.loginUser?.Sjhm }}</li>
        </div>
        <ul style="font: 600 11px/11px ''; margin: 10px 0">
          商品信息
        </ul>
        <template
          v-for="item in state.model.Tcs?.filter((w) => w.Sl > 0)"
          :key="item"
        >
          <div
            v-if="item.Sl"
            style="
              box-shadow: 0 0 10px 1px #e2e2e2;
              margin-bottom: 10px;
              padding: 10px;
              border-radius: 5px;
            "
          >
            <ul>
              <span style="color: #a2a2a2">使用日期：</span>
              {{
                item.Syrq
              }}
            </ul>
            <ul style="display: flex; margin-top: 10px">
              <li style="width: 100px; height: 100px; overflow: hidden">
                <el-image
                  :src="$http.defaults.baseURL + state.model.Tps[0]"
                  fit="full"
                />
              </li>
              <li
                style="
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-left: 10px;
                "
              >
                <ul>
                  {{
                    state.model.Mc
                  }}
                </ul>
                <ul>
                  <li
                    style="
                      color: #fff;
                      padding: 2px 5px;
                      background-color: #7ab1e7;
                      width: fit-content;
                      font-size: 13px;
                    "
                  >
                    {{ state.model.PlMc }}
                  </li>
                </ul>
                <ul
                  style="
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 600;
                  "
                >
                  <li>{{ item.SpTcMc }}</li>
                  <li
                    style="flex: 1; text-align: center; font: 500 13px/13px ''"
                  >
                    ￥{{ $priceFormat(item.Xsdj) }}&nbsp;x{{ item.Sl }}
                  </li>
                  <li style="color: #f00; font-weight: 600">
                    <span style="font-size: 10px">￥</span
                    >{{ $priceFormat(item.Xszj) }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </template>
        <ul style="font: 600 11px/11px ''; margin: 10px 0">
          订单备注
        </ul>
        <ul>
          <el-input
            v-model="state.Bz"
            type="textarea"
            :autosize="{ minRows: 3 }"
          />
        </ul>
        <div style="margin-top: 10px; display: flex; align-items: center">
          <el-button type="info" @click="state.showConfirm = false"
            >取消</el-button
          >
          <ul
            style="
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: end;
            "
          >
            共{{
              $priceFormat(state.item.Sl, 0)
            }}件，合计：
            <li style="font: 600 15px/15px ''; color: #f00; margin-right: 10px">
              <span style="font-size: 10px">￥</span
              >{{ $priceFormat(state.item.Xsj) }}
            </li>
            <el-button color="#f00" @click="onSubmit">确认提交</el-button>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpin_xddetail, shangpindingdan_add } from "@/http/api";
import { ElMessage, ElMessageBox, dayjs } from "element-plus";
import store from "@/store";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      model: {
        Tps: [],
        Tcs: [],
      },
      item: {
        Sl: 0,
        Xsyj: 0,
        Xsj: 0,
        Jsj: 0,
      },
      tableName: "xq",
      order: {
        Sl: 0,
        Xsj: 0,
        Jsj: 0,
      },
      selectData: [],
      totalCount: 0,
    });
    onMounted(() => {
      state.date = props.data?.date;
      onLoad();
    });

    const onLoad = () => {
      delete state.model.Tcs;
      shangpin_xddetail(props.data?.id, {
        date: state.date,
        isXy: props.data?.isXy,
      }).then((res) => {
        if (res.status == 200) {
          state.model = res.data;
          state.item = {
            Sl: 0,
            Xsyj: 0,
            Xsj: 0,
            Jsj: 0,
          };
        }
      });
    };

    //前后一天
    const onChangeDate = (value) => {
      if (value > 0) {
        state.date = dayjs(state.date).add(1, "day").format("YYYY-MM-DD");
      } else if (value < 0) {
        const date = dayjs(state.date).subtract(1, "day");
        if (date >= dayjs(dayjs().format("YYYY-MM-DD 00:00:00"))) {
          state.date = date.format("YYYY-MM-DD");
        }
      }
      onLoad();
    };

    //禁用非有效期内的日期
    const onJudgeDate = (value) => {
      if (dayjs(value) < dayjs(dayjs().format("YYYY-MM-DD 00:00:00"))) {
        return true;
      } else return false;
    };

    //数量改变事件
    const onSlChange = () => {
      state.item = {
        Sl: 0,
        Xsyj: 0,
        Xsj: 0,
      };
      state.model.Tcs.forEach((tc) => {
        tc.Xszj = tc.Sl * tc.Xsdj;

        state.item.Sl += tc.Sl;
        state.item.Xsyj += tc.Sl * tc.Xsyj;
        state.item.Xsj += tc.Sl * tc.Xsdj;
      });
    };

    //计算总数和总额
    const buildTotal = () => {
      let totalCount = 0,
        totalPrice = 0;
      state.selectData.forEach((e) => {
        totalCount += e.Sl;
        totalPrice += e.Sl * e.Xsdj;
      });
      return { totalCount, totalPrice };
    };

    //直接购买事件，验证是否选择商品，打开确认购买弹出框
    const onConfirm = () => {
      if (state.item.Sl <= 0) {
        ElMessage.warning("请选择要购买的商品！");
        return false;
      }

      state.showConfirm = true;
    };

    //创建订单
    const onSubmit = () => {
      state.item.KhId = props.data?.khId;
      state.item.Syrq = dayjs(state.date).format("YYYY-MM-DD 00:00:00");
      state.item.Mxs = state.model.Tcs.filter((w) => w.Sl > 0);

      shangpindingdan_add(state.item).then((res) => {
        if (res.status == 200) {
          ElMessageBox.confirm(res.data, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
            type: "success",
          }).then(() => {
            store.commit("onCloseCurrent");
            store.commit("openMenu", {
              path: "ShangPinDingDanList",
              title: "商品订单",
              refresh: true,
            });
          });
        }
      });
    };
    return {
      state,
      onLoad,
      onChangeDate,
      onSlChange,
      onConfirm,
      onSubmit,
      buildTotal,
      onJudgeDate,
    };
  },
};
</script>
<style lang="less" scoped>
.product {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: calc(25% - 10px);
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
}

.product:hover {
  box-shadow: 0 0 10px 1px #e2e2e2;
}

.tcs {
  flex: 1;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .date {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-x: auto;
  }

  .date::-webkit-scrollbar {
    display: none;
  }

  .date > div {
    padding: 5px 10px;
  }
}

.oldPrice {
  color: #a2a2a2;
  position: relative;

  &::after {
    content: "";
    border-bottom: 1px solid #a2a2a2;
    left: 0;
    bottom: 5px;
    width: 100%;
    position: absolute;
  }
}

.dateBox {
  border-radius: 20px;
  background-color: #f6f6f6;
  color: #000;
  font-size: 12px;
  margin-right: 10px;
  white-space: nowrap;
}

.dateSelect {
  background-color: #1779de !important;
  color: #fff !important;
}

.product {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: calc(33% - 10px);
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
}

.product:hover {
  box-shadow: 0 0 10px 1px #e2e2e2;
}

.cIco {
  cursor: pointer;
}

.cIco:hover {
  color: #ffa500;
}

.xzCss {
  :deep(.el-textarea__inner) {
    box-shadow: 0 0 0 0px;
  }
  :deep(.el-textarea__inner:hover) {
    box-shadow: 0 0 0 0px;
  }
  :deep(.el-textarea__inner:focus) {
    box-shadow: 0 0 0 0px;
  }
}
</style>
