<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li
        style="display: flex; align-items: center"
        v-if="
          $store.state.loginUser?.IsAdmin ||
          $store.state.loginUser?.Qxs?.includes('B_DaoZhangGongShi_List_添加')
        "
      >
        <el-button
          type="primary"
          icon="Plus"
          link
          @click="
            delete state.selectId;
            state.showAdd = true;
          "
        >
          添加
        </el-button>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          prop="IsQy"
          sortable="IsQy"
          label="状态"
          align="center"
          width="140"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />
        <el-table-column
          prop="Zzfs"
          sortable="Zzfs"
          label="到账方式/到账银行"
          min-width="120"
          :formatter="
            (row) =>
              $tab_GroupFormat(row.Zzfs?.Text, row.Dzyh || '', row.Zzfs?.Color)
          "
        />
        <el-table-column
          prop="Fkr"
          sortable="Fkr"
          label="付款人/付款账号"
          min-width="120"
          :formatter="(row) => $tab_GroupFormat(row.Fkr || '-', row.Fkzh || '')"
        />
        <el-table-column
          prop="Fklsh"
          label="付款流水号"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          prop="Dzje"
          sortable="Dzje"
          label="到账金额/手续费(元)"
          width="170"
          :formatter="(row) => $tab_GroupFormat(row.Dzje || '-', row.Sxf || '')"
        />
        <el-table-column
          prop="KhMc"
          sortable="KhMc"
          label="认领信息"
          width="150"
          :formatter="
            (row) => $tab_GroupFormat(row.KhMc || '-', row.Rlsj || '')
          "
        />
        <el-table-column
          prop="Cjsj"
          sortable="Cjsj"
          label="创建信息"
          width="160"
          :formatter="(row) => $tab_GroupFormat(row.CjrXm, row.Cjsj)"
        />
        <el-table-column
          label="操作"
          width="240"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                state.selectId = row.Id;
                state.showDetail = true;
              "
            >
              详情
            </el-button>
            <el-button
              type="warning"
              :icon="Close"
              link
              @click="onCancel(row)"
              v-if="
                row.Zt.Key == 1 &&
                ($store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes(
                    'B_DaoZhangGongShi_List_撤回'
                  ))
              "
            >
              撤回
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(row.Id)"
              v-if="
                row.Zt.Key == 0 &&
                ($store.state.loginUser?.IsAdmin ||
                  $store.state.loginUser?.Qxs?.includes(
                    'B_DaoZhangGongShi_List_删除'
                  ))
              "
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>

    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showAdd"
        title="添加到账公示"
        @close="state.showAdd = false"
      >
        <c_DaoZhangGongShiAdd
          @close="
            state.showAdd = false;
            onLoad();
          "
        ></c_DaoZhangGongShiAdd>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showDetail"
        title="到账公示详情"
        @close="state.showDetail = false"
      >
        <c_DaoZhangGongShiDetail
          @close="state.showDetail = false"
          :id="state.selectId"
        ></c_DaoZhangGongShiDetail>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  daozhanggongshi_list,
  daozhanggongshi_delete,
  daozhanggongshi_refund,
} from "@/http/api";
import { confirm_func, priceFormat } from "@/util/tFormat";
import { ElMessageBox, ElMessage } from "element-plus";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: { TableName: "DaoZhangGongShi_List" },
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  setup() {
    const state = reactive(new InitFormData());
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      daozhanggongshi_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      if (column.order) {
        state.params.Order = column.prop;
        state.params.Asc = column.order === "ascending";
      } else {
        state.params.Order = "Cjsj";
        state.params.Asc = false;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    //删除
    const onDelete = (id) => {
      confirm_func(daozhanggongshi_delete, id, onLoad);
    };

    const onCancel = (row) => {
      ElMessageBox.prompt(
        `撤回该到账公示将从对应的客户<strong style='color:#1e90ff'>${
          row.KhMc
        }</strong>账户余额里扣除<strong style='color:#f00'>${priceFormat(
          row.Dzje - row.Sxf
        )}元</strong>，您确定撤回吗？`,
        "到账公式撤回",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确定撤回",
          cancelButtonText: "取消",
          inputType: "textarea",
          inputValidator: (value) => {
            if (!value) return false;
          },
          inputErrorMessage: "请输入撤回说明",
        }
      ).then(({ value }) => {
        daozhanggongshi_refund(row.Id, value).then((res) => {
          if (res.status == 200) {
            ElMessage.success(
              `撤回成功！已从客户${row.KhMc}账户余额中成功撤回${priceFormat(
                row.Dzje - row.Sxf
              )}元！`
            );
          }
        });
      });

      // confirm_func(
      //   daozhanggongshi_refund,
      //   row.Id,
      //   onLoad,
      //   `撤回该到账公示将从对应的客户<strong style='color:#1e90ff'>${
      //     row.KhMc
      //   }</strong>账户余额里扣除<strong style='color:#f00'>${priceFormat(
      //     row.Dzje - row.Sxf
      //   )}元</strong>，您确定撤回吗？`
      // );
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onDelete,
      onCancel,
      daozhanggongshi_refund,
    };
  },
};
</script>
<style lang="less" scoped></style>
