<template>
  <div class="detailBox">
    <el-form
      :model="state.model"
      label-width="100px"
      style="width: 40%; margin-top: 10px"
      :scroll-to-error="true"
      ref="formDom"
    >
      <el-form-item
        label="帐号"
        prop="Account"
        :rules="[{ required: true, message: '请输入帐号', trigger: 'blur' }]"
      >
        <el-input v-model="state.model.Account" />
      </el-form-item>
      <el-form-item
        label="姓名"
        prop="Name"
        :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
      >
        <el-input v-model="state.model.Name" />
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="state.model.Phone" />
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="state.model.Sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 80px" type="primary" @click="onSubmit()">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { home_getuser, home_setuser } from "@/http/api";
import store from "@/store";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const formDom = ref(null);
    const state = reactive({
      model: {},
    });

    onMounted(() => {
      store.commit("openLoading");
      home_getuser().then((res) => {
        if (res.status == 200) state.model = res.data;
        store.commit("closeLoading");
      });
    });

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (!valid) return false;

        home_setuser(state.model).then((res) => {
          proxy.$post_response(res);
        });
      });
    };
    return { state, onSubmit, formDom };
  },
};
</script>
<style scoped></style>
