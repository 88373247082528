<!-- <template>
  <div class="login_bg">
    <el-image
      style="flex: 1.5"
      :src="require('@/assets/images/login.jpg')"
      fit="cover"
    ></el-image>
    <ul
      style="
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      "
    >
      <li
        style="
          background: #fff;
          width: 70%;
          margin-top: 100px;
          border-radius: 10px;
        "
      >
        <ul
          style="
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #2f2f2f;
          "
        >
          三河文旅
          <br />
          <text> 管理平台</text>
        </ul>
        <ul style="margin-top: 100px">
          <el-input
            v-model="state.Account"
            placeholder="帐号"
            prefix-icon="user"
            size="large"
            style="height: 45px; font-size: 14px"
          />
        </ul>
        <ul style="margin-top: 30px">
          <el-input
            v-model="state.Password"
            type="password"
            prefix-icon="lock"
            placeholder="密码"
            size="large"
            style="height: 45px; font-size: 14px"
          />
        </ul>
        <ul style="" class="agreement">
          登录即表示同意《<span>用户协议</span>》和《<span>客户隐私</span>》
        </ul>
        <el-button
          style="width: 100%; margin-top: 80px; height: 50px; font-size: 20px"
          type="primary"
          @click="onSubmit"
          size="large"
          >登录</el-button
        >
        <ul
          style="
            width: 100%;
            text-align: center;
            padding: 40px 0;
            font-size: 13px;
          "
        >
        </ul>
      </li>
    </ul>
  </div>
</template> -->

<template>
  <div class="login_bg">
    <el-image
      style="width: 62.5%"
      :src="require('@/assets/images/login.jpg')"
      fit="cover"
    ></el-image>
    <div style="flex: 1; display: flex; flex-direction: column">
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
          "
        >
          <el-image
            :src="require('@/assets/images/log.png')"
            fit="contain"
            style="width: 26.5%"
          ></el-image>
          <ul
            style="
              font-size: 23px;
              font-weight: 550;
              text-align: center;
              width: 100%;
              margin-top: 30px;
            "
          >
            肥西文旅集团旅游ERP综合管理平台
          </ul>
          <el-input
            v-model="state.Account"
            size="large"
            style="width: 60%; margin-top: 100px"
          >
            <template #prefix><text>账号：</text></template>
          </el-input>
          <el-input
            v-model="state.Password"
            type="password"
            size="large"
            style="width: 60%; margin-top: 30px"
          >
            <template #prefix><text>密码：</text></template>
          </el-input>
          <el-button
            style="width: 60%; margin-top: 30px; height: 50px; font-size: 18px"
            type="primary"
            @click="onSubmit"
            size="large"
            >登&nbsp;&nbsp;&nbsp;&nbsp;录</el-button
          >
        </div>
      </div>
      <div
        style="
          text-align: center;
          padding-bottom: 50px;
          color: #a2a2a2;
          font-size: 12px;
        "
      >
        © 2021 xyoa.com, Inc. 保留所有权利 皖ICP备11012359号-4
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, onUnmounted } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { judgeLogin } from "@/http/api";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({});
    onMounted(() => {
      //绑定监听事件
      window.addEventListener("keydown", keyDown);
    });

    onUnmounted(() => {
      //销毁事件
      window.removeEventListener("keydown", keyDown, false);
    });

    //点击回车键登录
    const keyDown = (e) => {
      if (e.keyCode == 13) {
        onSubmit();
      }
    };

    const onSubmit = () => {
      if (!state.Account) {
        ElMessage({
          message: "请输入帐号",
          type: "warning",
        });
        return;
      }

      if (!state.Password) {
        ElMessage({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      judgeLogin(state.Account, state.Password).then((res) => {
        if (res.status == 200) {
          localStorage.setItem("token", JSON.stringify(res.data));
          localStorage.removeItem("navData");
          router.push("/");
        }
      });
    };
    return { state, onSubmit, ElMessage };
  },
};
</script>
<style scoped>
.login_bg {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.agreement {
  margin-top: 10px;
  color: #888;
  font-size: 13px;
}

.agreement span {
  cursor: pointer;
}

.btn > button {
  margin-top: 40px;
  outline: none;
  height: 50px;
  background: #217ede;
  border: none;
  font-size: 16px;
  color: white;
  border-radius: 4px;
}

.loginTip {
  text-align: center;
  background: #86b9ef;
  color: white;
  padding: 5px 0px;
  opacity: 0.5;
}

.r_atta {
  margin-top: 30px;
  text-align: center;
}

.r_atta > :deep(div) {
  position: relative;
  padding: 0px 18px;
  display: inline-block;
}

.r_atta > :deep(div):not(:last-child)::after {
  content: " ";
  position: absolute;
  height: 18px;
  width: 1px;
  background: #ccc;
  right: 0px;
  top: 2px;
}
.wechat {
  color: #0fbb44;
}
</style>
